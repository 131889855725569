<div id="main-profile">
  <div class="main-page-container">
    <div class="container" #myDiv>
      <div class="invoice">
        <div class="row">
          <div class="col-md-6 col-sm-12 col-xs-12">
            <div style="display: block;clear:both;" class="mb-2">
              <img width="200" style="display: block;" src="assets/Background/dark-recruitlee-logo.svg" alt="recruitlee" />
            </div>

            <div class="offices">
              <ul class="office-details">
                <li class="search">
                  Recruitlee (Pty) Ltd 
                </li>
                <li class="search">
                  2019 / 253848 / 07
                </li>
                <li>
                  <img src="../../assets/Background/gps_PNG8.png" alt="" /> 
                  16 Solstice Road, Umhlanga, Durban, 4320
                </li>
                <li>
                  <img src="../../assets/Background/office-phone.png" alt="" />
                  +27 81 523 9870
                </li>
                <li>
                  <img src="../../assets/Background/Message-Email.png" alt="" />
                  <a href="#">info@searchadam.com </a>
                </li>
                <li>
                  <img src="../../assets/Background/world.png" alt="" />
                  <a href="#">www.searchadam.com</a>
                </li>
              </ul>
              <div class=" office-text" [ngClass]="{'safari-display': Ismedia == true}">
                <div class="col-md-6 col-lg-5 float-data p-0">
                  <label for="">Date :</label>
                </div>
                <div class="col-md-6 col-lg-7 float-data">
                  {{ myDate | date: 'dd/MM/yyyy'}}
                </div>
              </div>
              <div class=" office-text" [ngClass]="{'safari-display': Ismedia == true}">
                <div class="col-md-6 col-lg-5 float-data p-0">
                  <label for=""> Invoice Number :</label>
                </div>
                <div class="col-md-6 col-lg-7 float-data">SA00{{ CurrentUser.ID }}</div>
              </div>
              <div class=" office-text" [ngClass]="{'safari-display': Ismedia == true}">
                <div class="col-md-6 col-lg-5 float-data p-0">
                  <label for=""> Account Number :</label>
                </div>
                <div class="col-md-6 col-lg-7 float-data">Acc001{{ CurrentUser.ID }}</div>
              </div>
            </div>
            <!-- offices -->
          </div>
          <!--col-md-6-->

          <div class="col-md-6 col-sm-12 col-xs-12">

            <div class="office-text mob-pl-56" [ngClass]="{'safari-display': Ismedia == true}">
              <div class="float-data">
                <label for="">Company Name :</label>
              </div>
              <div class="">
                {{ CurrentUser.CompanyName }}
              </div>
            </div>
            <div class=" office-text mob-pl-56" [ngClass]="{'safari-display': Ismedia == true}">
              <div class="float-data">
                <label for=""> Company Registration:</label>
              </div>
              <div class="">
                {{ CurrentUser.CompanyInvoiceName }}
              </div>
            </div>
          </div>
          <!--col-md-6-->
        </div>
        <!--row-->
      </div>
      <!--invoice-->

      <div class="info-table">
        <div class="container">
          <div class="row" [ngClass]="{'safari-display': Ismedia == true}">
            <div class="col-md-12">
              <table>
                <tr class="text-center">
                  <th class="">ITEM</th>
                  <th>DESCRIPTION</th>
                  <th class="">PRICE</th>
                </tr>
                <tr class="">
                  <td class="text-center th-height">1</td>
                  <td class="th-height ml-height">Candidate Contact Details</td>
                  <td class="text-center" *ngIf="CurrentUser.SelectedCandidate"> 
                    {{ CurrentUser.SelectedCandidate.length }}
                  </td>
                </tr>
                <tr class="">
                  <td class="text-center th-height"></td>
                  <td class="th-height"></td>
                  <td class="text-center"></td>
                </tr>
                <tr class="">
                  <td class="text-center th-height"></td>
                  <td class="th-height"></td>
                  <td class="text-center"></td>
                </tr>
                <tr class="">
                  <td class="text-center th-height"></td>
                  <td class="th-height"></td>
                  <td class="text-center"></td>
                </tr>
                <tr class="">
                  <td class="text-center th-height"></td>
                  <div class="row " [ngClass]="{'safari-display': Ismedia == true}">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                      <td
                        class="text-center total399
                                                w-total"
                      >
                        Total
                      </td>
                    </div>
                  </div>

                  <td class="text-center total399">
                    R{{ CurrentUser.SubscriptionPlan }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--info-table-->

      <div class="invoice">
        <div class="row " [ngClass]="{'safari-display': Ismedia == true}">
          <div class="col-md-12">
            <div class="offices mob-w-80-p">
              <div class="row  office-text" [ngClass]="{'safari-display': Ismedia == true}">
                <div class="round-bor">
                  <div class="col-md-12">
                    <label for="" class="banking"> Banking Details</label>
                  </div>
                  <div class=" office-text" [ngClass]="{'safari-display': Ismedia == true}">
                    <div class="float-bank-header">
                      <label for=""> Bank:</label>
                    </div>
                    <div class="float-bank-label">
                      FNB
                    </div>
                  </div>
                  <div class=" office-text" [ngClass]="{'safari-display': Ismedia == true}">
                    <div class="float-bank-header">
                      <label for=""> Account Name:</label>
                    </div>
                    <div class="float-bank-label">
                      Recruitlee (Pty) Ltd
                    </div>
                  </div>
                  <div class=" office-text" [ngClass]="{'safari-display': Ismedia == true}">
                    <div class="float-bank-header">
                      <label for=""> Account Number:</label>
                    </div>
                    <div class="float-bank-label">62815753109</div>
                  </div>
                  <div class=" office-text" [ngClass]="{'safari-display': Ismedia == true}">
                    <div class="float-bank-header">
                      <label for=""> Account Type: </label>
                    </div>
                    <div class="float-bank-label">
                      Cheque
                    </div>
                  </div>
                  <div class=" office-text" [ngClass]="{'safari-display': Ismedia == true}">
                    <div class="float-bank-header">
                      <label for=""> Branch Code: </label>
                    </div>
                    <div class="float-bank-label">
                      250655
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- offices -->
          </div>
          <!--col-md-8-->

          <!--col-md-4-->
        </div>
        <div class="col-md-12 text-center">
          <div class="thanks">
            <h1 class="text-center">Thank you for using Recruitlee!</h1>
          </div>
          <!--thanks-->
        </div>
        <!--row-->
      </div>
      <!--container-->
    </div>
    <!--invoice-->
  </div>
  <!--content-box-lg-->
</div>
<!--main-profile-->
