import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HeaderModule } from '../../components/toolbar/aurthentication-toolbar/header.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({

    declarations: [ForgotPasswordComponent],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: ForgotPasswordComponent, pathMatch: 'full' }
        ]),
        HeaderModule,
        CommonModule,
        ReactiveFormsModule
    ],
    entryComponents: [],
    exports: [
        ForgotPasswordComponent

    ]
})

export class ForgotPasswordModule {

}
