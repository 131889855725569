import { CadidateListFilterParameterModel } from "../Authentication/searchmember/search.class";

export class GetFilteredFavouriteCandidateList extends CadidateListFilterParameterModel {
    JobId?: string | any = null;
    // RecruiterID?:string |any =null;
    public constructor(init?: Partial<GetFilteredFavouriteCandidateList>) {
        super();
        Object.assign(this, init);
    }
}

