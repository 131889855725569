<div id="candidate-profile" class="main-page">
	<div class="container">
			<div class="mycandidate">
				My Candidates
			</div>
			<div class="search-btn d-flex justify-content-center mt-5">
				<button class="search-btn1 btn-grad-border">
					search
				</button>
			</div>
	</div>

	<div class="main-page-container">
	<div class="row heading-container justify-content-center text-center">
    <h2 class="heading w-75">
        View and contact the candidates you have chosen. You can pick a maximum of 10 candidates to contact at one time.
    </h2>
</div>
		<div class="col-md-12">
			<div class="col-md-4 searchbar mb-2">
					<div class="">
						<div>
							<label class="label-text mb-4" href="#popup1"
								style="padding-left: 0px;">Registered
								Job:*</label>
						</div>
						<div class="mb-4">
							<select class="drop">
								<option value="Job Title">Job
									Title</option>
								<option value="Job Title">Job
									Title</option>
								<option value="Job Title">Job
									Title</option>
								<option value="Job Title">Job
									Title</option>
							</select>
						</div>
					</div>
					<div class="text-center">
						<button type="button" class="btn blue-button" (click)="ACTIVEMODEL=
												'Register'">Register
							a Job</button>
					</div>
			</div>
			<div class="col-md-8 candidate-detail-mobile">
					<div class="search-inner mb-4">
						<div class="row">
							<div class="col-md-8 no-border w-100">
								<h5 class="">4 candidates selected of 10</h5>
							</div>
							<div class="col-md-4 d-flex justify-content-center">
								<button class="btn blue-button"
									(click)="paymentMODEL='payment'"
									data-toggle="modal"
									data-target="#paymentmodal">
									Proceed
								</button>
							</div>
						</div>
					</div>
				<div class="search-proceed mb-4">
							<div class="search-inner">
								<input type="search" class="serach-m"
									placeholder="Search Candidates" name="q">
								<img class="serach-icon"
									src="assets/Background/Vector.png"
									alt="">
							</div>
				</div>
					<div class="">
							<form action="">
									<div class="">
											<div class="container p-0">
													<div class="text-center">
															<h2 class="view1">
																	View and contact the candidates you have
																	chosen. You can pick a maximum of 10
																	candidates to contact at one time.
															</h2>
													</div>
													<div class="marauto">
															<div class="row">
																	<div class="col-xs-12">
																			<div class="name1 no-border w-100">
																					<h5 class="mb-4">2 candidates selected of 10</h5>
																					<button class="btn blue-button w-50">
																							Proceed
																					</button>
																			</div>
																	</div>
															</div>
													</div>
											</div>										
												<div class="name candidate-detail-mobile">
													<div class="">
														<div class="hide-seek">
															<div class="col-md-3">
																<div class="infoimage text-center">
																	<div class="otherinfo">
																		<img src="assets/Background/Ellipse.png" class="imageeee
																																																				name-img">
																	</div>
												
																</div>
																<!-- <img class="name-img"
																																																src="assets/Background/name.png"
																																																alt=""> -->
															</div>
															<div class="col-md-6">
																<div class="row">
												
																	<div class="col-md-9">
																		<div class="first-name">
																			<label for="" class="firlast" [routerLink]="'/candidate-profile'">First
																				Name
																				&
																				Last Name</label>
																			<hr class="border-color">
																			<p class="location">Location</p>
																			<p class="message"><em>
																					“Message to the
																					employer placed
																					here.
																					Maximum of 200
																					characters</em>
																			</p>
																		</div>
																	</div>
																	<div class="col-md-3
																																																		pl-candidate">
																		<div class="dtar
																																																				">
																			<div class="
																																																						paddi-in
																																	text-center">
																				<small>Unselect
																					Candidate:</small>
																				<br>
																				<!-- <img
																																																								src="../../assets/Background/Groupright.png"
																																																								alt=""> -->
																				<div class="form-group">
																					<input type="checkbox" id="box-2">
																					<label for="box-2"></label>
																				</div>
																			</div>
																			<div class="paddi-in
																																																						text-center">
																				<small>Favorite
																					Candidate:</small>
																				<br>
																				<img src="assets/Background/Vectorr.png" alt="">
																			</div>
																		</div>
												
																	</div>
												
																</div>
																<!----row-->
												
															</div>
															<div class="col-md-3">
																<div class="added new-added-flex">
																	<!-- <img src="../../assets/Background/added.png"
																																																																							alt=""> -->
																	<div class="h1hide text-center">
																		<h1 class="press m-0">Press here to add to contact
																			list.
																		</h1>
												
																	</div>
																	<div class="form-group-circle">
																		<input type="checkbox" id="box-3">
																		<label for="box-3"></label>
																	</div>
																	<h3 class="text-center">
																		Added!
																	</h3>
																</div>
																<!----addeds-->
												
															</div>
														</div>
														<!--hide-seek-->
														<!----col-md-8-->
														<div class="show-star col-xs-12 p-0">
															<div class="imaeg-show">															
																		<div class="col-xs-4																																																			text-center">
																			<div class="infoimage																																																				text-center">
																				<div class="otherinfo">
																					<img src="assets/Background/Ellipse.png" class="imageeee																																																							name-img">
																				</div>						
																			</div>
																		</div>
																		<div class="col-xs-8">
																			<div class="first-name">
																				<label for="" class="firlast" [routerLink]="'/candidate-profile'">First
																					Name
																					&
																					Last Name</label>
																				<hr class="border-color">
																				<p class="location">Location</p>
																				<p class="message"><em>
																						“Message to
																						the
																						employer
																						placed
																						here.
																						Maximum of
																						200
																						characters</em>
																				</p>
																			</div>
																		</div>														
															</div>
															<div class="col-xs-12 col-md-12">
																<div class="col-xs-4 b-top																																				text-center">
																	<div class="mt-favorite">
																		<div class="																																																		pl-favorite">
																			<small>Favorite
																				Candidate:</small>
																		</div>
																		<div class="col-xs-6">
																			<img class="cen-img" src="assets/Background/Vectorr.png" alt="">
																		</div>			
																	</div>											
																</div>
																<div class="col-xs-4 b-left
																																																		b-top
																																																		text-center">
																	<div class="mt-favorite">
																		<div class="
																																																						pl-favorite">
																			<small>Unselect
																				Candidate:</small>
																		</div>
																		<div class="col-xs-6">
																			<img class="cen-img" src="assets/Background/Groupright.png" alt="">
																		</div>
												
																	</div>
																</div>
																<div class="col-xs-4 b-left
																																																		b-top">
																	<div class="star1e
																																																				btn-grad-filled6">
																		Added!
																	</div>
																</div>
															</div>
														</div>										
													</div>
													<!----row-->							
												</div>
									</div>
							</form>
	

					</div>
			</div>
	</div>
</div>





	<!-- register popup -->
	<app-register-job [ACTIVEMODEL]="ACTIVEMODEL"
		(hideModel)="hidenModel($event)"></app-register-job>

	<!-- proceed payment -->
	<div class="modal" [ngClass]="{modalShow: paymentMODEL == 'payment',
		modalHide: paymentMODEL !== 'payment'}" id="paymentmodal" tabindex="-1"
		role="dialog" aria-labelledby="paymentmodal" aria-hidden="true">
		<div class="modal-dialog model-xl modal-dialog-centered" role="document">
			<div class="modal-content btn1-grad-border">

				<button type="button" (click)="paymentMODEL= ''"
					class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true" style="float: right;
						width: 29px;
						font-size: 26px;outline: none;">×</span>
				</button>


				<div class="modal-body">
					<div class="mt-to-bt popup">
						<div class="inner-text">
							<h2 class="text-center">Payment Summary</h2>
							<hr class="border113">
						</div>
						<div class="summary">
							<p class="text-center">To retrieve the contact
								details of your chosen candidates,
								you will be redirected to complete payment of
								R399</p>
						</div>
						<div class="row content">
							<div class="col-md-12">
								<label for="" class="take1">Method of payment</label>
							</div>
							<div class="col-md-12">
								<select class="option option-job">
									<option value="JobTitle">Job Title</option>
									<option value="JobTitle">JobTitle</option>
									<option value="JobTitle">JobTitle</option>
									<option value="JobTitle">JobTitle</option>
								</select>


								<div _ngcontent-c0="" class="text-center">
									<button _ngcontent-c0=""
										class="btn blue-button w-50"> Proceed </button>
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>
		</div>
	</div>
</div>