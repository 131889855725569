<section class="search-member-page">
    <div class="search-page-container">
        <div class="row heading-container justify-content-center">
            <h1 class="heading">Search through our database for your perfect
                candidate
            </h1>
        </div>
        <div>
            <div class="col-md-12">
                <div class="col-md-4">
                    <div class="searchbar-container">
                        <div class="">
                            <div class="">
                                <label class="label-text mb-3" href="#popup1">Registered
                                    Job:<sup class="required">*</sup></label>
                                <div class="mb-4">
                                    <ng-select [(ngModel)]="jobId" [placeholder]="'Select Job Title'">
                                        <ng-option *ngFor="let job of RecruiterJobList" [value]="job.ID">
                                            {{ job.Title?.DisplayValue }} - {{ job.RefNo }}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="button" class="btn blue-button" (click)="ACTIVEMODEL='Register'">
                                    Register Job
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="dropdown-container mb-4">
                        <div class="section">
                            <h1 class="section-title">Personal Information</h1>
                            <div class="row mb-3">
                                <div class="col-md-3">
                                    <!-- Age From -->
                                    <label class="label-text mb-3">Age:</label>
                                    <ng-select (change)="checkAgeFrom()" [(ngModel)]="paramterFilter.AgeFrom"
                                        [placeholder]="'From'" [searchable]="true">
                                        <ng-option *ngFor="let age of ageList"
                                            [value]="age.ID">{{age.SystemValue}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-3">
                                    <!-- Age To -->
                                    <label class="label-text mb-3 opacity-0">Age:</label>
                                    <ng-select [(ngModel)]="paramterFilter.AgeTo" [placeholder]="'To'"
                                        [searchable]="true">
                                        <ng-option *ngFor="let age of ageList"
                                            [value]="age.ID">{{age.SystemValue}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-3">
                                    <!-- Gender -->
                                    <label class="label-text mb-3">Gender:</label>
                                    <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.Gender"
                                        [searchable]="false" [placeholder]="'Gender'">
                                        <ng-option *ngFor="let gender of GenderList" [value]="gender.ID">{{
                                            gender.SystemValue
                                            }}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-3">
                                    <!-- Race -->
                                    <label class="label-text mb-3">Race:</label>
                                    <ng-select [(ngModel)]="paramterFilter.Race" [placeholder]="'Race'"
                                        [searchable]="true">
                                        <ng-option *ngFor="let race of RaceList"
                                            [value]="race.ID">{{race.SystemValue}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <!-- Relationship -->
                                    <label class="label-text mb-3">Relationship:</label>
                                    <ng-select [(ngModel)]="paramterFilter.Relationship" [searchable]="true"
                                        placeholder="Relationship">
                                        <ng-option *ngFor="let relationship of RelationShipList"
                                            [value]="relationship.ID">{{relationship.SystemValue}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-3">
                                    <!-- Language -->
                                    <label class="label-text mb-3">Language:</label>
                                    <ng-select [(ngModel)]="paramterFilter.Language" [searchable]="true"
                                        placeholder="Language">
                                        <ng-option *ngFor="let language of LanguageList"
                                            [value]="language.ID">{{language.SystemValue}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-3">
                                    <!-- Citizenship -->
                                    <label class="label-text mb-3">Citizenship:</label>
                                    <ng-select [(ngModel)]="paramterFilter.Citizan" [searchable]="true"
                                        placeholder="Citizenship" class="custom-dropdown">
                                        <ng-option *ngFor="let citizionship of CitizionshipList"
                                            [value]="citizionship.ID">{{citizionship.DisplayValue}}
                                            <span class="tooltip-text">{{ citizionship.DisplayValue }}</span></ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="section">
                            <h1 class="section-title">Educational Information</h1>
                            <div class="row">
                                <div class="col-md-4">
                                    <!-- Qualification -->
                                    <label class="label-text mb-3">Qualification:</label>
                                    <ng-select [(ngModel)]="paramterFilter.mainQualification"
                                        (change)="getMinQualification()" [placeholder]="'Qualification'">
                                        <ng-option *ngFor="let qualification of qualificationList"
                                            [value]="qualification.ID">{{
                                            qualification.DisplayValue }}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-4">
                                    <!-- Minimum Qualification -->
                                    <label class="label-text mb-3">Minimum Qualification:</label>
                                    <ng-select class="drop3" (change)="setValueforMinCriteria($event)"
                                        [placeholder]="'Minimum Qualification'" [(ngModel)]="paramterFilter.minimumQualification">
                                        <ng-option *ngFor="let qualification of minQualification"
                                            [value]="qualification.ID">{{
                                            qualification.DisplayValue }}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-4" *ngIf="fieldofstudy==true">
                                    <!-- Field of Study -->
                                    <label class="label-text mb-3">Field of Study:</label>
                                    <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.Field" [placeholder]="'Field of Study'"
                                        class="custom-dropdown">
                                        <ng-option *ngFor="let field of fieldOfStudyList" [value]="field.ID">{{
                                            field.DisplayValue
                                            }}
                                            <span class="tooltip-text">{{ field.DisplayValue }}</span></ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="section">
                            <h1 class="section-title">Professional Information</h1>
                            <div class="row">
                                <div class="col-md-3">
                                    <!-- Job Title -->
                                    <label class="label-text mb-3">Job Title:</label>
                                    <ng-select [(ngModel)]="paramterFilter.JobTitle" [placeholder]="'Job Title'" class="custom-dropdown">
                                        <ng-option *ngFor="let job of JobList" [value]="job.ID">
                                            {{ job.SystemValue }}
                                            <span class="tooltip-text">{{ job.SystemValue }}</span>
                                          </ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-3">
                                    <!-- Industry -->
                                    <label class="label-text mb-3">Industry:</label>
                                    <ng-select [(ngModel)]="paramterFilter.Industry" [placeholder]="'Industry'" class="custom-dropdown">      
                                        <ng-option *ngFor="let industry of IndustryList" [value]="industry.ID">
                                            {{ industry.SystemValue }}
                                            <span class="tooltip-text">{{ industry.SystemValue }}</span>
                                        </ng-option>                
                                    </ng-select>     
                                </div>
                                <div class="col-md-3">
                                    <!-- Years of Experience -->
                                    <label class="label-text mb-3">Experience:</label>
                                    <ng-select [(ngModel)]="paramterFilter.YearOfExp" [placeholder]="'Year'" class="custom-dropdown">
                                        <ng-option *ngFor="let year of yearsOfExp" [value]="year.ID">{{ year.SystemValue
                                            }}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-3">
                                    <!-- Level -->
                                    <label class="label-text mb-3">Level:</label>
                                    <ng-select [(ngModel)]="paramterFilter.Level" [placeholder]="'Select Level'" class="custom-dropdown">
                                        <ng-option *ngFor="let level of LevelList" [value]="level.ID">{{
                                            level.SystemValue
                                            }}</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right button-container">
                <button type="button" class="btn blue-bottom-button mr-2" (click)="GetCandidateList()">Search
                    Members</button> <span class="mob-d-none">&nbsp;&nbsp;</span>
                <button type="button" class="btn blue-bottom-button" (click)="ClearFilter()">
                    Clear Filter
                </button>
            </div>
        </div>
        <!-- button layout -->
    </div>
    <app-register-job [ACTIVEMODEL]="ACTIVEMODEL" (hideModel)="hidenModel($event)"></app-register-job>
</section>