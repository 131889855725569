import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';


import { from } from 'rxjs';
import { InvoiceComponent } from './invoice.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenubarModule } from '../components/menubaar/aurthentication/menubar.module';
import { SteperModule } from '../components/steps/steps.module';
import { CommonModule } from '@angular/common';
// import { HeaderComponent } from '../../components/toolbar/aurthentication-toolbar/header.component';



@NgModule({

    declarations: [InvoiceComponent],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: InvoiceComponent, pathMatch: 'full' }
        ]),
        MenubarModule,
        SteperModule,
        CommonModule 
    ],
    entryComponents: [],
    exports: [
        InvoiceComponent
    ]
})

export class InvoiceModule {
a
}
