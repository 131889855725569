import { Injectable } from "@angular/core";
import { PageModel, PageScreen, SeoDetailModel, SeoHelp } from "./core.class";
import * as _ from "lodash";
import { Meta, Title } from "@angular/platform-browser";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class CoreService {

    private loggedIn = new BehaviorSubject<boolean>(
        this.getInitialLoggedInState()
    );
    constructor(
        private meta: Meta,
        private titleService: Title) {
    }

    pageChangeHelper = (pageData: PageModel, screenE: PageScreen) => {
        let dataToStore: PageModel[] = [];
        if (!!localStorage.getItem("PageInfo")) {
            let val = JSON.parse(localStorage.getItem("PageInfo"));
            if (!!val && val !== "null") {
                dataToStore = <PageModel[]>JSON.parse(localStorage.getItem("PageInfo"));
                if (dataToStore.length >= 1) {
                    let index = dataToStore.findIndex(a => a.screenName == screenE);
                    if (index != -1) {
                        dataToStore.splice(index, 1);
                    }
                    dataToStore.push(pageData);
                }
            } else {
                dataToStore.push(pageData);
            }
        } else {
            dataToStore.push(pageData);
        }

        if (dataToStore.length >= 1)
            this.setDataIntoLocal("PageInfo", JSON.stringify(dataToStore));
    }

    setDataIntoLocal = (key, data: string) => {
        localStorage.setItem(key, data);
    }
    getScreenP = (screen: PageScreen) => {
        if (!!localStorage.getItem("PageInfo")) {
            let val = JSON.parse(localStorage.getItem("PageInfo"));
            if (!!val && val !== "null") {
                let data = <PageModel[]>JSON.parse(localStorage.getItem("PageInfo"));
                // return data.find(p => { return p.screenName == screen });
                let d = data.find(p => { return p.screenName == screen });
                if (!!d) {
                    return d;
                } else {
                    return new PageModel(screen);
                }
            } else {
                return new PageModel(screen);
            }
        } else {
            return new PageModel(screen);

        }
    }

    getListDataByEnumId<T>(ListData: T | any, EnumId: number) {
        if (!!ListData && ListData.length >= 1) {
            return ListData.filter(x => { return x.EnumID == EnumId });
        } else {
            return [];
        }
    }

    getsysENUMObjectById<T>(ListData: T | any, Id: number) {
        if (!!ListData) {
            return ListData.find(x => { return x.ID == Id });
        } else {
            return {};
        }
    }

    getListDataByEnumIds<T>(ListData: T | any, EnumIdS: Array<number>) {

        if (!!ListData && ListData.length >= 1 && !!EnumIdS && EnumIdS.length >= 1) {
            let dataToreturn = [];
            EnumIdS.forEach((a: number) => {
                let d = this.getListDataByEnumId(ListData, a);
                if (!!d) {
                    dataToreturn.push(d);
                }
            });
            return _.flatten(dataToreturn);
        } else {
            return [];
        }
    }


    getSeoTagsFromList<T>(sL: Array<T>, spId: number) {
        if (sL.length >= 1) {
            return _.find(sL, (l:any) => { return l.SeoPageId == spId });
        } else {
            return new SeoDetailModel();
        }
    }

    setSeoTagsToScreen = (sT: SeoDetailModel | any) => {
        if (!!sT) {
            if (!!sT.Title && sT.Title !== "") {
                this.setTitle(sT.Title);
            }
            if (!!sT.MetaTag && sT.MetaTag !== "") {
                this.updateMeta(SeoHelp[SeoHelp.keywords], sT.MetaTag);
            }
            if (!!sT.Description && sT.Description !== "") {
                this.updateMeta(SeoHelp[SeoHelp.description], sT.Description);
            }
        }
    }
    private setTitle(pT: string) {
        this.titleService.setTitle(pT);
    }
    private updateMeta(mN: string, mC: string) {
        this.meta.updateTag({ name: mN, content: mC });
    }
    private addMeta(mN: string, mC: string) {
        this.meta.addTag({ name: mN, content: mC });
    }

    private getInitialLoggedInState(): boolean {
        const storedValue = localStorage.getItem("UserToken");
        console.log(storedValue);
        return !!storedValue;
    }

    login() {
        // Perform your login logic here, set the loggedIn status to true
        this.loggedIn.next(true);
    }

    logout() {
        // Perform your logout logic here, set the loggedIn status to false
        this.loggedIn.next(false);
    }

    isLoggedIn() {
        return this.loggedIn.asObservable();
    }

}
