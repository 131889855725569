import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { AppRoutingModule } from './app.routing.module';
import { LoginModule } from './Authentication/login/login.module';
import { LoginComponent } from './Authentication/login/login.component';
import { homeModule } from './home-page/home-page.module';
import { HomePageComponent } from './home-page/home-page.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { RegisterModule } from './Authentication/register/register.module';
import { SearchModule } from './Authentication/searchmember/search.module';

import { MyJobModule } from './my-jobs/my-jobs.module';
import { MessageModule } from './messages/message.module';
import { CandidateModule } from './Authentication/mycandidates/mycandidates.module';
import { CandidateProfileModule } from './candidate-profile/candidate-profile.module';
import { PostjobComponent } from './Authentication/post-job/postjob.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { CandidateContactListComponent } from './candidate-contactlist/candidate-contactlist.component';
import { CandidateContactListModule } from './candidate-contactlist/candidate-contactlist.module';
import { CandidateslistModule } from './candidateslist/candidateslist.module';
import { MenubarModule } from './components/menubaar/aurthentication/menubar.module';
import { HeaderModule } from './components/toolbar/aurthentication-toolbar/header.module';
import { SteperModule } from './components/steps/steps.module';

import { InvoiceModule } from './invoice/invoice.module';

import { FavoritesModule } from './favorites/favorites.module';
import { FavoritesComponent } from './favorites/favorites.component';
import { FooterModule } from './components/footer/footer.module';
import { RegisterJobModule } from './register-job/register-job.module';
import { ForgotPasswordModule } from './Authentication/forgot-password/forgot-password.module';
import { ResetPasswordModule } from './Authentication/reset-password/reset-password.module';
import { FooterGreyModule } from './components/footer-grey/footer-grey.module';
import { AppService } from './app.service';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { ServicesModule } from './services/services.module';
import { MyProfileModule } from './my-profile/my-profile.module';
import { PostjobModule } from './Authentication/post-job/postjob.module';
import { MessageComponent } from './messages/message.component';
import { AllCandidateModule } from './all-candidates/all-candidates.module';
import { SelectedCandidateContactListModule } from './selected-contactlist/selected-contactlist.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireAuth } from 'angularfire2/auth'
import { AllSearchCandidateModule } from './all-searchmember-candidate/all-searchmember-candidate.module';
import { AfterOnlinePaymentComponent } from './after-online-payment/after-online-payment.component';
import { ViewApplicantsModule } from './view-applicants/view-applicants.module';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AuthGuard } from './core/auth-guard.service';
import { LoaderService } from './core/loader.service';
import { NgSelectModule } from '@ng-select/ng-select';
export const firebaseConfig = {
  apiKey: "AIzaSyA5oWUqKjkdMkWvMkqDG7BvN1m3pwzutwE",
  authDomain: "searchadam-bb306.firebaseapp.com",
  databaseURL: "https://searchadam-bb306.firebaseio.com",
  storageBucket: "gs://searchadam-bb306.appspot.com",
  messagingSenderId: ""
};


// import { JobTitleModule } from './job-title/job-title.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,

    CandidateContactListComponent,
    FavoritesComponent,
    AfterOnlinePaymentComponent
    // ResetPasswordComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    RouterModule,
    TransferHttpCacheModule,
    AppRoutingModule,
    LoginModule,
    ForgotPasswordModule,
    ResetPasswordModule,
    homeModule,
    InvoiceModule,
    AngularFontAwesomeModule,
    SearchModule,
    RegisterModule,
    SearchModule,
    CandidateModule,
    MyJobModule,
    AngularFontAwesomeModule,
    FavoritesModule,
    RegisterJobModule,
    FormsModule,
    ReactiveFormsModule,
    MessageModule,
    // JobTitleModule,
    CandidateProfileModule,
    CandidateslistModule,
    MenubarModule,
    HeaderModule,
    SteperModule,
    FooterModule,
    FooterGreyModule,
    HttpClientModule,
    CoreModule,
   ServicesModule,
    MyProfileModule,
    PostjobModule,
    AllCandidateModule,
    SelectedCandidateContactListModule,
    NgxPaginationModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AllSearchCandidateModule,
    ViewApplicantsModule,
    NgMultiSelectDropDownModule,
    NgSelectModule
    // CandidateContactListModule,

  ],
  exports: [
    HomePageComponent,
    LoginComponent,
    MyProfileComponent,
    AfterOnlinePaymentComponent
  ],
  providers: [AuthGuard, AppService,LoaderService],
  bootstrap: [AppComponent]
  
})
export class AppModule { }
