import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenubarModule } from '../components/menubaar/aurthentication/menubar.module';
import { MyProfileComponent } from './my-profile.component';
import { FooterGreyModule } from '../components/footer-grey/footer-grey.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxGalleryModule } from 'ngx-gallery';
@NgModule({

    declarations: [
        MyProfileComponent,

    ],
    imports: [
        AngularFontAwesomeModule,
        MenubarModule,
        FooterGreyModule,
        ReactiveFormsModule,
        CommonModule,
        NgxGalleryModule
    ],
    entryComponents: [],
    exports: [
        MyProfileComponent,

    ]
})

export class MyProfileModule {

}
