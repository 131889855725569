import { Component } from "@angular/core";

@Component({
    selector: 'selected-contactlist',
    templateUrl: './selected-contactlist.component.html',
    styleUrls: ['./selected-contactlist.component.scss']
})
export class SelectedCandidateContactListComponent {
    //   for popup
    closeResult: string;
    ACTIVEMODEL = '';
    constructor() { }
    hidenModel(data): any {
        this.ACTIVEMODEL = data;
    }

    paymentMODEL = '';
    openModal(): any {
        
        // this.ACTIVEMODEL = 'Register1';
    }
}
