import { GetFilteredFavouriteCandidateList } from "../favorites/favorites.class";

export class GetFilteredAppliedCandidateList extends GetFilteredFavouriteCandidateList {
   
    public constructor(init?: Partial<GetFilteredAppliedCandidateList>) {
        super();
    }
}
export class GetFavoriteCandidateList{
    JObID:number;
    CandidateID:number;
}