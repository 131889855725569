import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppService } from "../app.service";
import { GetFilteredAppliedCandidateList } from "./all-searchmember-candidate.class";

import * as AllCandidatesClasses from "./all-searchmember-candidate.class";
import Swal from "sweetalert2";
import * as _ from 'lodash';
import { CoreService } from "../core/core.service";
import { FormControl } from "@angular/forms";
import { PageModel, PageScreen, ConfigPageModel, SeoDetailModel } from "../core/core.class";
import { ActivatedRoute } from "@angular/router";
import { FuseUtils } from "../core/search.class";

import { CadidateListLocalModel } from "../Authentication/searchmember/search.class";
import { QueryType, FilterCriteria } from "../app.class";
import { IDropdownSettings } from "ng-multiselect-dropdown/multiselect.model";
@Component({
    selector: 'app-all-searchmember-candidate',
    templateUrl: './all-searchmember-candidate.component.html',
    styleUrls: ['./all-searchmember-candidate.component.scss']
})
export class AllSearchcandidatesComponent implements OnDestroy, OnInit {
    IsSearch = false;
    Isfilter = false;
    show = false;
    closeResult: string;
    ACTIVEMODEL = '';
    currentUser: any;
    RecruiterJobList: any;
    GetCandidateListData: any = [];
    selectedJobDetails: any;
    CandidateJobList: any;
    GenderList: any;
    RaceList: any;
    AllListData: any;
    LanguageList: any;
    RelationShipList: any;
    CitizionshipList: any;
    JobList: any;
    LevelList: any;
    industryList: any;
    qualificationList: any;
    fieldOfStudyList: any;
    yearsOfExp: any;
    ageList: any;
    jobId: any;
    allGetCandidateListData: any = [];
    searchCandidate: FormControl;
    paymentMODEL: any = '';
    routeJobId: any;
    pageDetails = new PageModel(PageScreen.ALLCANDIDATE);
    config: ConfigPageModel = new ConfigPageModel();
    filterData: any;
    CityList: any;
    searchCategory = new FormControl("");
    isSelectedCriteria = true;
    fieldofstudy=false;


    dropdownList = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings;
    multiMinQualification: any;
    minQualification: any;
    qualificationLocal: any;
    selectedQualification: any;
    localData: CadidateListLocalModel = new CadidateListLocalModel();
    IsMultiselect =  false;
    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();
    isTabExpanded1: boolean = true;
    isTabExpanded2: boolean = true;
    isTabExpanded3: boolean = true;
    constructor(private appService: AppService, public _activeRoute: ActivatedRoute,
        private coreService: CoreService) {

        if (localStorage.getItem("UserInfo") != null && localStorage.getItem("UserInfo") != undefined) {
            this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
            .subscribe((data: any) => {
                if (!!data && !!data.Result) {
                    this.SList = data.Result;
                    if (this.SList.length >= 1) {
                        this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2503));
                    }
                }
            })

            this.getListData();

            this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));
            this.getJobDataByRecruiterId();


        }
        this.searchCandidate = new FormControl('');
        const filterData = localStorage.getItem("SearchMember");
        if (!!filterData) {
            if (filterData != "null") {
                debugger
                this.filterData = JSON.parse(localStorage.getItem("SearchMember"));
                
                this.paramterFilter = this.filterData;
                console.log(this.paramterFilter)
                this.qualificationLocal = JSON.parse(this.filterData.Qualification);
                this.localData = this.filterData;
                this.paramterFilter.JobId = Number(this.filterData.JobId);
            }
        }

        // this.dropdownList = [
        //     { item_id: 1, item_text: 'Mumbai' },
        //     { item_id: 2, item_text: 'Bangaluru' },
        //     { item_id: 3, item_text: 'Pune' },
        //     { item_id: 4, item_text: 'Navsari' },
        //     { item_id: 5, item_text: 'New Delhi' }
        // ];

    }
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2503));
        } 
}
    ngOnInit(): void {
        
        this._activeRoute.params.forEach(params => {
            const routeJobId = params['JOBID'];
            if (!!routeJobId) {
                this.routeJobId = routeJobId;
            }
        });
        this.searchCategory.valueChanges
            .subscribe(searchText => {
                debugger
                this.GetCandidateListData = FuseUtils.filterArrayByString(
                    this.allGetCandidateListData,
                    searchText
                );
            });
    }

    hidenModel(data): any {
        this.ACTIVEMODEL = data;
    }
    password() {
        this.show = !this.show;
    }
    // jobinfo
    private getJobDataByRecruiterId() {
        const data = {
            "QueryObjectID": "JobInfo",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "CreatedBy",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.currentUser.ID,
                    },
                ]
            },
            "Sort": [
                {
                    "FieldID": "ID",
                    "SortSequence": 2
                }
            ]
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.CandidateJobList = response.Result;
                if (!!this.filterData) {
                    const id = Number(this.filterData.JobId);
                    this.paramterFilter.JobId = id;
                    this.isSelectedCriteria = false;
                    this.GetCandidateList();
                } else {
                    this.selectedJobDetails = this.CandidateJobList[0];
                    this.paramterFilter.JobId = 0;
                    this.GetCandidateList();
                }
            }
        });

    }

    paramterFilter: GetFilteredAppliedCandidateList = new GetFilteredAppliedCandidateList();

    appltFilter = () => {
        debugger
        const keys = Object.values(this.paramterFilter);
        let IsREquired;
        keys[13] = 0;
        for (const key of keys) {

            if (!!key) {
                if (key != 0) {
                    IsREquired = true;
                    break;
                } else {
                    IsREquired = false;
                }
            } else {
                IsREquired = false;
            }
        }

        if (IsREquired == true) {
            if(this.localData.mainQualification == 0) {

            
            this.GetCandidateList();
            } else {
                 if (this.paramterFilter.Qualification != 0 ) {
                    this.GetCandidateList();
                 } else {
                    Swal({
                        // type: 'Warning',
                        text: 'Please select minimum qualification.',
                    });
                 }
            }
        } else {
            this.isSelectedCriteria = true;
            Swal({
                // type: 'Warning',
                text: 'Please select at least one of the three search criteria.',
            });
        }
    }
    // getfilteredcandidate
    GetCandidateList = () => {
        const job = this.paramterFilter.JobId;
        // this.paramterFilter = _.omit(this.paramterFilter, ['mainQualification']);
        let isFromDate = true;
        const agefrom = this.paramterFilter.AgeFrom;
        const ageto = this.paramterFilter.AgeTo;
        const fromdate = Number(this.paramterFilter.AgeFrom);
        const toDate = Number(this.paramterFilter.AgeTo); 
        if((fromdate!=0||toDate!=0)&& fromdate==toDate){
            isFromDate=false
                    }
        else if (fromdate > toDate) {
            isFromDate = false;
        } else {
            isFromDate = true;
            if (!!this.ageList) {
                console.log(this.ageList);
if (this.ageList && fromdate !== 0) {
    const foundAgeFrom = this.ageList.find(t => t.SystemValue === fromdate);
    if (foundAgeFrom) {
        this.paramterFilter.AgeFrom = foundAgeFrom.ID;
    }
}
if (this.ageList && toDate !== 0) {
    const foundAgeTo = this.ageList.find(t => t.SystemValue === toDate);
    if (foundAgeTo) {
        this.paramterFilter.AgeTo = foundAgeTo.ID;
    }
}

            }
        }
        if (this.paramterFilter.JobId != 0) {
            if (isFromDate == true) {
                this.isSelectedCriteria = false;
                console.log(this.paramterFilter)
                var parameter = {               
                    "JobId": this.paramterFilter.JobId,
                    "AgeFrom":this.paramterFilter.AgeFrom,
                    "AgeTo":this.paramterFilter.AgeTo,
                    "Gender":this.paramterFilter.Gender,
                    "Citizan": this.paramterFilter.Citizan,
                    "Field": this.paramterFilter.Field,
                    "Industry": this.paramterFilter.Industry,
                    "JobTitle": this.paramterFilter.JobTitle,
                    "Language": this.paramterFilter.Language,
                    "Level": this.paramterFilter.Level,
                    "Qualification": this.paramterFilter.Qualification,
                    "Race": this.paramterFilter.Race,
                    "Relationship": this.paramterFilter.Relationship,
                    "YearOfExp": this.paramterFilter.YearOfExp
                }   
                this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(parameter, "GetFilteredCandidateList"))
                    .subscribe((Response: any) => {
                        if (Response.Result != null) {
                            this.GetCandidateListData = Response.Result;
                            this.allGetCandidateListData = Response.Result;
                            
                            this.setPageD();
                            for (const data of this.GetCandidateListData) {
                                for (const city of this.CityList) {
                                    data['CityName'] = _.find(this.CityList, (t:any) => t.ID == data.city).DisplayValue;
                                    data['userName'] = data.firstname + ' ' + data.lastname;
                                }
                            }
                            if (this.paramterFilter.Qualification != 0 ) {
                                this.IsMultiselect = true;
                                
                                this.qualificationLocal = JSON.parse(this.paramterFilter.Qualification);
                                const multiMinQualification = this.coreService.getListDataByEnumIds(this.AllListData, [1026, 1027]);
                    this.multiMinQualification = _.filter(multiMinQualification, (v) => _.includes(this.qualificationLocal, v.ID));
                                this.selectedQualification = this.multiMinQualification;
                                this.dropdownSettings = {
                                    singleSelection: false,
                                    idField: 'ID',
                                    textField: 'DisplayValue',
                                    selectAllText: 'Select All',
                                    unSelectAllText: 'UnSelect All',
                                    itemsShowLimit: 1,
                                    allowSearchFilter: false,
                                };
                            }
                        } else {
                            this.GetCandidateListData = [];
                            this.allGetCandidateListData = [];
                        }
                    });
                this.paramterFilter.AgeFrom = agefrom;
                this.paramterFilter.AgeTo = ageto;
                this.paramterFilter['JobId'] = job;
            } else {
                Swal({
                    text: 'The To age limit should be greater than From ',
                });
            }

        } else {
            this.isSelectedCriteria = true;
            Swal({
                text: 'Select Registered Job.',
            });
        }


    }

    clearFilter(): any {
        const job = 0;
        this.minQualification = null;
        this.localData = new CadidateListLocalModel();
        this.IsMultiselect =  false;
        this.paramterFilter = new GetFilteredAppliedCandidateList();
        this.paramterFilter.JobId = job;
        localStorage.setItem("SearchMember", null);

        // this.paramterFilter = _.omit(this.paramterFilter, ['JobId']);
        this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.paramterFilter, "GetFilteredCandidateList"))
            .subscribe((Response: any) => {
                this.GetCandidateListData = Response.Result;
            });
        this.paramterFilter['JobId'] = job;
        for (const data of this.GetCandidateListData) {
            if (!!data.IsSelected) {
                data.IsSelected = true;
            } else {
                data.IsSelected = false;
            }
            for (const city of this.CityList) {
                data['CityName'] = _.find(this.CityList, (t:any) => t.ID == data.city).DisplayValue;
            }
        }
    }
    // change
    changed(job) {
        job = Number(job);

        this.searchCategory = new FormControl("");
        this.paramterFilter.JobId = job;
        this.jobId = job;
        this.GetCandidateList();
        // job = Number(job);
        // let Job = this.GetCandidateListData.filter(x => x.ID == job);
        // if (Job.length > 0) {
        //     this.selectedJobDetails = Job;
        //     this.GetCandidateList();
        // }

    }


    // favorite marked
    onFavoriteClick(event) {
        if (event.IsFav != 1) {
            const WhereClause = new FilterCriteria();
            WhereClause.Filters = [];
            WhereClause.FilterLogic = "1";
            const dataObj = this.setData(event);
            const data = {
                QueryObjectID: "FavouriteCandidateInfo",
                QueryType: QueryType.Insert,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj


            };
            this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
                if (response.StatusCode == "200") {
                    const UserId = response.Result;
                    Swal({
                        title: ' Added to Favorite!!',
                    });

                    this.GetCandidateList();

                }
            });
        }

    }
    private setData(event) {
        return [
            {
                AppFieldID: "JobID",
                Value: this.paramterFilter.JobId
            },
            {
                AppFieldID: "CandidateID",
                Value: event.id
            },
        ];

    }

    // delete data

    onUnFavoriteClick(event) {
        if (!!event.Isfav) {
            const WhereClause : any = new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "JobID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: this.paramterFilter.JobId
                },
                {
                    ConjuctionClause: 1,
                    FieldID: "CandidateID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: event.id
                },
            ];
            WhereClause.FilterLogic = "1";
            const data = {
                QueryObjectID: "FavouriteCandidateInfo",
                QueryType: QueryType.Delete,
                Joins: [],
                WhereClause: WhereClause,
            };
            this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
                if (response.StatusCode == "200") {
                    const UserId = response.Result;
                    Swal({
                        title: ' Removed From Favorite!!',
                    });

                    this.GetCandidateList();

                }
            });
        }
    }

    selectCanididate(event, id): any {
        if (event.target.checked == true) {
            const WhereClause = new FilterCriteria();
            WhereClause.Filters = [];
            WhereClause.FilterLogic = "1";

            const dataObj = this.setSelectedData(id);

            const data = {
                QueryObjectID: "SelectedCandidateInfo",
                QueryType: QueryType.Insert,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj
            };

            this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
            });
        } else {
            const WhereClause : any = new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "JobID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: this.paramterFilter.JobId
                },
                {
                    ConjuctionClause: 1,
                    FieldID: "CandidateID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: id
                }
            ];
            WhereClause.FilterLogic = "1";
            const data = {
                QueryObjectID: "SelectedCandidateInfo",
                QueryType: QueryType.Delete,
                Joins: [],
                WhereClause: WhereClause,
            };

            this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
                if (response.StatusCode == "200") {
                    this.getListData();
                }
            });
        }
    }

    setSelectedData(id): any {
        return [
            {
                AppFieldID: "RecruiterID",
                Value: this.currentUser.ID
            },
            {
                AppFieldID: "JobID",
                Value: this.paramterFilter.JobId
            },
            {
                AppFieldID: "CandidateID",
                Value: id
            },
        ];
    }

    private getListData() {
        const data = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": false,
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.AllListData = response.Result;
                this.BindListData();
                
                if (!!this.multiMinQualification) {
                    this.IsMultiselect = true;
                const multiMinQualification = this.coreService.getListDataByEnumIds(this.AllListData, [1026, 1027]);
                this.multiMinQualification = _.filter(multiMinQualification, (v) => _.includes(this.qualificationLocal, v.ID));
                this.selectedQualification = this.multiMinQualification;
               
                this.dropdownSettings = {
                    singleSelection: false,
                    idField: 'ID',
                    textField: 'DisplayValue',
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    itemsShowLimit: 1,
                    allowSearchFilter: false,
                };
                }
            }
        });

        const data1 = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [

            ],
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "EnumID",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": 1034
                    }
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.CityList = response.Result
            } else {
            }
        });
        // this.appService.getListValue(1034).subscribe(result => {

            
        //     if (result.StatusCode = "200") {
                
        //         this.CityList = result.Result;
        //     }
        // });
    }

    private BindListData() {
        
        let GenderList = this.AllListData.filter(x => x.EnumID == 1030);
        this.GenderList = _.sortBy(GenderList, ['SystemValue']);
        let RaceList = this.AllListData.filter(x => x.EnumID == 1014);
        this.RaceList = _.sortBy(RaceList, ['SystemValue']);
        let RelationShipList =  this.AllListData.filter(x => x.EnumID == 1015);
        this.RelationShipList =_.sortBy(RelationShipList, ['SystemValue']);
        let LanguageList = this.AllListData.filter(x => x.EnumID == 1016);
        this.LanguageList = _.sortBy(LanguageList, ['SystemValue']);
        let CitizionshipList = this.AllListData.filter(x => x.EnumID == 1017);
        this.CitizionshipList = _.sortBy(CitizionshipList, ['SystemValue']);
        let JobList =this.AllListData.filter(x => x.EnumID == 1029);
        this.JobList = _.sortBy(JobList, ['DisplayValue']);
        this.LevelList = this.AllListData.filter(x => x.EnumID == 1020);
        this.LevelList = _.orderBy( this.LevelList, 'SystemValue', 'asc');
        let IndustryList = this.AllListData.filter(x => x.EnumID == 1021);
        this.industryList = _.sortBy(IndustryList, ['SystemValue']);
 
        
        // this.qualificationList = this.AllListData.filter(x => (x.EnumID == 1026 && x.EnumID == 1027));
        this.yearsOfExp = this.AllListData.filter(x => x.EnumID == 1048);
        this.ageList = this.AllListData.filter(x => x.EnumID == 1049);
        _.each(this.ageList, (list:any) => {
            if (list.Sequence) {
                list.Sequence = Number(list.Sequence);
            }
        })
        
        this.ageList = _.sortBy(this.ageList, ["Sequence"], ['asc']);
        this.multiMinQualification = this.coreService.getListDataByEnumIds(this.AllListData, [1026, 1027]);
        this.qualificationList = this.AllListData.filter(x => x.EnumID == 1072);
        if(this.localData.mainQualification == 0){
            this.fieldofstudy=false
        }
        else if (this.localData.mainQualification == 2392) {
            this.minQualification = this.AllListData.filter(x => x.EnumID == 1026);
            this.fieldofstudy=false;
        } else {
            this.minQualification = this.AllListData.filter(x => x.EnumID == 1027);
            this.fieldOfStudyList = this.AllListData.filter(x => x.EnumID == 1019);
            
            this.fieldofstudy=true;
        }
    }
    private setPageD() {
        this.pageDetails = this.coreService.getScreenP(PageScreen.ALLCANDIDATE);
        this.config = {
            currentPage: this.pageDetails.currentPage,
            itemsPerPage: this.pageDetails.itemsPerPage,
            totalItems: this.pageDetails.totalItems
        };
    }
    pageChanged(event) {
        this.config.currentPage = event;
    }
    ngOnDestroy(): void {
        this.pageDetails = Object.assign(this.config);
        this.pageDetails.screenName = PageScreen.ALLCANDIDATE;
        this.coreService.pageChangeHelper(this.pageDetails, PageScreen.ALLCANDIDATE);
    }

    onItemSelect(item: any) {
        const data =  this.multiMinQualification.filter(x => x.ID == item.ID);
        this.selectedQualification.push(data);
        const data2 = _.map(this.selectedQualification, 'ID');
        this.paramterFilter.Qualification = JSON.stringify(data2);
      }
      onSelectAll(items: any) {
      }

      onItemDeSelect(items: any) {
        this.selectedQualification = this.selectedQualification.filter(x => x.ID != items.ID);
        const data = _.map(this.selectedQualification, 'ID');
        this.paramterFilter.Qualification = JSON.stringify(data);
      }

    getMinQualification = () => {
        try {
            const typeOfQualification = this.localData.mainQualification;
            if (typeOfQualification == 2392) {
                this.minQualification = this.AllListData.filter(x => x.EnumID == 1026);
                this.fieldofstudy = false;
            } else {
                this.minQualification = this.AllListData.filter(x => x.EnumID == 1027);
                this.fieldOfStudyList = this.AllListData.filter(x => x.EnumID == 1019);
                this.fieldOfStudyList = _.sortBy(this.fieldOfStudyList, ['SystemValue']);
                this.fieldofstudy = true;
            }
        } catch (error) {
            console.error('Error in getMinQualification:', error);
        }
    };
    
    setValueforMinCriteria = (selectedValue) => {
        const qualificationList = this.coreService.getListDataByEnumIds(this.AllListData, [1026, 1027]);
        const selectedQualification = qualificationList.find(q => q.ID == selectedValue);

        if (selectedQualification) {
            const getNextminValue = selectedQualification.Sequence;
            const filteredQualifications = qualificationList.filter(q => q.Sequence >= getNextminValue);
            const qaId = filteredQualifications.map(q => q.ID);
            this.paramterFilter.Qualification = JSON.stringify(qaId);
        } else {
            this.paramterFilter.Qualification = null;
        }
    }
    
    toggleTab1() {
        this.isTabExpanded1 = !this.isTabExpanded1;
    }

    toggleTab2() {
        this.isTabExpanded2 = !this.isTabExpanded2;
    }

    toggleTab3() {
        this.isTabExpanded3 = !this.isTabExpanded3;
    }
}
