import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';


import { from } from 'rxjs';
import { CandidateProfileComponent } from './candidate-profile.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenubarModule } from '../components/menubaar/aurthentication/menubar.module';
import { FooterModule } from '../components/footer/footer.module';
import { FooterGreyModule } from '../components/footer-grey/footer-grey.module';
// import { HeaderComponent } from '../../components/toolbar/aurthentication-toolbar/header.component';
import { NgxGalleryModule } from 'ngx-gallery';
import { CommonModule } from '@angular/common';
import { RegisterJobModule } from '../register-job/register-job.module';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({

    declarations: [CandidateProfileComponent],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: CandidateProfileComponent, pathMatch: 'full' }
        ]),
        MenubarModule,
        FooterGreyModule,
        NgxGalleryModule,
        CommonModule,
        RegisterJobModule,
        FormsModule,
        NgSelectModule
    ],
    entryComponents: [],
    exports: [
        CandidateProfileComponent
    ]
})

export class CandidateProfileModule {

}
