
export class GetCandidatePDetailEPSQ_A {
    CandidateId: number;
    Case: number;
    constructor(init?: Partial<GetCandidatePDetailEPSQ_A>) {
        Object.assign(this, init);
    }
}

export class GetCandidateProfileDetails  extends GetCandidatePDetailEPSQ_A {
    UserId?: number;
    JobID?: number;
    constructor(init?: Partial<GetCandidatePDetailEPSQ_A>) {
        super();
        Object.assign(this, init);
    }


}



