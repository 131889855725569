import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppService } from "../app.service";

import Swal from "sweetalert2";
import * as _ from 'lodash';
import { CoreService } from "../core/core.service";
import { FormControl } from "@angular/forms";
import { PageModel, PageScreen, ConfigPageModel, SeoDetailModel } from "../core/core.class";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseUtils } from "../core/search.class";
import { GetAppliedCandidateList } from "./view-applicants.class";
import { QueryType, FilterCriteria } from "../app.class";

@Component({
    selector: 'view-applicants',
    templateUrl: './view-applicants.component.html',
    styleUrls: ['./view-applicants.component.scss']
})
export class ViewApplicantsComponent implements OnDestroy, OnInit {
    IsSearch = false;
    Isfilter = false;
    show = false;
    closeResult: string;
    ACTIVEMODEL = '';
    currentUser: any;
    RecruiterJobList: any;
    GetCandidateListData: any = [];
    allGetCandidateListData: any = [];
    selectedJobDetails: any;
    CandidateJobList: any;
    GenderList: any;
    RaceList: any;
    AllListData: any;
    LanguageList: any;
    RelationShipList: any;
    CitizionshipList: any;
    JobList: any;
    LevelList: any;
    industryList: any;
    qualificationList: any;
    fieldOfStudyList: any;
    yearsOfExp: any;
    ageList: any;
    jobId: any;
    searchCandidate: FormControl;
    paymentMODEL: any = '';
    routeJobId: any;
    pageDetails = new PageModel(PageScreen.ALLCANDIDATE);
    config: ConfigPageModel = new ConfigPageModel();
    isSelectedCriteria = true;
    searchCategory = new FormControl("");
    count: any;
    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();
    isTabExpanded1: boolean = true;
    isTabExpanded2: boolean = true;
    isTabExpanded3: boolean = true;
    ngAfterViewInit(): void {
    }

    constructor(private appService: AppService, public _activeRoute: ActivatedRoute, public router: Router,
        private coreService: CoreService) {

        if (localStorage.getItem("UserInfo") != null && localStorage.getItem("UserInfo") != undefined) {

            this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
                .subscribe((data: any) => {
                    if (!!data && !!data.Result) {
                        this.SList = data.Result;
                        if (this.SList.length >= 1) {
                            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2508));
                        }
                    }
                })

            this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));
            this.getJobDataByRecruiterId();

        }
        // this.GetCandidateList();
        this.getListData();

        this.searchCandidate = new FormControl('');

    }

    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2508));
        }
    }

    ngOnInit(): void {
        this._activeRoute.params.forEach(params => {
            const routeJobId = params['JOBID'];
            if (!!routeJobId) {
                this.routeJobId = routeJobId;
            }
        });

        this.searchCategory.valueChanges
            .subscribe(searchText => {
                this.GetCandidateListData = FuseUtils.filterArrayByString(
                    this.allGetCandidateListData,
                    searchText
                );
            });


    }


    hidenModel(data): any {
        this.ACTIVEMODEL = data;
    }
    password() {
        this.show = !this.show;
    }
    // jobinfo
    private getJobDataByRecruiterId() {
        let data = {
            "QueryObjectID": "JobInfo",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "CreatedBy",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.currentUser.ID,
                    },
                ]
            },
            "Sort": [
                {
                    "FieldID": "ID",
                    "SortSequence": 2
                }
            ]
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.CandidateJobList = response.Result;
                if (!!this.routeJobId) {
                    const id = Number(this.routeJobId);
                    this.paramterFilter.JobId = id;
                    this.jobId = id;
                    this.GetCandidateList();
                } else {
                    this.selectedJobDetails = this.CandidateJobList[0];
                    this.paramterFilter.JobId = this.selectedJobDetails.ID;
                    this.GetCandidateList();
                }

            }
        });

    }

    paramterFilter: GetAppliedCandidateList = new GetAppliedCandidateList();

    ApplyFilter = () => {
        const keys = Object.values(this.paramterFilter);
        let IsREquired;
        keys[13] = 0;
        for (const key of keys) {

            if (!!key) {
                if (key != 0) {
                    IsREquired = true;
                    break;
                } else {
                    IsREquired = false;
                }
            } else {
                IsREquired = false;
            }
        }

        if (IsREquired == true) {
            this.GetCandidateList();
        } else {
            this.isSelectedCriteria = true;
            Swal({
                // type: 'Warning',
                text: 'Please select at least one of the three search criteria.',
            });
        }
    }


    // getfilteredcandidate
    GetCandidateList = () => {
        const job = this.paramterFilter.JobId;
        let isFromDate = true;
        const agefrom = this.paramterFilter.AgeFrom;
        const ageto = this.paramterFilter.AgeTo;
        const fromdate = Number(this.paramterFilter.AgeFrom);
        const toDate = Number(this.paramterFilter.AgeTo);
        if (fromdate > toDate) {
            isFromDate = false;
        } else {
            isFromDate = true;
            if (fromdate != 0) {
                this.paramterFilter.AgeFrom = _.find(this.ageList, (t:any) => t.SystemValue == fromdate).DisplayValue;
            }
            if (toDate != 0) {
                this.paramterFilter.AgeTo = _.find(this.ageList, (t:any) => t.SystemValue == toDate).DisplayValue;
            }

        }
        if (this.paramterFilter.JobId != 0) {

            if (isFromDate == true) {
                this.isSelectedCriteria = false;
                this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.paramterFilter, "GetAppliedCandidateList"))
                    .subscribe((Response: any) => {
                        this.GetCandidateListData = Response.Result;
                        this.allGetCandidateListData = Response.Result;
                        if (this.GetCandidateListData.length > 0) {
                            let getFilterCandidate = _.filter(this.GetCandidateListData, 'IsSelected');
                            this.count = getFilterCandidate.length;
                        }
                        this.setPageD();
                        for (let data of this.GetCandidateListData) {
                            data['userName'] = data.firstname + ' ' + data.lastname;
                            // if ( data.IsSelected == 1 ) {
                            //      
                            //     data.IsSelected = true;
                            // } else {
                            //     data.IsSelected = false;
                            // }
                        }

                        for (let ag of this.allGetCandidateListData) {
                            ag['userName'] = ag.firstname + ' ' + ag.lastname;
                            // if ( ag.IsSelected == 1 ) {
                            //      
                            //     ag.IsSelected = true;
                            // } else {
                            //     ag.IsSelected = false;
                            // }
                        }
                    });
                this.paramterFilter.AgeFrom = agefrom;
                this.paramterFilter.AgeTo = ageto;
                this.paramterFilter['JobId'] = job;

            } else {
                Swal({
                    // type: 'Warning',
                    text: 'The To age limit should be greater than From ',
                });
            }

        }


    }

    clearFilter(): any {
        const job = this.paramterFilter.JobId;
        this.paramterFilter = new GetAppliedCandidateList();
        this.paramterFilter.JobId = job;
        this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.paramterFilter, "GetAppliedCandidateList"))
            .subscribe((Response: any) => {
                this.GetCandidateListData = Response.Result;
                this.allGetCandidateListData = Response.Result;

            });
    }
    
    changed(job) {
        this.paramterFilter.JobId = job;
        this.jobId = job;
        this.GetCandidateList();
        let Job = this.GetCandidateListData.filter(x => x.ID == job);
        if (Job.length > 0) {
            this.selectedJobDetails = Job[0];
            this.GetCandidateList();
        }

    }

    // changed(jobId) {
    //     debugger
    //     this.paramterFilter.JobId = jobId;
    //     this.jobId = jobId;
    //     this.GetCandidateList();
    
    //     let Job = this.GetCandidateListData.filter(x => x.ID == jobId);
    //     if (Job.length > 0) {
    //         this.selectedJobDetails = Job[0];
    //         this.GetCandidateList();
    //     }
    // }
    


    // favorite marked
    onFavoriteClick(event) {
        if (event.IsFav == 0) {
            let WhereClause = new FilterCriteria();
            WhereClause.Filters = [];
            WhereClause.FilterLogic = "1";
            let dataObj = this.setData(event);
            let data = {
                QueryObjectID: "FavouriteCandidateInfo",
                QueryType: QueryType.Insert,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj


            };
            this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
                if (response.StatusCode == "200") {
                    var UserId = response.Result;
                    Swal({
                        title: ' Added to Favorite!!',
                    });

                    this.GetCandidateList();

                }
            });
        }
        else {

            let WhereClause: any = new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "JobID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: this.paramterFilter.JobId
                },
                {
                    ConjuctionClause: 1,
                    FieldID: "CandidateID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: event.id
                },
            ];
            WhereClause.FilterLogic = "1";
            const data = {
                QueryObjectID: "FavouriteCandidateInfo",
                QueryType: QueryType.Delete,
                Joins: [],
                WhereClause: WhereClause,
            };
            this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
                if (response.StatusCode == "200") {
                    const UserId = response.Result;
                    Swal({
                        title: ' Removed From Favorite!!',
                    });

                    this.GetCandidateList();

                }
            });
        }
    }
    private setData(event) {
        return [
            {
                AppFieldID: "JobID",
                Value: this.paramterFilter.JobId
            },
            {
                AppFieldID: "CandidateID",
                Value: event.id
            },
        ];

    }


    selectCanididate(event, id): any {
        if (event.target.checked == true) {
            const WhereClause = new FilterCriteria();
            WhereClause.Filters = [];
            WhereClause.FilterLogic = "1";

            const dataObj = this.setSelectedData(id);

            const data = {
                QueryObjectID: "SelectedCandidateInfo",
                QueryType: QueryType.Insert,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj
            };

            this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
                this.count++;
                this.getListData();
            });
        } else {
            let WhereClause: any = new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "CandidateID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: id
                },
                {
                    ConjuctionClause: 1,
                    FieldID: "JobID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: this.jobId
                }
            ];
            WhereClause.FilterLogic = "1";
            let data = {
                QueryObjectID: "SelectedCandidateInfo",
                QueryType: QueryType.Delete,
                Joins: [],
                WhereClause: WhereClause,
            };
            this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
                if (response.StatusCode == "200") {
                    this.count--;
                    this.getListData();
                }
            });
        }
    }

    setSelectedData(id): any {
        return [
            {
                AppFieldID: "RecruiterID",
                Value: this.currentUser.ID
            },
            {
                AppFieldID: "JobID",
                Value: this.paramterFilter.JobId
            },
            {
                AppFieldID: "CandidateID",
                Value: id
            },
        ];
    }

    private getListData() {
        let data = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": false,
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.AllListData = response.Result;
                this.BindListData()
            }
        });
    }

    private BindListData() {
        this.GenderList = this.AllListData.filter(x => x.EnumID == 1030);
        this.RaceList = this.AllListData.filter(x => x.EnumID == 1014);
        this.RelationShipList = this.AllListData.filter(x => x.EnumID == 1015);
        this.LanguageList = this.AllListData.filter(x => x.EnumID == 1016);
        this.CitizionshipList = this.AllListData.filter(x => x.EnumID == 1017);
        this.JobList = this.AllListData.filter(x => x.EnumID == 1029);
        this.LevelList = this.AllListData.filter(x => x.EnumID == 1020);
        this.industryList = this.AllListData.filter(x => x.EnumID == 1021);
        // this.qualificationList = this.AllListData.filter(x => (x.EnumID == 1026 && x.EnumID == 1027));
        this.fieldOfStudyList = this.AllListData.filter(x => x.EnumID == 1019);
        this.yearsOfExp = this.AllListData.filter(x => x.EnumID == 1048);
        this.ageList = this.AllListData.filter(x => x.EnumID == 1049);
        this.ageList.sort((a, b) => a.Sequence - b.Sequence);
        this.qualificationList = this.coreService.getListDataByEnumIds(this.AllListData, [1026, 1027]);
    }
    private setPageD() {
        this.pageDetails = this.coreService.getScreenP(PageScreen.ALLCANDIDATE);
        this.config = {
            currentPage: this.pageDetails.currentPage,
            itemsPerPage: this.pageDetails.itemsPerPage,
            totalItems: this.pageDetails.totalItems
        };
    }
    pageChanged(event) {
        this.config.currentPage = event;
    }
    ngOnDestroy(): void {
        this.pageDetails = Object.assign(this.config);
        this.pageDetails.screenName = PageScreen.ALLCANDIDATE;
        this.coreService.pageChangeHelper(this.pageDetails, PageScreen.ALLCANDIDATE);
    }

    proceed(): any {
        if (this.count > 0) {
            this.router.navigate(['/candidate-contactlist/' + this.paramterFilter.JobId]);
        } else {
            Swal({
                text: 'Please select candidate.'
            });
        }
    }

    toggleTab1() {
        this.isTabExpanded1 = !this.isTabExpanded1;
    }

    toggleTab2() {
        this.isTabExpanded2 = !this.isTabExpanded2;
    }

    toggleTab3() {
        this.isTabExpanded3 = !this.isTabExpanded3;
    }
}
