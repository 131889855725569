<section class="bady" class="main-page">
    <div class="container">
        <div class="mycandidate">
            Applicants
        </div>
        <div class="col-xs-6 search-btn">
            <button class="search-btn1 search-width btn-grad-border">
                search
            </button>
        </div>
        <div class="col-xs-6 search-btn1">
            <button class="search-btn2 search-width btn-grad-border">
                Filter<img src="../../../assets/Background/Vectorrrrr.png"
                    width="14px">
            </button>
        </div>
    </div>
    <div class="main-page-container">
        <div class="row heading-container justify-content-center">
            <h1 class="heading">View, filter them, choose the candidates you want to
                contact
            </h1>
        </div>
        <div class="col-lg-12 p-removed">
            <div class="col-md-4">
                <div class="searchbar mb-3">
                    <div class="">
                        <div class="">
                            <div class="">
                                <label class="label-text mb-3" href="#popup1" style="padding-left: 0px;">Registered
                                    Job:<sup class="required">*</sup></label>
                            </div>
                            <div class="mb-4">
                                <ng-select (ngModelChange)="changed($event)" [placeholder]="'Select Job Title'">
                                    <ng-option *ngFor="let job of SelectedCandidateJobList" [value]="job.ID">
                                        {{ job.Title?.DisplayValue }} - {{ job['RefNo'] }}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="button" data-toggle="modal" (click)="ACTIVEMODEL=
                                                    'Register'" data-target="#exampleModal"
                                class="btn blue-button">Register
                                a Job</button>
                        </div>
                    </div>
                </div>
                <div class="p-removed">
                    <div class="col b-shadow p-0">
                        <h1 class="text-center headertitel heading mb-0 border-0">Filter
                            Candidates
                        </h1>
                        <div class="tabs" [ngClass]="{'expanded-tab': isTabExpanded3}">
                            <div class="tab" [ngClass]="{'expanded-tab': isTabExpanded1}">
                                <input type="checkbox" id="chck1" class="checkint" checked>
                                <label class="tab-label f-info section-title" for="chck1" (click)="toggleTab1()">Personal
                                    Information</label>
                                <div class="tab-content">
                                    <label class="label-text mb-3 ml-4 margin-left-0">Age:</label>
                                    <div class="col-md-12 r-removed">
                                        <div class="row">
                                            <div class="col-md-12
                                                                    col-xs-12 plr-from-to
                                                                    p-removed
                                                                    r-removed p-0">
                                                <div class="col-xs-6 pl-0 pr-1">
                                                    <select class="drop3">
                                                        <option value="Job
                                                                                Title">From</option>
                                                        <option value="Job
                                                                                Title">From</option>
                                                        <option value="Job
                                                                                Title">From</option>
                                                        <option value="Job
                                                                                Title">From</option>
                                                    </select>
                                                </div>
                                                <div class="col-xs-6 pr-0 pl-1">
                                                    <select class="drop3">
                                                        <option value="Job
                                                                                Title">To</option>
                                                        <option value="Job
                                                                                Title">To</option>
                                                        <option value="Job
                                                                                Title">To</option>
                                                        <option value="Job
                                                                                Title">To</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div><br>
                                        <ng-select [(ngModel)]="paramterFilter.Gender" [placeholder]="'Gender'">
                                            <label class="label-text mb-3">Gender:</label>
                                            <ng-option disabled>Gender</ng-option>
                                            <ng-option *ngFor="let gender of GenderList" [value]="gender.ID">
                                                {{ gender.DisplayValue }}
                                            </ng-option>
                                        </ng-select>
                                    </div><br>
    
                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Race:</label>
                                        <ng-select [(ngModel)]="paramterFilter.Race" [placeholder]="'Race'">
                                            <ng-option disabled>Race</ng-option>
                                            <ng-option *ngFor="let race of RaceList" [value]="race.ID">
                                                {{ race.DisplayValue }}
                                            </ng-option>
                                        </ng-select>
                                    </div><br>
    
                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Relationship:</label>
                                        <ng-select [(ngModel)]="paramterFilter.Relationship" [placeholder]="'Relationship'">
                                            <ng-option disabled>Relationship</ng-option>
                                            <ng-option *ngFor="let relationship of RelationShipList"
                                                [value]="relationship.ID">
                                                {{ relationship.DisplayValue }}
                                            </ng-option>
                                        </ng-select>
                                    </div><br>
    
                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Language:</label>
                                        <ng-select [(ngModel)]="paramterFilter.Language" [placeholder]="'Select Language'">
                                            <ng-option disabled>Select Language</ng-option>
                                            <ng-option *ngFor="let language of LanguageList" [value]="language.ID">
                                                {{ language.DisplayValue }}
                                            </ng-option>
                                        </ng-select>
                                    </div><br>
    
                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Citizenship:</label>
                                        <ng-select [(ngModel)]="paramterFilter.Citizan"
                                            [placeholder]="'Select Citizenship'">
                                            <ng-option disabled>Select Citizenship</ng-option>
                                            <ng-option *ngFor="let citizenship of CitizionshipList"
                                                [value]="citizenship.ID">
                                                {{ citizenship.DisplayValue }}
                                            </ng-option>
                                        </ng-select>
                                    </div><br>
                                    <!-- <div class="col-md-12 p-drops">
                                                            <select class="drop3">
                                                                <option value="Job Title">Disability</option>
                                                                <option value="Job Title">Disability</option>
                                                                <option value="Job Title">Disability</option>
                                                                <option value="Job Title">Disability</option>
                                                            </select>
                                                        </div><br> -->
    
                                </div>
                            </div>
                            <div class="tab" [ngClass]="{'expanded-tab': isTabExpanded2}">
                                <input type="checkbox" id="chck2" class="checkint" checked>
                                <label class="tab-label f-info section-title" for="chck2" (click)="toggleTab2()">Educational
                                    Information</label>
                                <div class="tab-content">
                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Qualification:</label>
                                        <select class="drop3">
                                            <option value="Job Title">Qualification</option>
                                            <option value="Job Title">Qualification</option>
                                            <option value="Job Title">Qualification</option>
                                            <option value="Job Title">Qualification</option>
                                        </select>
                                    </div><br>
    
                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Field
                                            of Study:</label>
                                        <select class="drop3">
                                            <option value="Job Title">Field
                                                of Study</option>
                                            <option value="Job Title">Field
                                                of Study</option>
                                            <option value="Job Title">Field
                                                of Study</option>
                                            <option value="Job Title">Field
                                                of Study</option>
                                        </select>
                                    </div><br>
                                </div>
                            </div>
                            <div class="tab"  [ngClass]="{'expanded-tab': isTabExpanded3}">
                                <input type="checkbox" id="chck3" class="checkint" checked>
                                <label class="tab-label f-info section-title" for="chck3" (click)="toggleTab3()">Professional
                                    Information</label>
                                <div class="tab-content" [ngClass]="{'expanded-tab-content': isTabExpanded3}">
                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Job title:</label>
                                        <ng-select [(ngModel)]="paramterFilter.JobTitle" [placeholder]="'Select Job'">
                                            <ng-option disabled>Select Job</ng-option>
                                            <ng-option *ngFor="let job of JobList" [value]="job.ID">
                                                {{ job.DisplayValue }}
                                            </ng-option>
                                        </ng-select>
                                    </div><br>
    
                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Industry:</label>
                                        <select class="drop3">
                                            <option value="Job Title">Industry</option>
                                            <option value="Job Title">Industry</option>
                                            <option value="Job Title">Industry</option>
                                            <option value="Job Title">Industry</option>
                                        </select>
                                    </div><br>
    
                                    <div class="row">
                                        <div class="col-md-12 col-xs-12
                                                                p-drops">
                                            <div class="col-xs-7" style="padding: 0px;">
                                                <label class="label-text mb-3">Experience:</label>
                                            </div>
                                            <div class="col-xs-5 p-remv">
                                                <select class="drop7">
                                                    <option value="Job
                                                                            Title">Years</option>
                                                    <option value="Job
                                                                            Title">Years</option>
                                                    <option value="Job
                                                                            Title">Years</option>
                                                    <option value="Job
                                                                            Title">Years</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div><br>
    
                                    <div class="row">
                                        <div class="col-md-12 p-drops">
                                            <label class="label-text mb-3">Level:</label>
                                            <ng-select [(ngModel)]="paramterFilter.Level" [placeholder]="'Select Level'" class="custom-dropdown">
                                                <ng-option disabled>Select Level</ng-option>
                                                <ng-option *ngFor="let level of LevelList" [value]="level.ID">
                                                    {{ level.DisplayValue }}
                                                </ng-option>
                                            </ng-select>
                                        </div>
    
                                    </div><br>
                                    <div class="col-md-12 text-center
                                                            btnmarg">
                                        <button type="button" class="btn blue-bottom-button"
                                            (click)="GetSelectedCandidateList()">Apply
                                            Filter</button>
                                    </div>
    
    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="h-box">
                    <div class="searchbox">
                        <input class="serc" type="search" name="searchcandidates" value="" placeholder="Search Candidates">
                        <img src="../../../assets/Background/Vector.png" class="searchicon">
                    </div>
                </div>
                <div class="">
                    <div class="searchbox pl-proceed-w d-flex justify-content-center">
                        <div class="row justify-content-center align-items-center">
    
                            <div class="col-md-8">
                                <h2 class="heading-text">Proceed
                                    to retrieve contact details of
                                    candidates
                                </h2>
    
                            </div>
                            <div class="col-md-4 row justify-content-center">
                                <button type="button" class="btn blue-button"
                                    [routerLink]="'/candidate-contactlist'">Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="search-inner">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <h5 class="">{{countOfSelected}} candidates selected of 10</h5>
                                            </div>
                                            <div class="col-md-4 d-flex justify-content-center">
                                                <button class="btn blue-button" (click)="proceedPayment()" data-toggle="modal" data-target="#paymentmodal">
                                                    Proceed
                                                </button>
                                            </div>
                                        </div>
                                    </div> -->
                        <div class="col-md-12 p-0">
                            <div class="row">
                                <div class="col-md-12 p-0">
                                    <div class="row searchbox1 r-hiden"
                                        *ngFor="let candidate of this.GetSelectedCandidateListData | paginate: config">
                                        <div class="col-md-12 col-sm-12">
                                            <div class="col-md-3 col-sm-3 round">
                                                <div class="infoimage text-center">
                                                    <div class="otherinfo">
                                                        <img src="../../assets/Background/Ellipse.png" class="imageeee">
                                                    </div>
                        
                                                </div>
                                            </div>
                        
                                            <div class="col-md-7 col-sm-7 info">
                                                <span>
                                                    <span class="w-hr">
                                                        <span class="border-text"><span class="text-left greatitle"
                                                                [routerLink]="'/candidate-profile'">
                                                                {{candidate.firstname}} {{candidate.lastname}}
                                                            </span></span>
                                                    </span>
                                                </span>
                        
                                                <p class="location"><b>{{candidate.StateName}}</b></p>
                                                <p class="message"><i class="boxinfo">
                                                        {{candidate.Message}}
                                                    </i></p>
                                            </div>
                                            <div class="col-md-2 col-xs-12 col-sm-2 choise">
                                                <div class="col-md-12 col-xs-6 text-center
                                                                                                                        bordergread">
                                                    <h6 class="text-center">Select<br>
                                                        Candidate:
                                                    </h6>
                        
                                                    <!-- <div class="form-group">
                                                                                                                            <input type="checkbox" id="css">
                                                                                                                            <label for="css"></label>
                                                                                                                          </div> -->
                                                    <div class="form-group">
                                                        <input type="checkbox" id="box-2">
                                                        <label for="box-2"></label>
                                                    </div>
                        
                                                </div>
                                                <div class="col-md-12 col-xs-6 bordergread1
                                                                                                                        text-center">
                                                    <h6 class="text-center">Favorite <br>Candidate:</h6>
                                                    <img src="../../../assets/Background/Vector4.png" *ngIf="candidate.IsFav == 0"
                                                        (click)="onFavoriteClick(candidate)" class="posi1">
                                                    <img *ngIf="candidate.IsFav == 1" (click)="onFavoriteClick(candidate)"
                                                        src="../.././assets/Background/vector5.jpg" class="posi1">
                                                </div>
                                            </div>
                        
                        
                                        </div>
                                    </div>
                        
                                    <pagination-controls *ngIf="this.GetSelectedCandidateListData.length > 0"
                                        (pageChange)="pageChanged($event)"></pagination-controls>
                                    <div class="row searchbox1 r-show" *ngFor="let candidate of this.GetSelectedCandidateListData">
                                        <div class="col-xs-12 p-remove">
                                            <div class="col-xs-4 round p-right">
                                                <div class="infoimage text-center">
                                                    <div class="otherinfo">
                                                        <img src="../../assets/Background/Ellipse.png" class="imageeee">
                                                    </div>
                        
                                                </div>
                                            </div>
                                            <div class="col-xs-8 info p-remove">
                                                <span class="border-text">
                                                    <span class="text-left greatitle" [routerLink]="'/candidate-profile'">
                                                        {{candidate.firstname}} {{candidate.lastname}}
                                                    </span>
                                                </span>                                                      
                                                <p class="message"><i class="boxinfo">
                                                        {{candidate.Message}}
                                                    </i></p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <div class="col-xs-6 text-center selectbox">
                                                    <div class="row">
                                                        <div class="col-xs-6 p-rem">
                                                            <h6>Favorite <br>Candidate:</h6>
                                                        </div>
                                                        <div class="col-xs-6">
                                                            <img *ngIf="candidate.IsFav == 0" (click)="onFavoriteClick(candidate)"
                                                                src="../../../assets/Background/Vector4.png" class="posi1">
                                                            <img *ngIf="candidate.IsFav == 1" (click)="onFavoriteClick(candidate)"
                                                                src="../.././assets/Background/vector5.jpg" class="posi1">
                                                        </div>
                                                    </div>
                        
                        
                                                </div>
                                                <div class="col-xs-6 text-center
                                                                                                                        selectbox1">
                                                    <div class="row">
                                                        <div class="col-xs-6 p-rem">
                                                            <h6>Select<br> Candidate:</h6>
                                                        </div>
                                                        <div class="col-xs-6">
                                                            <img src="../../../assets/Background/Group3.png" class="posi1">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>

    <div class="modal" id="exampleModal" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog model-xl modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" (click)="ACTIVEMODEL= ''"
                        class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    ...
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"
                        data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div>
            </div>
        </div>
    </div>

    <!-- register popup -->
    <app-register-job [ACTIVEMODEL]="ACTIVEMODEL"
        (hideModel)="hidenModel($event)"></app-register-job>

    <!-- proceed payment -->
    <div class="modal" [ngClass]="{modalShow: paymentMODEL == 'payment',
        modalHide: paymentMODEL !== 'payment'}" id="paymentmodal" tabindex="-1"
        role="dialog" aria-labelledby="paymentmodal" aria-hidden="true">
        <div class="modal-dialog model-xl modal-dialog-centered" role="document">
            <div class="modal-content btn1-grad-border">

                <button type="button" (click)="paymentMODEL= ''" class="close"
                    data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style="float: right;
                        width: 29px;
                        font-size: 26px;outline: none;">×</span>
                </button>


                <div class="modal-body">
                    <div class="mt-to-bt popup">
                        <div class="inner-text">
                            <h2 class="text-center">Payment Summary</h2>
                            <hr class="border113">
                        </div>
                        <div class="summary">
                            <p class="text-center">To retrieve the contact
                                details of your chosen candidates, you will be
                                redirected to complete payment of R399
                            </p>
                        </div>
                        <div class="row content">
                            <div class="col-md-12">
                                <label for="" class="take1">Method of payment</label>
                            </div>
                            <div class="col-md-12">
                                <select class="option option-job">
                                    <option value="JobTitle">Job Title</option>
                                    <option value="JobTitle">JobTitle</option>
                                    <option value="JobTitle">JobTitle</option>
                                    <option value="JobTitle">JobTitle</option>
                                </select>


                                <div _ngcontent-c0="" class="text-center">
                                    <button _ngcontent-c0=""
                                        class="btn-btn-grad
                                        grad-btn"> Proceed </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</section>