import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SearchComponent } from './search.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenubarModule } from '../../components/menubaar/aurthentication/menubar.module';
import { CommonModule } from '@angular/common';

import { RegisterJobModule } from '../../register-job/register-job.module';
import { FooterGreyModule } from '../../components/footer-grey/footer-grey.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({

    declarations: [SearchComponent],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: SearchComponent, pathMatch: 'full' }
        ]),
        MenubarModule,
        CommonModule,
        FooterGreyModule,
        RegisterJobModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgSelectModule
    ],
})

export class SearchModule {}