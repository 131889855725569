export class CadidateListFilterParameterModel {
    AgeFrom?: string | any = null;
    AgeTo?: string | any = null;
    Gender?: string | any = null;
    Race?: string | any = null;
    Relationship?: string | any = null;
    Language?: string | any = null;
    Citizan?: string | any = null;
    Qualification?: string | any = null;
    Field?: string | any = null;
    JobTitle?: string | any = null;
    Industry?: string | any = null;
    YearOfExp?: string | any = null;
    Level?: string | any = null;
    public constructor(init?: Partial<CadidateListFilterParameterModel>) {
      Object.assign(this, init);
    }
  }
  
  export class CadidateListLocalModel extends CadidateListFilterParameterModel {
    mainQualification?: string | any = null;
    JobId?: string | any = null;
    minimumQualification?: string | any = null;
    public constructor(init?: Partial<CadidateListFilterParameterModel>) {
      super();
      Object.assign(this, init);
    }
  }