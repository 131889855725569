import { Component, OnInit } from "@angular/core";
import { AppService } from "../app.service";

import { Router } from "@angular/router";
import { forkJoin } from "rxjs";
import * as _ from "lodash";
import { DatePipe } from "@angular/common";
import { FilterCriteria, QueryType } from "../app.class";



@Component({
    selector: 'app-after-online-payment',
    templateUrl: './after-online-payment.component.html',
    styleUrls: ['./after-online-payment.component.scss'],
    providers: [
        DatePipe
    ]
})
export class AfterOnlinePaymentComponent implements OnInit {

    CurrentUser: any;

    constructor(private appService: AppService,
        private router: Router,
        private datePipe: DatePipe) {
            
        if (!!localStorage.getItem("UserInfo")) {
            
            this.CurrentUser = JSON.parse(localStorage.getItem("UserInfo"))
            if (!this.CurrentUser.SubscriptionPlan) {
                this.CurrentUser.SubscriptionPlan = 0;
            }
            let paymentDataEntry = {
                PaymentMode: this.CurrentUser["PaymentMode"],
                RecruiterID: this.CurrentUser["ID"],
                Amount: this.CurrentUser["SubscriptionPlan"],
                JobID: this.CurrentUser["JobId"],
                PaymentStatus: 1183,
                Date: new Date().getDate(),
                PaymentItem: !!this.CurrentUser && !!this.CurrentUser.SelectedCandidate ? this.CurrentUser.SelectedCandidate.length : 0
            }
            
            let WhereClause = new FilterCriteria();
            WhereClause.Filters = [];
            WhereClause.FilterLogic = "1";
            let dataObj = this.setData(paymentDataEntry)
            let dataD = {
                QueryObjectID: "PaymentInfo",
                QueryType: QueryType.Insert,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj
            };
            
            this.appService.executeQuery(dataD, QueryType.Insert).subscribe(response => {
                let selectedCandidateToUpdate = this.CurrentUser.SelectedCandidate;
                if (!!selectedCandidateToUpdate && selectedCandidateToUpdate.length >= 1) {
                    
                    this.callAPI(selectedCandidateToUpdate).subscribe(a => {
                        this.router.navigate(['/candidateslist']);
                    });
                }
            });
        }
    }
    ngOnInit() {

    }
    callAPI(data) {
        let createCall = [];
        _.each(data, (d:any) => {
            let obj = this.setObj(d);
            const WhereClause : any = new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "ID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: d.ID
                }];
            WhereClause.FilterLogic = "1";
            const dataObj = this.setObj(d);
            const dataD = {
                QueryObjectID: "SelectedCandidateInfo",
                QueryType: QueryType.Update,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj
            };

            let call = this.appService.execution(dataD, this.appService.MAIN_API_URL + "v1/CRUD/Update", true);
            createCall.push(call);
        });


        return forkJoin(createCall);
    }
    setData(data): any {
        const date = new Date();
        return [{
            AppFieldID: "TransactionID",
            Value: "Trs_" + this.getRandomInt(1, 1000)
        },
        {
            AppFieldID: "PaymentMode",
            Value: data.PaymentMode
        },
        {
            AppFieldID: "RecruiterID",
            Value: data.RecruiterID

        },
        {
            AppFieldID: "Amount",
            Value: data.Amount

        },
        {
            AppFieldID: "JobID",
            Value: data.JobID

        },
        {
            AppFieldID: "PaymentStatus",
            Value: data.PaymentStatus

        },
        {
            AppFieldID: "Date",
            Value: new Date()


        },
        {
            AppFieldID: "PaymentItem",
            Value: data.PaymentItem
        },
        {
            AppFieldID: "UpdatedOn",
            Value: null

        }
        ];
    }


    setObj(data) {
        return [
            {
                AppFieldID: "CandidateID",
                Value: data.CandidateID
            },
            {
                AppFieldID: "RecruiterID",
                Value: data.RecruiterID

            },
            {
                AppFieldID: "IsPaymentDone",
                Value: 1

            },
            {
                AppFieldID: "JobID",
                Value: data.JobID

            },
            {
                AppFieldID: "CreatedOn",
                Value: null

            },
            {
                AppFieldID: "UpdatedOn",
                Value: null

            }
        ];
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


}