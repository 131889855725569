<section id="my-jobs" class="my-jobs-page">
    <div class="my-jobs-page-container">
        <div class="stepper-myjob col-md-4 pt-5">
            <app-steps></app-steps>
        </div>
       <div class="col-md-8">
        <div *ngIf="!!RecruiterJobList">
            <div *ngIf="RecruiterJobList.length == 0">
                <div class="myjob">
                    <h2 class="text-center">My Jobs</h2>
                </div>            
                <div class="row no-data">
                    <div class="col-md-12 pl-info">
                        <h2>You have not posted any jobs. Click here to post a job for free</h2>
                        <br />
                        <button type="button" [routerLink]="'/post-job'" class="btnn btn-grad-filled">Post Job</button>
                    </div>
                </div>
            </div>
            <div *ngIf="!!RecruiterJobList">
                <div class="d-flex justify-content-center" *ngIf="RecruiterJobList.length > 0">
                    <h1 class="heading mb-2 mt-2">My Jobs</h1>
                </div>
                <div *ngFor="let job of RecruiterJobList | paginate: config"  >
                        <div>
                            <form action="">
                                        <div class="info-box">
                                            <div class="row">
                                            <div class="col-md-5">
                                                <div>
                                                    <div class="show-div">
                                                        <div class="d-flex mb-2" [routerLink]="'/post-job/' + job.ID + '/' + 'View'">   
                                                            <label for="" class="job">Job Title:</label>                                            
                                                            <span class=""> <span class="job">{{job.JobTitle}}</span></span>
                                                            </div>
                                                        <div class="d-flex mb-2">
                                                            <label for="" class="label-text">Address:</label>
                                                            <p class="simple-text">{{job.JobCity}} , {{job.JobState}}</p>
                                                        </div>
                                                        <div class="d-flex mb-2">
                                                            <label for="" class="label-text">RefNo:</label>
                                                            <p class="simple-text">{{job.RefNo}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="hide-div">
                                                        <div>
                                                            <h4 [routerLink]="'/post-job/' + job.ID + '/' + 'View'">
                                                                <div class="d-flex mb-2">
                                                                    <label for="" class="job">Job Title:</label>
                                                                    <span class=""> <span class="job">{{job.JobTitle}}</span></span>
                                                                </div>
                                                            </h4>
                                                            <!-- <hr class="border-color"> -->
                                                            <div class="d-flex mb-2">
                                                                <label for="" class="label-text">Address:</label>
                                                                <p class="simple-text">{{job.JobCity}} {{job.JobState}}</p>
                                                            </div>
                                                            <div class="d-flex mb-2">
                                                                <label for="" class="label-text">RefNo:</label>
                                                                <p class="simple-text">{{job.RefNo}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-1">
                                                            <div class="icon-hide">
                                                                <!-- <div>
                                                                    <img class="img-share"
                                                                        src="assets/Background/Vector3.png" alt="">
                                                                </div> -->
                                                                <!-- <div>
                                                                    <img class="img-edite" [routerLink]="'/post-job/' + job.ID + '/' + 'edit'"
                                                                        src="assets/Background/Vector1.png" alt="">
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <div class="d-flex">
                                                        <label for="" class="label-text ">Status:</label>
                                                        <!-- <select name="Open" class="ml-2 status-select-layout decorated" [(ngModel)]="job.Status"
                                                            (change)="ChangeJobStatus($event, job.ID)" id="myselect">
                                                            <option *ngFor="let job of JobStatusList" value={{job.ID}} class="option-hover-color">
                                                                {{job.DisplayValue}}
                                                            </option>
                                                        </select> -->
                                                        
                                                        <ng-select class="drop3 dropdwon-color custom-class custom-ng-select" [(ngModel)]="job.Status" [ngModelOptions]="{standalone: true}"  
                                                        [placeholder]="'Select status'" [clearable]="false"
                                                             (change)="ChangeJobStatus(job)">
                                                            <ng-option *ngFor="let job of JobStatusList" [value]="job.ID">{{job.DisplayValue}}</ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="d-flex">
                                                        <label for="" class="label-text">Date
                                                            Created:</label>
                                                        <label for="" class="simple-text"> {{job.CreatedDate |  date:'dd/MM/yyyy'}} </label>
                                                    </div>
                                                </div>
                                                <div  >
                                                    <div class="d-flex">
                                                        <label for="" class="label-text">Applicants:</label>
                                                        <label for="" class="simple-text">{{job.AppliedCandidateCount}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="button-container d-flex align-items-center text-right col-md-3">
                                                <button class="btn blue-bottom-button mb-2" [routerLink]="'/view-applicants/' + job.ID">
                                                    View Applicants
                                                </button>
                                                <button class="btn blue-bottom-button" [routerLink]="'/post-job/' + job.ID + '/' + 'edit'">
                                                    Edit Job
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                            </form>
                        </div>
                    <!-- <div class="col-md-1 pr-myjob" style="padding-bottom: 32px;">
                        <div class="info-box-fa module2 clienrs" style="height: 100%;"> -->
                            <!-- <div class="text-center bor">
                                <button class="share1">
                                    <img
                                        src="assets/Background/Vector3.png"
                                        alt="">
                                    <p class="text-center">Share</p>
                                </button>
                            </div> -->
                            
                        <!-- </div>
                    </div> -->
                </div>
                <pagination-controls  *ngIf="this.RecruiterJobList.length > 0" (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
          </div>
       </div>
    </div>
</section>