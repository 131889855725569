import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';


import { from } from 'rxjs';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenubarModule } from '../components/menubaar/aurthentication/menubar.module';
import { FavoritesComponent } from './favorites.component';

import { FooterGreyModule } from '../components/footer-grey/footer-grey.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';

// import { HeaderComponent } from '../../components/toolbar/aurthentication-toolbar/header.component';



@NgModule({

    declarations: [],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: FavoritesComponent, pathMatch: 'full' }
        ]),
        MenubarModule,
        FooterGreyModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        NgMultiSelectDropDownModule,
        NgSelectModule
    ],
})

export class FavoritesModule {

}
