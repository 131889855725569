import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as LoginClasses from "./login-classes";
import { LoginService } from './login.service';
import { AppService } from '../../app.service';

import { Router } from '@angular/router';
import Swal from 'sweetalert2';
//import { AngularFireDatabase, AngularFireObject ,AngularFireList } from "angularfire2/database"; 
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs/Observable';
import * as firebase from 'firebase/app';
import { Meta, Title } from '@angular/platform-browser';
import { SeoDetailModel } from '../../core/core.class';
import { CoreService } from '../../core/core.service';
import { QueryType } from '../../app.class';
@Component({
    selector: 'app-login-view',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    loginForm: FormGroup;
    loginFormErrors: any;
    emailPattern: string | RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    show = false;
    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();

    password() {
        this.show = !this.show;
    }

    constructor(private formBuilder: FormBuilder, private _loginService: LoginService,
        private appService: AppService,
        public router: Router,
        private _af: AngularFireDatabase,
        private coreService: CoreService,
    ) {


        // this.titleService.setTitle("dhbdhbsdhbhsdb");
        // this.meta.updateTag({ name: "keywords", content: "dbnd,fbhkfb,fbkfb,fbjhfbjh" });

        // var ref =  _af.object("Conversation").valueChanges();

        // ref.subscribe(action => {
        //     console.log(JSON.stringify(action));
        // });
        // this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
        //     .subscribe((data: any) => {
        //         if (!!data && !!data.Result) {
        //             this.SList = data.Result;
        //             if (this.SList.length >= 1) {
        //                 this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2511));
        //             }
        //         }
        //     })


        this.loginFormErrors = {
            email: {},
            password: {}
        };

    }
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2511));
        }
    }
    ngOnInit(): void {
        // localStorage.clear();
        if (localStorage.getItem('UserInfo')) {
            // User is already logged in, redirect to home page
            this.router.navigate(['/home-page']);
        }
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
            password: ['', Validators.required]
        });

        this.loginForm.valueChanges.subscribe(() => {

            this.onLoginFormValuesChanged();
        });
    }


    // user Sync
    userSync():any{
        const data = {
            "QueryObjectID": "UserInfo",
            "QueryType": 0,
            "ResultField_AppfieldIds": [

            ],
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [ ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            var data =response.Result.slice(450,500);
            // var pass =window.atob(data.Password)
            // this.appService.RegisterToken(data.UserName,pass).subscribe(response => {
            //              
            //             if(response.StatusCode ==200 ){
            //             }
            //         });
            data.forEach(element => {
                var role = element
                var pass =window.atob(role.Password)
                this.appService.RegisterToken(role.UserName,pass).subscribe(response => {      
                    if(response.StatusCode ==200 ){
                    }
                });
            });
        });
    }

    // On Login Click
    userLogin(): any {
        
        //document.getElementById('paypalbtn').click();
        const loginUser = new LoginClasses.LoginDetails();
        Object.assign(loginUser, this.loginForm.value);
        if (!!loginUser.email && !!loginUser.password) {
            if (this.loginForm.status != 'INVALID') {
                this.appService.GetToken(loginUser.email,loginUser.password).subscribe(response => {            
                        if(response.StatusCode ==200)
                        {
                            localStorage.setItem("UserToken" ,"bearer "+" "+ response.Result.Token);
                            localStorage.setItem("Token",response.Result.Token);
                            localStorage.setItem("RefreshToken" , response.Result.RefreshToken);
                                const user = { username: loginUser.email, password: loginUser.password };
                                const data = {
                                    "QueryObjectID": "UserInfo",
                                    "QueryType": 0,
                                    "ResultField_AppfieldIds": [

                                    ],
                                    "WhereClause": {
                                        "FilterLogic": "1",
                                        "Filters": [
                                            {
                                                "ConjuctionClause": 1,
                                                "FieldID": "UserName",
                                                "RelationalOperator": 3,
                                                "ValueType": 0,
                                                "value": user.username
                                            }

                                        ]
                                    }
                                };
                                this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
                                    if (response.StatusCode == "200" && response.Result.length > 0) {
                                        var UserID = response.Result[0].ID;
                                        if(response.Result[0].UserType != 2)
                                        {
                                            Swal({
                                                title:'Login Failed',
                                                text:'No such Recruiter available, please check try again.'
                                            })
                                        }

                                    else if (response.Result[0].Status == 1) {
                                            this.getProfileInfo(response.Result[0].ID);
                                        } else {
                                            Swal({
                                                // type: 'Warning',
                                                title: 'Login failed',
                                                text: 'Incorrect username or password!',
                                            });
                                        }
                                    } else {
                                        Swal({
                                            // type: 'Warning',
                                            title: 'Login failed',
                                            text: 'Incorrect username or password!',
                                        });
                                    }
                                });
                            }
                    });

            } else {
                Swal({
                    // type: 'Warning',
                    title: 'Required',
                    text: 'Please, enter valid values!',
                });
            }
        } else {
            Swal({
                // type: 'Warning',
                title: 'Required',
                text: 'Please, enter all required values!',
            });

            this.onLoginFormValuesChanged();
            this.loginForm.get('email').markAsTouched();
            this.loginForm.get('password').markAsTouched();
        }

        
    }

    private getProfileInfo(RecruiterID) {
        let data = {
            "QueryObjectID": "GetRecruiterInfo",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "UserID",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": RecruiterID  // use recruiter id here
                    }
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                localStorage.setItem("UserInfo", JSON.stringify(response.Result[0]));
                var person = response.Result[0];
                localStorage.setItem("UserInfo", JSON.stringify(response.Result[0]));
                this.router.navigate(['/home-page']);
                var object = {
                    id: person.UserID,
                    isOnline: 1,
                    name: person.FirstName + ' ' + person.LastName,
                    profile: person.PhotoURL
                }
                var ref = this._af.object("User/" + person.UserID);
                const seting = ref.update(object);
                seting.then(_ => {
                    console.log("success");
                    // Call this.coreService.login() after successful operation
                    this.coreService.login();
                  }).catch(error => {
                    console.log(error);
                });
                this.router.navigate(['/home-page']);
            }
        });
    }

    onLoginFormValuesChanged(): any {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if ((control && control.dirty && !control.valid)) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }
}