import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { from } from 'rxjs';
import { MyJobsComponent } from './my-jobs.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenubarModule } from '../components/menubaar/aurthentication/menubar.module';
import { SteperModule } from '../components/steps/steps.module';
import { CommonModule } from '@angular/common';
import { FooterGreyModule } from '../components/footer-grey/footer-grey.module';
import { FormsModule } from '@angular/forms';

import {NgxPaginationModule} from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
    declarations: [
        MyJobsComponent,
    ],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: MyJobsComponent, pathMatch: 'full' }
        ]),
        MenubarModule,
        SteperModule,
        FooterGreyModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgxPaginationModule,
        NgSelectModule
        
    ],
    entryComponents: [],
    exports: [
        MyJobsComponent,

    ]
})

export class MyJobModule {

}
