<section id="home" class="home-page">
    <div class="home-page-container">
        <div class="col-md-4">
            <app-steps></app-steps>
        </div>
        <div class="col-md-8 vertical-line">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="box" (click)="ACTIVEMODEL='Register'">
                            <div class="icon-container">
                                <i class=" fa fa-clipboard"></i>
                            </div>
                            <div class="text">
                                Register a Job
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4   ">
                        <div class="box" [routerLink]="'/searchmember'">
                            <div class="icon-container">
                                <i class=" fa fa-search"></i>
                            </div>
                            <div class="text">
                                Search Members
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4   ">
                        <div class="box" [routerLink]="'/post-job'">
                            <div class="icon-container">
                                <i class=" fa fa-briefcase"></i>
                            </div>
                            <div class="text">
                                Post Job Advert
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="box" [routerLink]="'/my-jobs'">
                            <div class="icon-container">
                                <i class=" fa fa-tasks"></i>
                            </div>
                            <div class="text">
                                My Jobs
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4   ">
                        <div class="box" [routerLink]="'/all-candidates'">
                            <div class="icon-container">
                                <i class=" fa fa-users"></i>
                            </div>
                            <div class="text">
                                My Candidates
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4   ">
                        <div class="box" [routerLink]="'/favorites'">
                            <div class="icon-container">
                                <i class=" fa fa-heart"></i>
                            </div>
                            <div class="text">
                                Favorites
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4   ">
                        <div class="box" [routerLink]="'/messages'">
                            <div class="icon-container">
                                <i class=" fa fa-envelope"></i>
                            </div>
                            <div class="text">
                                Messenger
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4   ">
                        <div class="box" [routerLink]="'/my-profile'">
                            <div class="icon-container">
                                <i class=" fa fa-user"></i>
                            </div>
                            <div class="text">
                                My Profile
                            </div>
                        </div>
                    </div>
<!-- 
                    <div class="col-md-4 d-none">
                        <div class="box">
                            <div class="icon-container">
                                <i class=" fa fa-life-ring"></i>
                            </div>
                            <div class="text">
                                Support
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="mt-content-hide">
                        <div class="col-xs-6 job-p">
                            <div class="img-wrapper"
                                (click)="ACTIVEMODEL='Register'">
                                <img class="rect5"
                                    src="/assets/Background/Rectangle2.png">
                                <div class=" ">
                                    Register a Job
                                </div>
                            </div>
    
                        </div>
                        <div class="col-xs-6 job-p">
                            <div class="img-wrapper" [routerLink]="'/searchmember'">
                                <img class="rect5"
                                    src="/assets/Background/Rectangle3.png">
                                <div class=" ">
                                    Search Members
                                </div>
                            </div>
    
                        </div>
                        <div class="col-xs-6 job-p">
                            <div class="img-wrapper" [routerLink]="'/post-job'">
                                <img class="rect5"
                                    src="/assets/Background/Rectangle4.png">
                                <div class=" ">
                                    Post Job Advert
                                </div>
                            </div>
    
                        </div>
    
                        <div class="col-xs-6 job-p">
                            <div class="img-wrapper" [routerLink]="'/my-jobs'">
                                <img class="rect5"
                                    src="/assets/Background/Rectangle5.png">
                                <div class=" ">
                                    My Jobs
                                </div>
                            </div>
    
                        </div>
    
                        <div class="col-xs-6 job-p">
                            <div class="img-wrapper" [routerLink]="'/all-candidates'">
                                <img class="rect5"
                                    src="/assets/Background/Rectangle6.png">
                                <div class=" ">
                                    My Candidates
                                </div>
                            </div>
    
                        </div>
    
                        <div class="col-xs-6 job-p">
                            <div class="img-wrapper" [routerLink]="'/favorites'">
                                <img class="rect5"
                                    src="/assets/Background/Rectangle7.png">
                                <div class=" ">
                                    Favorites
                                </div>
                            </div>
    
                        </div>
    
                        <div class="col-xs-6 job-p">
                            <div class="img-wrapper" [routerLink]="'/messages'">
                                <img class="rect5"
                                    src="/assets/Background/Rectangle8.png">
                                <div class=" ">
                                    Messenger
                                </div>
                            </div>
    
                        </div>
                        <div class="col-xs-6 job-p">
                            <div class="img-wrapper"
                                [routerLink]="'/my-profile'">
                                <img class="rect5"
                                    src="/assets/Background/Rectangle9.png">
                                <div class=" ">
                                    My Profile
                                </div>
                            </div>
    
                        </div>
                        <div class="col-xs-6 job-p">
                            <div class="img-wrapper">
                                    <img class="rect5" src="/assets/Background/Rectangle10.png">
                                <div class=" ">
                                    Support
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- <app-register-job-view></app-register-job-view> -->
    <div class="modal" [ngClass]="{modalShow: ACTIVEMODEL == 'Register',
        modalHide: ACTIVEMODEL !== 'Register'}" id="exampleModal" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog model-xl modal-dialog-centered" role="document">
            <div class="modal-content btn1-grad-border alignborder">

                <button type="button" (click)="ACTIVEMODEL= ''" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style="float: right;
                        width: 29px;
                        font-size: 26px;outline: none;">×</span>
                </button>

                <div class="modal-body">
                    <div class="mt-to-bt popup">
                        <div class="inner-text">
                            <h2 class="text-center">Register a Job</h2>
                            <hr class="border112">
                        </div>

                        <div class="row content">
                            <div class="col-md-12">
                                <label for="" class="take1 mb-2">Select Job
                                    title:</label>
                            </div>
                            <div class="col-md-12">
                                <!-- Job Title Dropdown with Search -->
                                <ng-select [items]="jobTitles" [searchable]="true" bindLabel="DisplayValue"
                                    bindValue="ID" placeholder="Search job title" [(ngModel)]="selectedjobtitle">
                                </ng-select>
                                <div _ngcontent-c0="" class="text-center">
                                    <button _ngcontent-c0="" class="btn blue-button" (click)="register()"> Register </button>
                                </div>
                                <!-- <div class="take1">
                                            <a class="align" style="text-decoration: underline;color: #6C6C6Cff;" href="https://www.searchadam.com/addjob" target="_blank">I can't find the Job title I'd like to register</a>
                                        </div> -->
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</section>