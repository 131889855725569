<section class="favorites-member-page">
    <div class="favorites-page-container">
        <div class="row heading-container justify-content-center pr-0">
            <h1 class="heading">These are the candidates that you have added to favourites.
                Click to view their profiles.
            </h1>
        </div>
        <div class="">
            <div class="">
                <div class="mycandidate text-center">
                    <h1 class="text-center">Favorites</h1>
                </div>
    
                <div class="col-xs-12 search-btn d-flex justify-content-center mb-4 mt-3">
                    <button class="btn-grad-border" (click)="Isfilter = !Isfilter">
                        Filter<img src="../../../assets/Background/Vectorrrrr.png" width="14px">
                    </button>
                    
                </div>
                <div class="col-md-12 search">
                    <div class="row pr-overstep">
                        <div class="col-md-4 pl-first-filter pl-filter" [ngClass]="{'filter-wrapper': Isfilter == false, 'filter-wrapper1' : Isfilter == true}">
                            <div class="col b-shadow">
                                <h1 class="heading">Filter
                                    Candidates
                                </h1>
                                <div class="tabs" [ngClass]="{'expanded-tab': isTabExpanded3}">
                                    <div class="tab" [ngClass]="{'expanded-tab': isTabExpanded1}">
                                        <input type="checkbox" id="chck1" class="checkint" checked>
                                        <label class="tab-label f-info b-top-ref section-title" for="chck1" (click)="toggleTab1()">Personal
                                            Information</label>
                                        <div class="tab-content">
                                            <label class="label-text mb-3 ml-4 margin-left-0">Age:</label>                                           
                                             <div class="col-md-12 r-drop">
                                                <div class="row">
                                                    <div class="col-md-12
                                                        col-xs-12 p-removed r-drop p-0">
                                                        <div class="col-xs-6 pl-0 pr-1">
                                                            <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.AgeFrom" [placeholder]="'From'">                                                          
                                                                <ng-option *ngFor="let age of ageList" [value]="age.SystemValue">
                                                                    {{ age.DisplayValue }}
                                                                </ng-option>
                                                            </ng-select>
                                                        </div>
                                                        <div class="col-xs-6 pr-0 pl-1">
                                                            <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.AgeTo" [placeholder]="'To'">
                                                                <ng-option *ngFor="let age of ageList" [value]="age.SystemValue">
                                                                    {{ age.DisplayValue }}
                                                                </ng-option>
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                </div><br>
                                                <label class="label-text mb-3">Gender:</label> 
                                                <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.Gender" [placeholder]="'Gender'" [searchable]="false">
                                                    <ng-option *ngFor="let gender of GenderList" [value]="gender.ID">
                                                        {{ gender.DisplayValue }}
                                                    </ng-option>
                                                </ng-select>                                            
                                            </div><br>
                                            <div class="col-md-12 r-drop">
                                                <label class="label-text mb-3">Race:</label> 
                                                <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.Race" [placeholder]="'Race'">
                                                    <ng-option *ngFor="let race of RaceList" [value]="race.ID">
                                                        {{ race.DisplayValue }}
                                                    </ng-option>
                                                </ng-select>                                            
                                            </div><br>
                                            <div class="col-md-12 r-drop">
                                                <label class="label-text mb-3">Relationship:</label> 
                                                <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.Relationship" [placeholder]="'Relationship'">
                                                    <ng-option *ngFor="let relationship of RelationShipList" [value]="relationship.ID">
                                                        {{ relationship.DisplayValue }}
                                                    </ng-option>
                                                </ng-select>                                            
                                            </div><br>
                                            <div class="col-md-12 r-drop">
                                                <label class="label-text mb-3">Language:</label> 
                                                <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.Language" [placeholder]="'Language'">
                                                    <ng-option *ngFor="let language of LanguageList" [value]="language.ID">
                                                        {{ language.DisplayValue }}
                                                    </ng-option>
                                                </ng-select>                                            
                                            </div><br>
                                            <div class="col-md-12 r-drop">
                                                <label class="label-text mb-3">Citizenship:</label> 
                                                <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.Citizan" [placeholder]="'Citizenship'">
                                                    <ng-option *ngFor="let citizenship of CitizionshipList" [value]="citizenship.ID">
                                                        {{ citizenship.DisplayValue }}
                                                    </ng-option>
                                                </ng-select>                                            
                                            </div><br>
                                        </div>
                                    </div>
                                    <div class="tab" [ngClass]="{'expanded-tab': isTabExpanded2}">
                                        <input type="checkbox" id="chck2" class="checkint" checked>
                                        <label class="tab-label f-info b-top-ref section-title" for="chck2" (click)="toggleTab2()">Educational
                                            Information</label>
                                        <div class="tab-content">
                                            <div class="col-md-12 r-drop">
                                                <label class="label-text mb-3">Qualification:</label><br>
                                                    <ng-select class="drop3 dropdwon-color" [(ngModel)]="localData.mainQualification" (change)="getMinQualification()" [placeholder]="'Qualification'">
                                                        <ng-option *ngFor="let qualification of qualificationList" [value]="qualification.ID">
                                                            {{ qualification.DisplayValue }}
                                                        </ng-option>
                                                    </ng-select>                                                
                                            </div><br>
                                            <div *ngIf="IsMultiselect == false" class="col-md-12 p-drops mob-p-r">
                                                <label class="label-text mb-3">Minimum Qualification:</label> 
                                                <ng-select class="drop3 dropdwon-color" (change)="setValueforMinCriteria($event)" [placeholder]="'Minimum Qualification'"
                                                 [(ngModel)]="paramterFilter.minimumQualification">
                                                    <ng-option *ngFor="let qualification of minQualification" [value]="qualification.ID">
                                                        {{ qualification.DisplayValue }}
                                                    </ng-option>
                                                </ng-select> 
                                            </div>
                                            <div *ngIf="IsMultiselect == true" class="col-md-12 p-drops">
                                                <label class="label-text mb-3">Minimum Qualification:</label>
                                                <ng-multiselect-dropdown [placeholder]="'Minimum Qualification'" [data]="multiMinQualification"
                                                    [(ngModel)]="selectedQualification" [settings]="dropdownSettings"
                                                    (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)">
                                                </ng-multiselect-dropdown>
                                            </div>
                                            <br>
                                            <div *ngIf="fieldofstudy==true" class="col-md-12 r-drop">
                                                <label class="label-text mb-3">Field of Study:</label> 
                                                <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.Field" [placeholder]="'Field of Study'">
                                                    <ng-option *ngFor="let field of fieldOfStudyList" [value]="field.ID">
                                                        {{ field.DisplayValue }}
                                                    </ng-option>
                                                </ng-select>                                         
                                            </div><br>
                                        </div>
                                    </div>
                                    <div class="tab" [ngClass]="{'expanded-tab': isTabExpanded3}">
                                        <input type="checkbox" id="chck3" class="checkint" checked>
                                        <label class="tab-label f-info b-top-ref section-title mb-0" for="chck3" (click)="toggleTab3()">Professional
                                            Information</label>
                                        <div class="tab-content" [ngClass]="{'expanded-tab-content': isTabExpanded3}">
                                            <div class="col-md-12 r-drop">
                                                <label class="label-text mb-3">Job title:</label><br>
                                                <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.JobTitle" [placeholder]="'JOb'">
                                                    <ng-option *ngFor="let job of JobList" [value]="job.ID">
                                                        {{ job.DisplayValue }}
                                                    </ng-option>
                                                </ng-select>                                            
                                            </div><br>
                                            <div class="col-md-12 r-drop">
                                                <label class="label-text mb-3">Industry:</label> 
                                                <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.Industry" [placeholder]="'Industry'">
                                                    <ng-option *ngFor="let industry of industryList" [value]="industry.ID">
                                                        {{ industry.DisplayValue }}
                                                    </ng-option>
                                                </ng-select>                                            
                                            </div><br>
                                            <div class="row">
                                                <div class="col-md-12 col-xs-12
                                                    p-right r-drop">
                                                    <div class="col-xs-7" style="padding:
                                                        0px;">
                                                        <label class="label-text mb-3">Experience:</label>
                                                    </div>
                                                    <div class="col-xs-5 p-remv pr-0">
                                                        <ng-select class="drop7" [placeholder]="'Year'">
                                                            <ng-option *ngFor="let year of yearsOfExp" [value]="year.ID">
                                                                {{ year.DisplayValue }}
                                                            </ng-option>
                                                        </ng-select>                                                    
                                                    </div>
                                                </div>
                                            </div><br>
                                            <div class="row">
                                                <div class="col-md-12 r-drop">
                                                    <label class="label-text mb-3">Level:</label>
                                                    <ng-select class="drop3 custom-dropdown" [(ngModel)]="paramterFilter.Level" [placeholder]="'Select Level'" style="max-height: 200px;">
                                                        <ng-option *ngFor="let level of LevelList" [value]="level.ID">
                                                            {{ level.DisplayValue }}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                            </div><br>
                                            <!-- <div class="row">
                                                <div class="col-md-12 col-xs-12
                                                    p-right r-drop">
                                                    <div class="col-xs-7" style="padding: 0px;">
                                                        <label class="mrglab1
                                                            pl-years">Years
                                                            of experience at level:</label>
                                                    </div>
                                                    <div class="col-xs-5 p-remv">
                                                        <select class="drop7">
                                                            <option value="0">
                                                                Year
                                                            </option>
                                                            <option *ngFor="let gender of yearsOfExp" value={{gender.ID}}>
                                                                {{gender.SystemValue}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <br>
                                            <div class="text-right button-container">
                                                <button type="button" class="btn blue-bottom-button mr-2" (click)="applyfilter(); Isfilter = false">Apply Filter</button>
                                                    <br/><br/>
                                                    <button type="button" class="btn blue-bottom-button mr-2" (click)="clearFilter(); Isfilter = false">Clear Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 p-removed">
                            <div *ngIf="this.favouriteListData.length == 0" class="d-flex align-items-center justify-content-center centered-text" style="min-height: calc(100vh - 50vh);">
                                <div class="text-center">
                                    <h1 class="no-record-heading"> No such records found. Please try again later</h1>
                                </div>
                            </div>
                            <div class="row box-design r-hiden"  *ngFor="let favorite of this.favouriteListData | paginate: config">
                                <div class="col-md-12 col-sm-12">
                                    <div class="col-md-3 col-sm-3 round">
                                        <div class="infoimage text-center">
                                            <div [routerLink]="'/candidate-profile/' + favorite.userid + '/' + favorite.PersonID + '/' + favorite.CandidateID + '/' + favorite.JobID" class="otherinfo">
                                                <img *ngIf="favorite.ImagePath == null || favorite.ImagePath == undefined" src="../../assets/Background/Ellipse.png" class="imageeee">
                                                <img *ngIf="!!favorite.ImagePath" src="{{favorite.ImagePath}}" class="imageeee">
                                            </div>
    
                                        </div>
                                    </div>
                                    <div class="col-md-7 col-sm-7 info" style="padding-top:12px">
                                        <span class="border-text">
                                        <span class="text-left greatitle" [routerLink]="'/candidate-profile/' + favorite.userid + '/' + favorite.PersonID + '/' + favorite.CandidateID + '/' + favorite.JobID">
                                            {{favorite.firstname}} {{favorite.lastname}}
                                            <!-- <hr class="horizontalrow"> -->
                                        </span>
                                    </span>
                                        <p class="location mb-0" style="margin-top: 5px"><b>{{favorite.StateName}}</b></p>
                                        <p class="location job-title"><b>{{favorite.JobTitle}} - {{favorite.RefNo}}</b></p>
                                        <p class="message"><i class="boxinfo">{{favorite.Quote}}</i></p>
                                    </div>
                                    <div class="col-md-2 col-xs-12 col-sm-2 choise">
                                        <div class="col-md-12 col-xs-6 text-center
                                            bordergread">
                                            <h6 class="text-center">Select<br>
                                                Candidate:
                                            </h6>
                                            <!-- <img
                                                src="../../../assets/Background/Group3.png"
                                                class="posi"> -->
                                                <!-- {{favorite.id}} -->
                                            <div class="form-group">
                                                <input type="checkbox" id="{{favorite.id}}" [ngClass]="{'cu-pointer' : favorite.isPayment == true}"  [disabled]="favorite.isPayment" [(ngModel)]="favorite.IsSelected" (change)="selectCanididate($event, favorite.CandidateID, favorite.JobID)">
                                                <label for="{{favorite.id}}" [ngClass]="{'cu-pointer' : favorite.isPayment == true}" ></label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-xs-6 bordergread1
                                            text-center">
                                            <h6 class="text-center">Favorite <br>Candidate:</h6>
                                            <img style="cursor: pointer;"  (click)="onUnFavoriteClick(favorite)"
                                            src="../.././assets/Background/vector5.jpg" class="posi1">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row box-design r-show" *ngFor="let favorite of this.favouriteListData">
                                <div class="col-xs-12 p-remove">
                                    <div class="col-xs-4 round p-right">
                                        <div class="infoimage text-center">
                                            <div class="otherinfo">
                                                <img *ngIf="favorite.ImagePath == null || favorite.ImagePath == undefined" src="../../assets/Background/Ellipse.png" class="imageeee">
                                                <img *ngIf="!!favorite.ImagePath" src="{{favorite.ImagePath}}" class="imageeee">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-8 info p-remove">
                                        <span class="border-text">
                                            <span class="text-left greatitle" [routerLink]="'/candidate-profile/' + favorite.userid + '/' + favorite.PersonID + '/' + favorite.CandidateID + '/' + favorite.JobID">
                                            {{favorite.firstname}} {{favorite.lastname}}
                                        </span>
                                        </span>
                                        <p class="message"><i class="boxinfo">{{favorite.Quote}}</i></p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12">
                                        <div class="col-xs-6 text-center selectbox">
                                            <div class="row">
                                                <div class="col-xs-6 p-rem">
                                                    <h6>Favorite <br>Candidate:</h6>
                                                </div>
                                                <div class="col-xs-6">
                                                    <img style="cursor: pointer;"  (click)="onUnFavoriteClick(favorite)"
                                            src="../.././assets/Background/vector5.jpg" class="posi1">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 text-center
                                            selectbox1">
                                            <div class="row">
                                                <div class="col-xs-6 p-rem">
                                                    <h6>Select<br> Candidate:</h6>
                                                </div>
                                                <div class="col-xs-6">
                                                    <div class="form-group">
                                                        <input type="checkbox" id="{{favorite.id}}" [ngClass]="{'cu-pointer' : favorite.isPayment == true}"  [disabled]="favorite.isPayment" [(ngModel)]="favorite.IsSelected" (change)="selectCanididate($event, favorite.CandidateID, favorite.JobID)">
                                                        <label for="{{favorite.id}}" [ngClass]="{'cu-pointer' : favorite.isPayment == true}" ></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <pagination-controls *ngIf="favouriteListData.length > 0" (pageChange)="pageChanged($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>