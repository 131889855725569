<div class="page-container">
    <aurthentication-toolbar></aurthentication-toolbar>
    <div class="container">
        <div class="login-layout-container">
            <div class="col-md-5 d-none d-md-block p-0">
                <div class="image-layout">
                    <div class="col logo text-center heading">
                        <img src="./../../../assets/Background/white-recruitlee-logo.svg" width="200px;"
                            [routerLink]="'/login'">
                    </div>
                </div>
            </div>
            <div id="login-form" class="col-md-7 d-md-block login-layout">
                <form name="loginForm" [formGroup]="loginForm" novalidate>
                    <div class="col-md-12 col-sm-12">
                        <!-- Logo for small screens -->
                        <div class="col logo text-center logo d-md-none">
                            <img src="./../../../assets/Background/dark-recruitlee-logo.svg" width="200px;"
                                [routerLink]="'/login'">
                        </div>

                        <!-- Heading for medium screens and larger -->
                        <div class="header-layout d-none d-md-block">
                            <h5 class="heading mb-3">Sign In</h5>
                            <p class="text-gray text-center">Enter e-mail and password to login.</p>
                        </div>
                        <div class="form-group gradient-field">
                            <input type="text" name="username" id="username" class="form-control"
                                placeholder="Enter your email" formControlName="email" required>

                            <div class="alert-msg"
                                *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)">
                                <span *ngIf="loginForm.get('email').hasError('required')">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Email Address is
                                    required
                                </span>

                                <span *ngIf="!loginForm.get('email').hasError('required') &&
                                    loginForm.get('email').hasError('pattern')">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Please enter a valid
                                    email address
                                </span>
                            </div>
                        </div><br>
                        <div class="form-group gradient-field">

                            <input [type]="show ? 'text' : 'password'" name="password" id="password"
                                class="form-control" placeholder="Password" formControlName="password" required>
                            <span class="icon" (click)="password()">
                                <img *ngIf="show == false" width="25" src="../../../assets/Background/eye-show.svg" alt="">
                                <img *ngIf="show == true" width="25" src="../../../assets/Background/eye-hide.svg"
                                    alt="">
                            </span>

                            <div class="alert-msg"
                                *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
                                <span *ngIf="loginForm.get('password').hasError('required')">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Pasword is required
                                </span>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="forget" [routerLink]="'/forgot-password'">
                                <span>Forgot Password?</span>
                            </label><br>
                        </div>
                    </div>
                    <div class="col-sm-12 text-center">
                        <button type="button" class="btn-grad-filled btn-btnn btn-block" (click)="userLogin()">Sign
                            in</button>
                    </div>
                    <div class="link-layout">
                        <p class="">Not a member?</p>
                        <a class="font-weight-bold" [routerLink]="'/register'">Register
                            here</a>
                    </div>
                </form>

                <!-- <div class="experience">
                    <label class="text-gray"> For a better user experience please use Chrome/Mozilla Firefox.
                    </label>
                </div> -->
                <div hidden>
                    <form action="https://sandbox.payfast.co.za/eng/process" method="POST" #form>
                        <input type="hidden" name="merchant_id" value="10015419">
                        <input type="hidden" name="merchant_key" value="s2o90g07oejjt">
                        <input type="hidden" name="amount" value="100.00">
                        <input type="hidden" name="item_name" value="Test Item">
                        <input type="hidden" name="payment_method" value="cc">
                        <input type="hidden" name="name_first" value="John">
                        <input type="hidden" name="name_last" value="Doe">
                        <input type="hidden" name="email_address" value="john@doe.com">
                        <input type="hidden" name="cell_number" value="0823456789">
                        <input type="hidden" name="return_url"
                            value="http://techext-002-site60.atempurl.com/candidate-profile">
                        <input type="hidden" name="cancel_url" value="https://www.yoursite.com/cancel">
                        <input type="hidden" name="notify_url" value="https://www.yoursite.com/notify">
                        <button type="submit" id="paypalbtn" (click)="form.submit()">Search</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>