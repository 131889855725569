
import { Injectable } from '@angular/core';
import * as LoginClasses from './login-classes';

@Injectable()
export class LoginService {

    constructor() {

     }
     login(loginDetails: LoginClasses.LoginDetails) {
       
       var data = loginDetails;
    }
}