import { Component } from "@angular/core";
import { HostListener, Input, Output, EventEmitter } from "@angular/core";
import { AppService } from "../app.service";

import Swal from 'sweetalert2';
import { SeoDetailModel } from "../core/core.class";
import { CoreService } from "../core/core.service";
import { FilterCriteria, QueryType } from "../app.class";
@Component({
    selector: 'app-home-view',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
    closeResult: string;
    ACTIVEMODEL = '';
    jobTitles:any;
    selectedjobtitle:any;
    @Output() hideModel = new EventEmitter();
    currentUser:any;
    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();
    constructor(private appService: AppService, private coreService:CoreService) {
        // set current user
        if(localStorage.getItem("UserInfo") != null &&  localStorage.getItem("UserInfo") != undefined)
        {   
            this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
            .subscribe((data: any) => {
                if (!!data && !!data.Result) {
                    this.SList = data.Result;
                    if (this.SList.length >= 1) {
                        this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2506));
                    }
                }
            })
            this.currentUser=JSON.parse(localStorage.getItem("UserInfo"));
            this.getSystemEnumForJobList(1029);
        }
    }
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2506));
        } 
}

    private getSystemEnumForJobList(enumID) {
        const data = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [

            ],
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "EnumID",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": enumID
                    }
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.jobTitles = response.Result
            } else {
            }
        });

        // this.appService.getListValue(enumID).subscribe(result => {
            
        //     if(result.StatusCode = "200")
        //     {
        //         this.jobTitles = result.Result
        //     }
        // });
    }
    
    hidenModel(data): any {
        this.ACTIVEMODEL = data;
    }
    register() {
        if(this.selectedjobtitle == undefined)
        {
            Swal({
                // type: 'Warning',
                //title: 'Validation',
                text: 'Select Registered Job.',
            });
        }
        else{

        this.ACTIVEMODEL = '';
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
        const lengthOfCode = 10;
        var RefNo =  this.makeRandom(lengthOfCode, possible);
        let WhereClause = new FilterCriteria();
        WhereClause.Filters = [];
        WhereClause.FilterLogic = "1";
        var date = new Date();
        let obj = [
            {
                AppFieldID: "Title",
                Value:parseInt(this.selectedjobtitle)
            },
            {
                AppFieldID: "CreatedBy",
                Value: this.currentUser.ID
            },
            {
                AppFieldID: "RefNo",
                Value: RefNo
            },
            {
                AppFieldID: "IsActive",
                Value: 1
            },
            {
                AppFieldID: "IsPublished",
                Value: 0
            },
            {
                AppFieldID: "CreatedDate",
                Value: date
            },
            {
                AppFieldID: "Status",
                Value: 1177
            }
        ]
            let data = {
                QueryObjectID: "JobInfo",
                QueryType: QueryType.Insert,
                Joins: [],
                WhereClause: WhereClause,
                Values: obj
            };
        this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
          if(response.StatusCode=="200")
          {
            Swal({
                // type: 'Success',
                title: 'Success',
                text: 'Job registered successfully!',
            });
            this.selectedjobtitle = null;
          }
        })
     }
    }
    makeRandom(lengthOfCode: number, possible: string) {
        let text = "";
        for (let i = 0; i < lengthOfCode; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
          return text;
      }
 }
