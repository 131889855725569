<div class="app-footer-grey">
  <div id="footer">
    <ul class="d-flex justify-content-center">
      <!-- <li class="term-service">
          Terms of Service & Privacy Policy
      </li>
   -->
      <li class="navkar"> Copyright 2019 - 2020. All Rights Reserved. Powered by
        <a href="https://www.techextensor.com/" target="blank" style="font-weight: bold;">
          <!-- <img src="../../../assets/Background/OnlyLogo.png" alt=""> -->
          TechExtensor
        </a></li>
      <!-- NavkarTechSpan -->
    </ul>

  </div>
</div>
