import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RegisterJobComponent } from './register-job.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({

    declarations: [RegisterJobComponent],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule
    ],
    entryComponents: [],
    exports: [
        RegisterJobComponent
    ]
})

export class RegisterJobModule {

}
