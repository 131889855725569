import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { PostjobComponent } from './postjob.component';
import { MenubarModule } from '../../components/menubaar/aurthentication/menubar.module';
import { CommonModule } from '@angular/common';
import { RegisterJobModule } from '../../register-job/register-job.module';
import { FooterGreyModule } from '../../components/footer-grey/footer-grey.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({

    declarations: [PostjobComponent],
    imports: [
        AngularFontAwesomeModule,

        RouterModule.forChild([
            { path: '', component: PostjobComponent, pathMatch: 'full' }
        ]),
        MenubarModule,
        FooterGreyModule,
        CommonModule,
        RegisterJobModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule
        // NgbModule
    ],
    exports: [PostjobComponent]

})

export class PostjobModule {

}
