<div class="body">
    <aurthentication-toolbar></aurthentication-toolbar>
    <div id="reset-password-form" class="container middel">
        <form name="resetpasswordForm" class="login-c" [formGroup]="resetpasswordForm" novalidate>
        <div class="row justify-content-center align-items-center login-c">
            <div class="col-md-10 col-lg-8">
                <div class="col-md-12 col-sm-12">

                    <h3 class="text-center heading">Reset Password</h3>

                    <div class="form-group tab-icon1 gradient-field">

                        <input [type]="show1 ? 'text' : 'password'"
                            name="newPassword" id="newPassword"
                            class="form-control" (input)="confirmPassword()"
                            placeholder="New Password" formControlName="Password">
                        <span class="icon1" (click)="newPassword()">
                                <img *ngIf="show1 == false" width="25"
                                src="../../../assets/Background/eye-show.svg"
                                alt="">
                                <img *ngIf="show1 == true" src="../../../assets/Background/eye-hide.svg"
                                alt=""  width="25">                            

                           
                            </span>

                                <div class="alert-msg" 
                                *ngIf="resetpasswordForm.controls['Password'].invalid && (resetpasswordForm.controls['Password'].dirty || resetpasswordForm.controls['Password'].touched)" >
                                <span *ngIf="resetpasswordForm.get('Password').hasError('required')">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Pasword is required
                                    </span>
                                </div>
                    </div>

                    <div class="form-group tab-icon2 gradient-field">

                        <input [type]="show2 ? 'text' : 'password'"
                            name="confirmPassword" id="confirmPassword"
                            class="form-control" (input)="confirmPassword()"
                            placeholder="Confirm Password" formControlName="ConfirmPassword">
                        <span class="icon2" (click)="confirmPasswordShow()">
                                <img *ngIf="show2 == false" width="25"
                                src="../../../assets/Background/eye-show.svg"
                                alt="">
                                <img *ngIf="show2 == true" src="../../../assets/Background/eye-hide.svg"
                                alt=""  width="25">      
                            </span>

                    <div class="alert-msg" 
                    *ngIf="resetpasswordForm.controls['ConfirmPassword'].dirty || resetpasswordForm.controls['ConfirmPassword'].touched || isPasswordNotMatch == true" >
                    <span *ngIf="resetpasswordForm.get('ConfirmPassword').hasError('required')">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Confirm Pasword is required
                        </span>    
                    <span  *ngIf="isPasswordNotMatch == true && !resetpasswordForm.get('ConfirmPassword').hasError('required')">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Passwords must match
                        </span>
                    </div>
                    </div>

                </div>
                <div class="col-sm-12 text-center">

                    <button type="button"  class="btn-grad-filled btn-radius
                        btn-btnn" (click)="ResetPassword()">
                        Submit</button>
                </div><br><br>

            </div>
        </div>
        </form>
    </div>
</div>