<div data-spy="scroll" class="container-fluid">
    <div class="row menubarbox">
        <div class="col-md-2 col-sm-2 logo col-lg-1">
            <img src="../../../../assets/Background/dark-recruitlee-logo.svg"
                width="154px;" [routerLink]="'/home-page'">
            <a class="icon menu-btn" (click)="isMenuHide= !isMenuHide">
                <i class="fa fa-bars"></i>
            </a>
        </div>
        <div  class="col-md-8 col-sm-8 col-lg-9 p-remove plr-nav align-self-center menu-bar"
            [ngStyle]="{'display':
            isMenuHide == true ? 'none'
            : 'flex'}">
            <nav>
                <div class="hoverline">
                    <ul class="p-rem m-0" >
                        <!-- remove a tag -->

                        <li class="acti" [routerLinkActive]="['active']"
                            [routerLink]="'/home-page'"
                            style="margin-left: 0px;"> <span>Home</span>
                            <!-- <hr class="horizontalrow"> -->
                        </li>


                        <li class="acti" [routerLinkActive]="['active']"
                            [routerLink]="'/searchmember'"><span>Search
                                Members</span>
                            <!-- <hr class="horizontalrow"> -->

                        </li>

                        <li class="acti" [routerLinkActive]="['active']"
                            [routerLink]="'/post-job'"><span>Post Job
                                Advert</span>
                            <!-- <hr class="horizontalrow"> -->

                        </li>

                        <li class="acti" [routerLinkActive]="['active']"
                            [routerLink]="'/my-jobs'"><span>My Jobs</span>
                            <!-- <hr class="horizontalrow"> -->

                        </li>

                        <li class="acti has-submenu mob-d-none"  
                           (click)="IsshowMEnu = !IsshowMEnu"
                           ><p class="menu-p m-0"><span>My
                                    Candidates</span></p>

                            <!-- <hr class="horizontalrow"> -->
                            <ul [ngClass]="{'submenu-show': IsshowMEnu == true}" class="submenu">
                                <li class="acti" [routerLinkActive]="['active']" 
                                    [routerLink]="'/all-candidates'"><span>All
                                        candidate application</span></li>
                                        <li class="acti" [routerLinkActive]="['active']"  [routerLink]="'/candidate-contactlist'"><span>Selected candidate list</span></li>
                                <li class="acti" [routerLinkActive]="['active']" 
                                    [routerLink]="'/candidateslist'"><span>Candidate
                                        contact list</span></li>
                               
                            </ul>
                        </li>
                        <li class="acti lg-d-none" [routerLinkActive]="['active']" 
                        [routerLink]="'/all-candidates'"><span>All
                            candidate application</span></li>
                            <li class="acti lg-d-none" [routerLinkActive]="['active']"  [routerLink]="'/candidate-contactlist'"><span>Selected candidate list</span></li>
                    <li class="acti lg-d-none" [routerLinkActive]="['active']" 
                        [routerLink]="'/candidateslist'"><span>Candidate
                            contact list</span></li>
                        <li class="acti " [routerLinkActive]="['active']"
                            [routerLink]="'/favorites'"><span>Favorites</span>
                            <!-- <hr class="horizontalrow"> -->

                        </li>


                        <li class="acti" [routerLinkActive]="['active']"
                            [routerLink]="'/messages'"><span>Messenger</span>
                        </li>

                        <!-- <li class="acti"><a class="link"
                                style="text-decoration:none"
                                href="https://www.searchadam.com/support"
                                target="blank"><span>Support</span></a>
                        </li> -->

                        <li class="acti lg-d-none" [routerLinkActive]="['active']"
                        [routerLink]="'/my-profile'"><span>My Profile</span>
                         </li>


                    <li class="acti lg-d-none" [routerLinkActive]="['active']"
                    (click)="SignOut()"><span>Sign out</span>
                    </li>


                    </ul>
                </div>
            </nav>
        </div>
        <!-- put parent div -->
        <div class="col-md-2 col-sm-2 userprofile">
            <div>
                <div class="col-md-3 col-sm-3 icon infoimage">
                    <div class="otherinfo">
                        <img *ngIf="userProfileimage == null || userProfileimage
                            == undefined" class="userImage"
                            src="../../../../assets/Background/fevicon-recruitlee-logo.png"
                            [routerLink]="'/my-profile'">
                        <img width="40" *ngIf="!!userProfileimage"
                            class="userImage" src="{{userProfileimage}}"
                            [routerLink]="'/my-profile'">
                    </div>
                </div>
                <div class="user-profile-name">
                    <div class="col-md-9 col-sm-9  plr-jon-profile">
                        <h5 *ngIf="!!uesrPrefferName" class="name mb-0"
                            [routerLink]="'/my-profile'"><b>{{uesrPrefferName}}</b></h5>
                        <h5 *ngIf="!uesrPrefferName" class="name"
                            [routerLink]="'/my-profile'"></h5>
                    </div>
                    <div class="right-link">
                        <h1 (click)="SignOut()"><b class="singout name">Sign out</b></h1>
                    </div>
                </div>
               
            </div>
        </div>


        

    </div>

</div>