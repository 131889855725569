import { Component, OnInit } from "@angular/core";
import { AppService } from "../../app.service";
import { HostListener, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as PostJopbClasses from "./post-job-classes";
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from 'lodash';
import { SeoDetailModel } from "../../core/core.class";
import { CoreService } from "../../core/core.service";
import { FilterCriteria, QueryType } from "../../app.class";
@Component({
    selector: 'app-postjob-view',
    templateUrl: './postjob.component.html',
    styleUrls: ['./postjob.component.scss']
})
export class PostjobComponent implements OnInit {

    postJobForm: FormGroup;
    postJobFormErrors: any;

    closeResult: string;
    ACTIVEMODEL = '';
    RecruiterJobList: any;
    currentUser: any;
    selectedJobDetails: any;
    selectedjobtitle?: string;
    StateList: any;
    CityList: any;
    JobTypeList: any;
    SalaryFromList: any;
    SalaryToList: any;
    IsMarketRelated: any;
    IsjobSelected: any;
    IsDisabled: any = false;
    jobId: any;
    type: any;
    isReadOnly: any;
    fillerList: any;
    jobTitle: string;
    jobRefNo:string
    selectedJOb:string

    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();
    constructor(private appService: AppService,
        private formBuilder: FormBuilder,
        private coreService: CoreService,
        public _activateRoutLink: ActivatedRoute,
        private router: Router) {

        if (localStorage.getItem("UserInfo") != null && localStorage.getItem("UserInfo") != undefined) {
            this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
                .subscribe((data: any) => {
                    if (!!data && !!data.Result) {
                        this.SList = data.Result;
                        if (this.SList.length >= 1) {
                            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2497));
                        }
                    }
                });


            this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));
            // this.getSystemEnumForJobList(1033);
            this.getSystemEnumData();
        }
        this.postJobFormErrors = {
            IsShowCompanyName: {},
            IsShowRecruiterName: {},
            RefNo: {},
            SalaryFrom: {},
            SalaryTo: {},
            City: {},
            State: {},
            Status: {},
            Type: {},
            IsUrgent: {},
            MarketRelated: {},
            Title: {},
        };
        this.getJobDataByRecruiterId();

        this._activateRoutLink.params.forEach(params => {
            const jobId = params['JOBID'];
            this.jobId = !!jobId ? jobId : null;
            const type = params['TYPE'];
            if (!!type) {
                this.type = type;
            }
            if (this.type == 'View') {
                this.isReadOnly = true;
                this.IsDisabled = true;
            }
        });

    }
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2497));
        }
    }
    hidenModel(data): any {
        this.ACTIVEMODEL = data;
        this.getJobDataByRecruiterId();
    }
    ngOnInit(): any {
        this.postJobForm = this.formBuilder.group({
            RefNo: ['', Validators.required],
            IsShowCompanyName: ['', Validators.required],
            IsShowRecruiterName: ['', Validators.required],
            City: [null, Validators.required],
            State: [null, Validators.required],
            Type: [null, Validators.required],
            IsUrgent: ['', Validators.required],
            MarketRelated: [''],
            Description: ['', Validators.required],
            SalaryFrom: [null],
            SalaryTo: [null]
        });

        this.postJobForm.valueChanges.subscribe(() => {
            this.onPostJobFormValuesChanged();
        });
    }

    onPostJobFormValuesChanged(): any {
        for (const field in this.postJobFormErrors) {
            if (!this.postJobFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.postJobFormErrors[field] = {};

            // Get the control
            const control = this.postJobForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.postJobFormErrors[field] = control.errors;
            }
        }
    }
    // marketRelated(event): any {

    // }
    selectMarket(event): any {
        const IsChecked = event.currentTarget.checked;
        if (IsChecked == true) {
            this.postJobForm.controls['SalaryFrom'].setValue(null);
            this.postJobForm.controls['SalaryTo'].setValue(null);
            this.IsDisabled = true;
        } else {
            this.IsDisabled = false;
        }
    }

    getSystemEnumData() {
        const data = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [

            ],
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                const Alldata = response.Result;
                this.StateList = Alldata.filter(x => x.EnumID == 1033);
                this.StateList = _.sortBy(this.StateList, ['SystemValue']);
                this.CityList = Alldata.filter(x => x.EnumID == 1034);
                this.CityList = _.sortBy(this.CityList, ['SystemValue']);
                this.fillerList = this.CityList;
                this.JobTypeList = Alldata.filter(x => x.EnumID == 1040);
                this.JobTypeList = _.sortBy(this.JobTypeList, ['SystemValue']);
                this.SalaryFromList = Alldata.filter(x => x.EnumID == 1041);
                _.each(this.SalaryFromList, (list:any) => {
                    if (list.Sequence) {
                        list.Sequence = Number(list.Sequence);
                    }
                });
                this.SalaryFromList = _.orderBy(this.SalaryFromList, ["Sequence"], ['asc']);
                this.SalaryToList = Alldata.filter(x => x.EnumID == 1042);
                _.each(this.SalaryToList, (list:any) => {
                    if (list.Sequence) {
                        list.Sequence = Number(list.Sequence);
                    }
                });
                this.SalaryToList = _.sortBy(this.SalaryToList, ["Sequence"], ['asc']);
            } else {

            }
        });
    }

    changeCity(data): any {
        this.CityList = _.sortBy(this.fillerList.filter(x => x.ParentEnumId == data.currentTarget.value));
    }
    PostJob() {
        const loginUser = new PostJopbClasses.PostJobDetails();
        Object.assign(loginUser, this.postJobForm.value);
        if (this.postJobForm.status != 'INVALID' && this.IsjobSelected == true) {
            if (loginUser.MarketRelated == true) {
                loginUser.MarketRelated = 1;
            } else {
                loginUser.MarketRelated = 0;
            }
            const WhereClause: any = new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "ID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: this.selectedJobDetails.ID
                }];
            WhereClause.FilterLogic = "1";
            const dataObj = this.setData(loginUser);
            const data = {
                QueryObjectID: "JobInfo",
                QueryType: QueryType.Update,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj
            };
            this.appService.executeQuery(data, QueryType.Update).subscribe(response => {
                if (response.StatusCode == "200") {
                    Swal({
                        // type: 'Success',
                        title: 'Success',
                        text: 'Advert posted successfully!',
                    });

                    this.selectedJobDetails = null;
                    this.postJobForm = this.formBuilder.group({
                        RefNo: ['', Validators.required],
                        IsShowCompanyName: ['', Validators.required],
                        IsShowRecruiterName: ['', Validators.required],
                        City: ['', Validators.required],
                        State: ['', Validators.required],
                        Type: ['', Validators.required],
                        IsUrgent: ['', Validators.required],
                        MarketRelated: [''],
                        Description: ['', Validators.required],
                        SalaryFrom: [''],
                        SalaryTo: [''],
                    });
                    this.getJobDataByRecruiterId();
                }
                this.router.navigate(['/my-jobs']);
            });
        } else {
            this.postJobForm.get('IsShowCompanyName').markAsTouched();
            this.postJobForm.get('IsShowRecruiterName').markAsTouched();
            this.postJobForm.get('State').markAsTouched();
            this.postJobForm.get('City').markAsTouched();
            this.postJobForm.get('Type').markAsTouched();
            this.postJobForm.get('IsUrgent').markAsTouched();
            this.postJobForm.get('SalaryFrom').markAsTouched();
            this.postJobForm.get('MarketRelated').markAsTouched();
            this.postJobForm.get('Description').markAsTouched();
            if (this.IsjobSelected == undefined || this.IsjobSelected == null || this.IsjobSelected == false) {
                this.IsjobSelected = false;

            }
        }
    }

    private setData(loginUser: PostJopbClasses.PostJobDetails) {
        const date = new Date();
        return [
            {
                AppFieldID: "City",
                Value: loginUser.City
            },
            {
                AppFieldID: "State",
                Value: loginUser.State
            },
            {
                AppFieldID: "MarketRelated",
                Value: loginUser.MarketRelated
            },
            {
                AppFieldID: "IsShowCompanyName",
                Value: loginUser.IsShowCompanyName
            },
            {
                AppFieldID: "IsShowRecruiterName",
                Value: loginUser.IsShowRecruiterName
            },
            {
                AppFieldID: "IsUrgent",
                Value: loginUser.IsUrgent
            },
            {
                AppFieldID: "SalaryFrom",
                Value: loginUser.SalaryFrom
            },
            {
                AppFieldID: "SalaryTo",
                Value: loginUser.SalaryTo
            },
            {
                AppFieldID: "Description",
                Value: loginUser.Description
            },
            {
                AppFieldID: "Type",
                Value: loginUser.Type
            },
            {
                AppFieldID: "IsPublished",
                Value: 1
            },
            {
                AppFieldID: "UpdatedDate",
                Value: date
            },

        ];
    }
    onChange(job) {
        this.selectedJobDetails = null;
        this.postJobForm = this.formBuilder.group({
            RefNo: ['', Validators.required],
            IsShowCompanyName: ['', Validators.required],
            IsShowRecruiterName: ['', Validators.required],
            City: ['', Validators.required],
            State: ['', Validators.required],
            Type: ['', Validators.required],
            IsUrgent: ['', Validators.required],
            MarketRelated: [''],
            Description: ['', Validators.required],
            SalaryFrom: [''],
            SalaryTo: [''],
        });
        const Job = this.RecruiterJobList.filter(x => x.ID == job);
        const selectedJob = this.RecruiterJobList.find(
            (job) => job.ID == this.jobId
          );
          if (selectedJob) {
            // Extract the Title property
            const jobTitle = selectedJob.Title?.DisplayValue;
            const jobRefNo = selectedJob.RefNo;
            this.jobTitle = jobTitle;
            this.jobRefNo = jobRefNo;
            this.selectedJOb = `${jobTitle} - ${jobRefNo}`;
          }
        if (Job.length > 0) {
            this.IsjobSelected = true;
            this.selectedJobDetails = Job[0];
            if ((  (typeof (this.selectedJobDetails.SalaryFrom))  == 'object' ) && (!!this.selectedJobDetails.SalaryFrom) &&
                (!!this.selectedJobDetails.MarketRelated || this.selectedJobDetails.MarketRelated != 1)) {
                this.selectedJobDetails.SalaryFrom = this.selectedJobDetails.SalaryFrom.ID;
            } else {
                this.selectedJobDetails.SalaryFrom = this.selectedJobDetails.SalaryFrom;
            }
            if ((  (typeof (this.selectedJobDetails.SalaryTo))  == 'object') && (!!this.selectedJobDetails.SalaryTo) &&
                (!!this.selectedJobDetails.MarketRelated || this.selectedJobDetails.MarketRelated != 1)) {
                this.selectedJobDetails.SalaryTo = this.selectedJobDetails.SalaryTo.ID;
            }  else {
                this.selectedJobDetails.SalaryTo = this.selectedJobDetails.SalaryTo;
            }
            if ((  (typeof (this.selectedJobDetails.City))  == 'object') && (!!this.selectedJobDetails.City) ) {
                this.selectedJobDetails.City = this.selectedJobDetails.City.ID;
            } else {
                this.selectedJobDetails.City = this.selectedJobDetails.City;
            }

            if ((  (typeof (this.selectedJobDetails.State))  == 'object') && (!!this.selectedJobDetails.State) ) {
                this.selectedJobDetails.State = this.selectedJobDetails.State.ID;
            } else {
                this.selectedJobDetails.State = this.selectedJobDetails.State;
              
            }


            if ((  (typeof (this.selectedJobDetails.Type))  == 'object') && (!!this.selectedJobDetails.Type) ) {
                this.selectedJobDetails.Type =  this.selectedJobDetails.Type.ID;
            } else {
                this.selectedJobDetails.Type = this.selectedJobDetails.Type;
            }

            if (!!this.selectedJobDetails.MarketRelated) {
                if (this.selectedJobDetails.MarketRelated == 1) {
                    this.selectedJobDetails.MarketRelated = true;
                    this.selectedJobDetails.SalaryFrom = null;
                    this.selectedJobDetails.SalaryTo = null;
                    this.IsDisabled = true;
                } else {
                    this.selectedJobDetails.MarketRelated = false;
                    this.IsDisabled = false;
                }
            }
            this.postJobForm.patchValue(this.selectedJobDetails);
            const state = _.sortBy(this.StateList.filter(x => x.SystemValue == this.selectedJobDetails['TABMD_SystemEnumDetails.State']));

            this.postJobForm.value["State"] = 1165;

        } else {
            this.IsjobSelected = false;
        }
    }
    private getJobDataByRecruiterId() {
        const data = {
            "QueryObjectID": "JobInfo",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "CreatedBy",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.currentUser.ID,
                    },
                ]
            },
            "Sort": [
                {
                    "FieldID": "ID",
                    "SortSequence": 2
                }
            ]
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.RecruiterJobList = response.Result;
                if (!!this.type) {
                    this.onChange(this.jobId);
                }
            }
        });

    }

}
