import { NgModule } from "@angular/core";
import { CoreHttpService } from "../services/core-http.service";

@NgModule({
    imports: [
    ],
    declarations: [],
    providers: [CoreHttpService],
    exports: [],
    entryComponents: []
})

export class CoreModule { }
