
export let SERVER_URL = 'http://161.97.104.204/recruitleeAPI/api/';



const map = {

  'ng2-google-recaptcha': 'node_modules/ng2-google-recaptcha'
};
const packages = {

  'ng2-google-recaptcha': { main: 'index.js', defaultExtension: 'js' },
};
