import { Component, AfterViewInit, OnDestroy } from "@angular/core";
import { AppService } from "../app.service";

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from "sweetalert2";
import { PageModel, PageScreen, ConfigPageModel, SeoDetailModel } from "../core/core.class";
import { CoreService } from "../core/core.service";
import { FilterCriteria, QueryType } from "../app.class";
import { AnyARecord } from "dns";
@Component({
    selector: 'app-job-view',
    templateUrl: './my-jobs.component.html',
    styleUrls: ['./my-jobs.component.scss']
})

export class MyJobsComponent implements AfterViewInit, OnDestroy {

    
    JobStatusList: any;
    RecruiterJobList: any = [];
    currentUser: any;
    selectedStatus: any;

    pageDetails = new PageModel(PageScreen.MYJOBS);
    config: ConfigPageModel = new ConfigPageModel();

    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();

    //Page Informations
    constructor(
        private appService: AppService,
        private formBuilder: FormBuilder,
        private coreService: CoreService) {
        this.getSystemEnumForJobList(1024);
        // set current user

        this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
            .subscribe((data: any) => {
                if (!!data && !!data.Result) {
                    this.SList = data.Result;
                    if (this.SList.length >= 1) {
                        this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2498));
                    }
                }
            })

        if (localStorage.getItem("UserInfo") != "null" && localStorage.getItem("UserInfo") != null && localStorage.getItem("UserInfo") != undefined) {
            this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));
            this.getJobDataByRecruiterId();
        }
        this.setPageD();
    }
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2498));
        } 
    }

    private getSystemEnumForJobList(enumID) {
        const data = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [

            ],
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "EnumID",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": enumID
                    }
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode = "200") {
                this.JobStatusList = response.Result;
            }
        });
    }
    ngAfterViewInit(): void {
    }

    // ChangeJobStatus = (status, id) => {
    //     debugger
    //     let value = id;
    //     let filteredjob = this.RecruiterJobList.filter(x => x.ID == id);
    //     if (status.target.value == "1177") {
    //         filteredjob[0].Status = 1177;

    //     } else {
    //         filteredjob[0].Status = 1172;

    //     }
    //     const WhereClause : any= new FilterCriteria();
    //     WhereClause.Filters = [
    //         {
    //             ConjuctionClause: 1,
    //             FieldID: "ID",
    //             RelationalOperator: 3,
    //             ValueType: 0,
    //             value: filteredjob[0].ID
    //         }];
    //     WhereClause.FilterLogic = "1";
    //     const dataObj = this.setData(filteredjob[0]);
    //     const data = {
    //         QueryObjectID: "JobInfo",
    //         QueryType: QueryType.Update,
    //         Joins: [],
    //         WhereClause: WhereClause,
    //         Values: dataObj
    //     };

    //     this.appService.executeQuery(data, QueryType.Update).subscribe(response => {
    //         if (response.StatusCode == "200") {
    //             Swal({
    //                 // type: 'Success',
    //                 title: 'Success',
    //                 text: 'Job status updated successfully!',
    //             });

    //             this.getJobDataByRecruiterId();
    //         }
    //     });
    // }


    ChangeJobStatus = (event: any) => {
        // Find the index of the job in the list based on its ID
        let index = this.RecruiterJobList.findIndex(job => job.ID === event.ID);
        if (index !== -1) {
            // Update the status of the found job
            if (event.Status == "1177") {
                event.Status = 1177;
            } else {
                event.Status = 1172;
            }

            const WhereClause: any = new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "ID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: this.RecruiterJobList[index].ID // Access the ID of the job at the found index
                }
            ];
            WhereClause.FilterLogic = "1";
            const dataObj = this.setData(this.RecruiterJobList[index]);
            const data = {
                QueryObjectID: "JobInfo",
                QueryType: QueryType.Update,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj
            };
            this.appService.executeQuery(data, QueryType.Update).subscribe(response => {
                if (response.StatusCode == "200") {
                    Swal({
                        // type: 'Success',
                        title: 'Success',
                        text: 'Job status updated successfully!',
                    });
                    this.getJobDataByRecruiterId();
                }
            });
        }
        else {
            console.log("Job not found in the list.");
        }
    }

    setData(data) {
        const date = new Date();
        return [
            {
                AppFieldID: "Status",
                Value: data.Status
            }
        ];
    }

    private getJobDataByRecruiterId() {
        let data = {
            "QueryObjectID": "GetRecruiterJoblist",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "CreatedBy",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.currentUser.ID
                    },
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "IsPublished",
                        "RelationalOperator": 3,
                        "ValueType": 1,
                        "value": 1
                    },
                ]
            },
            "Sort": [
                {
                    "FieldID": "ID",
                    "SortSequence": 2
                }
            ]
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.RecruiterJobList = response.Result;
                this.config.totalItems = this.RecruiterJobList.length;
            }
        });
    }



    // Page config
    private setPageD() {
        this.pageDetails = this.coreService.getScreenP(PageScreen.MYJOBS);
        this.config = {
            currentPage: this.pageDetails.currentPage,
            itemsPerPage: this.pageDetails.itemsPerPage,
            totalItems: this.pageDetails.totalItems
        };
    }
    pageChanged(event) {  
        this.config.currentPage = event;
    }
    ngOnDestroy(): void {
        this.pageDetails = Object.assign(this.config);
        this.pageDetails.screenName = PageScreen.MYJOBS;
        this.coreService.pageChangeHelper(this.pageDetails, PageScreen.MYJOBS);
    }
}
