import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HeaderModule } from '../../components/toolbar/aurthentication-toolbar/header.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginService } from './login.service';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../../core/core.module';
@NgModule({

    declarations: [LoginComponent],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: LoginComponent, pathMatch: 'full' }
        ]),
        HeaderModule,
        ReactiveFormsModule,
        CoreModule,
        CommonModule,
        FormsModule
    ],
    providers: [LoginService],
    entryComponents: [],
    exports: [
        LoginComponent

    ]
})

export class LoginModule {

}
