import {
    HttpClient,
    HttpHeaders
} from "@angular/common/http";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from "angularfire2/database";
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import { AppService } from "../app.service";
import { SeoDetailModel } from "../core/core.class";
import { CoreService } from "../core/core.service";
import { FuseUtils } from "../core/search.class";
let pushNotificationURL = 'https://fcm.googleapis.com/fcm/send';
@Component({
    selector: 'message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})

export class MessageComponent {
    @ViewChild('messagedetails') private myScrollContainer: ElementRef;
    IsDatailPage: boolean = false;
    user: Observable<firebase.User>;
    messages: any = [];
    msgVal: string = '';
    SelectedCandidate: any;
    ConversationID: any;
    CandidateList: any = [];
    FilteredClientList = [];
    currentUser: any;
    currentCandidate: any;
    textmessage: any;
    chatref: any;
    searchInput: FormControl;
    isfromnginit: boolean = false;
    isselectedcandidate = false;
    ngcandidate: any;
    candidatePhoto:any;
    image: any = 'assets/Background/fevicon-recruitlee-logo.png';
    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();
    // @ViewChildren('messages') messagesDetails: QueryList<any>;
    @ViewChild('myList') myList: ElementRef;
    constructor(public afAuth: AngularFireAuth, public af: AngularFireDatabase,
        public _activeRoute: ActivatedRoute, public http: HttpClient,private appService: AppService,private coreService:CoreService) {
        this.user = this.afAuth.authState;
        this.searchInput = new FormControl("");
        if (localStorage.getItem("UserInfo") != null && localStorage.getItem("UserInfo") != undefined) {
            this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
            .subscribe((data: any) => {
                if (!!data && !!data.Result) {
                    this.SList = data.Result;
                    if (this.SList.length >= 1) {
                        this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2502));
                    }
                }
            })
            this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));
        }
    }
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2502));
        } 
    }

    scrollToBottom(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch (err) { }
    }
    ngOnInit(): void {
        // let element = this.myScrollContainer.nativeElement
        this._activeRoute.params.forEach(params => {
            // ID  - candidate user id passed from candidate contact list
            const Offset = params['ID'];
        //  this.candidatePhoto=localStorage.getItem("photo1");
            if (Offset) {
                // Set SelectedCandidate user
                this.SelectedCandidate = parseInt(Offset);
                this.ngcandidate = parseInt(Offset);
                this.CheckForMessage();
                this.isfromnginit = true;
            }
          
             // Get Candidate list to whom conversation
             this.GetCandidateList();
        });
        
        // Local Search from search textbox using filtered candidate list
        this.searchInput.valueChanges
            .subscribe(searchText => {
                this.FilteredClientList = FuseUtils.filterArrayByString(
                    this.CandidateList,
                    searchText
                );
            });
    }
    GetCandidateList() {
        // Check for recruiter conversation in firebase
        var ref = firebase.database().ref("User/" + this.currentUser.UserID + "/" + "Conversation")
        ref.orderByChild("last_time_stamp").on("child_added", (snapshot) => {
            // If conversation exist
            if (snapshot.exists) {
                var element = snapshot.val();
                if (this.SelectedCandidate == null || this.SelectedCandidate == undefined) {
                    this.SelectedCandidate = element.Oid;
                }
                var index = this.CandidateList.findIndex(object => {
                    return object.id == element.Oid
                })
                // check if candidate already added in candidate list using index
                var filteredindex = this.FilteredClientList.findIndex(object => {
                    return object.id == element.Oid
                })
                // If not exist add candidate in list
                if (index < 0) {
                    this.CandidateList.push(snapshot.val())
                    this.FilteredClientList.push(snapshot.val())
                    this.GetUserDetails(snapshot.val(), snapshot.key);

                }
                // If already exist choose index and edit last message of same candidate
                else {
                    this.CandidateList[index].LastMessage = element.LastMessage;
                    if (filteredindex >= 0) {
                        this.FilteredClientList[filteredindex].LastMessage = element.LastMessage;
                    }
                    this.GetUserDetails(snapshot.val(), snapshot.key);
                }

            }
        });
        let a = this.af.list("User/" + this.currentUser.UserID + "/" + "Conversation");
        a.snapshotChanges(['child_added'])

            .subscribe(actions => {
            });

        var gref = firebase.database().ref("User/" + this.currentUser.UserID + "/" + "Conversation")
         gref.on("child_changed", (snapshot) => {
            if (snapshot.exists) {
                var index = this.CandidateList.findIndex(object => {
                    return   snapshot.key == object.cid
                })
                if(index >= 0)
                {
                    this.CandidateList[index].isRead=snapshot.val().isRead;
                    this.CandidateList[index].lastReadTimestamp = snapshot.val().lastReadTimestamp;
                    this.CandidateList[index].LastMessage=snapshot.val().LastMessage;
                }
            }
            
        });
        let aa = this.af.list("User/" + this.currentUser.UserID + "/" + "Conversation");
        aa.snapshotChanges(['child_changed'])

            .subscribe(actions => {
            });

    }

    // Send push Notification

    SendPushNotification(tokens, message, conversationid) {
        //var to = token
        var payload = {
            "data": {
                "title": this.currentUser.FirstName + ' ' + this.currentUser.LastName,
                "message": message,
                "senderId": this.currentUser.UserID,
                "conversationid": conversationid,
                "notification_type": "sendMessage",
                "content_available": "1",
            },
            "notification" : {
                "body" : message,
                "title":this.currentUser.FirstName + ' ' + this.currentUser.LastName,
                "content_available" : true,
                "priority" : "high",
                "message": message,
                "senderId": this.currentUser.UserID,
                "conversationid": conversationid,
                "notification_type": "sendMessage",
            },
            "to": tokens,
        };
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            "Authorization": 'key=AAAAOPsVlfA:APA91bHYXw6H7S4H8v7eWisr6YDacZPxdV2TP3rG9voGhj1iqz28OUXPZjjbtnvUzuj-E7kmMiJGViGExC5couKojmohKkxbMW2xE1Cj-vH1JzbS7e9G89eeZzyjiJlz-weJpvzlgxXa'
        });
        return this.http
            .post(pushNotificationURL, JSON.stringify(payload), { headers: headers })
            .map(response => {
                return response;
            });
    }


    MakeMessageAsRead(cid) {
        var ref = firebase.database().ref("User/" + this.currentUser.UserID + "/" + "Conversation/" + cid + "/isRead");
        
    }

    ListenSelectedCandidateConversation(id, cid) {
        let a = this.af.list("User/" + id + "/" + "Conversation/" + cid);
        a.snapshotChanges(['child_changed'])
            .subscribe(actions => {
                actions.forEach(element => {
                    if (element.key == "lastReadTimestamp") {
                        for (var i = 0; i < this.messages.length; i++) {
                            if (this.messages[i].FromID == this.currentUser.UserID) {
                                this.messages[i].timestamp != null ? this.messages[i].timestamp > element["payload"].val() ? "UNREAD" : "READ" : "READ";
                            }
                        }
                    }
                })

            });
    }


    GetMessageData(candidate) {
        // Get Message for selected candidate
        var data = candidate;
        this.SelectedCandidate = candidate.id;
        this.ListenSelectedCandidateConversation(this.currentUser.UserID, candidate.cid);
        if (this.currentCandidate == null || this.currentCandidate.cid != candidate.cid) {
            this.messages = [];
            var cref = firebase.database().ref("User/" + candidate.id + "/" + "Conversation/" + candidate.cid);
            cref.once("value", (snapshot1) => {
                this.currentCandidate = candidate;
                if (snapshot1.exists()) {
                    var lastread =candidate.lastReadTimestamp
                    if (this.chatref != null) {
                        this.chatref.off();
                        this.chatref = null;
                    }
                    this.chatref = firebase.database().ref("Conversation/" + candidate.cid)
                    this.chatref.on("child_added", (snapshot) => {
                        if (candidate.id != this.SelectedCandidate) {
                            return;
                        }
                        var Message = snapshot.val();
                      
                        var UserConversation = firebase.database().ref("User/" + candidate.id + "/" + "Conversation" + "/" + candidate.cid)
                        var LastMessageObject = {
                            "lastReadTimestamp": firebase.database.ServerValue.TIMESTAMP
                        }
                        UserConversation.update(LastMessageObject);


                        // Update IS Read
                        var UserConversation1 = firebase.database().ref("User/" + this.currentUser.UserID + "/" + "Conversation" + "/" + candidate.cid)
                        var LastMessageObject1 = {
                            "isRead": true
                        }
                        UserConversation1.update(LastMessageObject1);

                        this.MakeMessageAsRead(candidate.cid);
                        // Set From and To id + Photo and name in Message list
                        var dd = snapshot.val();
                        if (dd.FromID == this.currentUser.UserID) {
                            dd.ProfilePhoto = this.currentUser.PhotoURL;
                            dd.Name = this.currentUser.FirstName + " " + this.currentUser.LastName
                            dd.isRead = Message.timestamp != null ? Message.timestamp > lastread ? "UNREAD" : "READ" : "READ"
                        }
                        else {
                            dd.ProfilePhoto = this.currentCandidate.profile;
                            dd.Name = this.currentCandidate.name
                        }
                        // Add Message in Message list
                        this.messages.push(dd);
                       
                    });
                }
            });
        }
        if(this.chatref == null)
        {
        this.chatref = firebase.database().ref("Conversation/" + candidate.cid)
       
        this.chatref.on("child_added", (snapshot) => {
            if (candidate.id != this.SelectedCandidate) {
                return;
            }
            var Message = snapshot.val();
            var UserConversation = firebase.database().ref("User/" + candidate.id + "/" + "Conversation" + "/" + candidate.cid)
            var LastMessageObject = {
                "lastReadTimestamp": firebase.database.ServerValue.TIMESTAMP
            }
            UserConversation.update(LastMessageObject);


            // Update IS Read

            var UserConversation1 = firebase.database().ref("User/" + this.currentUser.UserID + "/" + "Conversation" + "/" + candidate.cid)
            var LastMessageObject1 = {
                "isRead": true
            }
            UserConversation1.update(LastMessageObject1);

            this.MakeMessageAsRead(candidate.cid);
            // Set From and To id + Photo and name in Message list
            var dd = snapshot.val();
            if (dd.FromID == this.currentUser.UserID) {
                dd.ProfilePhoto = this.currentUser.PhotoURL;
                dd.Name = this.currentUser.FirstName + " " + this.currentUser.LastName
                dd.isRead = Message.timestamp != null ? Message.timestamp >  this.currentCandidate.lastReadTimestamp ? "UNREAD" : "READ" : "READ"
            }
            else {
                dd.ProfilePhoto = this.currentCandidate.profile;
                dd.Name = this.currentCandidate.name
            }
            // Add Message in Message list
            this.messages.push(dd);
           
        });
    }
        let a = this.af.list("Conversation/" + candidate.cid);
        a.snapshotChanges(['child_added'])

            .subscribe(actions => {
            });
    }

    GetUserDetails(conversation, cid) {
            var ref = firebase.database().ref("User/" + conversation.Oid);
            ref.once("value", (snapshot) => {
                var UserDetails = snapshot.val();
                // var cid = null;
                var x = this.CandidateList.find(x => x.Oid == UserDetails.id);
                let index = this.CandidateList.indexOf(x);
                x.name = UserDetails.name;
                x.id = UserDetails.id;
                x.Oid = UserDetails.id;
                x.isOnline = UserDetails.isOnline;
                x.profile = UserDetails.profile;
                x.fcm_token = UserDetails.fcm_token;
                x.lastReadTimestamp=conversation.lastReadTimestamp

                x.cid = cid;
                // if (UserDetails.Conversation) {
                //     if(UserDetails.Conversation[cid].lastReadTimestamp == null)
                //     {

                //     }
                //     else
                //     {
                //         x.lastReadTimestamp = UserDetails.Conversation[cid].lastReadTimestamp;
                //     }
                // }
                this.CandidateList[index] = x;
                this.FilteredClientList[index] = x;
                if (this.isfromnginit == true && this.ngcandidate == x.id) {
                    this.isfromnginit = false;
                    this.ngcandidate = null;
                    this.GetMessageData(x)
                }
                else if (this.isfromnginit == false && this.isselectedcandidate == false) {
                    this.isselectedcandidate = true;
                    this.currentCandidate=this.CandidateList[0];
                    this.GetMessageData(this.CandidateList[0]);
                }
            });


        let a = this.af.list("User" + "/" + this.currentUser.UserID + "/" + "Conversation");
        a.snapshotChanges(['child_added'])

            .subscribe(actions => {
            });
    }

    CheckForMessage() {
        var Conversation = firebase.database().ref("\User" + "/" + this.currentUser.UserID + "/" + "Conversation");
        Conversation.orderByChild("Oid").equalTo(this.SelectedCandidate).on("value", (snapshot) => {
            if (snapshot.exists()) {
                var ConversationID = snapshot.key;
                this.ConversationID = snapshot.key;
                var MessagePath = firebase.database().ref("Conversation" + "/" + ConversationID);
                MessagePath.orderByChild("timestamp").on("child_added", function (snapshot) {

                });
            }
            else {
                
                var ref = firebase.database().ref("User/" + this.SelectedCandidate);
                ref.once("value", (snapshot) => {
                    var UserDetails = snapshot.val();
                    var cid = null;
                    var x = this.CandidateList.find(x => x.Oid == UserDetails.id);
                    let index = this.CandidateList.indexOf(x);
                    if (index < 0) {
                        if (UserDetails.Conversation) {
                            var arr = Object.keys(UserDetails.Conversation);
                            for (var i = 0; i < arr.length; i++) {
                                if (this.currentUser.UserID == UserDetails.Conversation[arr[i]].Oid) {
                                    cid = arr[i];
                                    UserDetails.LastMessage = UserDetails.Conversation[arr[i]].LastMessage;
                                }
                            }
                            if (cid != null) {
                                UserDetails.cid = cid;
                            }
                            else {
                                UserDetails.cid = firebase.database().ref("Conversation").push().key;
                            }
                        }
                        else {
                            UserDetails.cid = firebase.database().ref("Conversation").push().key;
                        }
                        if(UserDetails.Oid == null)
                        {
                            UserDetails.Oid= UserDetails.id;
                        }
                        this.CandidateList.unshift(UserDetails);
                        this.FilteredClientList.unshift(UserDetails);
                        this.currentCandidate=UserDetails;
                        
                    }
                });
            }

        });

    }
    Send() {
        // Send Message to selected candidate
        var ref = firebase.database().ref("Conversation");
        // generate random key
        if (this.textmessage.trim() != "") {
            let dateNow = firebase.database.ServerValue.TIMESTAMP;
            var RandomKey = this.currentCandidate.cid;
            var MessageRandomKey = firebase.database().ref("Conversation/" + RandomKey).push().key;
            var Message = firebase.database().ref("Conversation/" + RandomKey + "/" + MessageRandomKey);
            var object = {
                "FromID": this.currentUser.UserID.toString(),
                "TOID": this.SelectedCandidate,
                "Message": this.textmessage,
                "timestamp": dateNow
            };
            const seting = Message.set(object);
            seting.then(_ => console
                .log("success")).catch(error =>
                    console.log(error)
                );

            var UserConversation = firebase.database().ref("User/" + this.currentUser.UserID + "/" + "Conversation" + "/" + RandomKey)

            var LastMessageObject = {
                "LastMessage": this.textmessage,
                "Oid": this.SelectedCandidate,
                "isRead": true,
            };
            UserConversation.update(LastMessageObject);

            var CandidateConversation = firebase.database().ref("User/" + this.SelectedCandidate + "/" + "Conversation" + "/" + RandomKey);
            var LastMessageObjectForCandidate = {
                "LastMessage": this.textmessage,
                "Oid": this.currentUser.UserID,
                "isRead": false,
                "conversation_timestamp":firebase.database.ServerValue.TIMESTAMP
            };
            this.SendPushNotification(this.currentCandidate.fcm_token, this.textmessage, RandomKey)
                .subscribe(a => {
                    console.log("response" +JSON.stringify(a))
                });
            this.textmessage = "";
            CandidateConversation.update(LastMessageObjectForCandidate);
        }

    }
}