import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';

import { Router } from '@angular/router';
import * as ProfileClasses from "./my-profile.classes";
import * as _ from "lodash";
import { SeoDetailModel } from "../core/core.class";
import { CoreService } from "../core/core.service";
import * as firebase from 'firebase/app';
import { QueryType, FilterCriteria } from "../app.class";
import Swal from "sweetalert2";
@Component({
    selector: 'app-profile-view',
    templateUrl: './my-profile.component.html',
    styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent {
    profileForm: FormGroup;
    profileFormErrors: any;
    emailPattern: string | RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    image: any = 'assets/Background/fevicon-recruitlee-logo.png';
    isReadOnly = true;
    SubscriptionPlanList: any;
    changeListener($event): void {
        this.readThis($event.target);
    }

    CurrentUser: any;


    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();

    constructor(private formBuilder: FormBuilder,
        private appService: AppService,
        public router: Router,
        private coreService: CoreService
    ) {

        this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
            .subscribe((data: any) => {
                if (!!data && !!data.Result) {
                    this.SList = data.Result;
                    if (this.SList.length >= 1) {
                        this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2507));
                    }
                }
            })


        this.profileFormErrors = {
            FirstName: {},
            LastName: {},
            CompanyName: {},
            Designation: {},
            WorkTelephone: {},
            PhoneNo: {},
            Email: {},
            OrganizationSize: {}
        };

        if (!!localStorage.getItem("UserInfo")) {
            this.CurrentUser = JSON.parse(localStorage.getItem("UserInfo"))

        }
        this.getSubscriptionPlan();
    }

    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2507));
        }
    }

    ngOnInit(): void {

        this.profileForm = this.formBuilder.group({
            Email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            CompanyName: ['', Validators.required],
            Designation: ['', Validators.required],
            WorkTelephone: ['', Validators.required],
            PhoneNo: ['', Validators.required],
            OrganizationSize: ['', Validators.required],
            BusinessType: ['', Validators.required]
        });
        this.profileForm.valueChanges.subscribe(() => {
            this.onProfileFormValuesChanged();
        });
        this.profileForm.patchValue(this.CurrentUser);
    }

    onProfileFormValuesChanged(): any {
        for (const field in this.profileFormErrors) {
            if (!this.profileFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.profileFormErrors[field] = {};

            // Get the control
            const control = this.profileForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.profileFormErrors[field] = control.errors;
            }
        }
    }

    NeedtoCancel(): any {
        this.profileForm.patchValue(this.CurrentUser);
    }

    readThis(inputValue: any): void {
        let fileData: File = inputValue.files[0];
        this.appService.uploadFiles(1150, this.CurrentUser.UserID, 1, 1152, fileData).subscribe(data => {
            let dataa = data as any;
            var cref = firebase.database().ref("User/" + this.CurrentUser.UserID);
            var UserNameObj = {
                profile: dataa.Result[0].URL
            }
            cref.update(UserNameObj);
            this.CurrentUser.PhotoURL = dataa.Result[0].URL;
            localStorage.setItem("UserInfo", JSON.stringify(this.CurrentUser));
            this.appService.editNavigationImage(this.CurrentUser.PhotoURL);
        });
    }


    uploadImage(imageData, UserId) {
        let params = { 'Photo': imageData, ID: UserId };
    }

    editUserDetails(): any {

        this.isReadOnly = true;
    }

    getSubscriptionPlan(): any {
        const data = {
            "QueryObjectID": "SubscriptionPlan",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": false,
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            this.SubscriptionPlanList = response.Result;
        });
    }


    EditProfile() {
        const loginUser = new ProfileClasses.ProfileDetails();
        Object.assign(loginUser, this.profileForm.value);
        _.each(this.SubscriptionPlanList, (sub:any) => {
            let a = sub.ApplicableTo.split(",");
            if (_.includes(a, loginUser.BusinessType.toString()) && sub.OrganizationSize == Number(loginUser.OrganizationSize)) {
                loginUser.SubscriptionPlan = sub.ID;
                
            }
        });
        if (this.profileForm.status != 'INVALID') {
            this.isReadOnly = true;

            this.updateProfile(loginUser);
        } else {
            Swal({
                // type: 'Warning',
                title: 'Required',
                text: 'Please, enter all the values and valid value!',
            });
        }
    }
    private updateProfile(loginUser: ProfileClasses.ProfileDetails) {

        let WhereClause: any = new FilterCriteria();
        WhereClause.Filters = [
            {
                ConjuctionClause: 1,
                FieldID: "ID",
                RelationalOperator: 3,
                ValueType: 0,
                value: this.CurrentUser.ID
            }]
        WhereClause.FilterLogic = "1";
        let dataObj = this.setData(loginUser);

        let data = {
            QueryObjectID: "RecruiterInfo",
            QueryType: QueryType.Update,
            Joins: [],
            WhereClause: WhereClause,
            Values: dataObj
        };
        this.appService.executeQuery(data, QueryType.Update).subscribe(response => {

            if (response.StatusCode == "200") {
                // Successful update
                Swal({
                    text: 'Profile updated successfully!',
                });
                this.getProfileInfo(this.CurrentUser.UserID);

            }
            else {
                // Update failed
                Swal({
                    text: 'Failed to update profile. Please try again.',
                });
            }
        });
    }

    private setData(loginUser: ProfileClasses.ProfileDetails) {

        return [
            {
                AppFieldID: "CompanyName",
                Value: loginUser.CompanyName
            },
            {
                AppFieldID: "Designation",
                Value: loginUser.Designation
            },
            {
                AppFieldID: "BusinessType",
                Value: loginUser.BusinessType
            },
            {
                AppFieldID: "OrganizationSize",
                Value: loginUser.OrganizationSize
            },
            {
                AppFieldID: "SubscriptionPlan",
                Value: loginUser.SubscriptionPlan
            },
            {
                AppFieldID: "Person",
                Value: [
                    {
                        AppFieldID: "FirstName",
                        Value: loginUser.FirstName
                    },
                    {
                        AppFieldID: "Email",
                        Value: loginUser.Email
                    },
                    {
                        AppFieldID: "LastName",
                        Value: loginUser.LastName
                    },
                    {
                        AppFieldID: "WorkTelephone",
                        Value: loginUser.WorkTelephone
                    },
                    {
                        AppFieldID: "PhoneNo",
                        Value: loginUser.PhoneNo
                    },
                    {
                        AppFieldID: "ID",
                        Value: this.CurrentUser.Person
                    }
                ]
            }
        ];
    }
    private getProfileInfo(RecruiterID) {
        let data = {
            "QueryObjectID": "GetRecruiterInfo",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "UserID",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": RecruiterID  // use recruiter id here
                    }
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                localStorage.setItem("UserInfo", JSON.stringify(response.Result[0]));
                this.CurrentUser = JSON.parse(localStorage.getItem("UserInfo"));
                const userName = this.CurrentUser.FirstName + ' ' + this.CurrentUser.LastName;
                // UPdate Username in FIrebase 
                var cref = firebase.database().ref("User/" + this.CurrentUser.UserID);
                var UserNameObj = {
                    name: response.Result[0].FirstName + ' ' + response.Result[0].LastName
                }
                cref.update(UserNameObj);
                this.appService.editNavigationName(userName);
            }
        });
    }
}
