import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChild } from "@angular/core";
import { NgxGalleryAnimation, NgxGalleryOptions, NgxGalleryImage } from "ngx-gallery";
import { SafeUrl, Meta, Title } from "@angular/platform-browser";
import { SafeResourceUrl, DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { AppService } from "../app.service";

import * as _ from "lodash";
import { CoreService } from "../core/core.service";
import Swal from "sweetalert2";
import { GetCandidateProfileDetails, GetCandidatePDetailEPSQ_A } from "./pro.class";
import { forkJoin } from "rxjs";
import { SeoDetailModel } from "../core/core.class";
import { QueryType, FilterCriteria } from "../app.class";
@Component({
    selector: 'app-candidate-view',
    templateUrl: './candidate-profile.component.html',
    styleUrls: ['./candidate-profile.component.scss']
})
export class CandidateProfileComponent {
    IsPersonalDetails: any = false;
    IsInterviewQuation = false;
    IsSkills = false;
    IsAcchivements = false;
    IsEducation = false;
    IsProfessional = false;
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[] | any;
    index: any = 0;
    ACTIVEMODEL = '';
    loading = true;
    isSelected = false;
    @Input() infinityMove: boolean;
    @Output() onActiveChange = new EventEmitter<number>();
    @Input() animation: boolean;
    zoomValue = 1;
    rotateValue = 0;
    src: SafeUrl |any;
    srcIndex: number = 1;
    @ViewChild('previewImage') previewImage: ElementRef;
    candidateId: any;
    userId: any;
    personId: any;
    showSpinner = false;
    getCandidateDetails: any = [];
    professionalData: any;
    mediaList: any;
    QADetails: any;
    educationData: any;
    AllListData: any;
    GenderList: any;
    RaceList: any;
    RelationShipList: any;
    LanguageList: any;
    CitizionshipList: any;
    JobList: any;
    LevelList: any;
    userGender: any;
    CityList: any;
    skillInfo: any;
    qualificationList: any;
    fieldOfStudyList: any;
    IndustryList: any;
    RecruiterJobList: any;
    imageLength: any;
    calLength: any = 1;
    profileDetails: any;
    candidateDetails: any = [];
    candidateProfileFilter: GetCandidateProfileDetails = new GetCandidateProfileDetails();
    candidateOtherF: GetCandidatePDetailEPSQ_A = new GetCandidatePDetailEPSQ_A();
    jobId: any;
    QALength: any;
    Qaindex: any = 1;
    currentUser: any;
    selectedjobtitle: any = 0;
    selectedModel: any;
    cJobId: any;

    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();

    constructor(private sanitization: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef,
        private _activateRoute: ActivatedRoute,
        public appService: AppService,
        private coreService: CoreService,
        private meta: Meta,
        private titleService: Title
    ) {

        this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
            .subscribe((data: any) => {
                if (!!data && !!data.Result) {
                    this.SList = data.Result;
                    if (this.SList.length >= 1) {
                        this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2504));
                    }
                }
            })

        // this.titleService.setTitle("dhbdhbsdhbhsdb");
        // this.meta.updateTag({ name: "keywords", content: "dbnd,fbhkfb,fbkfb,fbjhfbjh" });
        // this.meta.updateTag({ name: "description", content: "bdsbhdsbhksdbhksdbhksdbshdbsdhb dsnsfnjnjfnjnfjlnfjnf fnfnljkfnklfmnlfnflnfl" });


        this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));
        this.galleryOptions = [
            {
                width: '100%',
                height: '100%',
                thumbnailsColumns: 0,
                imageAnimation: NgxGalleryAnimation.Slide,
                thumbnails: false,
                imageArrows: false,

            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '600px',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: true
            },
        ];

        // this.galleryImages = [
        //     {
        //         URL: "assets/Background/Ellipse.png"
        //     }
        // ];
        // this.show();
        // this.imageLength = this.galleryImages.length;
        this._activateRoute.params.forEach(params => {
            const userId = params['USERID'];
            const personId = params['PERSONID'];
            const candidateId = params['CANDIDATEID'];
            const jobId = params['JOBID'];
            if (!!userId) {
                this.userId = userId;
            }
            if (!!personId) {
                this.personId = personId;
            }
            if (!!candidateId) {
                this.candidateId = candidateId;
            }
            if (!!jobId) {
                this.cJobId = jobId;
                this.selectedjobtitle = jobId;
            }
        });

        let forSEdata = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": false,
        };


        this.appService.executeQuery(forSEdata, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.AllListData = response.Result;
            }
        });
        this.getJobDataByRecruiterId();

        this.getProfileData().subscribe(data => {
            this.profileDetails = data;
            this.candidateDetails = this.profileDetails[0].Result;

            this.mediaList = this.profileDetails[1].Result;
            this.educationData = this.profileDetails[2].Result;
            this.professionalData = this.profileDetails[3].Result;
            this.skillInfo = this.profileDetails[5].Result;
            this.QADetails = this.profileDetails[4].Result;


            this.QALength = this.QADetails.length;
            if (this.QALength == 0) {
                this.Qaindex = 0;
            }
            let imageFilter = this.mediaList.filter(x => x.FileType == 1152);
            if (imageFilter.length > 0) {
                this.galleryImages = imageFilter;

                this.galleryImages = _.sortBy(this.galleryImages, [{'Sequence': 'asc'}]);
                var d = _.sortBy(this.galleryImages, ['Sequence']);
                this.galleryImages = d;
                
                this.show();
                this.imageLength = this.galleryImages.length;
            }

            if (this.candidateDetails[0].IsSelected == 0) {
                this.isSelected = false;
            } else {
                this.isSelected = true;
            }
        });

    }
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2504));
        } 
}

    getJobDataByRecruiterId() {
        let data = {
            "QueryObjectID": "GetRecruiterJoblist",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "CreatedBy",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.currentUser.ID
                    },
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "IsPublished",
                        "RelationalOperator": 3,
                        "ValueType": 1,
                        "value": 1
                    },
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.RecruiterJobList = response.Result;
            }
        });
    }

    hidenModel(data): any {
        this.ACTIVEMODEL = data;
        if (this.candidateDetails[0].IsSelected == 0) {
            this.isSelected = false;
        } else {
            this.isSelected = true;
        }
    }
    showAtIndex(index: number): void {
        this.index = index;
    }



    showNext(): boolean {
        if (this.canShowNext()) {
            this.index++;
            this.calLength++;
            if (this.index === this.galleryImages.length) {
                this.index = 0;
                this.calLength = 1;
            }

            this.show();
            return true;
        } else {
            return false;
        }
    }

    showPrev(): void {
        if (this.canShowPrev()) {
            this.index--;
            this.calLength--;
            if (this.index < 0) {
                this.index = this.galleryImages.length - 1;
                this.calLength = 1;
            }

            this.show();
        }
    }

    getSafeUrl(image: string): SafeUrl {
        return image;
        // image : this.sanitization.bypassSecurityTrustUrl(image);
    }

    canShowNext(): boolean {
        if (this.galleryImages) {
            return this.infinityMove || this.index < this.galleryImages.length - 1 ? true : false;
        } else {
            return false;
        }
    }

    canShowPrev(): boolean {
        if (this.galleryImages) {
            return this.infinityMove || this.index > 0 ? true : false;
        } else {
            return false;
        }
    }

    prevVideo(): any {
        if (this.Qaindex > 1) {
            this.Qaindex--;
        } else {
            this.Qaindex = 1;
        }
    }
    nextVideo(): any {
        if (this.Qaindex != this.QALength) {
            this.Qaindex++;
        } else {
            this.Qaindex = this.QALength;
        }
    }
    private show(first = false) {
        this.loading = true;

        this.onActiveChange.emit(this.index);

        if (first || !this.animation) {
            this._show();
        } else {
            setTimeout(() => this._show(), 600);
        }
    }

    private _show() {
        this.zoomValue = 1;
        this.rotateValue = 0;
        // this.resetPosition();
      
        this.src = this.getSafeUrl(<string>this.galleryImages[this.index]);
    
      
        this.srcIndex = this.index;
        // this.description = this.descriptions[this.index];
        this.changeDetectorRef.markForCheck();

        // setTimeout(() => {
        //     if (this.isLoaded(this.previewImage.nativeElement)) {
        //         this.loading = false;
        //         // this.startAutoPlay();
        //         this.changeDetectorRef.markForCheck();
        //     } else {
        //         setTimeout(() => {
        //             if (this.loading) {
        //                 this.showSpinner = true;
        //                 this.changeDetectorRef.markForCheck();
        //             }
        //         });

        //         // this.previewImage.nativeElement.onload = () => {
        //         //     this.loading = false;
        //         //     this.showSpinner = false;
        //         //     this.previewImage.nativeElement.onload = null;
        //         //     // this.startAutoPlay();
        //         //     this.changeDetectorRef.markForCheck();
        //         // };
        //     }
        // });
    }


    private isLoaded(img): boolean {
        if (!img.complete) {
            return false;
        }

        if (typeof img.naturalWidth !== 'undefined' && img.naturalWidth === 0) {
            return false;
        }

        return true;
    }


    onFavoriteClick(event) {
        this.ACTIVEMODEL = ''
        if (this.candidateDetails[0].IsFav == 0) {
            let WhereClause = new FilterCriteria();
            WhereClause.Filters = [];
            WhereClause.FilterLogic = "1";
            let dataObj = this.setData(event);
            let data = {
                QueryObjectID: "FavouriteCandidateInfo",
                QueryType: QueryType.Insert,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj
            };
            this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
                if (response.StatusCode == "200") {
                    var UserId = response.Result;
                    Swal({
                        title: ' Added to Favorite!!',
                    });
                    this.candidateDetails[0].IsFav = 1;
                    this.getJobDataByRecruiterId();
                }
            });
        }

    }

    onUnFavoriteClick(event) {
        this.ACTIVEMODEL = '';

        let WhereClause : any = new FilterCriteria();
        WhereClause.Filters = [
            {
                ConjuctionClause: 1,
                FieldID: "JobID",
                RelationalOperator: 3,
                ValueType: 0,
                value: event.jobId
            },
            {
                ConjuctionClause: 1,
                FieldID: "CandidateID",
                RelationalOperator: 3,
                ValueType: 0,
                value: event.candidateId
            },
        ];
        WhereClause.FilterLogic = "1";
        let data = {
            QueryObjectID: "FavouriteCandidateInfo",
            QueryType: QueryType.Delete,
            Joins: [],
            WhereClause: WhereClause,
        }
        this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
            if (response.StatusCode == "200") {
                var UserId = response.Result;
                Swal({
                    title: ' Removed From Favorite!!',
                });
                this.candidateDetails[0].IsFav = 0;
                this.getJobDataByRecruiterId();
            }
        });

    }

    private setData(event) {
        return [
            {
                AppFieldID: "JobID",
                Value: event.jobId
            },
            {
                AppFieldID: "CandidateID",
                Value: event.candidateId
            },
        ];

    }

    private getProfileData() {
        //basic
        this.candidateProfileFilter.CandidateId = this.candidateId;
        this.candidateProfileFilter.UserId = this.userId;
        this.candidateProfileFilter.Case = 1;
        this.candidateProfileFilter.JobID = this.cJobId;
        let firstCall = this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.candidateProfileFilter, "GetCandidateProfileDetails"));
        //Media
        this.candidateProfileFilter.Case = 2;
        let secondCall = this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.candidateProfileFilter, "GetCandidateProfileDetails"));

        //Edu
        this.candidateOtherF.CandidateId = this.candidateId;
        this.candidateOtherF.Case = 1;
        let third = this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.candidateOtherF, "GetCandidatePDetailEPSQ_A"));
        this.candidateOtherF.Case = 2;
        let four = this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.candidateOtherF, "GetCandidatePDetailEPSQ_A"));
        this.candidateOtherF.Case = 3;
        let five = this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.candidateOtherF, "GetCandidatePDetailEPSQ_A"));
        this.candidateOtherF.Case = 4;
        let six = this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.candidateOtherF, "GetCandidatePDetailEPSQ_A"));

        // let seven = this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`);

        return forkJoin([firstCall, secondCall, third, four, five, six]);

        // this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.candidateProfileFilter, "GetCandidateProfileDetails"))
        //     .subscribe((Response: any) => {
        //     });



    }

    getJobId(event): any {
        this.ACTIVEMODEL = 'Register';
        this.selectedModel = event;


    }

    register(): any {
        if (this.selectedModel == 'fav') {
            if (this.selectedjobtitle != 0) {
                if (this.candidateDetails[0].IsFav == 0) {
                    let favData = {
                        jobId: this.selectedjobtitle,
                        candidateId: this.candidateId
                    };
                    this.onFavoriteClick(favData);
                } else {
                    let favData = {
                        jobId: this.selectedjobtitle,
                        candidateId: this.candidateId
                    };
                    this.onUnFavoriteClick(favData);
                }
            } else {
                Swal({
                    title: 'Please select job',
                });
            }
        } else {
            if (this.selectedjobtitle != 0) {
                if (this.isSelected == true) {
                    this.selectCanididate();
                } else {
                    this.removeCandidate(this.candidateId);
                }
            } else {
                Swal({
                    title: 'Please select job',
                });
            }
        }
    }

    selectCanididate(): any {
        this.ACTIVEMODEL = '';
        const WhereClause = new FilterCriteria();
        WhereClause.Filters = [];
        WhereClause.FilterLogic = "1";

        const dataObj = this.setSelectedData();

        const data = {
            QueryObjectID: "SelectedCandidateInfo",
            QueryType: QueryType.Insert,
            Joins: [],
            WhereClause: WhereClause,
            Values: dataObj
        };

        this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
            Swal({
                title: 'Candidate selected successfully',
            });
            this.getJobDataByRecruiterId();
        });

    }

    setSelectedData(): any {
        return [
            {
                AppFieldID: "RecruiterID",
                Value: this.currentUser.ID
            },
            {
                AppFieldID: "JobID",
                Value: this.selectedjobtitle
            },
            {
                AppFieldID: "CandidateID",
                Value: this.candidateDetails[0].CandidateID
            },
        ];
    }

    removeCandidate(id): any {
        id = Number(id);
        this.ACTIVEMODEL = '';
        let WhereClause : any= new FilterCriteria();
        WhereClause.Filters = [
            {
                ConjuctionClause: 1,
                FieldID: "CandidateID",
                RelationalOperator: 3,
                ValueType: 0,
                value: id
            },
            {
                ConjuctionClause: 1,
                FieldID: "JobID",
                RelationalOperator: 3,
                ValueType: 0,
                value: this.selectedjobtitle
            }
        ];
        WhereClause.FilterLogic = "1";
        let data = {
            QueryObjectID: "SelectedCandidateInfo",
            QueryType: QueryType.Delete,
            Joins: [],
            WhereClause: WhereClause,
        };

        this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
            if (response.StatusCode == "200") {
                Swal({
                    title: 'Candidate removed successfully',
                });
                this.getJobDataByRecruiterId();
            }
        });

    }
    previewFullscreen=false;
    openPreview(index: number): any {
        this.previewFullscreen=true
    }
}
