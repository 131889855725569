<app-menubar-view *ngIf="isLoggedIn"></app-menubar-view>
<router-outlet>
    <div *ngIf="showLoader">
        <div class="loader1">
          <div>
            <div class="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
</router-outlet>
<app-footer-grey *ngIf="isLoggedIn"></app-footer-grey>