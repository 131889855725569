<div class="page-container">
    <aurthentication-toolbar></aurthentication-toolbar>
    <div id="register-form" class="scaled-container">
        <div class="register-layout-container">
            <div class="col-md-5 d-none d-md-block p-0">
                <div class="image-layout">
                    <div class="col logo text-center heading">
                        <img src="./../../../assets/Background/white-recruitlee-logo.svg" width="250px;"
                            [routerLink]="'/login'">
                    </div>
                </div>
            </div>
            <div class="col-md-7 d-md-block login-layout">
                <div class="form-layout">
                    <form name="registerForm" [formGroup]="registerForm" novalidate autocomplete="off">
                        <div class="content-box-md">
                            <!-- Logo for small screens -->
                            <div class="col logo text-center logo d-md-none">
                                <img src="./../../../assets/Background/dark-recruitlee-logo.svg" width="200px;"
                                    [routerLink]="'/login'">
                            </div>

                            <!-- Heading for medium screens and larger -->
                            <div class="header-layout d-none d-md-block">
                                <h5 class="heading">Create Account</h5>
                                <p class="text-gray text-center">Please enter details below.</p>
                            </div>
                            <div class="row box">
                                <div class="col-md-12 fr-float p-remove main-register-wrapper">
                                    <div class="col-md-6" id="login">
                                        <!-- <label for="name" class="color-dark">Enter Name</label> -->
                                        <div class="form-group gradient-field spacing">
                                            <input type="text" value="" name="user-name" id="user-name"
                                                autocomplete="user-name" placeholder="First Name"
                                                formControlName="FirstName">
                                            <div class="alert-msg"
                                                *ngIf="registerForm.controls['FirstName'].dirty || registerForm.controls['FirstName'].touched">
                                                <span *ngIf="registerForm.get('FirstName').hasError('required')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> First
                                                    Name is
                                                    required
                                                </span>
                                            </div>
                                        </div>

                                        <div class="form-group gradient-field spacing">
                                            <!-- <label for="name" class="color-dark">Enter E-mail</label> -->
                                            <input type="email" class="email-address" name="user-email" id="user-email"
                                                autocomplete="user-email" value="" placeholder="Email Address"
                                                formControlName="UserName">
                                            <div class="alert-msg"
                                                *ngIf="registerForm.controls['UserName'].dirty || registerForm.controls['UserName'].touched">
                                                <span *ngIf="registerForm.get('UserName').hasError('required')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Email
                                                    Address is
                                                    required
                                                </span>
                                                <span
                                                    *ngIf="!registerForm.get('UserName').hasError('required') &&
                                                                        registerForm.get('UserName').hasError('pattern')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Please
                                                    enter a valid
                                                    email address
                                                </span>
                                            </div>
                                        </div>

                                        <div class="form-group gradient-field spacing">
                                            <!-- <label for="company" class="color-dark">Enter Company Details</label> -->
                                            <input type="text" name="user-company-name" id="user-company-name"
                                                autocomplete="user-company-name" value="" placeholder="Company Name"
                                                formControlName="CompanyName">
                                            <div class="alert-msg"
                                                *ngIf="registerForm.controls['CompanyName'].dirty || registerForm.controls['CompanyName'].touched ">
                                                <span *ngIf="registerForm.get('CompanyName').hasError('required')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Company
                                                    Name is
                                                    required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="posi form-group gradient-field spacing">
                                            <div class="">
                                                <!-- <label for="password" class="color-dark">Enter your Password</label> -->
                                            </div>
                                            <input [type]="passwordshow ? 'text' : 'password'" value=""
                                                name="new-password" id="new-password" autocomplete="new-password"
                                                placeholder="Password" formControlName="Password"
                                                (input)="validateConfirmPassword()"><span class="icon"
                                                (click)="password()">
                                                <img *ngIf="passwordshow == false" width="20"
                                                    src="../../../assets/Background/eye-show.svg" alt="">
                                                <img *ngIf="passwordshow == true" width="20"
                                                    src="../../../assets/Background/eye-hide.svg" alt="">
                                            </span>
                                            <div class="alert-msg"
                                                *ngIf="registerForm.controls['Password'].dirty || registerForm.controls['Password'].touched">
                                                <span *ngIf="registerForm.get('Password').hasError('required')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Password
                                                    is required
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group gradient-field spacing">
                                            <input type="text" name="work telephone" value="" type="number" oninput="validity.valid||(value='');" min="0"
                                                placeholder="Work Telephone" formControlName="WorkTelephone" autocomplete="work-telephone">
                                            <div class="alert-msg"
                                                *ngIf="registerForm.controls['WorkTelephone'].dirty || registerForm.controls['WorkTelephone'].touched">
                                                <span *ngIf="registerForm.get('WorkTelephone').hasError('required')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Work Telephone is required
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group gradient-field spacing">
                                            <input type="text" name="user-last-name" id="user-last-name"
                                                autocomplete="user-last-name" value="" placeholder="Last Name"
                                                formControlName="LastName">
                                            <div class="alert-msg"
                                                *ngIf="registerForm.controls['LastName'].dirty || registerForm.controls['LastName'].touched ">
                                                <span *ngIf="registerForm.get('LastName').hasError('required')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Last
                                                    Name is
                                                    required
                                                </span>
                                            </div>
                                        </div>

                                        <div class="form-group gradient-field spacing">
                                            <input type="text" name="user-phone-number" id="user-phone-number"
                                                autocomplete="user-phone-number" value="" type="number"
                                                oninput="validity.valid||(value='');" placeholder="Cellphone"
                                                formControlName="PhoneNo">
                                                <div class="alert-msg" *ngIf="registerForm.controls['PhoneNo'].dirty || registerForm.controls['PhoneNo'].touched ">
                                                    <span *ngIf="registerForm.get('PhoneNo').hasError('required')">
                                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Cellphone is required
                                                    </span>
                                                </div>
                                        </div>

                                        <div class="form-group gradient-field spacing">

                                            <input type="text" name="designation" value="" placeholder="Designation"
                                                formControlName="Designation" autocomplete="off">
                                            <div class="alert-msg"
                                                *ngIf="registerForm.controls['Designation'].dirty || registerForm.controls['Designation'].touched">
                                                <span *ngIf="registerForm.get('Designation').hasError('required')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                                    Designation is
                                                    required
                                                </span>
                                            </div>
                                        </div>

                                        <div class="posi form-group gradient-field spacing">
                                            <input [type]="confirmpasswordshow ? 'text' : 'password'"
                                                name="confirm password" value="" id="confirmPassword"
                                                formControlName="ConfirmPassword" autocomplete="new-password"
                                                placeholder="Confirm Password" formControlName="ConfirmPassword"
                                                (input)="validateConfirmPassword()"><span class="icon"
                                                (click)="confirmpassword()">
                                                <img *ngIf="confirmpasswordshow == false" width="20"
                                                    src="../../../assets/Background/eye-show.svg" alt="">
                                                <img *ngIf="confirmpasswordshow == true" width="20"
                                                    src="../../../assets/Background/eye-hide.svg" alt="">
                                            </span>
                                            <div class="alert-msg"
                                                *ngIf="registerForm.controls['ConfirmPassword'].dirty || registerForm.controls['ConfirmPassword'].touched || isPasswordNotMatch == true">
                                                <span *ngIf="registerForm.get('ConfirmPassword').hasError('required')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Confirm
                                                    Password is
                                                    required
                                                </span>
                                                <span
                                                    *ngIf="isPasswordNotMatch == true && !registerForm.get('ConfirmPassword').hasError('required')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                                    Passwords must match
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="radio-input-layout">
                                        <div class="form-group gradient-field pt-1 pb-1">
                                            <label for="yes_no_radio" class="lab">I am a:</label>
                                            <label for="opt1" class="radio">
                                                <input type="radio" name="BusinessType" id="opt1" class="hidden"
                                                    value="1178" formControlName="BusinessType" />
                                                <span class="label"></span><span class="rdobtn-span">Business
                                                    Owner</span>
                                            </label>
                                            <label for="opt2" class="radio">
                                                <input type="radio" name="BusinessType" id="opt2" class="hidden"
                                                    value="1179" formControlName="BusinessType" />
                                                <span class="label"></span><span class="rdobtn-span">Recruiter</span>
                                            </label>
                                            <label for="opt3" class="radio">
                                                <input type="radio" name="BusinessType" id="opt3" class="hidden"
                                                    value="1180" formControlName="BusinessType" />
                                                <span class="label"></span><span class="rdobtn-span">Hiring
                                                    Manager</span>
                                            </label>
                                        </div>



                                        <div class="form-group gradient-field pt-1 pb-1">
                                            <label for="yes_no_radio" class="lab">Size of
                                                Organization:</label>
                                            <label for="opt4" class="radio">
                                                <input type="radio" name="OrganizationSize" id="opt4" class="hidden"
                                                    value="1118" formControlName="OrganizationSize" />
                                                <span class="label"></span><span class="rdobtn-span">1 - 25</span>
                                            </label>
                                            <label for="opt5" class="radio">
                                                <input type="radio" name="OrganizationSize" id="opt5" class="hidden"
                                                    value="1119" formControlName="OrganizationSize" />
                                                <span class="label"></span><span class="rdobtn-span"> > 25 </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 text-center p-0">
                            <button type="button" class="btn-grad-filled btn-btnn mb-3" (click)="SignUP()">Register</button>
                        </div>
                        <div class="row col-sm-12 justify-content-center link-layout">
                            <p>Already have an account? <a [routerLink]="'/login'"
                                    class="color-dark font-weight-bold">Sign in</a>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>