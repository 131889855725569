import { Component } from "@angular/core";
import { GetFilteredSelectedCandidateList } from "./mycandidates-classes";
import { AppService } from "../../app.service";

import Swal from "sweetalert2";
import { PageModel, PageScreen, ConfigPageModel } from "../../core/core.class";
import { CoreService } from "../../core/core.service";
import { QueryType, FilterCriteria } from "../../app.class";

@Component({
    selector: 'app-mycandidates-view',
    templateUrl: './mycandidates.component.html',
    styleUrls: ['./mycandidates.component.scss']
})
export class MycandidatesComponent {
    show = false;
    closeResult: string;
    ACTIVEMODEL = '';
    GetSelectedCandidateListData: any = [];
    currentUser: any;
    SelectedCandidateJobList: any;
    selectedJobDetails: any;
    AllListData: any;
    GenderList: any;
    RaceList: any;
    RelationShipList: any;
    LanguageList: any;
    CitizionshipList: any;
    JobList: any;
    LevelList: any;
    pageDetails = new PageModel(PageScreen.MYCANDIDATE);
    config: ConfigPageModel = new ConfigPageModel();
    isTabExpanded1: boolean = true;
    isTabExpanded2: boolean = true;
    isTabExpanded3: boolean = true;
    constructor(private appService: AppService, private coreService: CoreService) {
        this.getListData()
        if (localStorage.getItem("UserInfo") != null && localStorage.getItem("UserInfo") != undefined) {

            this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));
        }
        this.getJobDataByRecruiterId();

    }
    hidenModel(data): any {
        this.ACTIVEMODEL = data;
    }
    paymentMODEL = '';
    password() {
        this.show = !this.show;
    }




    paramterFilter: GetFilteredSelectedCandidateList = new GetFilteredSelectedCandidateList();

    private getJobDataByRecruiterId() {
        let data = {
            "QueryObjectID": "JobInfo",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "CreatedBy",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.currentUser.ID,
                    },
                ]
            },
            "Sort": [
                {
                    "FieldID": "ID",
                    "SortSequence": 2
                }
            ]
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.SelectedCandidateJobList = response.Result;
                this.selectedJobDetails = this.SelectedCandidateJobList[0];
                if (!!this.selectedJobDetails) {
                    this.paramterFilter.JobId = this.selectedJobDetails.ID;
                }
                this.GetSelectedCandidateList();
            }
        });

    }
    //getfilteredcandidate
    GetSelectedCandidateList = () => {
        this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.paramterFilter, "GetFilteredSelectedCandidateList"))
            .subscribe((Response: any) => {

                this.GetSelectedCandidateListData = Response.Result;
            });
    }

    changed(job) {
        this.paramterFilter.JobId = job;
        this.GetSelectedCandidateList();
        let Job = this.GetSelectedCandidateListData.filter(x => x.ID == job);
        if (Job.length > 0) {
            this.selectedJobDetails = Job[0];
            this.GetSelectedCandidateList();
        }

    }


    //favorite marked
    onFavoriteClick(event) {

        if (event.IsFav == 0) {
            let WhereClause = new FilterCriteria();
            WhereClause.Filters = [];
            WhereClause.FilterLogic = "1";

            let dataObj = this.setData(event);

            let data = {
                QueryObjectID: "FavouriteCandidateInfo",
                QueryType: QueryType.Insert,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj


            }
            this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
                if (response.StatusCode == "200") {
                    var UserId = response.Result;
                    Swal({
                        title: ' Added Favorite !!',
                    });
                    this.GetSelectedCandidateList();
                }
            });
        }
        else {
            let WhereClause: any = new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "JobID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: this.paramterFilter.JobId
                },
                {
                    ConjuctionClause: 1,
                    FieldID: "CandidateID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: event.id
                },
            ];
            WhereClause.FilterLogic = "1";
            let data = {
                QueryObjectID: "FavouriteCandidateInfo",
                QueryType: QueryType.Delete,
                Joins: [],
                WhereClause: WhereClause,
            }
            this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
                if (response.StatusCode == "200") {
                    var Id = response.Result;
                    Swal({
                        title: ' Removed From Favorite !!',
                    });
                    this.GetSelectedCandidateList();
                }

            });

        }
    }
    private setData(event) {
        return [
            {
                AppFieldID: "JobID",
                Value: this.paramterFilter.JobId
            },
            {
                AppFieldID: "CandidateID",
                Value: event.id
            },
        ];

    }

    //delete data

    onUnFavoriteClick(event) {
        if (event.IsFav == 1) {
            let WhereClause: any = new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "JobID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: event.PersonID
                },
                {
                    ConjuctionClause: 1,
                    FieldID: "CandidateID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: event.id
                },
            ];
            WhereClause.FilterLogic = "1";
            let data = {
                QueryObjectID: "FavouriteCandidateInfo",
                QueryType: QueryType.Delete,
                Joins: [],
                WhereClause: WhereClause,
            }
            this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
                if (response.StatusCode == "200") {
                    var Id = response.Result;
                    Swal({
                        title: ' Added Un-Favorite !!',
                    });
                }

            });
        }
    }
    private getListData() {
        let data = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": false,
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.AllListData = response.Result;
                this.BindListData();
                this.setPageD();
            }
        });
    }

    private BindListData() {
        this.GenderList = this.AllListData.filter(x => x.EnumID == 1030);
        this.RaceList = this.AllListData.filter(x => x.EnumID == 1014);
        this.RelationShipList = this.AllListData.filter(x => x.EnumID == 1015);
        this.LanguageList = this.AllListData.filter(x => x.EnumID == 1016);
        this.CitizionshipList = this.AllListData.filter(x => x.EnumID == 1017);
        this.JobList = this.AllListData.filter(x => x.EnumID == 1029);
        this.LevelList = this.AllListData.filter(x => x.EnumID == 1020);
    }

    //page config
    private setPageD() {
        this.pageDetails = this.coreService.getScreenP(PageScreen.MYCANDIDATE);
        this.config = {
            currentPage: this.pageDetails.currentPage,
            itemsPerPage: this.pageDetails.itemsPerPage,
            totalItems: this.pageDetails.totalItems
        };
    }
    pageChanged(event) {
        this.config.currentPage = event;
    }

    toggleTab1() {
        this.isTabExpanded1 = !this.isTabExpanded1;
    }

    toggleTab2() {
        this.isTabExpanded2 = !this.isTabExpanded2;
    }

    toggleTab3() {
        this.isTabExpanded3 = !this.isTabExpanded3;
    }

    ngOnDestroy(): void {
        this.pageDetails = Object.assign(this.config);
        this.pageDetails.screenName = PageScreen.MYCANDIDATE;
        this.coreService.pageChangeHelper(this.pageDetails, PageScreen.MYCANDIDATE);
    }
}
