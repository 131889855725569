import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';


@NgModule({

    declarations: [FooterComponent],
    imports: [
        CommonModule,
        RouterModule
    ],
    entryComponents: [],
    exports: [
      FooterComponent
    ]
})

export class FooterModule {

}
