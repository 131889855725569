import { Component, HostListener } from "@angular/core";

@Component({
    selector: 'app-steps',
    templateUrl: './steps.component.html',
    styleUrls  : ['./steps.component.scss']
})
export class SteperComponent {
    constructor() {
    }
 }
