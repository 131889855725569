<div class="main-page">
    <div class="container">
        <div class="mycandidate">
            Applicants
        </div>
        <div class="col-xs-6 search-btn">
            <button *ngIf="IsSearch == false" (click)="IsSearch = true" class="search-btn1 search-width btn-grad-border">
                search
            </button>
            <button *ngIf="IsSearch == true" (click)="IsSearch = false" class="search-btn1 search-width btn-grad-border">
                close
            </button>
        </div>
        <div class="col-xs-6 search-btn1">
            <button (click)="Isfilter = !Isfilter" class="search-btn2 search-width btn-grad-border">
                Filter<img src="../../../assets/Background/Vectorrrrr.png" width="14px">
            </button>
        </div>
     
    </div>
    <div class="main-page-container">
        <div [ngClass]="{'mob-d-none': Isfilter == true, 'mob-d-show' : Isfilter == false}" class="row titl">
            <div class="row heading-container justify-content-center">
                <h1 class="heading">View, filter them, choose the candidates you want to
                    contact
                </h1>
            </div>
        </div>
        <div class="col-md-12">
            <div class="col-md-4">
                <div [ngClass]="{'mob-d-none': Isfilter == true, 'mob-d-show' : Isfilter == false}">
                    <div class="">
                        <div class="searchbar-container mb-3">
                            <div class="">
                                <div class="">
                                    <div class="">
                                        <label class="label-text mb-3" href="#popup1" style="padding-left: 0px;">Registered
                                            Job:*</label>
                                    </div>
                                    <div class="mb-4">
                                        <ng-select (ngModelChange)="changed($event)" [(ngModel)]="paramterFilter.JobId"
                                            [placeholder]="'Select Job Title'">
                                            <ng-option *ngFor="let job of CandidateJobList" [value]="job.ID">
                                                {{ job.Title?.DisplayValue }} - {{ job.RefNo }}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button type="button" data-toggle="modal" (click)="ACTIVEMODEL=
                                                        'Register'" data-target="#exampleModal" class="btn blue-button">Register
                                        a Job</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-removed" [ngClass]="{'filter-wrapper': Isfilter == false, 'filter-wrapper1' : Isfilter == true}">

                    <div class="col b-shadow p-0">
                        <h1 class="text-center headertitel heading border-bottom-0">Filter
                            Candidates

                        </h1>
                        <div class="tabs rounded-0" [ngClass]="{'expanded-tab': isTabExpanded3}">
                            <div class="tab" [ngClass]="{'expanded-tab': isTabExpanded1}">
                                <input type="checkbox" id="chck1" class="checkint" checked>
                                <label class="tab-label f-info section-title" for="chck1" (click)="toggleTab1()">Personal
                                    Information</label>
                                <div class="tab-content">
                                    <label class="label-text mb-3 ml-4 margin-left-0">Age:</label>
                                    <div class="col-md-12 r-removed">

                                        <div class="row">
                                            <div class="col-md-12
                                                col-xs-12 plr-from-to
                                                p-removed
                                                r-removed p-0">
                                                <div class="col-xs-6 pl-0 pr-1">
                                                    <ng-select class="drop3" [(ngModel)]="paramterFilter.AgeFrom" [placeholder]="'From'">
                                                        <ng-option *ngFor="let age of ageList" [value]="age.ID">
                                                            {{ age.DisplayValue }}
                                                        </ng-option>
                                                    </ng-select>                                                        
                                                </div>
                                                <div class="col-xs-6 pr-0 pl-1">
                                                    <ng-select class="drop3" [(ngModel)]="paramterFilter.AgeTo" [placeholder]="'To'">
                                                        <ng-option *ngFor="let age of ageList" [value]="age.ID">
                                                            {{ age.DisplayValue }}
                                                        </ng-option>
                                                    </ng-select>                                                        
                                                </div>
                                            </div>
                                        </div><br>
                                        <label class="label-text mb-3">Gender:</label>
                                        <ng-select class="drop3" [(ngModel)]="paramterFilter.Gender" [placeholder]="'Gender'">
                                            <ng-option *ngFor="let gender of GenderList" [value]="gender.ID">
                                                {{ gender.DisplayValue }}
                                            </ng-option>
                                        </ng-select>                                            
                                    </div><br>

                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Race:</label>
                                        <ng-select class="drop3" [(ngModel)]="paramterFilter.Race" [placeholder]="'Race'">
                                            <ng-option *ngFor="let race of RaceList" [value]="race.ID">
                                                {{ race.DisplayValue }}
                                            </ng-option>
                                        </ng-select>                                            
                                    </div><br>

                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Relationship:</label>
                                        <ng-select class="drop3" [(ngModel)]="paramterFilter.Relationship" [placeholder]="'Relationship'">
                                            <ng-option *ngFor="let relationship of RelationShipList" [value]="relationship.ID">
                                                {{ relationship.DisplayValue }}
                                            </ng-option>
                                        </ng-select>                                            
                                    </div><br>

                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Language:</label>
                                        <ng-select class="drop3" [(ngModel)]="paramterFilter.Language" [placeholder]="'LanguageList'">
                                            <ng-option *ngFor="let language of LanguageList" [value]="language.ID">
                                                {{ language.DisplayValue }}
                                            </ng-option>
                                        </ng-select>                                            
                                    </div><br>

                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">CitizenShip:</label>
                                        <ng-select class="drop3" [(ngModel)]="paramterFilter.Citizan" [placeholder]="'Citizen Ship'">
                                            <ng-option *ngFor="let citizenship of CitizionshipList" [value]="citizenship.ID">
                                                {{ citizenship.DisplayValue }}
                                            </ng-option>
                                        </ng-select>
                                        
                                    </div><br>
                                    <!-- <div class="col-md-12 p-drops">
                                        <select class="drop3">
                                            <option value="Job Title">Disability</option>
                                            <option value="Job Title">Disability</option>
                                            <option value="Job Title">Disability</option>
                                            <option value="Job Title">Disability</option>
                                        </select>
                                    </div><br> -->

                                </div>
                            </div>
                            <div class="tab" [ngClass]="{'expanded-tab': isTabExpanded2}">
                                <input type="checkbox" id="chck2" class="checkint" checked>
                                <label class="tab-label f-info section-title" for="chck2"
                                    style="border-top: 1px solid #C4C4C4" (click)="toggleTab2()">Educational
                                    Information</label>
                                <div class="tab-content">
                                    <div class="col-md-12 p-drops">
                                            <label class="label-text mb-3">Qualification:</label><br>
                                            <ng-select class="drop3" [(ngModel)]="localData.mainQualification" (change)="getMinQualification()" [placeholder]="'Qualification'">
                                                <ng-option *ngFor="let gender of qualificationList" [value]="gender.ID">
                                                    {{ gender.DisplayValue }}
                                                </ng-option>
                                            </ng-select>                                                
                                    </div><br>
                                    <div *ngIf="IsMultiselect == true" class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Minimum Qualification:</label>
                                        <ng-select class="drop3" (change)="setValueforMinCriteria($event)"
                                         [placeholder]="'Minimum Qualification'" [(ngModel)]="paramterFilter.minimumQualification">
                                            <ng-option *ngFor="let qualification of minQualification" [value]="qualification.ID">
                                                {{ qualification.DisplayValue }}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                    <div *ngIf="IsMultiselect == false" class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Minimum Qualification:</label> 
                                         <ng-multiselect-dropdown  
                            [placeholder]="'Minimum Qualification'"
                            [data]="multiMinQualification"
                            [(ngModel)]="selectedQualification"
                            [settings]="dropdownSettings"
                            (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            (onDeSelect)="onItemDeSelect($event)"
                          >
                          </ng-multiselect-dropdown>
                                    </div>
                                    <br>
                                    <div *ngIf="fieldofstudy==true" class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Field of Study:</label>
                                        <ng-select class="drop3 dropdwon-color" [(ngModel)]="paramterFilter.Field" [placeholder]="'Field of Study'">
                                            <ng-option *ngFor="let field of fieldOfStudyList" [value]="field.ID">
                                                {{ field.DisplayValue }}
                                            </ng-option>
                                        </ng-select>
                                    </div><br>
                                </div>
                            </div>
                            <div class="tab" [ngClass]="{'expanded-tab': isTabExpanded3}">
                                <input type="checkbox" id="chck3" class="checkint" checked>
                                <label class="tab-label f-info section-title" for="chck3"
                                    style="border-top: 1px solid #C4C4C4" (click)="toggleTab3()">Professional
                                    Information</label>
                                <div class="tab-content"  [ngClass]="{'expanded-tab-content': isTabExpanded3}">
                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Job Title:</label>
                                        <ng-select class="drop3" [(ngModel)]="paramterFilter.JobTitle" [placeholder]="'Select Job Title'">
                                            <ng-option *ngFor="let job of JobList" [value]="job.ID">
                                                {{ job.DisplayValue }}
                                            </ng-option>
                                        </ng-select>                                            
                                    </div><br>

                                    <div class="col-md-12 p-drops">
                                        <label class="label-text mb-3">Industry:</label>
                                        <ng-select class="drop3" [(ngModel)]="paramterFilter.Industry" [placeholder]="'Select Industry'">
                                            <ng-option *ngFor="let gender of industryList" [value]="gender.ID">
                                                {{ gender.DisplayValue }}
                                            </ng-option>
                                        </ng-select>                                            
                                    </div><br>

                                    <div class="row">
                                        <div class="col-md-12 col-xs-12
                                            p-drops">
                                            <div class="col-xs-7" style="padding: 0px;">
                                                <label class="label-text mb-3">Experience:</label>
                                            </div>
                                            <div class="col-xs-5 p-remv p-0">
                                                <ng-select class="drop7" [(ngModel)]="paramterFilter.YearOfExp" [placeholder]="'Year'">
                                                    <ng-option *ngFor="let gender of yearsOfExp" [value]="gender.ID">
                                                        {{ gender.DisplayValue }}
                                                    </ng-option>
                                                </ng-select>                                                    
                                            </div>
                                        </div>
                                    </div><br>

                                    <div class="row">
                                        <div class="col-md-12 p-drops">
                                            <label class="label-text mb-3">Level:</label>
                                            <ng-select class="drop3 custom-dropdown" [(ngModel)]="paramterFilter.Level" [placeholder]="'Select Level'">
                                                <ng-option [value]="level.ID" *ngFor="let level of LevelList">
                                                    {{ level.DisplayValue }}
                                                </ng-option>
                                            </ng-select>                                                
                                        </div>
                                    </div><br>
<!-- 
                                    <div class="row">
                                        <div class="col-md-12 col-xs-12
                                            p-right p-drops">
                                            <div class="col-xs-7 pl-years">
                                                <label class="mrglab1">Years
                                                    of experience at level:</label>
                                            </div>
                                            <div class="col-xs-5 p-remv">
                                                <select class="drop7" [(ngModel)]="paramterFilter.YearOfExp">
                                                    <option value="0">
                                                        Year
                                                    </option>
                                                    <option *ngFor="let gender of yearsOfExp" value={{gender.ID}}>
                                                        {{gender.SystemValue}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div> -->
                                    <br>
                                    <div class="button-container">
                                        <button type="button" class="btn blue-bottom-button mr-2" (click)="appltFilter(); Isfilter = false">Apply Filter</button>
                                        <button type="button" class="btn blue-bottom-button" (click)="clearFilter(); Isfilter = false">Clear Filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
            <div class="col-md-8">
                <div class=""  [ngClass]="{'mob-d-none': Isfilter == true, 'mob-d-show' : Isfilter == false}">
                    <div class="">
                        <div class="search mt-0" [ngClass]="{'Position-initial' : Isfilter == true}">
                            <div class="">
                                <div class="" [ngClass]="{'mob-d-none': IsSearch == false, 'mob-d-show' : IsSearch == true}">
                                    <div class="searchbox">
                                        <input class="serc" type="search" name="searchcandidates" value=""
                                            placeholder="Search Candidates" #filter [formControl]="searchCategory">
                                        <img src="../../../assets/Background/Vector.png" class="searchicon">
                                    </div>
                                </div>
                                <div class="">
                                    <div class="searchbox pl-proceed-w">
                                        <div class="row justify-content-center align-items-center">
                                            <div class="col-md-8">
                                                <h2 class="heading-text">Proceed
                                                    to retrieve contact details of
                                                    candidates
                                                </h2>
                                            </div>
                                            <div class="col-md-4 row justify-content-center">
                                                <button type="button" class="btn blue-button"
                                                    [routerLink]="'/candidate-contactlist/' + paramterFilter.JobId">Proceed</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="container mb-container p-0">
                        <div class="col-md-12 search"  [ngClass]="{'Position-initial' : Isfilter == true}">
                            <div class="">
            
                              
                                <div class=" p-removed" [ngClass]="{'mob-d-none': Isfilter == true, 'mob-d-show' : Isfilter == false}">
                                    <div *ngIf="isSelectedCriteria == true">
                                        <h1>Please select at least one of the three search criteria.</h1>
                                    </div>
                                    <div *ngIf="GetCandidateListData.length == 0 && isSelectedCriteria == false"> 
                                            <h1>  No such records found. Please try again later</h1>
                                    </div>
                                    <div class="row searchbox1 no-border r-hiden" *ngFor="let candidate of this.GetCandidateListData | paginate: config">
                                        <div class="col-md-12 col-sm-12">
                                            <div class="col-md-3 col-sm-3 round">
                                                <div class="infoimage text-center">
                                                    <div class="otherinfo"  [routerLink]="'/candidate-profile/' + candidate.userid + '/' + candidate.PersonID + '/' + candidate.id + '/' + paramterFilter.JobId">
                                                        <img *ngIf="candidate.ImagePath == null || candidate.ImagePath == undefined" src="../../assets/Background/Ellipse.png" class="imageeee">
                                                        <img *ngIf="!!candidate.ImagePath" src="{{candidate.ImagePath}}" class="imageeee">
            
                                                    </div>
            
                                                </div>
                                            </div>
            
                                            <div class="col-md-7 col-sm-7 info">
                                                <span>
                                                        <span class="border-text">
                                                        <span class="text-left greatitle"
                                                        [routerLink]="'/candidate-profile/' + candidate.userid + '/' + candidate.PersonID + '/' + candidate.id + '/' + paramterFilter.JobId">{{candidate.firstname}}
                                                            {{candidate.lastname}}
                                                    
                                                        </span>
                                                    </span>
                                                </span>
            
                                                <p class="location"><b>{{candidate.CityName}}</b></p>
                                                <p class="message"><i class="boxinfo">{{candidate.Quote}}</i></p>
                                            </div>
                                            <div class="col-md-2 col-xs-12 col-sm-2 choise">
                                                <div class="col-md-12 col-xs-6 text-center
                                                    bordergread">
                                                    <h6 class="text-center">Select<br>
                                                        Candidate:
                                                    </h6>
            
                                                    <!-- <div class="form-group">
                                                        <input type="checkbox" id="css">
                                                        <label for="css"></label>
                                                      </div> -->
                                                    <div class="form-group">
                                                        <input type="checkbox" [(ngModel)]="candidate.IsSelected" id="{{candidate.id}}" (change)="selectCanididate($event, candidate.id)">
                                                        <label for="{{candidate.id}}"></label>
                                                    </div>
            
                                                </div>
                                                <div class="col-md-12 col-xs-6 bordergread1
                                                    text-center">
                                                    <h6 class="text-center">Favorite <br>Candidate:</h6>
                                                  
            
                                                    <img *ngIf="candidate.Isfav == 0" style="cursor: pointer;" (click)="onFavoriteClick(candidate)"
                                                    src="../../assets/Background/Vector4.png" class="posi1">
                                                <img *ngIf="candidate.Isfav == 1" style="cursor: pointer;" (click)="onUnFavoriteClick(candidate)"
                                                    src="../../assets/Background/vector5.jpg" class="posi1">
            
                                                </div>
                                            </div>
            
            
                                        </div>
                                    </div>
            
                                    <div class="row searchbox1 no-border r-show" *ngFor="let candidate of this.GetCandidateListData">
                                        <div class="col-xs-12 p-remove">
                                            <div class="col-xs-3 round p-right">
                                                <div class="infoimage text-center">
                                                    <div class="otherinfo"   [routerLink]="'/candidate-profile/' + candidate.userid + '/' + candidate.PersonID + '/' + candidate.id + '/' + paramterFilter.JobId">
                                                        <img *ngIf="candidate.ImagePath == null || candidate.ImagePath == undefined" src="../../assets/Background/Ellipse.png" class="imageeee">
                                                        <img *ngIf="!!candidate.ImagePath" src="{{candidate.ImagePath}}" class="imageeee">
                                                    </div>
            
                                                </div>
                                            </div>
                                            <div class="col-xs-9 info p-remove">
                                                    <span class="border-text"><span class="text-left greatitle"
                                                [routerLink]="'/candidate-profile/' + candidate.userid + '/' + candidate.PersonID + '/' + candidate.id + '/' + paramterFilter.JobId">
                                                {{candidate.firstname}} {{candidate.lastname}}
                                                </span></span>
            
                                                <p class="message"><i class="boxinfo">{{candidate.Quote}}</i></p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <div class="col-xs-6 text-center selectbox">
                                                    <div class="row justify-content-center align-items-center">
                                                        <div class="col-xs-6 p-rem">
                                                            <h6 class="text-candidate">Favorite Candidate:</h6>
                                                        </div>
                                                        <div class="col-xs-6">
                                                           
                                                                <img *ngIf="candidate.Isfav == 0" style="cursor: pointer;" (click)="onFavoriteClick(candidate)"
                                                                src="../../assets/Background/Vector4.png" class="posi1">
                                                            <img *ngIf="candidate.Isfav == 1" style="cursor: pointer;" (click)="onUnFavoriteClick(candidate)"
                                                                src="../../assets/Background/vector5.jpg" class="posi1">
                    
                                                        </div>
                                                    </div>
            
            
                                                </div>
                                                <div class="col-xs-6 text-center
                                                    selectbox1">
                                                    <div class="row justify-content-center align-items-center">
                                                        <div class="col-xs-6 p-rem">
                                                            <h6 class="text-candidate">Select Candidate:</h6>
                                                        </div>
                                                        <div class="col-xs-6">
                                                            <!-- <img src="../../../assets/Background/Group3.png" class="posi1"> -->
                                                            <div class="form-group">
                                                                    <input type="checkbox" [(ngModel)]="candidate.IsSelected" id="{{candidate.id}}" (change)="selectCanididate($event, candidate.id)">
                                                                    <label for="{{candidate.id}}"></label>
                                                                </div>
                                                        </div>
                                                    </div>
            
            
                                                </div>
                                            </div>
                                        </div>
            
            
                                    </div>
            
                                    <pagination-controls *ngIf="this.GetCandidateListData.length > 0" (pageChange)="pageChanged($event)"></pagination-controls>
            
            
                                </div>
            
            
                            </div>
            
                        </div>
            
                    </div>
            
                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="exampleModal" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog model-xl modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" (click)="ACTIVEMODEL= ''" class="close" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    ...
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div>
            </div>
        </div>
    </div>

    <!-- register popup -->
    <app-register-job [ACTIVEMODEL]="ACTIVEMODEL" (hideModel)="hidenModel($event)"></app-register-job>

    <!-- proceed payment -->
    <div class="modal" [ngClass]="{modalShow: paymentMODEL == 'payment',
        modalHide: paymentMODEL !== 'payment'}" id="paymentmodal" tabindex="-1" role="dialog"
        aria-labelledby="paymentmodal" aria-hidden="true">
        <div class="modal-dialog model-xl modal-dialog-centered" role="document">
            <div class="modal-content btn1-grad-border">

                <button type="button" (click)="paymentMODEL= ''" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style="float: right;
                        width: 29px;
                        font-size: 26px;outline: none;">×</span>
                </button>


                <div class="modal-body">
                    <div class="mt-to-bt popup">
                        <div class="inner-text">
                            <h2 class="text-center">Payment Summary</h2>
                            <hr class="border113">
                        </div>
                        <div class="summary">
                            <p class="text-center">To retrieve the contact
                                details of your chosen candidates, you will be
                                redirected to complete payment of R399
                            </p>
                        </div>
                        <div class="row content">
                            <div class="col-md-12">
                                <label for="" class="take1">Method of payment</label>
                            </div>
                            <div class="col-md-12">
                                <select class="option option-job">
                                    <option value="JobTitle">Job Title</option>
                                    <option value="JobTitle">JobTitle</option>
                                    <option value="JobTitle">JobTitle</option>
                                    <option value="JobTitle">JobTitle</option>
                                </select>


                                <div _ngcontent-c0="" class="text-center">
                                    <button _ngcontent-c0="" class="btn-btn-grad
                                        grad-btn"> Proceed </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>