<section class="main-page">
    <div class="">
    <div class="main-page-container">
        <div class="col-md-12">
            <div class="col-md-4">
                <div class="row">
                    <div class="searchbar-container">
                        <div class="">
                            <label class="label-text mb-3" href="#popup1" style="padding-left: 0px;">
                                Registered Job:*</label>
                        </div>
                        <div class="mb-4">
                            <ng-select (ngModelChange)="changed($event)" [placeholder]="'Select Job Title'">
                                <ng-option *ngFor="let job of RecruiterJobList" [value]="job.ID">
                                    {{ job.Title?.DisplayValue }} - {{ job.RefNo }}
                                </ng-option>
                            </ng-select>
                        </div>
                        <div class="text-center" *ngIf="this.selectedJobDetails">
                            <span class="refernce">Reference Number: <span>
                                </span>{{this.selectedJobDetails.RefNo}}</span>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-8">
                <div *ngIf="this.paidJobDetails.length > 0" class="row titl">
                    <div class="row heading-container justify-content-center">
                        <h1 class="heading">Congratulations! You can now contact, and find
                            your perfect candidate.</h1>
                    </div>
                </div>
                <div class="container">
                        <div *ngIf="this.paidJobDetails.length == 0" class="d-flex align-items-center justify-content-center centered-text" style="min-height: calc(100vh - 50vh);">
                            <div class="row heading-container justify-content-center">
                                <h1 class="heading">No such records found. Please try again later</h1>
                            </div>
                        </div>  
                    <div class="row">         
                        <div class="col-md-12 candidatebox" *ngFor="let user of this.paidJobDetails | paginate: config">              
                            <div class="row searchbox1">                    
                                <div class="col-lg-12 plr-box col-md-12">
                                    <div class="col-md-4 plr-img text-center">
                                        <div class="infoimage text-center">
                                            <div class="otherinfo"  [routerLink]="'/candidate-profile/' + user.UserID + '/' + user.PersonID + '/' + user.CandidateID + '/' + selectedJobDetails.ID">
                                                <img  *ngIf="user.Photo == null || user.Photo == undefined"
                                                src="../../assets/Background/Ellipse.png"
                                                class="imageeee">
                                                <img  *ngIf="!!user.Photo"
                                                src="{{user.Photo}}"
                                                class="imageeee">
                                            </div>
            
                                        </div>
            
                                    </div>
                                    <div class="col-md-6 infobox">
                                            <span class="border-text"> 
                                                <span class="text-left infotitle" [routerLink]="'/candidate-profile/' + user.UserID + '/' + user.PersonID + '/' + user.CandidateID + '/' + selectedJobDetails.ID">
                                                    {{user.FirstName}} {{user.LastName}}
                                        </span></span>
                                        <p style="margin-top:12px"><b> {{user.State}}</b></p><br>
                                        <div class="col-md-12 subinfobox">
                                            <div class="col-md-12 mailbox">
                                                <img src="../assets/Background/mail.png">
                                                <span
                                                    class="mail">{{user.Email}}</span>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 mailbox mailborder">
                                                    <img
                                                        src="../assets/Background/call.png"><span
                                                        class="mail1"
                                                        style="margin-top:5px;">{{user.PhoneNo}}</span>
            
                                                </div>
                                                <div class="col-md-6 mailbox callborder">
                                                    <img class="pl-number"
                                                        src="../assets/Background/mobile-phone.png"><span
                                                        class="mail1"
                                                        style="margin-top:5px;">{{user.WorkTelephone}}</span>
                                                </div>
                                            </div>
                                        </div>
            
            
                                    </div>
                                    <div class="col-md-2 infomessage text-center">
                                        <h6 class="text-center">Send<br> Message:</h6>
                                        <img src="../assets/Background/sendmassege.png" (click)="sendMessage(user)">
                                    </div>
                                </div>
             
                                <!-- display in small screen -->
                                <div class="row r-hide">
                                    <div class="row">
                                        <div class="row"  style="width: 100%; align-items: center;">
                                        <div class="col-xs-4">
                                            <div class="infoimage text-center">
                                                <div class="otherinfo"  [routerLink]="'/candidate-profile/' + user.UserID + '/' + user.PersonID + '/' + user.CandidateID + '/' + selectedJobDetails.ID">
                                                    <img  *ngIf="user.Photo == null || user.Photo == undefined"
                                                        src="../../assets/Background/Ellipse.png"
                                                        class="imageeee">
                                                        <img  *ngIf="!!user.Photo"
                                                        src="{{user.Photo}}"
                                                        class="imageeee">
                                                </div>
            
                                            </div>
                                        </div>
            
                                        <div class="col-xs-8">
                                                <span class="border-text"> <span class="text-left infotitle"
                                            [routerLink]="'/candidate-profile/' + user.UserID + '/' + user.PersonID + '/' + user.CandidateID + '/' + selectedJobDetails.ID">{{user.FirstName}}
                                                {{user.LastName}} 
                                            </span></span>
                                            <p style="margin-top:12px"><b> {{user.City}} </b></p><br>
                                            <!-- <div class="subinfobox">
                                                <div class="col-xs-12 mailbox">
                                                    <img
                                                        src="../assets/Background/mail.png">
                                                    <span
                                                        class="mail">email@searchadam.com</span>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 mailbox
                                                        mailborder">
                                                        <img
                                                            src="../assets/Background/call.png"><span
                                                            class="mail1"
                                                            style="margin-top:5px;">011
                                                            000 1234</span>
            
                                                    </div>
                                                    <div class="col-md-6 mailbox
                                                        callborder">
                                                        <img class="pl-number"
                                                            src="../assets/Background/mobile-phone.png"><span
                                                            class="mail1"
                                                            style="margin-top:5px;">076
                                                            000 1234</span>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                        </div>
                                        <div class="mt-send-message">
                                            <h6 class="text-center" >Send<br> Message:</h6>
                                            <img src="../assets/Background/sendmassege.png" (click)="sendMessage(user)">
                                        </div>
                                    </div><!---row-->
            
                                    <!-- hide in-full screen -->
                                    <div class="h-row">
                                        <div class="col-xs-2">
                                            <img src="../assets/Background/mail.png">
                                        </div>
                                        <div class="col-xs-10">
                                            <span class="mail"> {{user.Email}} </span>
                                        </div>
            
                                        <div class="col-xs-2">
                                            <img src="../assets/Background/call.png">
                                        </div>
                                        <div class="col-xs-10">
                                            <span class="mail1" style="margin-top:5px;">
                                                {{user.PhoneNo}}</span>
                                        </div>
            
                                        <div class="col-xs-2">
                                            <img
                                                src="../assets/Background/mobile-phone.png">
                                        </div>
                                        <div class="col-xs-10">
                                            <span class="mail1" style="margin-top:5px;">
                                                {{user.WorkTelephone}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <pagination-controls class="pagination" *ngIf="this.paidJobDetails.length > 0" (pageChange)="pageChanged($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</section>
