
export class ConfigPageModel {
    itemsPerPage: number = 10
    currentPage: number = 1;
    totalItems: number = 0;
    constructor(init?: Partial<ConfigPageModel>) {
        Object.assign(this, init);
    }
}

export class PageModel extends ConfigPageModel {
    screenName?: PageScreen;
    constructor(screenName?: PageScreen, init?: Partial<PageModel>) {
        super();
        this.screenName = screenName;
        Object.assign(this, init);
    }
}


export class SeoDetailModel {
    ID: number;
    PageUrl: string;
    Title: string;
    objectTypeId: number;
    MetaTag: string;
    Description: string;
    SeoPageId: number;
    createdOn: Date | any;

    constructor(init?: Partial<SeoDetailModel>) {
        Object.assign(this, init);
    }
}


export enum PageScreen {
    MYJOBS,
    ALLCANDIDATE,
    SEARCHMEMBER,
    MYCANDIDATE,
    FAVOURITECANDIDATE,
    CANDIDATELIST
}


export enum SeoHelp {
    keywords,
    description,
    og_title,
    og_description,
}




