import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SteperComponent } from './steps.component';

@NgModule({

    declarations: [SteperComponent],
    imports: [
        CommonModule,
        RouterModule
    ],
    entryComponents: [],
    exports: [
      SteperComponent
    ]
})

export class SteperModule {

}
