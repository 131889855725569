import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { HttpHeaders } from "@angular/common/http";
import { SERVER_URL } from "./config";
import { CoreHttpService } from "./core-http.service";

const getContractorCountURL = SERVER_URL + '/Partner/GetPartnerdashboardCount?userId=';

@Injectable()
export class AuthService {
  public currentuser: any;
  getAllResources: any;
  public userPrefferName = new BehaviorSubject<string>("");
  public currentUserName = new BehaviorSubject<string>("");
  cast = this.currentUserName.asObservable();

  constructor(private http: HttpClient, public _coreHttpService: CoreHttpService) { }

  SetCurrentUser(response): any {
    localStorage.setItem("UserDetail", JSON.stringify(response));
    this.currentuser = response;
    this.userPrefferName.next(this.currentuser.FirstName);
  }
  userValueforNavigation(): any
  {
    this.currentuser = JSON.parse(localStorage.getItem('UserDetail'));
    if (this.currentuser != undefined && this.currentuser != null && this.currentuser != '') {
     // this.currentuser = JSON.parse(localStorage.getItem('userinfo'));
     const user = this.currentuser.FirstName + " " + this.currentuser.LastName;
      this.userPrefferName.next(user);
    }
  }
  userDatachange(): any {
    this.currentuser = JSON.parse(localStorage.getItem("UserDetail"));
    if (!!this.currentuser.LastName) {
      const user = this.currentuser.FirstName + " " + this.currentuser.LastName;
      this.userPrefferName.next(user);
    } else {
      this.userPrefferName.next(this.currentuser.FirstName);
    }
  }
  editNavigationName(prefferName): any {
    this.userPrefferName.next(prefferName);
  }
  getPrefferedName(): any {
    return this.userPrefferName.asObservable();
  }

  getAllResourceData(): any {
      const getAllResources  = localStorage.getItem('gelAllResourceData');
      if (!!getAllResources){
        this.getAllResources = JSON.parse(localStorage.getItem('gelAllResourceData'));
        return this.getAllResources;
      }
      else {
          return null;
      }
  }

  getDashboardCount = (id) => {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const url = getContractorCountURL + id;
    return this._coreHttpService.httpGetRequest<any>(
        url,
        headers
    );
  }

}
