import { Component, HostListener } from "@angular/core";

@Component({
    selector: 'app-footer-grey',
    templateUrl: './footer-grey.component.html',
    styleUrls: ['./footer-grey.component.scss']
})
export class FooterGreyComponent {
    constructor() {
    }
}
