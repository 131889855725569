import { Component, HostListener, OnDestroy } from "@angular/core";
import { Router } from '@angular/router';
import { AppService } from "../../../app.service";
import { CoreService } from "../../../core/core.service";
@Component({
  selector: 'app-menubar-view',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent{
  isMenuHide: boolean;
  innerWidth: any;
  currentuser: any;
  uesrPrefferName: any;
  userProfileimage: any;
  IsProfile: any = false;
  IsshowMEnu: any = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 767) {
      this.isMenuHide = true;
    } else {
      this.isMenuHide = false;
    }
  }
  constructor(public router: Router, public authService: AppService, public coreService : CoreService) {
    const url = router.routerState.snapshot.url;
    if (url == '/my-profile') {
      this.IsProfile = true;
    } else {
      this.IsProfile = false;
    }
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 767) {
      this.isMenuHide = true;
    } else {
      this.isMenuHide = false;
    }

    if (localStorage.getItem("UserInfo") != "null" && localStorage.getItem("UserInfo") != undefined) {

      this.currentuser = JSON.parse(localStorage.getItem("UserInfo"));

      this.uesrPrefferName = this.currentuser.FirstName + ' ' + this.currentuser.LastName;
      this.userProfileimage = this.currentuser.PhotoURL;
      this.authService.userValueforNavigation();
      this.authService.getPrefferedName().subscribe((userPrefferName) => {

        this.uesrPrefferName = userPrefferName;
      });
      this.authService.getNavigationImage().subscribe((userProfileimage) => {

        this.userProfileimage = userProfileimage;
      });
    }


  }
 
  SignOut() {
    localStorage.setItem("UserInfo", null);
    localStorage.clear();
    this.coreService.logout();
    localStorage.setItem("PageInfo",null);
    localStorage.setItem("UserToken",'');
    localStorage.setItem("Token",null);
    localStorage.setItem("RefreshToken",null);
    this.router.navigate(['/login']);
  }
}
