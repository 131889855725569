export enum QueryType {
    Select, Insert, Update, Delete, Upsert
}
enum JoinType {
    LeftJoin, RightJoin
}
export enum ConjuctionClause {
    AND = 1, OR = 2, AND_NOT = 3, OR_NOT = 4
}
export enum RelationalOperator {
    GreaterThan = 1, LessThan = 2, EqualTo = 3
}
export enum FilterValueType {
    Literal, Parameter, Property, Global
}
export enum ObjectCreationType {
    Standard = 1, Custom = 2
}
export enum DeploymentStatus {
    InDevelopment = 1, Deployed = 2
}
export enum DataTypes {
    AutoNumber = 1,
    Number = 2,
    CheckBox = 3,
    Currency = 4,
    Date = 5,
    DateTime = 6,
    Email = 7,
    GeoLocation = 8,
    Percent = 9,
    PhoneNumber = 10,
    PickList = 11,
    PickList_MultiSelect = 12,
    Text = 13,
    TextArea = 14,
    TextArea_RICHEDITOR = 15,
    URL = 16,
    UniqueIdentifier = 17
}
enum SortSequence {
    Asc, Desc
}

export class Application {
    ID: string;
    AppName: string;
    DisplayName: string;
    PlatformVersion: string;
    AppVersion: string;
    Description: string;
    AppObjects: AppObject[];
    //  AppScreens: AppScreen[];
    //  Roles: Role;
    Profiles: Profile;
    //  WorkFlows: WorkFlow;
}

export class Query {
    QueryObject: AppObject;
    QueryType: QueryType;
    Joins: JoinDetails[];
    WhereClause: FilterCriteria;
    Values: ValueSets[];

    constructor() {
        this.Joins = new Array<JoinDetails>();
        this.WhereClause = new FilterCriteria();
    }
}

export class ValueSets {
    Field: AppFields;
    Value: string;
}

export class AppObject {
    ID: string;
    ObjectName: string;
    DisplayName: string;
    Description: string;
    EnableTracking: boolean;
    AllowSearchable: boolean;
    CreationType: ObjectCreationType;
    AllowReports: boolean;
    AllowActivities: boolean;
    AllowSharing: boolean;
    DeploymentStatus: DeploymentStatus;
    Fields: AppFields[];
    ChildRelationShips: ChildRelationShip[];
    DataSourceQueries: DataSourceQueries[];
    SystemDBTableName: string;
    AccessList: ObjectAccess[];
    EmailTemplates: EmailTemplate[];
    Resources: Resources[];
    IsSystem: boolean;
    IsVisible: boolean;
    IsDeprecated: boolean;
}

class EmailTemplate {
    ID: string;
    Template: string;
}

class Resources {
    ID: string;
    Value: string;
}

class ObjectAccess {
    ID: string;
    ProfileID: Profile;
    IsVisible: boolean;
    IsReadOnly: boolean;
    CanCreate: boolean;
    CanEdit: boolean;
    CanDelete: boolean;
}

class DataSourceQueries {
    ID: string;
    ObjectID: AppObject;
    QueryName: string;
    DisplayName: string;
    Fields: AppFields[];
    Filters: FilterCriteria;
    Sort: Sort[];
    Parameters: Parameters[];
}

class Parameters {
    ID: string;
    public ParameterName: string;
}

class Sort {
    ID: string;
    Field: AppFields;
    SortSequence: SortSequence;
    Sequence: number;
}

export class AppFields {
    ID: string;
    ObjectID: AppObject;
    FieldName: string;
    DisplayName: string;
    FieldType: FieldType;
    Description: string;
    CreationType: ObjectCreationType;
    IsRequired: boolean;
    IsUnique: boolean;
    IsSearchable: boolean;
    IsConfidential: boolean;
    SystemDBFieldName: string;
    IsSystem: boolean;
    IsVisible: boolean;
    IsDeprecated: boolean;
    LookUpDetails: LookUpDetails;
    AccessList: FieldAccess[];
    Data: string;
}

export class FieldType {
    DataType: DataTypes;
    RelationalOperator: RelationalOperator[];
}

export class LookUpDetails {
    LookupObject: AppObject;
    LookupField: AppFields;
}

export class FieldAccess {
    ID: string;
    ProfileID: Profile;
    IsVisible: boolean;
    IsReadOnly: boolean;
}

export class Profile {
    ID: string;
    ProfileName: string;
    Description: string;
}

class JoinDetails {
    JoinType: JoinType;
    Relationship: ChildRelationShip;
}

export class Filter {
    ID: string;
    ConjuctionClause: ConjuctionClause;
    Field: AppFields;
    RelationalOperator: RelationalOperator;
    ValueType: FilterValueType;
    value: string;
    Sequence: number;
    GroupID: number;
}

export class FilterCriteria {
    Filters: Filter[];
    FilterLogic: string;
}

class ChildRelationShip {
    RelSourceObject: AppObject;
    RelSourceField: AppFields;
    RelTargetObject: AppObject;
    RelTargetField: AppFields;
}

export interface IAppConfig {
    apiServer: {
        link: string;
        subFolder: string;
        locallink: string;
        serverlink: string;
        screenDesigner: string;
        LoginURLLocal: string;
        LoginURLLive: string;
        LocalAPPUrl: string;
        LiveAPPUrl: string;
    };
}





export class AppScreen {
    ID: string;
    Name: string;
    Description: string;
    DataSourceQuery: DataSourceQueries;
    Container: any;
}


class User {
    ID: string;
    UserName: string;
    Password: string;
    UserType: number;
    LastLoginDate: string;
    LoginCount: number;
}


export class NavigationManagement {
    ID?: string;
    Name?: string;
    CurrentActiveScreenId?: string;
    PreviousActiveScreenId?: string;
}

export class ImageModel {
    id?: string;
    extension?: string;
    fileData?: string;
}