<div id="main-profile" class="main-page">
    <div class="main-page-container">
        <div class="col-md-12">
            <div class="col-md-4">
                <div class="card">
                    <div class="card_background_img"></div>
                    <div class="text-center">
                        <img *ngIf="this.CurrentUser.PhotoURL == null" [src]="image" class="imageeee card_profile_img">
                        <img *ngIf="this.CurrentUser.PhotoURL != null" [src]="this.CurrentUser.PhotoURL"
                            class="imageeee card_profile_img">
                    </div>
                    <div class="text-center">
                        <input hidden type="file" #fileInput id="file1" (click)="fileInput.value = null"
                            accept="image/x-png,image/gif,image/jpeg" (change)="changeListener($event)" />
                        <button class="btn btn-picture" (click)="fileInput.click()">
                            Click to add your picture
                        </button>
                    </div>
                    <div class="user_details" *ngIf="!!this.CurrentUser">
                        <h3 class="">{{this.CurrentUser.FirstName}} {{this.CurrentUser.LastName}}
                        </h3>
                        <p class=""> {{this.CurrentUser.Designation}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="info-person">
                    <form name="profileForm" [formGroup]="profileForm" novalidate>
                        <div class="form-pad mb-4" *ngIf="!!this.CurrentUser">
                            <div class="img-ad" *ngIf="isReadOnly">
                                <button class="btn btn-picture" (click)="isReadOnly = !isReadOnly "><img
                                        src="assets/Background/edit.png"></button>
                            </div>
                            <div class="img-ad" *ngIf="isReadOnly == false">
                                <button class="btn btn-picture" (click)="isReadOnly = !isReadOnly;NeedtoCancel() "><img
                                        src="assets/Background/cancel.png"></button>
                            </div>
                            <div class="row row-display">

                                <div class="col-md-4 boredr">
                                    <label for="" class="firstname">
                                        First Name </label>
                                </div>
                                <div class="col-md-8 boredr">
                                    <input class="input-feild" formControlName="FirstName"
                                        [ngClass]="{'input-border-none': isReadOnly == true, 'make-input-cursor' : isReadOnly == false}"
                                        [readonly]="isReadOnly">
                                </div>
                            </div>
                            <div class="row row-display">
                                <div class="col-md-4 boredr">
                                    <label for="" class="firstname">
                                        Last Name </label>
                                </div>
                                <div class="col-md-8 boredr">
                                    <input class="input-feild" formControlName="LastName"
                                        [ngClass]="{'input-border-none': isReadOnly == true,'make-input-cursor' : isReadOnly == false}"
                                        [readonly]="isReadOnly">
                                </div>
                            </div>
                            <div class="row row-display">
                                <div class="col-md-4 boredr">
                                    <label for="" class="firstname">
                                        Email Address
                                    </label>
                                </div>
                                <div class="col-md-8 boredr">
                                    <input class="input-feild" formControlName="Email"
                                        [ngClass]="{'input-border-none': isReadOnly == true,'make-input-cursor' : isReadOnly == false}"
                                        [readonly]="isReadOnly">

                                </div>
                            </div>
                            <div class="row row-display">
                                <div class="col-md-4 boredr">
                                    <label for="" class="firstname">
                                        Cellphone </label>
                                </div>
                                <div class="col-md-8 boredr">
                                    <input class="input-feild" formControlName="PhoneNo"
                                        [ngClass]="{'input-border-none': isReadOnly == true,'make-input-cursor' : isReadOnly == false}"
                                        [readonly]="isReadOnly">

                                </div>
                            </div>
                            <div class="row row-display">
                                <div class="col-md-4 boredr">
                                    <label for="" class="firstname">
                                        Company Name
                                    </label>
                                </div>
                                <div class="col-md-8 boredr">
                                    <input class="input-feild" formControlName="CompanyName"
                                        [ngClass]="{'input-border-none': isReadOnly == true,'make-input-cursor' : isReadOnly == false}"
                                        [readonly]="isReadOnly">
                                </div>
                            </div>
                            <div class="row row-display">
                                <div class="col-md-4 boredr">
                                    <label for="" class="firstname">
                                        Designation </label>
                                </div>
                                <div class="col-md-8 boredr">
                                    <input class="input-feild" formControlName="Designation"
                                        [ngClass]="{'input-border-none': isReadOnly == true,'make-input-cursor' : isReadOnly == false}"
                                        [readonly]="isReadOnly">

                                </div>
                            </div>
                            <div class="row row-display">
                                <div class="col-md-4 boredr">
                                    <label for="" class="firstname">
                                        Work Telephone
                                    </label>
                                </div>
                                <div class="col-md-8 boredr">
                                    <input class="input-feild" formControlName="WorkTelephone"
                                        [ngClass]="{'input-border-none': isReadOnly == true,'make-input-cursor' : isReadOnly == false}"
                                        [readonly]="isReadOnly">

                                </div>
                            </div>

                            <div class="row row-display">
                                <div class="col-md-4 boredr">
                                    <label for="" class="firstname"> I am a: </label>
                                </div>
                                <div class="col-md-8 boredr d-flex">
                                    <label for="opt1" class="radio" [ngClass]="{'cu-pointer' : isReadOnly == false}">
                                        <input type="radio" id="opt1" name="BusinessType" [value]="1178"
                                            class="hidden firstname" formControlName="BusinessType"
                                            [attr.disabled]="isReadOnly == true ? true : null">
                                        <span class="label"></span><span class="rdobtn-span">Business Owner</span>
                                    </label>
                                    <label for="opt2" class="radio" [ngClass]="{'cu-pointer' : isReadOnly == false}">
                                        <input type="radio" id="opt2" name="BusinessType" [value]="1180"
                                            class="hidden firstname" formControlName="BusinessType"
                                            [attr.disabled]="isReadOnly == true ? true : null">
                                        <span class="label"></span><span class="rdobtn-span">Hiring Manager</span>
                                    </label>
                                    <label for="opt3" class="radio" [ngClass]="{'cu-pointer' : isReadOnly == false}">
                                        <input type="radio" id="opt3" class="firstname" name="BusinessType"
                                            [value]="1179" class="hidden firstname" formControlName="BusinessType"
                                            [attr.disabled]="isReadOnly == true ? true : null">
                                        <span class="label"></span><span class="rdobtn-span">Recruiter</span>
                                    </label>
                                </div>
                            </div>
                            <div class="row row-display">
                                <div class="col-md-4">
                                    <label for="" class="firstname">
                                        Size of
                                        Organization:
                                    </label>

                                </div>
                                <div class="col-md-8 d-flex">
                                    <label for="opt4" class="radio" [ngClass]="{'cu-pointer' : isReadOnly == false}">
                                        <input type="radio" id="opt4" class="firstname" name="OrganizationSize"
                                            class="hidden" [value]="1118" formControlName="OrganizationSize"
                                            [attr.disabled]="isReadOnly == true ? true : null">
                                        <span class="label"></span><span class="rdobtn-span">1 - 25</span>
                                    </label>
                                    <label for="opt5" class="radio" [ngClass]="{'cu-pointer' : isReadOnly == false}">
                                        <input type="radio" id="opt5" style="margin-left:
                                                            10px;" name="OrganizationSize" class="hidden"
                                            [value]="1119" formControlName="OrganizationSize"
                                            [attr.disabled]="isReadOnly == true ? true : null">
                                        <span class="label"></span><span class="rdobtn-span"> > 25 </span>
                                    </label>
                                </div>
                            </div>


                        </div>
                    </form>
                </div>
                <div *ngIf="isReadOnly == false" class="text-center button-container">
                    <button class="btn blue-bottom-button" (click)="EditProfile()">
                        Save changes
                    </button>
                </div>
            </div>
        </div>
         
    </div>
</div>