import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from "../../app.service";
import * as ResetPasswordDetails from "./reset-password-classes";
import { ActivatedRoute, Router } from '@angular/router';

import Swal from "sweetalert2";
import { FilterCriteria, QueryType } from "../../app.class";
@Component({
    selector: 'app-reset-view',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
    resetpasswordForm: FormGroup;
    resetpasswordFormErrors: any;

    show1 = false;
    show2 = false;
    currentuser: any;
    Flag:any;
    isPasswordNotMatch?: boolean = false;
    newPassword() {
        this.show1 = !this.show1;
    }
    confirmPasswordShow() {
        this.show2 = !this.show2;
    }
    constructor(private formBuilder: FormBuilder,
        public _activeRoute: ActivatedRoute, public router: Router,
        private appService: AppService) {
        this.resetpasswordFormErrors = {
            Password: {},
            ConfirmPassword: {},
        };
    }
    ngOnInit(): void {
        this._activeRoute.params.forEach(params => {
            const Offset = params['ID'];
            if (Offset) {
                this.currentuser = parseInt(Offset);
            }
            const Flag = params['Flag'];
            if (Flag) {
                this.Flag = parseInt(Flag);
            }
        });

        this.resetpasswordForm = this.formBuilder.group({
            Password: ['', Validators.required],
            ConfirmPassword: ['', Validators.required]
        });
        this.resetpasswordForm.valueChanges.subscribe(() => {
            this.onresetpasswordFormValuesChanged();
        });
    }
    onresetpasswordFormValuesChanged(): any {
        for (const field in this.resetpasswordFormErrors) {
            if (!this.resetpasswordFormErrors.hasOwnProperty(field)) {
                continue;
            }
            // Clear previous errors
            this.resetpasswordFormErrors[field] = {};
            // Get the control
            const control = this.resetpasswordForm.get(field);
            if (control && control.dirty && !control.valid) {
                this.resetpasswordFormErrors[field] = control.errors;
            }
        }
    }


    confirmPassword(): any {
        const Password = this.resetpasswordForm.controls["Password"].value;
        const confirmPassword = this.resetpasswordForm.controls["ConfirmPassword"].value;
        if (!Password || !confirmPassword) {
            return;
        }
        if (confirmPassword === "") {
            this.isPasswordNotMatch = false;
        }

        if (Password !== confirmPassword) {
            this.isPasswordNotMatch = true;
        } else {
            this.isPasswordNotMatch = false;
        }
    }



    // On Login Click
    ResetPassword(): any {
        const loginUser = new ResetPasswordDetails.ResetPasswordDetails();
        Object.assign(loginUser, this.resetpasswordForm.value);
        if (this.resetpasswordForm.status != 'INVALID' && this.isPasswordNotMatch == false) {
            this.appService.GetToken("bhavik.thakkar@live.com","P@ssw0rd").subscribe(response => {
                if(response.StatusCode==200)
                {
                    localStorage.setItem("UserToken","bearer "+" "+response.Result.Token);
                    let WhereClause: any = new FilterCriteria();
                    WhereClause.Filters = [
                        {
                            ConjuctionClause: 1,
                            FieldID: "ID",
                            RelationalOperator: 3,
                            ValueType: 0,
                            value: this.currentuser
                        }]
                    WhereClause.FilterLogic = "1";
                    let dataObj = this.setData(loginUser);

                    let data = {
                        QueryObjectID: "UserInfo",
                        QueryType: QueryType.Select,
                        Joins: [],
                        WhereClause: WhereClause,
                    };
                    this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
                        if (response.StatusCode == "200") {
                            var userdata =response.Result[0];
                            let resetpassworddata ={
                                "Email":userdata.UserName,
                                "CurrentPassword": "",
                                "NewPassword":loginUser.Password
                            }
                            this.appService.changeuserPassword(resetpassworddata).subscribe(response => {               
                                    if(this.Flag == 0)
                                    {
                                        Swal({
                                            title: 'Success',
                                            text: 'Password changed successfully. Please login into app now.!',
                                        });
                                        
                                        
                                    }
                                    else{
                                        Swal({
                                            title: 'Success',
                                            text: 'Password changed successfully!',
                                        });

                                        // Update Password in User Table 

                                        let WhereClause: any = new FilterCriteria();
                                        WhereClause.Filters = [
                                            {
                                                ConjuctionClause: 1,
                                                FieldID: "ID",
                                                RelationalOperator: 3,
                                                ValueType: 0,
                                                value: this.currentuser
                                            }]
                                        WhereClause.FilterLogic = "1";
                                        let dataObj = this.setPasswordData(loginUser.Password);
                                        let data = {
                                            QueryObjectID: "UserInfo",
                                            QueryType: QueryType.Update,
                                            Joins: [],
                                            WhereClause: WhereClause,
                                            Values: dataObj
                                        };
                                        this.appService.executeQuery(data, QueryType.Update).subscribe(response => {                                       
                                            if (response.StatusCode == "200") {
                                            }
                                            else {
                                                alert("Fail");
                                            }
                                        });

                                    this.router.navigate(['/login']);
                                    }
                        })
                        }
                        else {
                            Swal({
                                title: 'Fail',
                                text: 'User not found!',
                            });
                        }
                    });
        }
    });
        } else {
            this.resetpasswordForm.get('Password').markAsTouched();
            this.resetpasswordForm.get('ConfirmPassword').markAsTouched();
        }
    }
    private setData(resetPasswordUser: ResetPasswordDetails.ResetPasswordDetails) {

        return [

            {
                AppFieldID: "Password",
                Value: btoa(resetPasswordUser.Password)
            },

        ];
    }

    private setPasswordData(password)
    {
        return [

            {
                AppFieldID: "Password",
                Value: btoa(password)
            },

        ];
    }

}
