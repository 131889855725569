import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';


import { from } from 'rxjs';
import { CandidateslistComponent } from './candidateslist.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenubarModule } from '../components/menubaar/aurthentication/menubar.module';
import { FooterModule } from '../components/footer/footer.module';
import { CommonModule } from '@angular/common';
import { FooterGreyModule } from '../components/footer-grey/footer-grey.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';

// import { HeaderComponent } from '../../components/toolbar/aurthentication-toolbar/header.component';



@NgModule({

    declarations: [CandidateslistComponent],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: CandidateslistComponent, pathMatch: 'full' }
        ]),
        MenubarModule,
        FooterGreyModule,
        CommonModule,
        NgxPaginationModule,
        NgSelectModule

    ],
})

export class CandidateslistModule {

}
