<!-- <button type="button" class="btnn2
    btn-grad-border" (click)="ACTIVEMODEL=
    'Register'">Register
    a Job</button> -->
<div class="modal" [ngClass]="{modalShow: ACTIVEMODEL == 'Register',
    modalHide: ACTIVEMODEL !== 'Register'}" id="exampleModal" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog model-xl modal-dialog-centered" role="document">
        <div class="modal-content btn1-grad-border alignborder">
            <button type="button" (click)="hidenModel()" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" style="float: right;
                                width: 29px;
                                font-size: 26px;outline: none;">×</span>
            </button>
            <div class="modal-body">
                <div class="mt-to-bt popup">
                    <div class="inner-text">
                        <span class="text-center">Register a Job</span>
                        <!-- <hr class="border112"> -->
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <label for="" class="take1 mb-2">Select Job
                                title:</label>
                        </div>
                        <div class="col-md-12">
                                <!-- <select style="cursor: pointer;" [(ngModel)]='selectedjobtitle'>
                                        <option  *ngFor="let job of this.jobTitles" value={{job.ID}}>
                                          {{job.DisplayValue}} 
                                        </option>
                                </select> -->
                                <ng-select style="cursor: pointer;" [(ngModel)]="selectedjobtitle" [placeholder]="'Select Job'">
                                    <ng-option *ngFor="let job of jobTitles" [value]="job.ID" class="left-align-option">
                                        {{ job.DisplayValue }}
                                    </ng-option>
                                </ng-select>
                            <div _ngcontent-c0="" class="text-center">
                                <button _ngcontent-c0=""
                                    class="btn blue-button" (click)="register()"> Register </button>
                            </div>
                            <!-- <div class="take1">
                                <a class="align" style="text-decoration: underline;color: #6C6C6Cff;" href="https://www.searchadam.com/addjob" target="_blank">I can't find the Job title I'd like to register</a>
                            </div> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>