import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FooterGreyComponent } from './footer-grey.component';


@NgModule({

    declarations: [FooterGreyComponent],
    imports: [
        CommonModule,
        RouterModule
    ],
    entryComponents: [],
    exports: [
        FooterGreyComponent
    ]
})

export class FooterGreyModule {

}
