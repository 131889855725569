<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex flex-column align-items-center">
                <div class="step">
                    <a class="num-div-layout" data-toggle="tab" aria-controls="step1" role="tab" title="Step 1">
                        <span class="number-tab">
                            <span class=" ">
                                <span class="text-span">1</span>
                            </span>
                        </span>
                    </a>
                    <div class="text-tab">
                        <p>Register a Job</p>
                    </div>
                </div>

                <div class="step">
                    <a class="num-div-layout" data-toggle="tab" aria-controls="step2" role="tab" title="Step 2">
                        <span class="number-tab">
                            <span class=" ">
                                <span class="text-span">2</span>
                            </span>
                        </span>
                    </a>
                    <div class="text-tab">
                        <p>Search Members / Post Job Advert</p>
                    </div>
                </div>

                <div class="step">
                    <a class="num-div-layout" data-toggle="tab" aria-controls="step3" role="tab" title="Step 3">
                        <span class="number-tab">
                            <span class=" ">
                                <span class="text-span">3</span>
                            </span>
                        </span>
                    </a>
                    <div class="text-tab">
                        <p>Filter / Find your candidates</p>
                    </div>
                </div>

                <div class="step">
                    <a class="num-div-layout" data-toggle="tab" aria-controls="complete" role="tab" title="Step 4">
                        <span class="number-tab">
                            <span class=" ">
                                <span class="text-span">4</span>
                            </span>
                        </span>
                    </a>
                    <div class="text-tab">
                        <p>View candidates info, videos, voice & pictures</p>
                    </div>
                </div>

                <div class="step">
                    <a class="num-div-layout" data-toggle="tab" aria-controls="complete" role="tab" title="Step 5">
                        <span class="number-tab">
                            <span class=" ">
                                <span class="text-span">5</span>
                            </span>
                        </span>
                    </a>
                    <div class="text-tab">
                        <p>Select the candidates you want to connect</p>
                    </div>
                </div>

                <div class="step">
                    <a class="num-div-layout" data-toggle="tab" aria-controls="complete" role="tab" title="Step 6">
                        <span class="number-tab">
                            <span class=" ">
                                <span class="text-span">6</span>
                            </span>
                        </span>
                    </a>
                    <div class="text-tab">
                        <p>Make payment to retrieve the contact details</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>