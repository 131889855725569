export class ProfileDetails {
    FirstName: string;
    LastName: string;
    CompanyName: string;
    Designation: string;
    WorkTelephone: string;
    PhoneNo: string;
    UserName: string;
    Password: string;
    ConfirmPassword: string;
    BusinessType: number;
    OrganizationSize: string;
    ID: number;
    Person: number;
    Email:string;
    SubscriptionPlan?: any;
}