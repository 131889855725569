import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(public router: Router) { }
    // canActivate(): boolean {
    //      ;
    //     if (!!localStorage.getItem("UserInfo")) {
    //          ;
    //         let d = JSON.parse(localStorage.getItem("UserInfo"));
    //         if (!!d.UserID && d.UserID != 0) {
    //             return true;
    //         } else {
    //             this.router.navigate(['/']);
    //             return false;
    //         }
    //     } else {
    //          ;
    //         this.router.navigate(['/']);
    //         return false
    //     }
    // }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {

        if (!!localStorage.getItem("UserInfo")) {
            let d = JSON.parse(localStorage.getItem("UserInfo"));
            if (!!d.UserID && d.UserID != 0) {
                return true;
            } else {
                this.router.navigate(['/']);
                return false;
            }
        } else {
            this.router.navigate(['/']);
            return false
        }

    }

}