import { Component } from "@angular/core";

import { AppService } from "../app.service";
import * as _ from "lodash";
import Swal from "sweetalert2";
// import undefined = require("firebase/empty-import");
import { Router, ActivatedRoute } from "@angular/router";
import { FormControl } from "@angular/forms";
import { FuseUtils } from "../core/search.class";
import { SeoDetailModel } from "../core/core.class";
import { CoreService } from "../core/core.service";
import { QueryType, FilterCriteria } from "../app.class";
import { AnyARecord } from "dns";
@Component({
    selector: 'app-candidatecontact-view',
    templateUrl: './candidate-contactlist.component.html',
    styleUrls: ['./candidate-contactlist.component.scss']
})
export class CandidateContactListComponent {
    //   for popup
    IsSearch = false;
    closeResult: string;
    ACTIVEMODEL = '';
    paymentMODEL: any = '';
    currentUser: any;
    jobList: any;
    selectedJob: any;
    allPaymentsJobList: any = [];
    paymentJobList: any = [];
    selectedUser: any = [];
    countOfSelected: any = 0;
    paymentType = 0;
    searchCandidate: FormControl;
    AllListData: any;
    paymentMethod: any;
    routeJobId: any;
    jobId: any;
    searchCategory = new FormControl("");
    flag:any;
    amountValueForRec?: any;
    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();
    SubscriptionPlanList:any;

    constructor(
        public appService: AppService,
        private router: Router,
        public _activeRoute: ActivatedRoute,
        private coreService: CoreService,
       
    ) {
     
        this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
            .subscribe((data: any) => {
                if (!!data && !!data.Result) {
                    this.SList = data.Result;
                    this.setSeo();
                }
            })


            
            

            const currentUser = localStorage.getItem("UserInfo");      
            
        
        if (!!currentUser) {
            this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));
     
            const data = {
                "QueryObjectID": "SubscriptionPlan",
                "QueryType": 0,
                "ResultField_AppfieldIds": [],
                "LoadLookUpValues": false,
            };
            this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
                this.SubscriptionPlanList = response.Result;
                
                var plandata = this.SubscriptionPlanList.find(x=>x.ID == this.currentUser.SubscriptionPlan);
                if(plandata)
                {
                      this.amountValueForRec =plandata.Amount;
                }
            });
            
            
    
            if (this.currentUser.IsVIP == null || this.currentUser.IsVIP == undefined) {
                this.currentUser.IsVIP = false;
            } else {
                this.currentUser.IsVIP = this.currentUser.IsVIP;
            }
            
            // if (!this.currentUser.SubscriptionPlan) {
            //     this.amountValueForRec = 0;
            // } else {
            //     this.amountValueForRec = this.currentUser.SubscriptionPlan;
            // }

            this.getJobDataByRecruiterId();
            this.getListEnumData();
            // this.getListData();
            this.searchCandidate = new FormControl('');
        }
    }
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2390));
        }
    }

    ngOnInit(): void {
        this._activeRoute.params.forEach(params => {
            const routeJobId = params['JOBID'];
            if (!!routeJobId) {
                this.routeJobId = Number(routeJobId);
            }
            const flag = params['Flag'];
            if (!!flag) {
                this.flag = Number(flag);
            }
            if(this.flag==0)
            {
                Swal({
                    title: 'Your transaction got canceled. Please try again or contact us if you have any queries.',
                });
            }
        });
        this.searchCategory.valueChanges
            .subscribe(searchText => {
                this.paymentJobList = FuseUtils.filterArrayByString(
                    this.allPaymentsJobList,
                    searchText
                );
            });

    }
    hidenModel(data): any {
        this.ACTIVEMODEL = data;
    }

    getJobDataByRecruiterId(): any {
        const data = {
            "QueryObjectID": "JobInfo",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "CreatedBy",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.currentUser.ID,
                    },
                ]
            },
            "Sort": [
                {
                    "FieldID": "ID",
                    "SortSequence": 2
                }
            ]
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.jobList = response.Result;
                if (!!this.routeJobId) {
                    this.selectedJob = this.routeJobId;
                    this.getListData();

                } else {
                    if (!!this.jobId) {
                        this.selectedJob = this.jobId;
                    } else {
                        this.selectedJob = this.jobList[0].ID;
                        this.getListData();
                    }
                }
            }
        });

    }

    onJobChange(job) {
        this.searchCategory.setValue('');
        let Job = this.jobList.filter(x => x.ID == job);
        if (Job.length > 0) {
            this.selectedJob = Job[0].ID;
            this.getListData();
        }

    }


    getListData(): any {
        const data = {
            "QueryObjectID": "CandidateContactList",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "JobID",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.selectedJob,
                    },
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "IsPaymentDone",
                        "RelationalOperator": 6,
                        "ValueType": 0,
                        "value": null,
                    }
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.paymentJobList = response.Result;
                this.allPaymentsJobList = response.Result;
                for (let data of this.paymentJobList) {
                    data['isSelected'] = true;
                    data['userName'] = data.FirstName + ' ' + data.LastName;


                }

            }
        });
    }

    removeCandidate(event, id): any {
        if (event.target.checked == false) {
            let WhereClause : any= new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "ID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: id
                }
            ];
            WhereClause.FilterLogic = "1";
            let data = {
                QueryObjectID: "SelectedCandidateInfo",
                QueryType: QueryType.Delete,
                Joins: [],
                WhereClause: WhereClause,
            };

            this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
                if (response.StatusCode == "200") {
                    this.getListData();
                }
            });
        }
    }


    addCandidate(event, evData): any {
        if (event.target.checked == true) {
            // evData.isAdded = true;
            const data = _.filter(this.paymentJobList, ['isAdded', true]);

            if (data.length < 10) {
            } else {
                evData.isAdded = false;
            }
            this.countOfSelected = data.length;

        } else {
            const data = _.filter(this.paymentJobList, ['isAdded', true]);
            this.countOfSelected = data.length;
        }
    }


    proceedPayment(): any {

        if (this.countOfSelected != 0) {
            this.paymentMODEL = 'payment';
        } else {
            Swal({
                title: 'Please select candidate.',
            });
        }
    }


    private getListEnumData() {
        let data = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": false,
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.AllListData = response.Result;

                this.BindListData();
            }
        });
    }

    private BindListData() {
        this.paymentMethod = this.AllListData.filter(x => x.EnumID == 1044);
    }

    processToPay() {
        let selectedCan = _.filter(this.paymentJobList, (list:any) => { return list.isAdded });
        let ids: any = [];
        this.currentUser["PaymentMode"] = this.paymentType;
        this.currentUser["JobId"] = this.selectedJob;
        this.currentUser["SelectedCandidate"] = selectedCan;
        localStorage.setItem("UserInfo", JSON.stringify(this.currentUser));

        if (this.currentUser.IsVIP) {
            if (!!this.paymentType) {
                if (this.paymentType == 1182) {
                    this.onlinePay();
                } else {
                    // manual

                    this.router.navigate(['/invoice']);
                }
            } else {
                Swal({
                    title: 'Please select Payment Mode.',
                });
            }
        } else {
            this.paymentType = 1182; // online
            this.onlinePay();
        }
    }

    onlinePay = () => {
        
        document.getElementById('amount')["value"] = this.amountValueForRec;
        document.getElementById('useremail')["value"] = this.currentUser.Email;
        document.getElementById('cancelurl')["value"]=this.appService.RedirectUrl+'/candidate-contactlist'+'/' +this.routeJobId + '/' + 0;
        document.getElementById('paypalbtn').click();
    }

    onFavoriteClick(event) {

        if (event['IsFav '] == 0) {
            let WhereClause = new FilterCriteria();
            WhereClause.Filters = [];
            WhereClause.FilterLogic = "1";
            let dataObj = this.setData(event);
            let data = {
                QueryObjectID: "FavouriteCandidateInfo",
                QueryType: QueryType.Insert,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj
            };
            this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
                
                if (response.StatusCode == "200") {
                    var UserId = response.Result;
                    Swal({
                        title: ' Added to Favorite!!',
                    });

                    this.getListData();

                }
            });
        }
        else {
            if (event['IsFav '] == 1) {
                let WhereClause : any = new FilterCriteria();
                WhereClause.Filters = [
                    {
                        ConjuctionClause: 1,
                        FieldID: "JobID",
                        RelationalOperator: 3,
                        ValueType: 0,
                        value: event.JobID
                    },
                    {
                        ConjuctionClause: 1,
                        FieldID: "CandidateID",
                        RelationalOperator: 3,
                        ValueType: 0,
                        value: event.CandidateID
                    },
                ];
                WhereClause.FilterLogic = "1";
                let data = {
                    QueryObjectID: "FavouriteCandidateInfo",
                    QueryType: QueryType.Delete,
                    Joins: [],
                    WhereClause: WhereClause,
                }
                this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
                    if (response.StatusCode == "200") 
                    {
                        var UserId = response.Result;
                        Swal({
                            title: ' Removed From Favorite!!',
                        });

                        this.getListData();
                    }
                });
            }
        }
    }

    private setData(event) {
        return [
            {
                AppFieldID: "JobID",
                Value: event.JobID
            },
            {
                AppFieldID: "CandidateID",
                Value: event.CandidateID
            },
        ];

    }



}
