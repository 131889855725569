import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./home/home.component";
import { HomePageComponent } from "./home-page/home-page.component";
// import { JobTitleComponent } from "./job-title/job-title.component";

import { MyJobsComponent } from "./my-jobs/my-jobs.component";
import { MenubarComponent } from "./components/menubaar/aurthentication/menubar.component";
import { MycandidatesComponent } from "./Authentication/mycandidates/mycandidates.component";
import { CandidateProfileComponent } from "./candidate-profile/candidate-profile.component";
import { PostjobComponent } from "./Authentication/post-job/postjob.component";
import { MyProfileComponent } from "./my-profile/my-profile.component";
import { LoginComponent } from "./Authentication/login/login.component";
import { CandidateslistComponent } from "./candidateslist/candidateslist.component";
import { CandidateContactListComponent } from "./candidate-contactlist/candidate-contactlist.component";
import { SteperComponent } from "./components/steps/steps.component";

import { InvoiceComponent } from "./invoice/invoice.component";

import { FavoritesComponent } from "./favorites/favorites.component";
import { ForgotPasswordComponent } from "./Authentication/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./Authentication/reset-password/reset-password.component";
import { MessageComponent } from "./messages/message.component";
import { AllcandidatesComponent } from "./all-candidates/all-candidates.component";
import { SelectedCandidateContactListModule } from "./selected-contactlist/selected-contactlist.module";
import { SelectedCandidateContactListComponent } from "./selected-contactlist/selected-contactlist.component";
import { AllSearchcandidatesComponent } from "./all-searchmember-candidate/all-searchmember-candidate.component";
import { AfterOnlinePaymentComponent } from "./after-online-payment/after-online-payment.component";
import { ViewApplicantsComponent } from "./view-applicants/view-applicants.component";
import { AuthGuard } from "./core/auth-guard.service";


// import { JobTitleComponent } from "./job-title/job-title.component";

const appRoutes: Routes = [

  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "lazy",
    loadChildren: () => import('./lazy/lazy.module').then(m => m.LazyModule)
  },
  { path: "lazy/nested", loadChildren: () => import('./lazy/lazy.module').then(m => m.LazyModule) },
  { path: "login", component: LoginComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password/:ID", component: ResetPasswordComponent},
  { path: "reset-password/:ID/:Flag", component: ResetPasswordComponent},
  { path: "register", loadChildren: () => import('./Authentication/register/register.module').then(m => m.RegisterModule) },
  { path: "searchmember", loadChildren: () => import('./Authentication/searchmember/search.module').then(m => m.SearchModule),
  canActivate: [AuthGuard]  },

  {
    path: 'home-page', component: HomePageComponent,
    canActivate: [AuthGuard]
  },
  { path: 'menubar', component: MenubarComponent,   
  canActivate: [AuthGuard] },

  { path: 'my-jobs', component: MyJobsComponent,
  canActivate: [AuthGuard] },

  { path: 'menu', component: MenubarComponent,
  canActivate: [AuthGuard] },

  { path: 'mycandidates', component: MycandidatesComponent,
  canActivate: [AuthGuard] },

  { path: 'menu', component: MenubarComponent,
  canActivate: [AuthGuard] },

  { path: 'candidate-profile/:USERID/:PERSONID/:CANDIDATEID/:JOBID', component: CandidateProfileComponent,
  canActivate: [AuthGuard] },

  { path: 'post-job', component: PostjobComponent,
  canActivate: [AuthGuard] },

  { path: 'post-job/:JOBID/:TYPE', component: PostjobComponent,   canActivate: [AuthGuard] },
  {
    path: 'my-profile', component: MyProfileComponent,
    canActivate: [AuthGuard]
  },
  { path: 'candidateslist', component: CandidateslistComponent,
  canActivate: [AuthGuard] },
  { path: 'candidateslist/:JOBID', component: CandidateslistComponent,
  canActivate: [AuthGuard] },
  // { path: 'job-title', component: JobTitleComponent },

  { path: 'candidate-contactlist', component: CandidateContactListComponent,
  canActivate: [AuthGuard] },

  { path: 'candidate-contactlist/:JOBID', component: CandidateContactListComponent,
  canActivate: [AuthGuard] },
  { path: 'candidate-contactlist/:JOBID/:Flag', component: CandidateContactListComponent,
  canActivate: [AuthGuard] },
  { path: 'steper', component: SteperComponent,
  canActivate: [AuthGuard] },

  { path: 'invoice', component: InvoiceComponent,
  canActivate: [AuthGuard] },

  { path: 'favorites', component: FavoritesComponent,
  canActivate: [AuthGuard] },

  { path: 'messages', component: MessageComponent,
  canActivate: [AuthGuard] },

  { path: 'messages/:ID', component: MessageComponent,
   canActivate: [AuthGuard] },

  { path: 'all-candidates', component: AllcandidatesComponent,
   canActivate: [AuthGuard] },

  { path: 'all-candidates/:JOBID', component: AllcandidatesComponent, 
  canActivate: [AuthGuard] },

  { path: 'selected-candidate', component: SelectedCandidateContactListComponent, 
  canActivate: [AuthGuard] },

  { path: 'all-searchmember-candidate', component: AllSearchcandidatesComponent,
   canActivate: [AuthGuard] },

  { path: 'after-online-payment', component: AfterOnlinePaymentComponent,
   canActivate: [AuthGuard] },

  { path: 'view-applicants/:JOBID', component: ViewApplicantsComponent,
   canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)]
})
export class AppRoutingModule { }
