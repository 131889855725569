<div id="candidate-profile" class="main-page">
   <div class="main-page-container">
      <div class="">
         <div class="col-lg-12 p-0">
            <h3 class="candidate123 text-center">Candidate Profile</h3>
            <div class="">
               <div class="col-md-12 p-0">
                  <div class="col-md-4">
                     <div class="candidate">
                        <div class="row justify-content-center">
                           <div class="text-center bordrimp">
                              <!-- <img class="rect6" src="/assets/Background/candidate.png"> -->
                              <div class="infoimage text-center">
                                 <div class="otherinfo">
                                    <img *ngIf="galleryImages == null || galleryImages == undefined"
                                       src="assets/Background/Ellipse.png" class="imageeee">
                                    <img *ngIf="galleryImages" #previewImage (click)="openPreview(index)"
                                       class="imageeee ngx-gallery-preview-img ngx-gallery-center" [src]="src.URL"
                                       [class.ngx-gallery-active]="!loading" [class.animation]="animation" />
                                 </div>
                              </div>
                              <!-- <button class="btn btn-picture">
                                 click to for more pictures
                              </button> -->
                              <div *ngIf="!!galleryImages" class="aroow">
                                 <img class="inner" (click)="showPrev()" src="assets/Background/left.png" alt="">
                                 <span style="color:black;margin: 0 20px;">{{calLength}} / {{imageLength}}</span>
                                 <img class="inner" (click)="showNext()" src="assets/Background/right.png" alt="">
                              </div>
      
                           </div>
                           <div class="">
                              <div class="row justify-content-center">
                                 <div class="bordrimp col-md-12">
                                    <div class="parent">
                                       <div *ngIf="candidateDetails.length > 0" class="inner-text propert-middle">
                                          <h1>{{candidateDetails[0].FirstName}}
                                             {{candidateDetails[0].LastName}}</h1>
                                          <h1 class="durban">{{candidateDetails[0].CityValue}}</h1>
                                          <!-- <p *ngIf="candidateDetails.length >  0"> <span
                                                *ngIf="candidateDetails[0].Quote">"{{candidateDetails[0].Quote}}"</span>
                                          </p> -->
                                       </div>
                                    </div>
                                 </div>
      
                                    <div class="row">
                                       <div class="col-xs-12 bordrimp" >
                                          <!-- <div class="col-md-12 small-pad dgfg
                                             forborder
                                             forleftrder">
                                             <div class="reduce"><small>Close <br>
                                                   Window:</small></div>
                                             <div class="ofmargin text-center">
                                                <img class="rect5"
                                                   src="/assets/Background/cros.png">
                                             </div>
                                          </div> -->
                                          <div class="row">
      
                                             <div class="col-xs-12 cenalign">
                                                <div class="col-md-6" style="height: 50%;">
                                                   <div class="reduce"> <small class="text-center">Select
                                                         <br> Candidate:</small></div>
                                                   <div class="ofmargin text-center form-group">
                                                      <input type="checkbox" id="{{isSelected}}"
                                                         (change)="getJobId('selected')" [ngModelOptions]="{standalone: true}"
                                                         [(ngModel)]="isSelected">
                                                      <label for="{{isSelected}}"></label>
                                                   </div>
      
                                                </div>
                                                <div class="col-md-6
                                                   forbtop
                                                   col-xs-6" style="height: 50%;">
                                                   <div class="reduce"> <small clas="text-center">Favorite
                                                         <br> Candidate:</small>
                                                   </div>
                                                   <div style="cursor: pointer;" *ngIf="candidateDetails.length > 0"
                                                      class="ofmargin text-center">
                                                      <img *ngIf="candidateDetails[0].IsFav == 0" data-toggle="modal"
                                                         (click)="getJobId('fav')" data-target="#exampleModal" class=""
                                                         src="/assets/Background/Vector4.png">
                                                      <img *ngIf="candidateDetails[0].IsFav == 1" data-toggle="modal"
                                                         (click)="getJobId('fav')" data-target="#exampleModal" class=""
                                                         src="/assets/Background/vector5.jpg">
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="candidate p-0">
                        <div class="person">
                           <label class="tab-label-m
                              text-center" for="">Interview Questions</label>
                        </div>
                        <div class="person-detail">
                           <div class="row" *ngFor="let qa of QADetails;let i = index;">

                              <div class="col-md-12" *ngIf="(i+1) == Qaindex">
                                 <label for="" class="age question
                                    text-center">Question {{Qaindex}}
                                 </label>
                              </div>

                              <div class="col-md-12" *ngIf="(i+1) == Qaindex">
                                 <label for="" class="age
                                    text-center">{{qa.Name}}</label>
                              </div>

                              <video controls *ngIf="qa.FileType == 1153 && (i+1) == Qaindex">
                                 <source src="{{qa.URL}}" type="video/mp4">

                              </video>


                              <audio *ngIf="qa.FileType == 1154 && (i+1) == Qaindex" controls>
                                 <source src="{{qa.URL}}" type="audio/ogg">

                              </audio>

                           </div>
                           <div class="col-md-12 text-center">
                              <button class="btn-grad-filled
                                 take-bor">
                                 <i class="fa fa-chevron-left inner" aria-hidden="true" (click)="prevVideo()"></i>
                                 {{Qaindex}} / {{QALength}}
                                 <i class="fa fa-chevron-right
                                    inner" (click)="nextVideo()" aria-hidden="true"></i>
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-8">
                     <div class="row">
                        <!-- hide div in full screen, show in small screen -->
                        <div class="hide-in-fullscreen" style="width: 100%">
                           <div class="col-md-4 col-12">
                              <div class="personal-info">
                                 <!-- accordian -->
                                 <div class="row">
                                    <div class="col-12">
                                       <div class="tabs">
                                          <!-- tab1 -->
                                          <div class="tab">
                                             <input type="checkbox">
                                             <label class="tab-label
                                                text-center"
                                                (click)="IsPersonalDetails = !IsPersonalDetails">Personal Information</label>
                                             <div class="tab-content "
                                                [ngClass]="{'hide-div': IsPersonalDetails == false, 'show-div': IsPersonalDetails == true}">
                                                <form action="">
                                                   <div class="row" *ngIf="candidateDetails.length > 0">
      
                                                      <div class="col-md-5 boreder">
                                                         <label for="" class="age">Age</label>
                                                      </div>
                                                      <div *ngIf="candidateDetails.length > 0" class="col-md-7 boreder">
                                                         <label for="" class="male">{{candidateDetails[0].ActualAGE }}</label>
                                                      </div>
      
                                                      <div class="col-md-5 boreder">
                                                         <label for="" class="age">Gender</label>
                                                      </div>
                                                      <div class="col-md-7 boreder">
                                                         <label for="" class="male">
                                                            {{candidateDetails[0].GenderVal}}
                                                         </label>
                                                      </div>
      
                                                      <div class="col-md-5 boreder">
                                                         <label for="" class="age">Relationship</label>
                                                      </div>
                                                      <div class="col-md-7 boreder">
                                                         <label for=""
                                                            class="male">{{candidateDetails[0].RelationShipVal}}</label>
                                                      </div>
      
                                                      <div class="col-md-5 boreder">
                                                         <label for="" class="age">Race</label>
                                                      </div>
                                                      <div class="col-md-7 boreder">
                                                         <label for="" class="male">{{candidateDetails[0].RaceVal}}</label>
                                                      </div>
                                                      <div class="col-md-5 boreder">
                                                         <label for="" class="age">Language</label>
                                                      </div>
                                                      <div class="col-md-7 boreder">
                                                         <label for="" class="male"
                                                            style="word-break: break-all;">{{candidateDetails[0].Language}}</label>
                                                      </div>
      
      
      
      
                                                      <div class="col-md-5 boreder">
                                                         <label for="" class="age">Citizenship</label>
                                                      </div>
                                                      <div class="col-md-7 boreder">
                                                         <label for="" class="male">{{candidateDetails[0].CitizanZenshipVal}}
                                                         </label>
                                                      </div>
      
      
                                                      <div class="col-md-5 boreder">
                                                         <label for="" class="age">Drivers
                                                            License</label>
                                                      </div>
                                                      <div *ngIf="candidateDetails.length > 0" class="col-md-7 boreder">
                                                         <label for="" class="male">
                                                            <span *ngIf="candidateDetails[0].Driving_Licence == true">
                                                               yes
                                                            </span>
                                                            <span *ngIf="candidateDetails[0].Driving_Licence == false">
                                                               No
                                                            </span>
                                                         </label>
                                                      </div>
      
                                                      <div class="col-md-5 boreder">
                                                         <label for="" class="age">Location</label>
                                                      </div>
                                                      <div class="col-md-7 boreder">
                                                         <label for="" class="male">{{candidateDetails[0].CityValue}}</label>
                                                      </div>
      
      
      
                                                      <!-- <div class="col-md-5 boreder">
                                                         <label for="" class="age"></label>
                                                      </div>
                                                      <div class="col-md-7 boreder">
                                                         <label for="" class="male">Durban</label>
                                                      </div> -->
                                                      <!-- <div class="col-md-5 boreder">
                                                         <label for="" class="age">Relocation</label>
                                                      </div>
                                                      <div class="col-md-7 boreder">
                                                         <label for="" class="male">Yes</label>
                                                      </div> -->
      
      
      
                                                   </div>
                                                   <!--row-->
      
                                                </form>
                                             </div>
                                          </div>
                                          <!--tab--->
                                          <!-- tab2 -->
                                          <div class="tab">
                                             <input type="checkbox">
                                             <label class="tab-label 
                                                text-center" (click)="IsInterviewQuation = !IsInterviewQuation">Interview
                                                Questions</label>
                                             <div *ngIf="!!QADetails && QADetails.length >=1" class="person-detail"
                                                [ngClass]="{'hide-div': IsInterviewQuation == false, 'show-div': IsInterviewQuation == true}">
                                                <div class="row" *ngFor="let qa of QADetails;let i = index;">
      
                                                   <div class="col-md-12" *ngIf="(i+1) == Qaindex">
                                                      <label for="" class="age question
                                                            text-center">Question {{Qaindex}}
                                                      </label>
                                                   </div>
      
                                                   <div class="col-md-12" *ngIf="(i+1) == Qaindex">
                                                      <label for="" class="age
                                                            text-center">{{qa.Name}}</label>
                                                   </div>
      
                                                   <video controls *ngIf="qa.FileType == 1153 && (i+1) == Qaindex">
                                                      <source src="{{qa.URL}}" type="video/mp4">
      
                                                   </video>
      
      
                                                   <audio *ngIf="qa.FileType == 1154 && (i+1) == Qaindex" controls>
                                                      <source src="{{qa.URL}}" type="audio/ogg">
      
                                                   </audio>
      
                                                </div>
                                                <div class="col-md-12 text-center">
                                                   <button class="btn-grad-filled
                                                         take-bor">
                                                      <i class="fa fa-chevron-left inner" aria-hidden="true"
                                                         (click)="prevVideo()"></i>
                                                      {{Qaindex}} / {{QALength}}
                                                      <i class="fa fa-chevron-right
                                                            inner" (click)="nextVideo()" aria-hidden="true"></i>
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                          <!---tab--->
                                          <div class="tab">
                                             <input type="checkbox">
                                             <label class="tab-label btn-grad-filled
                                                text-center" (click)="IsSkills = !IsSkills">Skills</label>
                                             <div class="tab-content "
                                                [ngClass]="{'hide-div': IsSkills == false, 'show-div': IsSkills == true}">
                                                <form action="">
                                                   <div class="row">
                                                      <div class="col-md-12 boreder" *ngFor="let data of skillInfo">
                                                         <label for="" class="male-custom skills"
                                                            *ngIf="data.Type == 1">{{data.Achievement}}</label>
                                                      </div>
      
      
      
      
                                                   </div>
                                                   <!--row-->
                                                </form>
                                             </div>
                                          </div>
                                          <!---tab--->
                                          <div class="tab">
                                             <input type="checkbox">
                                             <label class="tab-label btn-grad-filled
                                                text-center" (click)="IsAcchivements = !IsAcchivements">Achievements</label>
                                             <div class="tab-content"
                                                [ngClass]="{'hide-div': IsAcchivements == false, 'show-div': IsAcchivements == true}">
                                                <form action="">
                                                   <div class="row">
      
                                                      <div class="col-md-12 boreder" *ngFor="let data of skillInfo">
                                                         <label for="" class="male-custom
                                                            Achievement skills" *ngIf="data.Type == 2">{{data.Achievement}}
                                                         </label>
                                                      </div>
      
                                                   </div>
                                                   <!--row-->
      
                                                </form>
                                             </div>
                                          </div>
                                          <!---tab--->
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <!-- personal info -->
                           </div> <!-- col-md-8 -->
      
                           <div class="col-md-8">
      
                              <div class="Eduation-info">
                                 <div class="tab">
                                    <input type="checkbox" *ngIf="!!educationData">
                                    <label class="tab-label" (click)="IsEducation = !IsEducation">Educational Information</label>
                                    <div class="person-detail1" *ngIf="!!educationData"
                                       [ngClass]="{'hide-div': IsEducation == false, 'show-div': IsEducation == true}">
                                       <div class="no-data-details" *ngIf="educationData.length == 0">
                                          <img class="no-data" src="assets/Background/no-data.png" alt="">
                                          <h1 style="color: #000;">No data available</h1>
                                       </div>
                                       <div class="row" *ngIf="!!educationData">
                                          <div *ngFor="let edu of educationData"
                                             [ngClass]="{'hide-div': IsEducation == false, 'show-div': IsEducation == true}">
                                             <div class="tab-content col-12">
                                                <div *ngIf="edu.Type == 1" class="row">
                                                   <div class="col-md-12 university">
                                                      <h5 class="text-center">Seconadry School
                                                         Information
                                                      </h5>
                                                   </div>
                                                </div>
                                                <div *ngIf="edu.Type == 1" class="row  education-info">
      
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Name of
                                                         School/
                                                         College</label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male"
                                                         *ngIf="!!edu.SchoolName">{{edu.SchoolName}}</label>
                                                   </div>
      
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Highest
                                                         Qualification</label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">{{edu.SchoolGradeValue}}
                                                      </label>
                                                   </div>
      
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Year
                                                         Obtained
                                                      </label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">
                                                         {{edu.SchoolYear}}
                                                      </label>
                                                   </div>
      
      
      
      
      
                                                </div>
                                             </div>
                                             <div *ngIf="edu.Type == 2" class="tab-content">
                                                <!---row-->
                                                <div class="row education-info">
                                                   <div class="col-md-12 university">
                                                      <h5 class="text-center">University /
                                                         Tertiary
                                                         Information
                                                      </h5>
                                                   </div>
      
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Name of
                                                         University
                                                      </label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">{{edu.UniversityName}}</label>
                                                   </div>
      
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Degree/
                                                         Qualification</label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">{{edu.DegreeValue}}
                                                      </label>
                                                   </div>
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Field of
                                                         Study
                                                      </label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">
                                                         {{edu.FieldValue}}
      
                                                      </label>
                                                   </div>
      
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Year
                                                         Obtained
                                                      </label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">
                                                         {{edu.UniversityYear}}
                                                      </label>
                                                   </div>
      
                                                </div>
      
      
                                             </div>
                                          </div>
                                       </div>
                                       <!--tab-content-->
      
                                    </div>
                                    <!---tab---->
      
                                    <!-- education -->
                                    <div class="tab">
                                       <input type="checkbox">
                                       <label class="tab-label"
                                          (click)="IsProfessional = !IsProfessional">Professional Information</label>
                                       <div class="person-detail1" *ngIf="!!professionalData"
                                          [ngClass]="{'hide-div': IsProfessional == false, 'show-div': IsProfessional == true}">
                                          <div class="no-data-details" *ngIf="professionalData.length == 0">
                                             <img class="no-data" src="assets/Background/no-data.png" alt="">
                                             <h1 style="color: #000;">No data available</h1>
                                          </div>
                                          <div class="tab-content col-12"
                                             [ngClass]="{'hide-div': IsProfessional == false, 'show-div': IsProfessional == true}"
                                             *ngFor="let data of professionalData" style="overflow:scroll;overflow-x:hidden;overflow-y:
                                       auto;">
                                             <form action="">
      
      
                                                <h5 class="">{{data.JobTitleValue}}</h5>
                                                <br>
                                                <div class="row">
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Company</label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">{{data.Company}}</label>
                                                   </div>
      
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Industry</label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">{{data.IndustryValue}}
                                                      </label>
                                                   </div>
      
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Level
                                                      </label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">
                                                         {{data.LevelDetailValue}}
      
                                                      </label>
                                                   </div>
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Date
                                                      </label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">{{data.YearFrom}} To <span *ngIf ="data.YearTo">{{data.YearTo}}</span><span *ngIf = "!data.YearTo" >Present</span></label>
                                                   </div>
                                                   <div class="col-md-5">
                                                      <label for="" class="age">Description</label>
                                                   </div>
                                                   <div class="col-md-7">
                                                      <label for="" class="male">{{data.Description}}
                                                      </label>
                                                   </div>
      
      
      
                                                </div>
                                                <!---row-->
                                                <hr class="border-color111">
      
                                             </form>
                                          </div>
                                          <!--tab-content-->
      
                                       </div>
                                       <!---tab---->
      
      
      
                                       <!-- Deelete it -->
      
                                    </div>
                                    <!-- Education info -->
                                 </div> <!-- col-md-8 -->
      
                              </div>
                              <!--hide-in-fullscreen-->
                           </div>
                        </div>
                        <!-- hide div in small screen, show in full screen -->
                        <div class="container show-in-fullscreen">
                           <div class="right-layout">
                              <div class="pl-person">
                                 <div class="person">
                                    <label class="tab-label-m
                                       text-center" for="personalDetails">Personal Information</label>
                                 </div>
                                 <div class="person-detail">
                                    <div class="row" *ngIf="candidateDetails.length > 0">
      
                                       <div class="col-md-5 boreder">
                                          <label for="" class="age">Age</label>
                                       </div>
                                       <div class="col-md-7 boreder">
                                          <label for="" class="male-custom">{{candidateDetails[0].ActualAGE}}</label>
                                       </div>
      
                                       <div class="col-md-5 boreder">
                                          <label for="" class="age">Gender</label>
                                       </div>
                                       <div class="col-md-7 boreder">
                                          <label for="" class="male-custom">
                                             {{candidateDetails[0].GenderVal}}</label>
                                       </div>
      
                                       <div class="col-md-5 boreder">
                                          <label for="" class="age">Relationship</label>
                                       </div>
                                       <div class="col-md-7 boreder">
                                          <label for="" class="male-custom">{{candidateDetails[0].RelationShipVal}}</label>
                                       </div>
      
                                       <div class="col-md-5 boreder">
                                          <label for="" class="age">Race</label>
                                       </div>
                                       <div class="col-md-7 boreder">
                                          <label for="" class="male-custom">{{candidateDetails[0].RaceVal}}</label>
                                       </div>
                                       <div class="col-md-5 boreder">
                                          <label for="" class="age">Language</label>
                                       </div>
                                       <div class="col-md-7 boreder">
                                          <label for="" class="male-custom"
                                             style="word-break: break-all;">{{candidateDetails[0].Language}}</label>
                                       </div>
      
                                       <!-- <div class="col-md-5 boreder">
                                          <label for="" class="age"></label>
                                       </div>
                                       <div class="col-md-7 boreder">
                                          <label for="" class="male-custom">Africans</label>
                                       </div> -->
      
      
                                       <div class="col-md-5 boreder">
                                          <label for="" class="age">Citizenship</label>
                                       </div>
                                       <div class="col-md-7 boreder">
                                          <label for="" class="male-custom">{{candidateDetails[0].CitizanZenshipVal}}</label>
                                       </div>
      
      
                                       <div class="col-md-5 boreder">
                                          <label for="" class="age">Drivers
                                             License</label>
                                       </div>
                                       <div class="col-md-7 boreder" *ngIf="candidateDetails.length > 0">
                                          <label for="" class="male-custom"> <span
                                                *ngIf="candidateDetails[0].Driving_Licence == true">
                                                yes
                                             </span>
                                             <span *ngIf="candidateDetails[0].Driving_Licence == false">
                                                No
                                             </span></label>
                                       </div>
      
                                       <div class="col-md-5 boreder">
                                          <label for="" class="age">Location</label>
                                       </div>
                                       <div class="col-md-7 boreder">
                                          <label for="" class="male-custom">{{candidateDetails[0].CityValue}}</label>
                                       </div>
      
      
      
                                       <!-- <div class="col-md-5 boreder">
                                          <label for="" class="age"></label>
                                       </div>
                                       <div class="col-md-7 boreder">
                                          <label for="" class="male-custom">Durban</label>
                                       </div> -->
                                       <!-- <div class="col-md-5 boreder">
                                          <label for="" class="age">Relocation</label>
                                       </div>
                                       <div class="col-md-7 boreder">
                                          <label for="" class="male-custom">Yes</label>
                                       </div> -->
      
      
      
                                    </div>
                                    <!--row-->
      
                                 </div>
                                 <div class="par-education">
                                    <div class="person">
                                       <label class="tab-label-m
                                          text-center" for="">Educational Information</label>
                                    </div>
                                    <div class="person-detail1" *ngIf="!!educationData">
                                       <div class="no-data-details" *ngIf="educationData.length == 0">
                                          <img class="no-data" src="assets/Background/no-data.png" alt="">
                                          <h1 style="color: #000;">No data available</h1>
                                       </div>
                                       <div class="row" *ngIf="!!educationData">
                                          <div style="width: 100%" *ngFor="let edu of educationData">
                                             <div *ngIf="edu.Type == 1" class="tab-content">
                                                <div *ngIf="edu.Type == 1" class="row">
                                                   <div class="col-md-12 university">
         
                                                      <h5 *ngIf="edu.Type == 1" class="text-center">Seconadry School
                                                         Information
                                                      </h5>
                                                   </div>
                                                </div>
         
                                                <div *ngIf="edu.Type == 1" class="row  education-info">
         
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Name of
                                                         School/
                                                         College</label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male"
                                                         *ngIf="!!edu.SchoolName">{{edu.SchoolName}}</label>
                                                   </div>
         
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Highest
                                                         Qualification</label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">{{edu.SchoolGradeValue}}
                                                      </label>
                                                   </div>
         
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Year
                                                         Obtained
                                                      </label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">
                                                         {{edu.SchoolYear}}
                                                      </label>
                                                   </div>
         
         
         
         
         
                                                </div>
                                             </div>
                                             <div *ngIf="edu.Type == 2" class="tab-content">
                                                <!---row-->
                                                <div class="row education-info">
                                                   <div class="col-md-12 university">
                                                      <h5 class="text-center">University /
                                                         Tertiary
                                                         Information
                                                      </h5>
                                                   </div>
         
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Name of
                                                         University
                                                      </label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">{{edu.UniversityName}}</label>
                                                   </div>
         
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Degree/
                                                         Qualification</label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">{{edu.DegreeValue}}
                                                      </label>
                                                   </div>
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Field of
                                                         Study
                                                      </label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">
                                                         {{edu.FieldValue}}
         
                                                      </label>
                                                   </div>
         
                                                   <div class="col-md-5 boreder">
                                                      <label for="" class="age">Year
                                                         Obtained
                                                      </label>
                                                   </div>
                                                   <div class="col-md-7 boreder">
                                                      <label for="" class="male">
                                                         {{edu.UniversityYear}}
                                                      </label>
                                                   </div>
         
                                                </div>
         
         
                                             </div>
                                          </div>
                                       </div>
                                       <!--row-->
         
                                    </div>
                                    <!---person-detail--->
         
                                    <div class="person">
                                       <label class="tab-label-m
                                          text-center" for="">Professional Information</label>
                                    </div>
                                    <div class="person-detail2" *ngIf="!!professionalData">
                                       <div class="no-data-details" *ngIf="professionalData.length == 0">
                                          <img class="no-data" src="assets/Background/no-data.png" alt="">
                                          <h1 style="color: #000;">No data available</h1>
                                       </div>
                                       <div class="row">
                                          <div class="tab-content" *ngFor="let data of professionalData">
         
                                             <h5 class="">{{data.JobTitleValue}}</h5>
                                             <div class="row">
                                                <div class="col-md-5 boreder">
                                                   <label for="" class="age">Company</label>
                                                </div>
                                                <div class="col-md-7 boreder">
                                                   <label for="" class="male">{{data.Company}}</label>
                                                </div>
         
                                                <div class="col-md-5 boreder">
                                                   <label for="" class="age">Industry</label>
                                                </div>
                                                <div class="col-md-7 boreder">
                                                   <label for="" class="male">{{data.IndustryValue}}
                                                   </label>
                                                </div>
         
                                                <div class="col-md-5 boreder">
                                                   <label for="" class="age">Level
                                                   </label>
                                                </div>
                                                <div class="col-md-7 boreder">
                                                   <label for="" class="male">
                                                      {{data.LevelDetailValue}}
         
                                                   </label>
                                                </div>
                                                <div class="col-md-5 boreder">
                                                   <label for="" class="age">Date
                                                   </label>
                                                </div>
                                                <div class="col-md-7 boreder">
                                                   <label for="" class="male">{{data.YearFrom}} To <span *ngIf ="data.YearTo">{{data.YearTo}}</span><span *ngIf = "!data.YearTo" >Present</span></label>
                                                </div>
                                                <div class="col-md-5">
                                                   <label for="" class="age">Description</label>
                                                </div>
                                                <div class="col-md-7">
                                                   <label for="" class="male">{{data.Description}}</label>
                                                </div>
                                             </div>
                                             <!---row-->
         
         
         
                                          </div>
                                       </div>
                                       <!--row-->
         
                                    </div>
                                    <!---person-detail--->
         
                                 </div>
                                 <!---person-detail--->
      
                                 <div class="row">
                                    
                             
                                 <!---person-detail--->
                                  <div class="col-md-6">
                                 <div class="person">
                                    <label class="tab-label-m
                                       text-center" for="">Skills</label>
                                 </div>
                                 <div class="person-detail">
                                    <div class="row">
      
                                       <div class="col-md-12 boreder" *ngFor="let data of skillInfo">
                                          <label for="" class="male-custom skills"
                                             *ngIf="data.Type == 1">{{data.Achievement}}</label>
                                       </div>
      
      
                                    </div>
                                    <!--row-->
      
                                 </div>
                              </div>
                                 <!---person-detail--->
      
                                 <div class="col-md-6">
                                 <div class="person">
                                    <label class="tab-label-m
                                       text-center" for="">Achievements</label>
                                 </div>
                                 <div class="person-detail">
                                    <div class="row">
      
                                       <div class="col-md-12 boreder" *ngFor="let data of skillInfo">
                                          <label for="" class="male-custom
                                             Achievement skills" *ngIf="data.Type == 2">{{data.Achievement}}
                                          </label>
                                       </div>
      
                                    </div>
                                    <!--row-->
      
                                 </div>
                                 </div>
                              </div>
                                 <!---person-detail--->
      
                              </div>
                          
                           </div>
                           <!--show-in-fullscreen-->
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <!----col-lg-12-->
         <div class="modal" [ngClass]="{modalShow: ACTIVEMODEL == 'Register',
         modalHide: ACTIVEMODEL !== 'Register'}" id="exampleModal" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog model-xl modal-dialog-centereds" role="document">
               <div class="modal-content btn1-grad-border">

                  <button type="button" (click)="hidenModel('')" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true" style="float: right;
                         width: 29px;
                         font-size: 26px;outline: none;">×</span>
                  </button>

                  <div class="modal-body">
                     <div class="mt-to-bt popup">
                        <div class="inner-text">
                           <span class="text-center">
                              Register a Job
                           </span>
                           <!-- <hr class="border112"> -->
                        </div>

                        <div class="row content">
                           <div class="col-md-12">
                              <label for="" class="take1">Select Job
                                 title:</label>
                           </div>
                           <div class="col-md-12">
                              <ng-select [(ngModel)]="selectedjobtitle" [disabled]="true" [placeholder]="'Select a Job'" class="mb-4">
                                 <ng-option value="0">Select a Job</ng-option>
                                 <ng-option *ngFor="let job of RecruiterJobList" [value]="job.ID">
                                     {{ job.JobTitle }}
                                 </ng-option>
                             </ng-select>
                             
                              <div _ngcontent-c0="" class="text-center">
                                 <button _ngcontent-c0="" class="blue-button" (click)="register()"> Select Job </button>
                              </div>
                              <!-- <div class="take1">
                                 <a style="text-decoration: underline;color: #6C6C6Cff;" href="https://www.searchadam.com/addjob" target="_blank">I can't find the Job title I'd like to register</a>
                             </div> -->
                           </div>
                        </div>

                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>
   </div>
</div>
