import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from "../../app.service";
import * as RegisterClasses from "./register-classes";

import { Router } from "@angular/router";
import Swal from "sweetalert2";
import * as _ from "lodash";
import { CoreService } from "../../core/core.service";
import { SeoDetailModel } from "../../core/core.class";
import { QueryType, FilterCriteria } from "../../app.class";
@Component({
    selector: 'app-register-view',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent {
    registerForm: FormGroup;
    registerFormErrors: any;
    emailPattern: string | RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    passwordshow = false;
    confirmpasswordshow = false;
    SubscriptionPlanList: any;
    subscriptionplanId:any;
    isPasswordNotMatch?: boolean = false;
    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();
    constructor(private formBuilder: FormBuilder,
        private appService: AppService,
        public router: Router, private coreService: CoreService
    ) {
        this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
            .subscribe((data: any) => {
                if (!!data && !!data.Result) {
                    this.SList = data.Result;
                    if (this.SList.length >= 1) {
                        this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2510));
                    }
                }
            })
        this.registerFormErrors = {
            email: {},
            password: {},
            confirmpassword: {},
            companyname: {},
            designation: {},
            worktelephone: {},
            cellphone: {},
            businesstype: {},
            organizationsize: {}
        };

      
    }
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2510));
        }
    }
    password() {
        this.passwordshow = !this.passwordshow;
    }
    confirmpassword() {
        this.confirmpasswordshow = !this.confirmpasswordshow;
    }
    ngOnInit(): void {
        this.registerForm = this.formBuilder.group({
            UserName: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
            Password: ['', Validators.required],
            CompanyName: ['', Validators.required],
            Designation: ['', Validators.required],
            WorkTelephone: ['', Validators.required],
            PhoneNo: ['',Validators.required],
            BusinessType: ['', Validators.required],
            OrganizationSize: ['', Validators.required],
            FirstName: ['', Validators.required],
            LastName: ['', Validators.required],
            ConfirmPassword: ['', Validators.required]
        });

        this.registerForm.valueChanges.subscribe(() => {
            this.onRegisterFormValuesChanged();
        });
    }

    onRegisterFormValuesChanged(): any {
        for (const field in this.registerFormErrors) {
            if (!this.registerFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.registerFormErrors[field] = {};

            // Get the control
            const control = this.registerForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.registerFormErrors[field] = control.errors;
            }
        }
    }

    validateConfirmPassword(): any {
        const Password = this.registerForm.controls["Password"].value;
        const confirmPassword = this.registerForm.controls["ConfirmPassword"].value;
        if (!Password || !confirmPassword) {
            return;
        }

        if (confirmPassword === "") {
            this.isPasswordNotMatch = false;
        }

        if (Password !== confirmPassword) {
            this.isPasswordNotMatch = true;
        } else {
            this.isPasswordNotMatch = false;
        }

    }

    getSubscriptionPlan(loginUser): any {
        const data = {
            "QueryObjectID": "SubscriptionPlan",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": false,
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            this.SubscriptionPlanList = response.Result;
            _.each(this.SubscriptionPlanList, (sub:any) => {
                let a = sub.ApplicableTo.split(",");
                if (_.includes(a, loginUser.BusinessType) && sub.OrganizationSize == Number(loginUser.OrganizationSize)) {
                    loginUser.subPlan = sub.ID;
                    this.subscriptionplanId = sub.ID;
                }
            });
        });
    }

 
    // On Login Click
    SignUP(): any {
        
        const loginUser = new RegisterClasses.RegisterDetails();
        Object.assign(loginUser, this.registerForm.value);
       


        // let d = _.find(this.SubscriptionPlanList, list => { return _.includes(a, loginUser.BusinessType) && list.OrganizationSize == Number(loginUser.OrganizationSize) });
        // if (!!d) {
        //     loginUser.subPlan = d.Amount;
        // }
        let user = { username: loginUser.UserName };
        if (this.registerForm.status != 'INVALID' && this.isPasswordNotMatch == false) {
            // Check for already Exist User

            // let logindata = {
            //     "QueryObjectID": "UserInfo",
            //     "QueryType": 0,
            //     "ResultField_AppfieldIds": [

            //     ],
            //     "WhereClause": {
            //         "FilterLogic": "1",
            //         "Filters": [
            //             {
            //                 "ConjuctionClause": 1,
            //                 "FieldID": "UserName",
            //                 "RelationalOperator": 3,
            //                 "ValueType": 0,
            //                 "value": user.username
            //             }
            //         ]
            //     }
            // };
            // this.appService.executeQuery(logindata, QueryType.Select).subscribe(response => {

            //     if (response.StatusCode == "200" && response.Result.length > 0) {
            //         Swal({
            //             // type: 'Warning',
            //             title: 'SignUp failed',
            //             text: 'User already exist!',
            //         });
            //     } else {
                    // register for token
                    this.appService.RegisterToken(loginUser.UserName,loginUser.Password).subscribe(response => {
                       if(response.StatusCode ==200 ){
                            //register
                            localStorage.setItem("UserToken","bearer "+" "+response.Result.Token)
                             this.getSubscriptionPlan(loginUser);
                            
                            let WhereClause = new FilterCriteria();
                            WhereClause.Filters = [];
                            WhereClause.FilterLogic = "1";

                            let dataObj = this.setData(loginUser);
                                
                            let data = {
                                QueryObjectID: "RecruiterInfo",
                                QueryType: QueryType.Insert,
                                Joins: [],
                                WhereClause: WhereClause,
                                Values: dataObj
                            };
                            this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
                                if (response.StatusCode == "200") {
                                    var UserId = response.Result;
                                    Swal({
                                        //  type: 'Success',
                                        title: 'Signup successfully!',
                                    });
                                    this.getProfileInfo(UserId);
                                    this.UpdateSubscriptionPlan(UserId);
                                    this.router.navigate(['/login']);
                                }
                                else {
                                    Swal({
                                        // type: 'Warning',
                                        title: 'SignUp failed',
                                        text: 'Something went wrong with signing up!',
                                    });
                                }
                            })
                        }
                 })
                 ;
               // }
           // });
        } else {
            Swal({
                title: 'Required',
                text: 'Please, enter all required values and valid values! ',
            });

            this.registerForm.get('UserName').markAsTouched();
            this.registerForm.get('Password').markAsTouched();
            this.registerForm.get('CompanyName').markAsTouched();
            this.registerForm.get('Designation').markAsTouched();
            this.registerForm.get('WorkTelephone').markAsTouched();
            this.registerForm.get('BusinessType').markAsTouched();
            this.registerForm.get('OrganizationSize').markAsTouched();
            this.registerForm.get('FirstName').markAsTouched();
            this.registerForm.get('LastName').markAsTouched();
            this.registerForm.get('PhoneNo').markAsTouched();
            this.registerForm.get('ConfirmPassword').markAsTouched();
        }
    }
    private getProfileInfo(RecruiterID) {
        let data = {
            "QueryObjectID": "GetRecruiterDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "ID",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": RecruiterID  // use recruiter id here
                    }
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                var person = response.Result[0];
                var data =
                {
                    "templateID": 1,
                    "emailIds": person.Email,
                    "tokens": {
                        "UserId": person.ID,
                    }
                };
                // this.appService.sendEmail(data).subscribe(response => {
                // });
            }
        });
    }
    private UpdateSubscriptionPlan(recruiterID)
    {
        let WhereClause: any = new FilterCriteria();
        WhereClause.Filters = [
            {
                ConjuctionClause: 1,
                FieldID: "ID",
                RelationalOperator: 3,
                ValueType: 0,
                value:recruiterID
            }]
        WhereClause.FilterLogic = "1";
        let dataObj =  [{
            AppFieldID: "SubscriptionPlan",
            Value: this.subscriptionplanId
        }]
        let data = {
            QueryObjectID: "RecruiterInfo",
            QueryType: QueryType.Update,
            Joins: [],
            WhereClause: WhereClause,
            Values: dataObj
        };
        this.appService.executeQuery(data, QueryType.Update).subscribe(response => {
            if (response.StatusCode == "200") {
            }
            else {
                alert("Fail");
            }
        });
    }

    private setData(loginUser: RegisterClasses.RegisterDetails) {
        
        return [
            {
                AppFieldID: "CompanyName",
                Value: loginUser.CompanyName
            },
            {
                AppFieldID: "Designation",
                Value: loginUser.Designation
            },
            {
                AppFieldID: "BusinessType",
                Value: loginUser.BusinessType
            },
            {
                AppFieldID: "OrganizationSize",
                Value: loginUser.OrganizationSize
            },
            {
                AppFieldID: "SubscriptionPlan",
                Value: loginUser.subPlan
            },
            {
                AppFieldID: "Person",
                Value: [
                    {
                        AppFieldID: "FirstName",
                        Value: loginUser.FirstName
                    },
                    {
                        AppFieldID: "Email",
                        Value: loginUser.UserName
                    },
                    {
                        AppFieldID: "LastName",
                        Value: loginUser.LastName
                    },
                    {
                        AppFieldID: "WorkTelephone",
                        Value: loginUser.WorkTelephone
                    },
                    {
                        AppFieldID: "PhoneNo",
                        Value: loginUser.PhoneNo
                    },
                    {
                        AppFieldID: "CreatedOn",
                        Value: new Date()
                    },
                    {
                        AppFieldID: "UserId",
                        Value: [
                            {
                                AppFieldID: "UserName",
                                Value: loginUser.UserName
                            },
                            {
                                AppFieldID: "Status",
                                Value: 1
                            },
                            {
                                AppFieldID: "Password",
                                Value: btoa(loginUser.Password)
                            },
                            {
                                AppFieldID: "UserType",
                                Value: 2
                            }
                        ]
                    }
                ]
            }
        ];
    }
}
