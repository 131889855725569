import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenubarModule } from '../components/menubaar/aurthentication/menubar.module';
import { SteperModule } from '../components/steps/steps.module';
import { RegisterJobModule } from '../register-job/register-job.module';
import { HomePageComponent } from './home-page.component';
// import { HeaderComponent } from '../../components/toolbar/aurthentication-toolbar/header.component';



@NgModule({

    declarations: [HomePageComponent],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: HomePageComponent, pathMatch: 'full' }
        ]),
        MenubarModule,
        SteperModule,
        CommonModule,
        RegisterJobModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule

    ],
    entryComponents: [],
    exports: [
        HomePageComponent
    ]
})

export class homeModule {

}
