import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from "../../app.service";
import * as ForgotPasswordClasses from "./forgot-password-classes";

import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { SeoDetailModel } from "../../core/core.class";
import { CoreService } from "../../core/core.service";
import { QueryType } from "../../app.class";
@Component({
    selector: 'app-forgot-view',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
    closeResult: string;
    ACTIVEMODEL = '';
    forgotPasswordForm: FormGroup;
    forgotPasswordFormErrors: any;
    emailPattern: string | RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();

    constructor(private formBuilder: FormBuilder, public router: Router,
        private appService: AppService,private coreService:CoreService) {
            this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
            .subscribe((data: any) => {
                if (!!data && !!data.Result) {
                    this.SList = data.Result;
                    if (this.SList.length >= 1) {
                        this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2509));
                    }
                }
            })

        this.forgotPasswordFormErrors = {
            UserName: {}
        };
    }
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2509));
        } 
}
    ngOnInit(): void {
        this.forgotPasswordForm = this.formBuilder.group({
            UserName: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
        });
        this.forgotPasswordForm.valueChanges.subscribe(() => {
            this.forgotPasswordFormValuesChanged();
        });
    }
    forgotPasswordFormValuesChanged(): any {
        for (const field in this.forgotPasswordFormErrors) {
            if (!this.forgotPasswordFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.forgotPasswordFormErrors[field] = {};

            // Get the control
            const control = this.forgotPasswordForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.forgotPasswordFormErrors[field] = control.errors;
            }
        }
    }
    // On Forgot Password Click
    ForGotPassword(): any {
        // Get Token
      
        const loginUser = new ForgotPasswordClasses.ForgotPasswordDetails();
        Object.assign(loginUser, this.forgotPasswordForm.value);
        if(this.forgotPasswordForm.status != 'INVALID'){
            this.appService.GetToken("bhavik.thakkar@live.com","P@ssw0rd").subscribe(response => {
                 
                if(response.StatusCode==200)
                {
                    localStorage.setItem("UserToken","bearer "+" "+response.Result.Token);
               
                let logindata = {
                    "QueryObjectID": "UserInfo",
                    "QueryType": 0,
                    "ResultField_AppfieldIds": [

                    ],
                    "WhereClause": {
                        "FilterLogic": "1",
                        "Filters": [
                            {
                                "ConjuctionClause": 1,
                                "FieldID": "UserName",
                                "RelationalOperator": 3,
                                "ValueType": 0,
                                "value": loginUser.UserName
                            }
                        ]
                    }
                }
                this.appService.executeQuery(logindata, QueryType.Select).subscribe(response => {
                     
                    if (response.Result.length > 0) {
                        var UserId = response.Result;
                        var data =
                        {   "id":""+UserId[0].ID.toString(),
                            "Email": UserId[0].UserName,
                            "UserId": ""+UserId[0].ID.toString()
                        };
                        this.appService.executeWorkFlow('E0E689E2-B128-4384-8DC0-122987B7F889',data).subscribe(response => {
                            var data = response;
                            if (data.StatusCode == "200") {
                                Swal({
                                    title: 'Success',
                                    text: 'Please check your mail to reset password!',
                                });
                                this.router.navigate(['/login']);
                            } else  {
                                Swal({
                                    title: 'Error',
                                    text: 'No user with this email Exist!',
                                });
                            }
                        });
                        
                    } else {
                        Swal({
                            title: 'Error',
                            text: 'No user with this email Exist!',
                        });
                    }
                });
            }
            });
    } else {
        this.forgotPasswordFormValuesChanged();
        this.forgotPasswordForm.get('UserName').markAsTouched();
    }
}
}

