export class PostJobDetails {
    IsShowCompanyName:number;
    IsShowRecruiterName:number;
    RefNo:string
    SalaryFrom:string;
    SalaryTo:string;
    City:any;
    State:any;
    Status:number;
    Type:any;
    IsUrgent:number;
    MarketRelated:any;
    Title:string;
    Description:string;
}