import { Component, HostListener, Input, Output, EventEmitter } from "@angular/core";
import { AppService } from "../app.service";

import Swal from "sweetalert2";
import { QueryType, FilterCriteria } from "../app.class";

@Component({
    selector: 'app-register-job',
    templateUrl: './register-job.component.html',
    styleUrls: ['./register-job.component.scss']
})
export class RegisterJobComponent {
    @Input() ACTIVEMODEL;
    @Output() hideModel = new EventEmitter();
    jobTitles: any;
    currentUser: any;
    selectedjobtitle: any;
    RecruiterJobList: any;
    constructor(private appService: AppService) {
        // set current user
        if (localStorage.getItem("UserInfo") != null && localStorage.getItem("UserInfo") != undefined) {

            this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));
            this.getSystemEnumForJobList(1029);
        }
    }
    private getSystemEnumForJobList(enumID) {
        const data = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [

            ],
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "EnumID",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": enumID
                    }
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {

            if (response.StatusCode = "200") {
                this.jobTitles = response.Result;
            }
        });
    }
    private getJobDataByRecruiterId() {
        let data = {
            "QueryObjectID": "JobInfo",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "CreatedBy",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.currentUser.ID
                    }
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.RecruiterJobList = response.Result;
            }
        });
    }

    hidenModel(): any {
        this.hideModel.emit({
            value: ''
        });
    }
    SelectJobTitle(event) {
        var data = event;
    }
    register() {
        if (this.selectedjobtitle == undefined || this.selectedjobtitle == null) {
            Swal({
                //type: 'Warning',
                //title: 'Validation',
                text: 'Select Registered Job.',
            });
        }
        else {

            let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
            const lengthOfCode = 10;
            var RefNo = this.makeRandom(lengthOfCode, possible);
            let WhereClause = new FilterCriteria();
            WhereClause.Filters = [];
            WhereClause.FilterLogic = "1";
            var date = new Date();
            let obj = [
                {
                    AppFieldID: "Title",
                    Value: parseInt(this.selectedjobtitle)
                },
                {
                    AppFieldID: "CreatedBy",
                    Value: this.currentUser.ID
                },
                {
                    AppFieldID: "RefNo",
                    Value: RefNo
                },
                {
                    AppFieldID: "IsActive",
                    Value: 1
                },
                {
                    AppFieldID: "IsPublished",
                    Value: 0
                },
                {
                    AppFieldID: "CreatedDate",
                    Value: date
                },
                {
                    AppFieldID: "Status",
                    Value: 1177
                }

            ];
            let data = {
                QueryObjectID: "JobInfo",
                QueryType: QueryType.Insert,
                Joins: [],
                WhereClause: WhereClause,
                Values: obj
            };
            this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
                if (response.StatusCode == "200") {
                    this.hideModel.emit({
                        value: parseInt(this.selectedjobtitle)
                    });
                    Swal({
                        //type: 'Success',
                        title: 'Success',
                        text: 'Job registered successfully!',
                    });
                    this.selectedjobtitle = null;
                }


            })
        }
    }
    makeRandom(lengthOfCode: number, possible: string) {
        let text = "";
        for (let i = 0; i < lengthOfCode; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

}

