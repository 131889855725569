import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ImageModel, QueryType } from './app.class';
import { CoreHttpService } from './services/core-http.service';


@Injectable()
export class AppService {
    
    public RedirectUrl = "https://recruiter.searchadam.com";
     MAIN_API_URL = "https://recruitleeapi.techextensor.com/api/";
    LOG_IN_URL_Live = "https://recruitleeapi.techextensor.com/api/"
  
    formValue: string;

    token :string;
    private dataSourceSidebar = new BehaviorSubject<string>("");
    sidebar = this.dataSourceSidebar.asObservable();

    private dataSourceHeader = new BehaviorSubject<string>(""); 
    header = this.dataSourceHeader.asObservable();

    private imageUpload = new BehaviorSubject<string>("");
    images = this.imageUpload.asObservable();

    isBackClicked = new BehaviorSubject<string>("false");


    public userPrefferName = new BehaviorSubject<string>("");
    public userProfileimage = new BehaviorSubject<string>("");
    cast = this.userPrefferName.asObservable();

    public currentuser: any;

    constructor(private _coreHttpService: CoreHttpService, private http: HttpClient) {
      var tokendata=   localStorage.getItem("UserToekn");
        if(tokendata)
        {
            this.token =  tokendata
        }

        setTimeout(() => this.refteshToken().subscribe(response => {
            if (response.StatusCode == 200) {
                localStorage.setItem("UserToken" ,"bearer "+" "+ response.Result.Token);
                localStorage.setItem("Token" , response.Result.Token);
                localStorage.setItem("RefreshToken" , response.Result.Token);
            }
        }), 1000 * 60 * 60);
    }





    // navigation changes
    userValueforNavigation() {
        this.currentuser = JSON.parse(localStorage.getItem('UserInfo'));

        if (this.currentuser != undefined && this.currentuser != null && this.currentuser != '') {
            const uesrPrefferName = this.currentuser.FirstName + ' ' + this.currentuser.LastName;
            this.userPrefferName.next(uesrPrefferName);
            // this.userPrefferName.next(this.currentuser.LastName);
            this.userProfileimage.next(this.currentuser.PhotoURL);
        }
    }
    editNavigationName(prefferName) {
        this.userPrefferName.next(prefferName);
    }
    getPrefferedName() {
        return this.userPrefferName.asObservable();
    }
    editNavigationImage(photo) {
        this.userProfileimage.next(photo);
    }
    getNavigationImage() {

        return this.userProfileimage.asObservable();
    }
    // Set Current User Data
    SetCurrentUser(response) {

        localStorage.setItem('userinfo', JSON.stringify(response));
        this.currentuser = response;

    }






    uploadSliderImages = (imageModel: Array<ImageModel>) => {
        const url = this.MAIN_API_URL + "CRUD/UploadImage";
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            "No-Auth": "true"
        });
        return this._coreHttpService.httpPostRequest(
            url,
            imageModel,
            headers,

        );
    }

    uploadFiles = (type, refId, sequence, fileType, fileData, fileNames?: string) => {
        var token =   localStorage.getItem("UserToken")
        const url = this.MAIN_API_URL + "v1/File/Upload";
        // let headers = new HttpHeaders();
        var headers = new HttpHeaders({
            "enctype":"multipart/form-data",
            'Authorization': token,
            "Access-Control-Allow-Origin":"*"
        });

        let formData = new FormData();
        fileNames = !!fileNames && fileNames !== "" ? fileNames : fileData.name;
        formData.append(type + ";" + refId + ";" + sequence + ";" + fileType, fileData, fileData.name);
        return this._coreHttpService.httpPostRequest(
            url,
            formData,
            headers
        );
    }

    uploadInvoice = (formData) => {
        var token =   localStorage.getItem("UserToken")
        const url = this.MAIN_API_URL + "v1/File/Upload";
        // let headers = new HttpHeaders();
        var headers = new HttpHeaders({
            "enctype":"multipart/form-data",
            'Authorization': token,
            "Access-Control-Allow-Origin":"*"
        });
        return this._coreHttpService.httpPostRequest(
            url,
            formData,
            headers
        );
    }

    changeuserPassword(data) :Observable<any> {
        var token = localStorage.getItem("UserToken");
        var url = this.MAIN_API_URL + "v1/identity/ChangePassword"
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            "Authorization":token
        });
        
        var datatopass =
        {
            "Email":data.Email,
            "CurrentPassword":data.CurrentPassword,
            "NewPassword":data.NewPassword
        };
        return this._coreHttpService.httpPostRequest(url,
        datatopass, headers, true);
        
    }


    SendMailwithAttachments = (attchUrl, templateId, emailId, tokens?: string) => {
        const url = this.MAIN_API_URL + "CRUD/TriggerMail";
        let headers = new HttpHeaders()
        let dataToSend = {
            "templateID": 6,
            "emailIds": emailId,
            "tokens": {
                "TABATTACHMENT": attchUrl
            }
        }
        return this._coreHttpService.httpPostRequest(
            url,
            dataToSend,
            headers
        );
    }





    updateSidebar(data: string) {
        this.dataSourceSidebar.next(data);
    }
    updateHeader(data: string) {
        this.dataSourceHeader.next(data);
    }

    updateImageScreen(data: string) {
        this.imageUpload.next(data);
    }

    getJSON(path): Observable<any> {
        return this.http.get(path);
    }
    getAppDetails(refreshSchema) {
        // let guid = this.uuidv4();
        // const url = this.MAIN_API_URL + "CRUD/CreateAppData?id=" + guid;
        const url = this.MAIN_API_URL + "CRUD/GetAppDetail?refreshSchema=" + refreshSchema;
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            "No-Auth": "true"
        });

        return this._coreHttpService.httpGetRequest<any>(
            url,
            headers,
            true
        );
    }

    uuidv4() {
        return (this.S4() + this.S4() + "-" + this.S4() + "-4" + this.S4().substr(0, 3) + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4()).toLowerCase()
    }

    S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    

    executeQuery(dataObj: any, queryType: QueryType): Observable<any> {

        switch (queryType) {
            case QueryType.Insert: {
                //return this.execution(dataObj, this.MAIN_API_URL + "ExecuteInsertQuery", false);
                return this.execution(dataObj, this.MAIN_API_URL + "v1/CRUD/insert", false);

                break;
            }
            case QueryType.Update: {
                //return this.execution(dataObj, this.MAIN_API_URL + "ExecuteUpdateQuery", true);
                return this.execution(dataObj, this.MAIN_API_URL + "v1/CRUD/update", true);

                break;
            }
            case QueryType.Delete: {
                // return this.execution(dataObj, this.MAIN_API_URL + "ExecuteDeleteQuery", true);
                return this.execution(dataObj, this.MAIN_API_URL + "v1/CRUD/delete", true);

                break;
            }
            case QueryType.Select: {
                // return this.execution(dataObj, this.MAIN_API_URL + "ExecuteSelectQuery", false);
                return this.execution(dataObj, this.MAIN_API_URL + "v1/CRUD/select", false);

                break;
            }
        }
    }
    
    // Identity Registration
    RegisterToken(Email,password):Observable<any>
    {
        var url = this.MAIN_API_URL + "v1/identity/register"
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        
        var datatopass =
        {
            "Email": Email,
            "Password":password,
            "Role":"Recruiter"
        };
        return this._coreHttpService.httpPostRequest(url,
        datatopass, headers, true);
        
    }

    GetToken(Email,password):Observable<any>
    {
        var url = this.MAIN_API_URL + "v1/identity/login"
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        
        var datatopass =
        {
            "Email": Email,
            "Password":password
        };
        return this._coreHttpService.httpPostRequest(url,
        datatopass, headers, true);
    }


    refteshToken():Observable<any> {
        var token = localStorage.getItem('Token');
        var refreshtoken = localStorage.getItem('RefreshToken');
        var url = this.MAIN_API_URL + "v1/identity/refresh";
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        var datatopass =
        {
            "Token": token,
            "RefreshToken": refreshtoken
        };
        return this._coreHttpService.httpPostRequest(url,
        datatopass, headers, true);
    }


    executeWorkFlow(workflowid,Reqtokens):Observable<any>
    {
        var token =   localStorage.getItem("UserToken")
        var url = this.MAIN_API_URL + "v1/Workflow/execute"
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': token
        });
        
        var datatopass =
        {
            "WorkflowID": workflowid,
             Reqtokens
        };
        return this._coreHttpService.httpPostRequest(url,
        datatopass, headers, true);
    }


    ExecuteRAWQuery(selectQuery) {
        var token =   localStorage.getItem("UserToken")
        var headers = new HttpHeaders({
            'Content-Type':'application/json',
            'Authorization':token
        });
        //const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  
        return this._coreHttpService.httpPostRequest(this.MAIN_API_URL + "v1/CRUD/RawSQL?query=" + selectQuery,
            null, headers, true);
    }

    doProcessForSP<T>(object: T, spName: string) {
        let strToReturn: string = `EXEC ` + spName + " ";
        for (let key in object) {
            if (object.hasOwnProperty(key)) {
                let value = !!object[key] ? object[key] : null;
                if (Object.keys(object)[Object.keys(object).length - 1] === key) {
                    strToReturn += this.getNameOfK(key) + value;
                } else {
                    strToReturn += this.getNameOfK(key) + value + ","
                }
            }
        }
        return strToReturn;
    }
    private getNameOfK(str: string) {
        return `@` + str + ` =`;
    }

    public execution(dataObj: any, api: string, isAuth: boolean) {
        var token =   localStorage.getItem("UserToken")
        const url = api;
        if (isAuth) {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
            'Authorization':token
            });
            return this._coreHttpService.httpPostRequest<any, any>(url, dataObj, headers, true);
        } else {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
            'Authorization':token
            });
            return this._coreHttpService.httpPostRequest<any, any>(url, dataObj, headers, true);
        }
    }

    getFieldsName(dataType: number, isLookup): string {

        if (isLookup) {
            return "string";
        }
        switch (dataType) {

            case 1:
                return "number";

            case 2:
                return "number";

            case 3:
                return "boolean";

            case 4:
                return "string";

            case 5:
                return "Date";

            case 6:
                return "Date";

            case 7: return "string";
                break;
            case 8: return "string";
                break;
            case 9: return "string";
                break;
            case 10: return "string";
                break;
            case 11: return "string";
                break;
            case 12: return "string";
                break;
            case 13: return "string";
                break;
            case 14: return "string";
                break;
            case 15: return "string";
                break;
            case 16: return "number";
                break;
            default:
                return "string";
        }
    }

    login = (user) => {
        var data =
            "username=" +
            user.emailAddress +
            "&password=" +
            user.password +
            "&grant_type=password";
        var reqHeaders = new HttpHeaders({
            "Content-type": "application/x-www-form-urlencoded",
            "No-Auth": "true"
        });
        return this._coreHttpService.httpPostRequest(
            this.LOG_IN_URL_Live,
            data,
            reqHeaders
        );
    };


    sendEmail = (user) => {
        var url = this.MAIN_API_URL + "CRUD/TriggerMail"
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            "No-Auth": "true"
        });
        return this._coreHttpService.httpPostRequest<any, any>(url, user, headers

        );
    }

    sendMail = (sendTo) => {
        var url = this.MAIN_API_URL + "CRUD/SendMail?sendTo=" + "'" + sendTo + "'";
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            "No-Auth": "true"
        });
        return this._coreHttpService.httpGetRequest<any>(
            url,
            headers,
            true
        );
    }

    changePassword = (user) => {
        var url = this.MAIN_API_URL + "CRUD/ChangePassword?password=" + "'" + user.oldPassword + "'" + "&userName=" + "'" + user.userName + "'" + "&newPassword=" + "'" + user.newPassword + "'";
        var headers = new HttpHeaders({
            'Content-Type': 'application/json',
            "No-Auth": "true",
        });
        return this._coreHttpService.httpGetRequest<any>(
            url,
            headers,
            true
        );
    }

}