import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './reset-password.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HeaderModule } from '../../components/toolbar/aurthentication-toolbar/header.module';
import { ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
@NgModule({

    declarations: [ResetPasswordComponent],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: ResetPasswordComponent, pathMatch: 'full' }
        ]),
        HeaderModule,
        ReactiveFormsModule,
        CommonModule
    ],
    entryComponents: [],
    exports: [
        ResetPasswordComponent

    ]
})

export class ResetPasswordModule {

}
