import { Component, AfterViewInit, OnDestroy } from "@angular/core";
import { GetFilteredFavouriteCandidateList } from "./favorites.class";
import { AppService } from "../app.service";

import Swal from "sweetalert2";
import { PageModel, PageScreen, ConfigPageModel, SeoDetailModel } from "../core/core.class";
import { CoreService } from "../core/core.service";
import * as _ from "lodash";

import { CadidateListLocalModel } from "../Authentication/searchmember/search.class";
import { QueryType, FilterCriteria } from "../app.class";
import { IDropdownSettings } from "ng-multiselect-dropdown/multiselect.model";
@Component({
    selector: 'app-favorites-view',
    templateUrl: './favorites.component.html',
    styleUrls: ['./favorites.component.scss']
})

export class FavoritesComponent implements AfterViewInit, OnDestroy {
    favouriteListData: any = [];
    AllListData: any;
    GenderList: any;
    RelationShipList: any;
    RaceList: any;
    LanguageList: any;
    CitizionshipList: any;
    JobList: any;
    LevelList: any;
    Isfilter = false;
    pageDetails = new PageModel(PageScreen.FAVOURITECANDIDATE);
    config: ConfigPageModel = new ConfigPageModel();
    currentUser: any;
    RecruiterJobList: any;
    selectedjobtitle: any = 0;
    industryList: any;
    fieldOfStudyList: any;
    yearsOfExp: any;
    ageList: any;
    qualificationList: any;
    isSelectedCriteria = true;
    IsSelected: any;
    IsMultiselect = false;
    minQualification: any;
    qualificationLocal: any;
    multiMinQualification: any;
    selectedQualification: any;
    fieldofstudy = false;
    dropdownSettings: IDropdownSettings;
    localData: CadidateListLocalModel = new CadidateListLocalModel();
    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();
    isTabExpanded1: boolean = true;
    isTabExpanded2: boolean = true;
    isTabExpanded3: boolean = true;
    ngAfterViewInit(): void {
    }
    constructor(private appService: AppService, private coreService: CoreService) {

        this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
            .subscribe((data: any) => {
                if (!!data && !!data.Result) {
                    this.SList = data.Result;
                    if (this.SList.length >= 1) {
                        this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2501));
                    }
                }
            })


        if (localStorage.getItem("UserInfo") != null && localStorage.getItem("UserInfo") != undefined) {

            this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));

        }
        this.GetFavouriteList();
        this.getJobDataByRecruiterId();
        this.getListData();
    }


    paramterFilter: GetFilteredFavouriteCandidateList = new GetFilteredFavouriteCandidateList();
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2501));
        }
    }
    applyfilter = () => {

        const keys = Object.values(this.paramterFilter);
        // this.paramterFilter.RecruiterID = this.currentUser.ID;
        let IsREquired;
        keys[13] = 0;
        for (const key of keys) {

            if (!!key) {
                if (key != 0) {
                    IsREquired = true;
                    break;
                } else {
                    IsREquired = false;
                }
            } else {
                IsREquired = false;
            }
        }

        if (IsREquired == true) {
            if (this.localData.mainQualification == 0) {
                this.GetFavouriteList();
            };
            if (IsREquired == true) {
                if (this.localData.mainQualification == 0) {


                    this.GetFavouriteList();
                } else {
                    if (this.paramterFilter.Qualification != 0) {
                        this.GetFavouriteList();
                    } else {
                        Swal({
                            // type: 'Warning',
                            text: 'Please select minimum qualification.',
                        });
                    }
                }
            }
        } else {
            this.isSelectedCriteria = true;
            Swal({
                text: 'Please select at least one of the three search criteria.',
            });
        }
    }
    GetFavouriteList = () => {
        this.paramterFilter['RecruiterID'] = this.currentUser.ID;
        let isFromDate = true;
        const agefrom = this.paramterFilter.AgeFrom;
        const ageto = this.paramterFilter.AgeTo;

        const fromdate = Number(this.paramterFilter.AgeFrom);
        const toDate = Number(this.paramterFilter.AgeTo);
        if ((fromdate != 0 || toDate != 0) && fromdate == toDate) {
            isFromDate = false
        }
        else if (fromdate > toDate) {
            isFromDate = false;
        } else {
            isFromDate = true;
            // if (fromdate != 0) {
            //     this.paramterFilter.AgeFrom = _.find(this.ageList, t => t.SystemValue == fromdate).ID;
            // }
            // if (toDate != 0) {
            //     this.paramterFilter.AgeTo = _.find(this.ageList, t => t.SystemValue == toDate).ID;

            // }

        }
        if (isFromDate == true) {
            this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.paramterFilter, "GetFilteredFavouriteCandidateList"))
                .subscribe((data: any) => {
                    console.log("Data retrieved:", data);
                    this.favouriteListData = data.Result;
                    console.log("Favourite list data:", this.favouriteListData);
                    this.setPageD();
                    for (let data of this.favouriteListData) {
                        if (data.IsPaymentDone == 0) {
                            data['isPayment'] = false;
                        } else {
                            data['isPayment'] = true;
                        }

                        if (data.IsSelected == 1) {
                            this.IsSelected = true;
                        } else {
                            this.IsSelected = false;
                        }
                    }
                    if (this.paramterFilter.Qualification != 0) {
                        this.IsMultiselect = true;

                        this.qualificationLocal = JSON.parse(this.paramterFilter.Qualification);
                        const multiMinQualification = this.coreService.getListDataByEnumIds(this.AllListData, [1026, 1027]);
                        this.multiMinQualification = _.filter(multiMinQualification, (v) => _.includes(this.qualificationLocal, v.ID));
                        this.selectedQualification = this.multiMinQualification;
                        this.dropdownSettings = {
                            singleSelection: false,
                            idField: 'ID',
                            textField: 'DisplayValue',
                            selectAllText: 'Select All',
                            unSelectAllText: 'UnSelect All',
                            itemsShowLimit: 1,
                            allowSearchFilter: false,
                        };
                    }
                });
            this.paramterFilter.AgeFrom = agefrom;
            this.paramterFilter.AgeTo = ageto;
        } else {
            Swal({
                // type: 'Warning',
                text: 'The To age limit should be greater than From ',
            });
        }
    }

    private getListData() {
        let data = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": false,
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.AllListData = response.Result;
                this.BindListData();
                if (!!this.multiMinQualification) {
                    this.IsMultiselect = true;
                    const multiMinQualification = this.coreService.getListDataByEnumIds(this.AllListData, [1026, 1027]);
                    this.multiMinQualification = _.filter(multiMinQualification, (v) => _.includes(this.qualificationLocal, v.ID));
                    this.selectedQualification = this.multiMinQualification;
                    this.dropdownSettings = {
                        singleSelection: false,
                        idField: 'ID',
                        textField: 'DisplayValue',
                        selectAllText: 'Select All',
                        unSelectAllText: 'UnSelect All',
                        itemsShowLimit: 1,
                        allowSearchFilter: false,
                    };
                }

            }
        });
    }

    private BindListData() {
        let GenderList = this.AllListData.filter(x => x.EnumID == 1030);
        this.GenderList = _.sortBy(GenderList, ['SystemValue']);
        let RaceList = this.AllListData.filter(x => x.EnumID == 1014);
        this.RaceList = _.sortBy(RaceList, ['SystemValue']);
        let RelationShipList =  this.AllListData.filter(x => x.EnumID == 1015);
        this.RelationShipList =_.sortBy(RelationShipList, ['SystemValue']);
        let LanguageList = this.AllListData.filter(x => x.EnumID == 1016);
        this.LanguageList = _.sortBy(LanguageList, ['SystemValue']);
        let CitizionshipList = this.AllListData.filter(x => x.EnumID == 1017);
        this.CitizionshipList = _.sortBy(CitizionshipList, ['SystemValue']);
        let JobList =this.AllListData.filter(x => x.EnumID == 1029);
        this.JobList = _.sortBy(JobList, ['DisplayValue']);
        this.LevelList = this.AllListData.filter(x => x.EnumID == 1020);
        this.LevelList = _.orderBy( this.LevelList, 'SystemValue', 'asc');
        let IndustryList = this.AllListData.filter(x => x.EnumID == 1021);
        this.industryList = this.AllListData.filter(x => x.EnumID == 1021);
        // this.qualificationList = this.AllListData.filter(x => (x.EnumID == 1026 && x.EnumID == 1027));
        this.yearsOfExp = this.AllListData.filter(x => x.EnumID == 1048);
        this.ageList = this.AllListData.filter(x => x.EnumID == 1049);
        this.ageList = this.AllListData.filter(x => x.EnumID == 1049);
        _.each(this.ageList, (list:any) => {
            if (list.Sequence) {
                list.Sequence = Number(list.Sequence);
            }
        })

        this.ageList = _.sortBy(this.ageList, ["Sequence"], ['asc']);
        this.qualificationList = this.coreService.getListDataByEnumIds(this.AllListData, [1026, 1027]);
        this.qualificationList = this.AllListData.filter(x => x.EnumID == 1072);
    }


    clearFilter(): any {
        this.IsMultiselect = false;
        this.minQualification = null;
        this.paramterFilter = new GetFilteredFavouriteCandidateList();
        this.localData = new CadidateListLocalModel();
        this.paramterFilter['RecruiterID'] = this.currentUser.ID;
        this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.paramterFilter, "GetFilteredFavouriteCandidateList"))
            .subscribe((Response: any) => {
                this.favouriteListData = Response.Result;

            });
    }


    //delete data

    private setPageD() {
        this.pageDetails = this.coreService.getScreenP(PageScreen.FAVOURITECANDIDATE);
        this.config = {
            currentPage: this.pageDetails.currentPage,
            itemsPerPage: this.pageDetails.itemsPerPage,
            totalItems: this.pageDetails.totalItems
        };
    }
    pageChanged(event) {
        this.config.currentPage = event;
    }
    ngOnDestroy(): void {
        this.pageDetails = Object.assign(this.config);
        this.pageDetails.screenName = PageScreen.FAVOURITECANDIDATE;
        this.coreService.pageChangeHelper(this.pageDetails, PageScreen.FAVOURITECANDIDATE);
    }

    onUnFavoriteClick(event): any {

        let WhereClause: any = new FilterCriteria();
        WhereClause.Filters = [
            {
                ConjuctionClause: 1,
                FieldID: "JobID",
                RelationalOperator: 3,
                ValueType: 0,
                value: event.JobID
            },
            {
                ConjuctionClause: 1,
                FieldID: "CandidateID",
                RelationalOperator: 3,
                ValueType: 0,
                value: event.id
            },
        ];
        WhereClause.FilterLogic = "1";
        let data = {
            QueryObjectID: "FavouriteCandidateInfo",
            QueryType: QueryType.Delete,
            Joins: [],
            WhereClause: WhereClause,
        }
        this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
            if (response.StatusCode == "200") {
                var UserId = response.Result;
                Swal({
                    title: ' Removed From Favorite!!',
                });
                this.GetFavouriteList();
            }
        });
    }
    selectCanididate(event, id, jobId): any {
        if (event.target.checked == true) {
            const WhereClause = new FilterCriteria();
            WhereClause.Filters = [];
            WhereClause.FilterLogic = "1";

            const dataObj = this.setSelectedData(id, jobId);

            const data = {
                QueryObjectID: "SelectedCandidateInfo",
                QueryType: QueryType.Insert,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj
            };

            this.appService.executeQuery(data, QueryType.Insert).subscribe(response => {
            });
        } else {
            let WhereClause: any = new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "CandidateID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: id
                },
                {
                    ConjuctionClause: 1,
                    FieldID: "JobID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: jobId
                }
            ];
            WhereClause.FilterLogic = "1";
            let data = {
                QueryObjectID: "SelectedCandidateInfo",
                QueryType: QueryType.Delete,
                Joins: [],
                WhereClause: WhereClause,
            };

            this.appService.executeQuery(data, QueryType.Delete).subscribe(response => {
                if (response.StatusCode == "200") {
                    this.getListData();
                }
            });
        }
    }

    setSelectedData(id, jobId): any {
        return [
            {
                AppFieldID: "RecruiterID",
                Value: this.currentUser.ID
            },
            {
                AppFieldID: "JobID",
                Value: jobId
            },
            {
                AppFieldID: "CandidateID",
                Value: id
            },
        ];
    }


    getJobDataByRecruiterId() {
        let data = {
            "QueryObjectID": "GetRecruiterJoblist",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "CreatedBy",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.currentUser.ID
                    },
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "IsPublished",
                        "RelationalOperator": 3,
                        "ValueType": 1,
                        "value": 1
                    },
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.RecruiterJobList = response.Result;
            }
        });
    }

    getMinQualification = () => {
        try {
            this.IsMultiselect = false;
            const typeOfQualification = this.localData.mainQualification;
            if (typeOfQualification == 2392) {
                this.minQualification = this.AllListData.filter(x => x.EnumID == 1026);
                this.fieldofstudy = false;
            } else {
                this.minQualification = this.AllListData.filter(x => x.EnumID == 1027);
                this.fieldOfStudyList = this.AllListData.filter(x => x.EnumID == 1019);
                this.fieldOfStudyList = _.sortBy(this.fieldOfStudyList, ['SystemValue']);
                this.fieldofstudy = true;
            }
        } catch (error) {
            console.error('Error in getMinQualification:', error);
        }
    };
    

    setValueforMinCriteria = (selectedValue) => {
        const qualificationList = this.coreService.getListDataByEnumIds(this.AllListData, [1026, 1027]);

        // Find the selected qualification object
        const selectedQualification = qualificationList.find(q => q.ID == selectedValue);

        if (selectedQualification) {
            const getNextminValue = selectedQualification.Sequence;
            const filteredQualifications = qualificationList.filter(q => q.Sequence >= getNextminValue);
            const qaId = filteredQualifications.map(q => q.ID);
            this.paramterFilter.Qualification = JSON.stringify(qaId);
        } else {
            this.paramterFilter.Qualification = null;
        }
    }

    onItemDeSelect(items: any) {
        this.selectedQualification = this.selectedQualification.filter(x => x.ID != items.ID);
        const data = _.map(this.selectedQualification, 'ID');
        this.paramterFilter.Qualification = JSON.stringify(data);
    }
    onSelectAll(items: any) {
    }
    onItemSelect(item: any) {
        const data = this.multiMinQualification.filter(x => x.ID == item.ID);
        this.selectedQualification.push(data);
        const data2 = _.map(this.selectedQualification, 'ID');
        this.paramterFilter.Qualification = JSON.stringify(data2);
    }

    toggleTab1() {
        this.isTabExpanded1 = !this.isTabExpanded1;
    }

    toggleTab2() {
        this.isTabExpanded2 = !this.isTabExpanded2;
    }

    toggleTab3() {
        this.isTabExpanded3 = !this.isTabExpanded3;
    }
}
