import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from "@angular/core";
// import * as jspdf from 'jspdf';
// import html2canvas from 'html2canvas';
import { Inject } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { AppService } from "../app.service";

import { Router } from "@angular/router";
import * as _ from "lodash";
import { forkJoin } from "rxjs";
// import jsPDF from 'jspdf';
import Swal from "sweetalert2";
import { FilterCriteria, QueryType } from "../app.class";
import { LoaderService } from "../core/loader.service";



@Component({
    selector: 'app-home-view',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss'],
    providers: [
        DatePipe
    ]
})
export class InvoiceComponent implements OnInit, OnDestroy {
    ngOnDestroy(): void {
        this.loaderService.display(false);
    }
    ngOnInit(): void {
        this.loaderService.display(true);
    }

    @ViewChild('myDiv') myDiv: ElementRef;
    CurrentUser: any;
    CompanyInvoiceName :any;
    myDate = new Date();
    Ismedia = false;

    constructor(
        private appService: AppService, private router: Router,
        private datePipe: DatePipe,
        private loaderService: LoaderService,

        @Inject(DOCUMENT) doc) {
        if (!!localStorage.getItem("UserInfo")) {
            this.CurrentUser = JSON.parse(localStorage.getItem("UserInfo"))
            if (!this.CurrentUser.SubscriptionPlan) {
                this.CurrentUser.SubscriptionPlan = 0;
            }
        }
    }
    converthtmlToPdf = (data) => {
         
        const html2canvas = require("html2canvas");
        html2canvas(this.myDiv.nativeElement).then(canvas => {
            // Few necessary setting options  
            const jsPDF = require('jspdf')
            var imgWidth = 208;
            var pageHeight = 295;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            const contentDataURL = canvas.toDataURL('image/png')
            let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
            var blob = pdf.output('blob');
            var formData = new FormData();
            formData.append(1205 + ";" + this.CurrentUser.UserID + ";" + 1 + ";" + 1204, blob, "invoice.pdf");
            this.appService.uploadInvoice(formData).subscribe((data: any) => {
                // Send Mail with attachments
                 
                if (!!data && !!data.Result && !!data.Result[0]) {

                    // this.appService.SendMailwithAttachments(data.Result[0].URL, 2, this.CurrentUser.Email)
                    //     .subscribe(data => {
                    //     });
                       
                        let paymentDataEntry = {
                        PaymentMode: this.CurrentUser["PaymentMode"],
                        RecruiterID: this.CurrentUser["ID"],
                        Amount: this.CurrentUser["SubscriptionPlan"],
                        JobID: this.CurrentUser["JobId"],
                        PaymentStatus: 1183,
                        Date: new Date().getDate(),
                        PaymentItem: !!this.CurrentUser && !!this.CurrentUser.SelectedCandidate ? this.CurrentUser.SelectedCandidate.length : 0
                    };


                    let WhereClause = new FilterCriteria();
                    WhereClause.Filters = [];
                    WhereClause.FilterLogic = "1";
                    let dataObj = this.setData(paymentDataEntry);
                    let dataD = {

                        QueryObjectID: "PaymentInfo",
                        QueryType: QueryType.Insert,
                        Joins: [],
                        WhereClause: WhereClause,
                        Values: dataObj
                    };

                    this.appService.executeQuery(dataD, QueryType.Insert).subscribe(response => {
                         
                        let Datatopass={
                            "ID":this.CurrentUser.ID,
                            "EailID":this.CurrentUser.Email,
                            "TABATTACHMENT":data.Result[0].URL,
                     
                        }
                        this.appService.executeWorkFlow("5EB3CD46-F877-4D6F-BCCE-06295727BD88",Datatopass).subscribe(data => {
                        });

                        let selectedCandidateToUpdate = this.CurrentUser.SelectedCandidate;
                        if (!!selectedCandidateToUpdate && selectedCandidateToUpdate.length >= 1) {
                            this.callAPI(selectedCandidateToUpdate).subscribe(a => {

                                Swal({
                                    title: 'Thank you for making payment for the Contact list of the Candidates. Your transaction completed successfully.',
                                });

                                this.router.navigate(['/candidateslist/' + this.CurrentUser["JobId"]]);
                            });
                        }
                    });
                }
            });
        });
    }
   
    callAPI(data) {
         
        let createCall = [];
        _.each(data, (d:any) => {
            let obj = this.setObj(d);
            const WhereClause: any = new FilterCriteria();
            WhereClause.Filters = [
                {
                    ConjuctionClause: 1,
                    FieldID: "ID",
                    RelationalOperator: 3,
                    ValueType: 0,
                    value: d.ID
                }];
            WhereClause.FilterLogic = "1";
            const dataObj = this.setObj(d);
            const dataD = {
                QueryObjectID: "SelectedCandidateInfo",
                QueryType: QueryType.Update,
                Joins: [],
                WhereClause: WhereClause,
                Values: dataObj
            };

            let call = this.appService.execution(dataD, this.appService.MAIN_API_URL + "v1/CRUD/Update", true);
            createCall.push(call);
        });
        return forkJoin(createCall);
    }
    setData(data): any {
        const date = new Date();
        return [{
            AppFieldID: "TransactionID",
            Value: "Trs_" + this.getRandomInt(1, 1000)
        },
        {
            AppFieldID: "PaymentMode",
            Value: data.PaymentMode
        },
        {
            AppFieldID: "RecruiterID",
            Value: data.RecruiterID

        },
        {
            AppFieldID: "Amount",
            Value: data.Amount

        },
        {
            AppFieldID: "JobID",
            Value: data.JobID

        },
        {
            AppFieldID: "PaymentStatus",
            Value: data.PaymentStatus

        },
        {
            AppFieldID: "Date",
            Value: new Date()

        },
        {
            AppFieldID: "PaymentItem",
            Value: data.PaymentItem

        },
        {
            AppFieldID: "UpdatedOn",
            Value: null

        }
        ];
    }


    setObj(data) {
        return [
            {
                AppFieldID: "CandidateID",
                Value: data.CandidateID
            },
            {
                AppFieldID: "RecruiterID",
                Value: data.RecruiterID

            },
            {
                AppFieldID: "IsPaymentDone",
                Value: 1

            },
            {
                AppFieldID: "JobID",
                Value: data.JobID

            },
            {
                AppFieldID: "CreatedOn",
                Value: null

            },
            {
                AppFieldID: "UpdatedOn",
                Value: null

            }
        ];
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.converthtmlToPdf(null);
        }, 0);
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}
