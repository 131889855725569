import { Component } from "@angular/core";
import { FormBuilder } from '@angular/forms';
import { Router } from "@angular/router";
import * as _ from "lodash";
import Swal from 'sweetalert2';
import { AppService } from "../../app.service";
import { ConfigPageModel, PageModel, PageScreen, SeoDetailModel } from "../../core/core.class";
import { CoreService } from "../../core/core.service";
import { CadidateListLocalModel } from "./search.class";
import { IDropdownSettings } from "ng-multiselect-dropdown/multiselect.model";
import { QueryType } from "../../app.class";


@Component({
    selector: 'app-searchmember-view',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent {

    closeResult: string;
    ACTIVEMODEL = '';
    currentUser: any;
    RecruiterJobList: any;
    GenderList: any;
    RaceList: any;
    RelationShipList: any;
    LanguageList: any;
    CitizionshipList: any;
    JobList: any;
    IndustryList: any;
    LevelList: any;
    AllListData: any;

    jobId: any;
    paramterFilter: CadidateListLocalModel = new CadidateListLocalModel();

    pageDetails = new PageModel(PageScreen.SEARCHMEMBER);
    config: ConfigPageModel = new ConfigPageModel();
    fieldOfStudyList: any;
    yearsOfExp: any;
    ageList: any;
    qualificationList: any[];
    minQualification: any;
fieldofstudy=false;
    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();

    dropdownList = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings;
    constructor(private appService: AppService, private formBuilder: FormBuilder, private coreService: CoreService, public router: Router) {
        if (localStorage.getItem("UserInfo") != null && localStorage.getItem("UserInfo") != undefined) {



            this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
                .subscribe((data: any) => {
                    if (!!data && !!data.Result) {
                        this.SList = data.Result;
                        if (this.SList.length >= 1) {
                            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2496));
                        }
                    }
                })
 

            this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));
            this.getJobDataByRecruiterId();
            this.getListData();
            
        }
        // this.setPageD();

        this.dropdownList = [
            { item_id: 1, item_text: 'Mumbai' },
            { item_id: 2, item_text: 'Bangaluru' },
            { item_id: 3, item_text: 'Pune' },
            { item_id: 4, item_text: 'Navsari' },
            { item_id: 5, item_text: 'New Delhi' }
        ];
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: false,
        };
    }

    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2496));
        } 
    }
    onItemSelect(item: any) {
    }
    onSelectAll(items: any) {
    }
    hidenModel(data): any {
        this.ACTIVEMODEL = data;
        this.getJobDataByRecruiterId();
    }
    private getJobDataByRecruiterId() {
        let data = {
            "QueryObjectID": "JobInfo",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "CreatedBy",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.currentUser.ID,
                    },
                ]
            },
            "Sort": [
                {
                    "FieldID": "ID",
                    "SortSequence": 2
                }
            ]
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.RecruiterJobList = response.Result;
                this.RecruiterJobList.forEach(element => {
                // console.log(element.Title.DisplayValue)
            });
                
            }
        });

    }

    private getListData() {
        const data = {
            "QueryObjectID": "TABMD_SystemEnumDetails",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": false,
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.AllListData = response.Result;
                this.BindListData();
            }
        });
    }

    private BindListData() {
        let GenderList = this.AllListData.filter(x => x.EnumID == 1030);
        this.GenderList = _.sortBy(GenderList, ['SystemValue']);
        let RaceList = this.AllListData.filter(x => x.EnumID == 1014);
        this.RaceList = _.sortBy(RaceList, ['SystemValue']);
        let RelationShipList =  this.AllListData.filter(x => x.EnumID == 1015);
        this.RelationShipList =_.sortBy(RelationShipList, ['SystemValue']);
        let LanguageList = this.AllListData.filter(x => x.EnumID == 1016);
        this.LanguageList = _.sortBy(LanguageList, ['SystemValue']);
        let CitizionshipList = this.AllListData.filter(x => x.EnumID == 1017);
        this.CitizionshipList = _.sortBy(CitizionshipList, ['SystemValue']);
        let JobList =this.AllListData.filter(x => x.EnumID == 1029);
        this.JobList = _.sortBy(JobList, ['DisplayValue']);
        this.LevelList = this.AllListData.filter(x => x.EnumID == 1020);
        this.LevelList = _.orderBy( this.LevelList, 'SystemValue', 'asc');
        let IndustryList = this.AllListData.filter(x => x.EnumID == 1021);
        this.IndustryList = _.sortBy(IndustryList, ['SystemValue']);
        this.yearsOfExp = this.AllListData.filter(x => x.EnumID == 1048);
        this.ageList = this.AllListData.filter(x => x.EnumID == 1049); 
        _.each(this.ageList, (list:any) => {
            if (list.Sequence) {
                list.Sequence = list.Sequence;
            }
        })
        this.ageList.sort((a, b) => a.Sequence - b.Sequence);
        // this.qualificationList = this.coreService.getListDataByEnumIds(this.AllListData, [1026, 1027]);
        this.qualificationList = this.AllListData.filter(x => x.EnumID == 1072);

    }
    getMinQualification = () => {
        try {
            const typeOfQualification = this.paramterFilter.mainQualification;
            if (typeOfQualification == 2392) {
                this.minQualification = this.AllListData.filter(x => x.EnumID == 1026);
                this.fieldofstudy = false;
            } else {
                this.minQualification = this.AllListData.filter(x => x.EnumID == 1027);
                this.fieldOfStudyList = this.AllListData.filter(x => x.EnumID == 1019);
                this.fieldOfStudyList = _.sortBy(this.fieldOfStudyList, ['SystemValue']);
                this.fieldofstudy = true;
            }
        } catch (error) {
            console.error('Error in getMinQualification:', error);
        }
    };
    
    setValueforMinCriteria = (selectedValue) => {
        const qualificationList = this.coreService.getListDataByEnumIds(this.AllListData, [1026, 1027]);
        const selectedQualification = qualificationList.find(q => q.ID == selectedValue);

        if (selectedQualification) {
            const getNextminValue = selectedQualification.Sequence;
            const filteredQualifications = qualificationList.filter(q => q.Sequence >= getNextminValue);
            const qaId = filteredQualifications.map(q => q.ID);
            this.paramterFilter.Qualification = JSON.stringify(qaId);
        } else {
            this.paramterFilter.Qualification = null;
        }
    }


    GetCandidateList = () => {
        // this.appService.ExecuteRAWQuery(this.appService.doProcessForSP(this.paramterFilter, "GetFilteredCandidateList"))
        //     .subscribe(data => {
        //     });
        const keys = Object.values(this.paramterFilter);
        let IsREquired;
        for (const key of keys) {
            if (!!key) {
                if (key != 0) {
                    IsREquired = true;
                    break;
                } else {
                    IsREquired = false;
                }
            } else {
                IsREquired = false;
            } 
        }
        let isFromDate = true;
        const fromdate = Number(this.paramterFilter.AgeFrom);
        const toDate = Number(this.paramterFilter.AgeTo);
        if((fromdate != 0 || toDate != 0)&&fromdate==toDate){
            isFromDate = false;
        }
        else if(fromdate >  toDate){
            isFromDate = false;
        }
        else {
            isFromDate = true;
        }
        if (this.jobId != 0) {
            if (IsREquired == true) {
                if (isFromDate == true) {

                    if(this.paramterFilter.mainQualification == 0)
                    {
                        
                    this.doSomething();
                    } else {
                        if(this.paramterFilter.Qualification != 0) {
                            this.doSomething();
                        } else  {
                            Swal({
                                // type: 'Warning',
                                text: 'Please select the minimum qualification.',
                            });
                        }
                    } 
                } 
                else {
                  
                    Swal({
                        // type: 'Warning',
                        text: 'The To age limit should be greater than From ',
                    });
                    return false
                }
            } else {
                Swal({
                    // type: 'Warning',
                    text: 'Please select at least one of the three search criteria.',
                });
            }

        } else {
            Swal({
                // type: 'Warning',
                text: 'Select Registered Job.',
            });
        }
    }

    doSomething = () => {
        this.paramterFilter.JobId = this.jobId;
        localStorage.setItem("SearchMember", JSON.stringify(this.paramterFilter));
        this.router.navigate(['/all-searchmember-candidate']);
    }

    // page config
    private setPageD() {
        this.pageDetails = this.coreService.getScreenP(PageScreen.SEARCHMEMBER);
        this.config = {
            currentPage: this.pageDetails.currentPage,
            itemsPerPage: this.pageDetails.itemsPerPage,
            totalItems: this.pageDetails.totalItems
        };
    }
    pageChanged(event) {
        this.config.currentPage = event;
    }
    ngOnDestroy(): void {
        this.pageDetails = Object.assign(this.config);
        this.pageDetails.screenName = PageScreen.SEARCHMEMBER;
        this.coreService.pageChangeHelper(this.pageDetails, PageScreen.SEARCHMEMBER);
    }

    ClearFilter(): any {
        this.minQualification = null;
        this.paramterFilter = new CadidateListLocalModel();
        this.jobId = null;
    }

    checkAgeFrom(): any {

    }
}