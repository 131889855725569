import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { from } from 'rxjs';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenubarModule } from '../components/menubaar/aurthentication/menubar.module';
import { SteperModule } from '../components/steps/steps.module';
import { CommonModule } from '@angular/common';
import { FooterGreyModule } from '../components/footer-grey/footer-grey.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';
import { MessageComponent } from './message.component';
@NgModule({
    declarations: [MessageComponent
    ],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: MessageComponent, pathMatch: 'full' }
        ]),
        MenubarModule,
        SteperModule,
        FooterGreyModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgxPaginationModule,
        HttpClientModule 
        
    ],
    entryComponents: [],
    exports: [
        MessageComponent
    ]
})

export class MessageModule {

}
