import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { AuthService } from './auth';
import { CoreHttpService } from './core-http.service';
import { CoreService } from '../core/core.service';
import { LoaderService } from '../core/loader.service';

@NgModule({
  imports: [
  ],
  declarations: [
  ],
  providers: [CoreHttpService, LoaderService, AuthService, CoreService]
})
export class ServicesModule {
}
