<div class="page-container">
    <aurthentication-toolbar></aurthentication-toolbar>
    <div id="forgotpassword-form" class="container">
        <div class="forgot-layout-container">
            <div class="col-md-5 d-none d-md-block p-0">
                <div class="image-layout">
                    <div class="col logo text-center logo-heading-layout">
                        <img src="./../../../assets/Background/white-recruitlee-logo.svg" width="200px;"
                            [routerLink]="'/login'">
                    </div>
                </div>
            </div>
            <div class="col-md-7 d-md-block forgot-layout">
                <form name="forgotPasswordForm" class="" [formGroup]="forgotPasswordForm" novalidate>
                    <div class="col-md-12 col-sm-12">

                        <!-- Logo for small screens -->
                        <div class="col logo text-center logo d-md-none">
                            <img src="./../../../assets/Background/dark-recruitlee-logo.svg" width="200px;"
                                [routerLink]="'/login'">
                        </div>

                        <!-- logo-heading-layout for medium screens and larger -->
                        <div class="header-layout d-none d-md-block">
                            <h5 class="text-center logo-heading-layout">Forgot Password</h5>
                            <p class="text-gray">Enter your email and we'll send you link to reset your password.</p>
                        </div>
                        <div class="form-group gradient-field">
                            <input type="text" name="username" id="username" class="form-control"
                                placeholder="Enter your email" formControlName="UserName">
                            <div class="alert-msg"
                                *ngIf="forgotPasswordForm.controls['UserName'].invalid && (forgotPasswordForm.controls['UserName'].dirty || forgotPasswordForm.controls['UserName'].touched)">
                                <span *ngIf="forgotPasswordForm.get('UserName').hasError('required')">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Email Address is
                                    required
                                </span>
                                <span *ngIf="!forgotPasswordForm.get('UserName').hasError('required') &&
                                forgotPasswordForm.get('UserName').hasError('pattern')">
                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Please enter a valid
                                    email
                                    address
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-12 text-center p-0">
                            <button type="button" class="btn-grad-filled btn-btnn btn-block"
                                (click)="ForGotPassword()">Submit
                            </button>
                        </div>

                    </div>
                </form>
                <div class="text-center link-layout">
                    <i class="fa fa-angle-left arrow-icon"></i>
                    <a class="color-dark" [routerLink]="'/login'">Back to login</a>
                </div>
            </div>
            <!-- popup html -->
            <div class="modal" [ngClass]="{modalShow: ACTIVEMODEL == 'Register',
                modalHide: ACTIVEMODEL !== 'Register'}" id="exampleModal" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog model-xl modal-dialog-centered" role="document">
                    <div class="modal-content btn1-grad-border">

                        <button type="button" (click)="ACTIVEMODEL= ''" class="close" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true" style="float: right;
                                width: 29px;
                                font-size: 26px;outline: none;">×</span>
                        </button>

                        <div class="modal-body">
                            <div class="mt-to-bt popup">
                                <div class="email-mess">
                                    <h3>Your message has been sent!</h3>
                                </div>
                                <div class="text-center">
                                    <button _ngcontent-c0="" class="btn-grad-filled2
                                        grad-btn" [routerLink]="'/reset-password'">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>