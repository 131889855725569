import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import {  RegisterComponent } from './register.component';

import { from } from 'rxjs';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HeaderModule } from '../../components/toolbar/aurthentication-toolbar/header.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({

    declarations: [RegisterComponent],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: RegisterComponent, pathMatch: 'full' }
        ]),
        HeaderModule,
        ReactiveFormsModule,
        CommonModule
    ],
    entryComponents: [],
    exports: [
    ]
})

export class RegisterModule {

}
