<section id="messages" class="main-page">
  <div class="main-page-container">
    <div class="row heading-container justify-content-center">
      <h2 class="heading mob-d-none">Message your favorite candidates here</h2>
    </div>
    <div class="row d-flex align-content-stretch">
      <div class="col-md-4 col-12 col-lg-4 card-wrapper list-card" [ngClass]="{'mob-d-block': IsDatailPage == false, 'mob-d-none': IsDatailPage == true}">
        <div class="card">
          <div class="py-24 px-12 search-wrapper">
            <input class="search-input"  [formControl]="searchInput" placeholder="Search" />
            <i class="fa fa-search"></i>
          </div>

          <div class="message-lists-wrapper" *ngIf="this.FilteredClientList">
            <div class="list" (click)= "IsDatailPage=true" [ngClass]="{'bg-color': candidate.id == this.SelectedCandidate}" *ngFor="let candidate of this.FilteredClientList" >
             <div class=" d-flex" >
              <div class="img-wrapper" style="cursor: pointer;" (click)="this.GetMessageData(candidate)" >
                <span class="round-tab">
                  <span class="border-span">
                    <img *ngIf="candidate.profile"
                      class="msg-list-img"
                      src={{candidate.profile}}
                    />
                    <img   *ngIf="!candidate.profile"
                      class="msg-list-img"
                      [src]="image"
                    />
                  </span>
                </span>
              </div>
              <div class="list-detail-wrapper d-flex" style="cursor: pointer;" (click)="this.GetMessageData(candidate)">
                <div class="w-70-p">
                  <h3>
                    <span class="border-text"
                      ><span>{{candidate.name}}</span></span
                    >
                  </h3>
                  <p>{{candidate.LastMessage}}..</p>
                </div>
                <div>
                <p class="time-stamp" *ngIf=candidate.lastReadTimestamp>
                  Last Seen on<br />
                  {{candidate.lastReadTimestamp | date:'dd MMM, EEE hh:mm a'}}
                </p>
                <p class="time-stamp" *ngIf=!candidate.lastReadTimestamp>
                  Not seen yet <br />
       
                </p>
                  <img *ngIf="!candidate.isRead" class="msgdot" src="../../assets/Background/Message.png">
              </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-8  card-wrapper content-card" [ngClass]="{'mob-d-block': IsDatailPage == true, 'mob-d-none': IsDatailPage == false}">
        
        <div class="card overflow-hidden"  *ngIf="this.messages">
          <div *ngFor = "let message of this.messages">
          </div>
          
          <div class="message-container" #myList [scrollTop]="myList.scrollHeight" *ngIf="this.messages">
            <div class="message-list p-12 d-flex" *ngFor="let message of messages">
              <div class="img-wrapper">
                <span class="round-tab">
                  <span class="border-span">
                    <img *ngIf="message.ProfilePhoto"
                      class="msg-list-img"
                      src={{message.ProfilePhoto}}
                    />
                    <img  *ngIf="!message.ProfilePhoto"
                    class="msg-list-img"
                    src="../../assets/Background/Ellipse.png"
                  />
                  </span>
                </span>
              </div>
              <div class="message-detail p-12 d-flex ">
                <div class="w-90-p">
                  <h3>
                    <span class="border-text">
                      <span>{{message.Name}} </span>
                    </span>
                  </h3>
                  <p>{{message.Message}}</p>
                </div>
                <div class="time-wrapper">
                    <p>{{message.timestamp | date:'hh:mm a'}}</p>
                    <p class="msg-status">{{message.isRead}}</p>
                </div>
              </div>
             
            </div>
          </div>
            <!-- <div class="col-md-12 pl-info" *ngIf="this.messages.length == 0">
              <h1>Choose Conversation </h1>
              <br />
            </div> -->
          <div class="message-type-wrapper text-right">
                <textarea (keyup.enter)="Send()" rows="1" [(ngModel)]="textmessage" placeholder="Type Message..."></textarea>
                <button class="btn-grad-filled lg-d-none mx-12" (click)="IsDatailPage=false">
                    Back
                </button>
                <button class="btn-grad-filled" (click)="Send('Hello')">
                    Send
                </button>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</section>
