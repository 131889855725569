import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';


import { from } from 'rxjs';
import { HeaderComponent } from '../../components/toolbar/aurthentication-toolbar/header.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MycandidatesComponent } from './mycandidates.component';
import { MenubarModule } from '../../components/menubaar/aurthentication/menubar.module';
import { CommonModule } from '@angular/common';

import { RegisterJobModule } from '../../register-job/register-job.module';
import { FooterGreyModule } from '../../components/footer-grey/footer-grey.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({

    declarations: [MycandidatesComponent],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: MycandidatesComponent, pathMatch: 'full' }
        ]),
        MenubarModule,
        CommonModule,
        FooterGreyModule,
        RegisterJobModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        NgSelectModule
    ],

})

export class CandidateModule {

}
