import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { from } from 'rxjs';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenubarComponent } from './menubar.component';
import { AppRoutingModule } from '../../../app.routing.module';


@NgModule({

    declarations: [MenubarComponent],
    imports: [
        AngularFontAwesomeModule,
        CommonModule,
        RouterModule 
    ],
    entryComponents: [],
    exports: [
        MenubarComponent
    ]
})

export class MenubarModule {

}
