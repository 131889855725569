<section class="post-job-page">
    <div class="post-job-page-container">
        <div class="post-job text-center">
            <h1 class="text-center">Post Job Advert</h1>
        </div>
            <div class="row heading-container justify-content-center">
                <h1 class="heading">Post your Job advert which can be seen by everyone</h1>
            </div>
            <div>
            <div class="col-md-12">
                <div class="col-md-4">
                    <div class="searchbar-container" [ngClass]="{'searchMenu': IsjobSelected == false}">
                        <div class="">
                            <div class="">
                                <div class="">
                                    <label class="label-text mb-4" href="#popup1">Registered
                                        Job:<sup class="required">*</sup></label>
                                </div>
                                <div class="mb-4">
                                    <ng-select [ngClass]="{'cu-pointer': isReadOnly}" [(ngModel)]="selectedJOb" [placeholder]="'Select Job Title'"
                                        [attr.disabled]="isReadOnly ? true : null" (change)="onChange($event)">
                                        <ng-option *ngFor="let job of RecruiterJobList" [value]="job.ID">
                                            {{ job.Title?.DisplayValue }} - {{ job.RefNo }}
                                        </ng-option>
                                    </ng-select>
                                    <div class="alert-msg">
                                        <span *ngIf="IsjobSelected == false">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Job Title is required.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="button" class="btn blue-button" (click)="ACTIVEMODEL='Register'">Register
                                    a Job</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                        <div class="dropdown-container">
                        <form name="postJobForm" [formGroup]="postJobForm" novalidate>
                            <div class="">
                                <div class="section">
                                    <div class="flex-box mb-4">                                   
                                        <div> <span *ngIf="this.selectedJobDetails == null || selectedJobDetails == undefined" class="section-title">Job title
                                            </span>
                                            <span *ngIf="!!selectedJobDetails" class="section-title">
                                                Job title - {{selectedJobDetails.Title.DisplayValue}}
                                            </span>
                                        </div>
                                        <div> <span *ngIf="this.selectedJobDetails == null || selectedJobDetails == undefined"
                                                class="section-title">Reference
                                                Number</span>                
                                            <span *ngIf="!!selectedJobDetails" class="section-title">Reference
                                                Number - {{this.selectedJobDetails.RefNo}}</span>
                                        </div>                           
                                    </div>                    
                                    <div class="row">
                                    </div>
                                </div>
                                <div class="section mb-4">
                                    <div class="row">
                                        <div class="col-md-4">
                                        <label for="stateSelect" class="label-text">Select Province<sup class="required">*</sup></label>
                                        <ng-select [ngClass]="{'cu-pointer': isReadOnly}" [placeholder]="'Select Province'" [attr.disabled]="isReadOnly ? true : null"  formControlName="State" (ngModelChange)="changeCity($event)">
                                            <ng-option *ngFor="let state of StateList" [value]="state.ID">
                                                {{ state.DisplayValue }}
                                            </ng-option>
                                        </ng-select>
                                        <div  class="alert-msg"
                                        *ngIf="postJobForm.controls['State'].dirty || postJobForm.controls['State'].touched " >
                                            <span *ngIf="postJobForm.get('State').hasError('required')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Province is required.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="citySelect" class="label-text">Select City<sup class="required">*</sup></label>
                                        <ng-select [ngClass]="{'cu-pointer': isReadOnly}" [placeholder]="'Select City'" formControlName="City" [attr.disabled]="isReadOnly ? true : null">
                                            <ng-option *ngFor="let city of CityList" [value]="city.ID">
                                                {{ city.DisplayValue }}
                                            </ng-option>
                                        </ng-select>                            
            
                                        <div  class="alert-msg"
                                        *ngIf="postJobForm.controls['City'].dirty || postJobForm.controls['City'].touched " >
                                            <span *ngIf="postJobForm.get('City').hasError('required')">
                                                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> City is required.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="jobTypeSelect" class="label-text">Select Job Type<sup class="required">*</sup></label>
                                        <ng-select [ngClass]="{'cu-pointer': isReadOnly}" [attr.disabled]="isReadOnly ? true : null" [placeholder]="'Select Job Type'" [searchable]="false"  formControlName="Type"> 
                                            <ng-option *ngFor="let job of JobTypeList" [value]="job.ID">
                                                {{ job.DisplayValue }}
                                            </ng-option>
                                        </ng-select>                            
                                            <div  class="alert-msg"
                                            *ngIf="postJobForm.controls['Type'].dirty || postJobForm.controls['Type'].touched " >
                                                <span *ngIf="postJobForm.get('Type').hasError('required')">
                                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Job Type is required.
                                                </span>
                                        </div>
                                    </div>
                                  </div>
                                 
                                
                                </div>
                                <div class="section mb-4">
                                    <div class="row">
                                      <!-- First Section -->
                                      <div class="col-md-4 col-xs-12">
                                        <label class="label-text">Company Name:<sup class="required">*</sup></label>
                                        <div class="d-flex urgent-radio">
                                              <label for="opt4" class="radio" [ngClass]="{'cu-pointer' : isReadOnly == true}">
                                                <input type="radio" id="opt4" [attr.disabled]="isReadOnly == true ? true : null"
                                                  name="IsShowCompanyName" class="firstname hidden" [value]="1" formControlName="IsShowCompanyName">
                                                <span class="label"></span><span class="rdobtn-span user-post">Show</span>
                                              </label>
                                           
                                          
                                              <label for="opt5" class="radio" [ngClass]="{'cu-pointer' : isReadOnly == true}">
                                                <input type="radio" id="opt5" [attr.disabled]="isReadOnly == true ? true : null"
                                                  name="IsShowCompanyName" class="hidden firstname ml-hidden user-post compny-radio"
                                                  [value]="0" formControlName="IsShowCompanyName">
                                                <span class="label"></span><span class="rdobtn-span user-post">Hidden</span>
                                              </label>
                                            </div>
                                         
                                          <div class="alert-msg"
                                            *ngIf="postJobForm.controls['IsShowCompanyName'].dirty || postJobForm.controls['IsShowCompanyName'].touched ">
                                            <span *ngIf="postJobForm.get('IsShowCompanyName').hasError('required')">
                                              <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Select the visibility status of the Company.
                                            </span>
                                          </div>
                                        
                                      </div>
                                  
                                      <!-- Second Section -->             
                                      <div class="col-md-4 col-xs-12">
                                        <label class="label-text">My Name:<sup class="required">*</sup></label>
                                        <div class="d-flex urgent-radio">
                                              <label for="opt1" class="radio" [ngClass]="{'cu-pointer' : isReadOnly == true}">
                                                <input type="radio" id="opt1" [attr.disabled]="isReadOnly == true ? true : null"
                                                  name="IsShowRecruiterName" class="hidden firstname" [value]="1"
                                                  formControlName="IsShowRecruiterName">
                                                <span class="label"></span><span class="rdobtn-span user-post">Show</span>
                                              </label>
                                           
                                          
                                              <label for="opt2" class="radio" [ngClass]="{'cu-pointer' : isReadOnly == true}">
                                                <input type="radio" id="opt2" [attr.disabled]="isReadOnly == true ? true : null"
                                                  name="IsShowRecruiterName" class="hidden firstname" [value]="0"
                                                  formControlName="IsShowRecruiterName">
                                                <span class="label"></span><span class="rdobtn-span user-post">Hidden</span>
                                              </label>
                                            </div>
                                         
                                          <div   class="alert-msg"
                                            *ngIf="postJobForm.controls['IsShowRecruiterName'].dirty || postJobForm.controls['IsShowRecruiterName'].touched ">
                                            <span *ngIf="postJobForm.get('IsShowRecruiterName').hasError('required')">
                                              <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Select the visibility status of Recruiter Name.
                                            </span>
                                          </div>
                                       
                                      </div>

                                      <!-- Third Section -->  
                                      <div class="col-md-4 col-xs-12">
                                        <label class="label-text">Urgent:<sup class="required">*</sup></label>
                                        <div class="d-flex urgent-radio">
                                            <label for="IsUYes" class="radio" [ngClass]="{'cu-pointer' : isReadOnly == true}">
                                                <input type="radio" id="IsUYes" [attr.disabled]="isReadOnly == true ? true : null" name="IsUrgent"
                                                    formControlName="IsUrgent" class="hidden firstname" [value]="1">
                                                <span class="label"></span><span class="rdobtn-span user-post">Yes</span>
                                            </label>
                                            <label for="IsUNo" class="radio" [ngClass]="{'cu-pointer' : isReadOnly == true}">
                                                <input type="radio" id="IsUNo" [attr.disabled]="isReadOnly == true ? true : null"
                                                    name="IsShowRecruiterName" class="hidden firstname" name="IsUrgent" formControlName="IsUrgent"
                                                    [value]="0">
                                                <span class="label"></span><span class="rdobtn-span user-post">No</span>
                                            </label>
                                        </div>
                                        <div class="alert-msg"
                                            *ngIf="postJobForm.controls['IsUrgent'].dirty || postJobForm.controls['IsUrgent'].touched ">
                                            <span *ngIf="postJobForm.get('IsUrgent').hasError('required')">
                                                <i class="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>Select Job Priority status.
                                            </span>
                                        </div>
                                
                                        <!-- <input type="radio"  [value]="1">
                                                                                                        <p class="user-post">Yes </p>
                                                                                                        <input type="radio" >                                                            <p class="user-post">No </p> -->
                                      </div>
                                    </div>
                                </div>
                                <div class="section mb-4">
                                    <div class="row">                                                    
                                        <div class="col-md-12">                                      
                                                <div class="d-flex">
                                                    <div class="">
                                                        <div>
                                                            <label class="label-text">Salary:</label>
                                                        </div>                                            
                                                        <div class="mobile-flex">
                                                            <ng-select [ngClass]="{'cu-pointer': isReadOnly}" class="drop5 ml-0 mb-2" formControlName="SalaryTo"
                                                                [attr.disabled]="IsDisabled ? true : null" [placeholder]="'Salary From'">
                                                                <ng-option *ngFor="let salaryfrom of SalaryFromList" [value]="salaryfrom.ID">
                                                                    {{ salaryfrom.DisplayValue }}
                                                                </ng-option>
                                                            </ng-select>
                                                            <ng-select [ngClass]="{'cu-pointer': isReadOnly}" class="drop5 mb-2" formControlName="SalaryFrom"
                                                                [attr.disabled]="IsDisabled ? true : null" [placeholder]="'Salary To'">
                                                                <ng-option *ngFor="let salaryto of SalaryToList" [value]="salaryto.ID">
                                                                    {{ salaryto.DisplayValue }}
                                                                </ng-option>
                                                            </ng-select>
                                                            <div [ngClass]="{'cu-pointer' : isReadOnly == true}" class="input-check d-flex align-items-center ml-4">
                                                                <div class="form-group" [ngClass]="{'cu-pointer' : isReadOnly == true}">
                                                                    <input type="checkbox" formControlName="MarketRelated"
                                                                        [attr.disabled]="isReadOnly == true ? true : null" (change)="selectMarket($event)"
                                                                        id="box-2">
                                                                    <label for="box-2" [ngClass]="{'cu-pointer' : isReadOnly == true}"></label>
                                            
                                                                </div>
                                                                <span class="rdobtn-span user-post">Market Related
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <label for="MRL" class="radio">
                                                                                        <input type="radio" id="MRL"   (change)="selectMarket($event)"
                                                                                        name="MarketRelated" formControlName="MarketRelated" [value]="1" class="hidden firstname" >  
                                                                                            <span class="label"></span><span class="rdobtn-span user-post">Market Raleted</span>
                                                                                        </label> -->
                                                
                                                    <div class="alert-msg"
                                                        *ngIf="postJobForm.controls['SalaryFrom'].dirty || postJobForm.controls['SalaryTo'].touched ">
                                                        <span *ngIf="postJobForm.get('SalaryTo').hasError('required')">
                                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Please select it is urgent or not.
                                                        </span>
                                                    </div>
                                                </div>                            
                                        </div>
                                    </div>
                                </div>
                                <div class="section">
                                    <div class="row">
                                        <div class="col-md-12 text-left">
                                            <label for="" class="label-text">Description<sup class="required">*</sup></label>
                                            <textarea cols="8" rows="3"
                                              formControlName="Description" [attr.disabled]= "isReadOnly == true ? true : null"  placeholder="Enter description"></textarea>
                                              <div  class="alert-msg" 
                                              *ngIf="postJobForm.controls['Description'].dirty || postJobForm.controls['Description'].touched " >
                                                  <span *ngIf="postJobForm.get('Description').hasError('required')">
                                                          <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Description is required.
                                                  </span>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>    
                    </div>
                    <div class="text-right button-container">
                        <div *ngIf="isReadOnly == null || isReadOnly == undefined" class="">
                            <button type="button" class="btn blue-bottom-button" (click)="PostJob()">Post
                                Advert</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </div>
    <app-register-job [ACTIVEMODEL]="ACTIVEMODEL"
        (hideModel)="hidenModel($event)"></app-register-job>
</section>