import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { from } from 'rxjs';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HeaderComponent } from './header.component';



@NgModule({

    declarations: [HeaderComponent],
    imports: [
        AngularFontAwesomeModule,
        CommonModule,
 RouterModule

    ],
    entryComponents: [],
    exports: [
        HeaderComponent
    ]
})

export class HeaderModule {

}
