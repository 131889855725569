import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "../app.service";
import { PageModel, PageScreen, ConfigPageModel, SeoDetailModel } from "../core/core.class";
import { CoreService } from "../core/core.service";
import { QueryType } from "../app.class";

@Component({
    selector: 'app-candidates-view',
    templateUrl: './candidateslist.component.html',
    styleUrls: ['./candidateslist.component.scss']
})

export class CandidateslistComponent {
    currentUser: any;
    AllListData: any;
    RecruiterJobList: any;
    BindListData: any;
    closeResult: string;
    ACTIVEMODEL = '';
    selectedJobDetails: any;
    paidJobDetails: any = [];

    pageDetails = new PageModel(PageScreen.CANDIDATELIST);
    config: ConfigPageModel = new ConfigPageModel();

    routeJobId: any;
    
    SList: Array<SeoDetailModel> = new Array<SeoDetailModel>();

    ngAfterViewInit(): void {
    }
    constructor(private appService: AppService, private router: Router, private coreService: CoreService,
        public _activeRoute: ActivatedRoute) {
        if (localStorage.getItem("UserInfo") != null && localStorage.getItem("UserInfo") != undefined) {
            this.appService.ExecuteRAWQuery(`select * from SeoConfiguration`)
            .subscribe((data: any) => {
                if (!!data && !!data.Result) {
                    this.SList = data.Result;
                    if (this.SList.length >= 1) {
                        this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2500));
                    }
                }
            })
            this.currentUser = JSON.parse(localStorage.getItem("UserInfo"));
            this.getJobDataByRecruiterId();
            // this.getListData();
        }
        this._activeRoute.params.forEach(params => {
            const routeJobId = params['JOBID'];
            if (!!routeJobId) {
                this.routeJobId = Number(routeJobId);
            }
        });
    }
    setSeo() {
        if (this.SList.length >= 1) {
            this.coreService.setSeoTagsToScreen(this.coreService.getSeoTagsFromList(this.SList, 2500));
        } 
    }
    sendMessage(candidate) {
        let photo=candidate.Photo
        localStorage.setItem("photo1",photo)
        this.router.navigate(['/messages/'+candidate.UserID]);
    }
    private getJobDataByRecruiterId() {

        let data = {
            "QueryObjectID": "JobInfo",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "CreatedBy",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.currentUser.ID,
                    },
                ]
            },
            "Sort": [
                {
                    "FieldID": "ID",
                    "SortSequence": 2
                }
            ]
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.RecruiterJobList = response.Result;
                if (!!this.routeJobId) {
                    let Job = this.RecruiterJobList.filter(x => x.ID == this.routeJobId);
                    if (Job.length > 0) {
                        this.selectedJobDetails = Job[0];
                        this.getListData();
                    }
                } else {
                    this.selectedJobDetails = this.RecruiterJobList[0];

                    this.getListData();
                }
            }
        });

    }

    changed(job) {
        let Job = this.RecruiterJobList.filter(x => x.ID == job);
        if (Job.length > 0) {
            this.selectedJobDetails = Job[0];
            this.getListData();
        }

    } 
    private getListData() {
        let data = {
            "QueryObjectID": "CandidateContactList",
            "QueryType": 0,
            "ResultField_AppfieldIds": [],
            "LoadLookUpValues": true,
            "WhereClause": {
                "FilterLogic": "1",
                "Filters": [
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "JobID",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": this.selectedJobDetails.ID,
                    },
                    {
                        "ConjuctionClause": 1,
                        "FieldID": "IsPaymentDone",
                        "RelationalOperator": 3,
                        "ValueType": 0,
                        "value": "1",
                    },
                ]
            }
        };
        this.appService.executeQuery(data, QueryType.Select).subscribe(response => {
            if (response.StatusCode == "200") {
                this.paidJobDetails = response.Result;
            }
            this.setPageD();
        });
    }

    //page config
    private setPageD() {
        this.pageDetails = this.coreService.getScreenP(PageScreen.CANDIDATELIST);
        this.config = {
            currentPage: this.pageDetails.currentPage,
            itemsPerPage: this.pageDetails.itemsPerPage,
            totalItems: this.pageDetails.totalItems
        };
    }
    pageChanged(event) {
        this.config.currentPage = event;
    }
    ngOnDestroy(): void {
        this.pageDetails = Object.assign(this.config);
        this.pageDetails.screenName = PageScreen.CANDIDATELIST;
        this.coreService.pageChangeHelper(this.pageDetails, PageScreen.CANDIDATELIST);
    }
}

