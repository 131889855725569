import { NgModule, Component } from '@angular/core';
import { RouterModule } from '@angular/router';


import { from } from 'rxjs';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MenubarModule } from '../components/menubaar/aurthentication/menubar.module';
import { CommonModule } from '@angular/common';

import { RegisterJobModule } from '../register-job/register-job.module';
import { FooterGreyModule } from '../components/footer-grey/footer-grey.module';
import { SelectedCandidateContactListComponent } from './selected-contactlist.component';
// import { HeaderComponent } from '../../components/toolbar/aurthentication-toolbar/header.component';



@NgModule({

    declarations: [SelectedCandidateContactListComponent],
    imports: [
        AngularFontAwesomeModule,
        RouterModule.forChild([
            { path: '', component: SelectedCandidateContactListComponent, pathMatch: 'full' }
        ]),
        MenubarModule,
        CommonModule,
        FooterGreyModule,
        RegisterJobModule,
    ],
    entryComponents: [],
    exports: [
        SelectedCandidateContactListComponent
    ]
})

export class SelectedCandidateContactListModule {

}
