<section id="candidate-profile" class="candidate-page">
    <div class="candidate-page-container">
                <div  class="row heading-container justify-content-center">
                    <h2 class="heading w-75">
                        View and contact the candidates you have chosen. You can
                        pick a maximum of 10 candidates to contact at one time.
                    </h2>
                </div>
            <div class="col-md-12">
                <div class="col-md-4">
                    <!-- register-->
                    <div class="searchbar-container mb-5">
                        <div class="">
                            <div class="">
                                <div class="">
                                    <label class="label-text mb-4" href="#popup1" style="padding-left: 0px;">Registered
                                        Job:*</label>
                                </div>
                                <div class="mb-4">
                                    <ng-select class="drop" [(ngModel)]="selectedJob" (ngModelChange)="onJobChange($event)"
                                        [placeholder]="'Select Job Title'">
                                        <ng-option *ngFor="let job of jobList" [value]="job.ID">
                                            {{ job.Title?.DisplayValue }} - {{ job.RefNo }}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="text-center">
                                    <button type="button" class="btn blue-button" (click)="ACTIVEMODEL='Register'"> Register
                                        a Job</button>                                                                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="search-inner mb-4">
                            <input type="search" class="serach-m" placeholder="Search Candidates" value="" name="q"
                                #filter [formControl]="searchCategory">
                            <img class="serach-icon" src="assets/Background/Vector.png" alt="">
                    </div>
                    <div class="search-inner mb-4">
                        <div class="row">
                            <div class="col-md-8">
                                <h5 class="">{{countOfSelected}} candidates selected of 10</h5>
                            </div>
                            <div class="col-md-4 d-flex justify-content-center">
                                <button class="btn blue-button" (click)="proceedPayment()" data-toggle="modal" data-target="#paymentmodal">
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                        <div class="container">
                            <form action="">
                                <div class="row">
                                    <div class="container">
                                        <div class="text-center">
                                            <h2 class="view1">
                                                View and contact the candidates you have
                                                chosen. You can pick a maximum of 10
                                                candidates to contact at one time.
                                            </h2>
                                        </div>
                                        <div class="container mt-4">
                                            <div class="mycandidate mob-d-none">
                                                My Candidates
                                            </div>
                                            <div class="search-btn d-flex justify-content-center">
                                                <button *ngIf="IsSearch == false" (click)="IsSearch = true" class="search-btn1 search-width btn-grad-border">
                                                    search
                                                </button>
                                                <button *ngIf="IsSearch == true" (click)="IsSearch = false" class="search-btn1 search-width btn-grad-border">
                                                    close
                                                </button>
                                            </div>
                                         
                                            <div class="search-proceed">
                                                <div class="row">
                                                </div>
                                                <!--row-->
                                            </div>
                                            <!--search-->
                                        </div>
                                        <div class="marauto">
                                            <div class="row">             
                                                <div class="col-xs-12 lg-d-none" >
                                                    <div class="search-inner" [ngClass]="{'mob-d-none': IsSearch == false, 'mob-d-show' : IsSearch == true}">
                                                        <input type="search" class="serach-m" placeholder="Search Candidates" value="" name="q"
                                                            #filter [formControl]="searchCategory">
                                                        <img class="serach-icon" src="assets/Background/Vector.png" alt="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">                                          
                                                <div class="col-xs-12 p-0">
                                                    <div class="name1 no-border w-100">
                                                        <h5>{{countOfSelected}} candidates selected of 10</h5>
                                                        <button class="btn blue-button" (click)="proceedPayment()"
                                                            data-toggle="modal" data-target="#paymentmodal">
                                                            Proceed
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="take-center col-12">
                                        <div class="col-md-12">                                          
                                            <div *ngIf="this.paymentJobList.length == 0" class="row heading-container justify-content-center">
                                                <h1 class="no-record-heading"> No such records found. Please try again later</h1>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div class="col-md-12 p-0 m-0" *ngFor="let data of paymentJobList">                                  
                                            <div class="name no-border mb-5">
                                                <div class="row">
                                                    <div class="hide-seek col-12">
                                                        <div class="col-md-3 col-sm-4 col-xs-4
                                                            text-center
                                                            alin-img2">
                                                            <div class="infoimage text-center">
                                                                <div class="otherinfo">
                                                                    <img *ngIf="data.Photo == null || data.Photo == undefined"
                                                                        src="assets/Background/Ellipse.png" class="imageeee
                                                                        name-img">
                
                                                                    <img *ngIf="!!data.Photo" src="{{data.Photo}}" class="imageeee
                                                                    name-img">
                                                                </div>
                
                                                            </div>
                                                            <!-- <img class="name-img"
                                                                        src="assets/Background/name.png"
                                                                        alt=""> -->
                                                        </div>
                                                        <div class="col-md-6 col-sm-8 col-xs-8">
                                                            <div class="row">
                
                                                                <div class="col-md-9" style="padding-top: 12px;">
                                                                    <div class="first-name">
                                                                        <span class="border-text"> <label for="" class="firlast"
                                                                                [routerLink]="'/candidate-profile/' + data.UserID + '/' + data.PersonID + '/' + data.CandidateID + '/' + selectedJob ">{{data.FirstName}}
                                                                                {{data.LastName}}</label></span>
                
                                                                        <p class="location" style="margin-top: 5px;">{{data.City}}</p>
                                                                        <p class="message"><em>
                                                                                {{data.Quote}}</em>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3
                                                                    pl-candidate">
                                                                    <div class="dtar">
                                                                        <div class="paddi-in
                                                                        text-center">
                                                                            
                                                                            <small>Unselect
                                                                                Candidate:</small>
                                                                            <br>
                                                                            <!-- <img
                                                                                src="../../assets/Background/Groupright.png"
                                                                                alt=""> -->
                                                                            <div class="form-group">
                                                                                <input type="checkbox" id="{{data.ID}}"
                                                                                    [ngModelOptions]="{standalone: true}"
                                                                                    [(ngModel)]="data.isSelected"
                                                                                    (change)="removeCandidate($event, data.ID)">
                                                                                <label for="{{data.ID}}"></label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="paddi-in
                                                                            text-center">
                                                                            <small>Favorite
                                                                                Candidate:</small>
                                                                            <br>
                                                                            <!-- <img *ngIf="data.IsFav== 0" style="cursor: pointer;" (click)="onFavoriteClick(data)"
                                                                            src="../.././assets/Background/Vector4.png" class="posi1">
                                                                        <img *ngIf="data.IsFav== 1" src="../.././assets/Background/vector5.png" style="cursor: pointer;" (click)="onUnFavoriteClick(data)"
                                                                             class="posi1"> -->
                                                                            <img style="cursor: pointer;" *ngIf="data['IsFav ']== 0"
                                                                                src="../.././assets/Background/Vector4.png"
                                                                                (click)="onFavoriteClick(data)" class="posi1">
                                                                            <img style="cursor: pointer;" *ngIf="data['IsFav ']== 1"
                                                                                src="../.././assets/Background/vector5.jpg"
                                                                                (click)="onFavoriteClick(data)" class="posi1">
                                                                        </div>
                                                                    </div>
                                                                </div>
                
                                                            </div>
                                                            <!----row-->
                
                                                        </div>
                                                        <div class="col-md-3 col-sm-3 minheight p-0">
                                                            <div class="added new-added-flex">
                                                                <div *ngIf="this.paymentJobList.length >0">
                                                                    <h1 class="press mob-d-none">Press here to add to contact
                                                                        list.
                                                                    </h1>
                                                                </div>
                                                                <div class="form-group-circle">
                                                                    <input type="checkbox" id="{{data.CandidateID}}" [(ngModel)]="data.isAdded"
                                                                        [ngModelOptions]="{standalone: true}" (change)="addCandidate($event, data)">
                                                                    <label for="{{data.CandidateID}}"></label>
                                                                </div>
                                                                <h3 *ngIf="data.isAdded == true" class="text-center">
                                                                    Added!
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--hide-seek-->
                                                    <!----col-md-8-->
                                                    <div class="show-star">
                                                        <div class="imaeg-show">
                                                            <div class="row">
                                                                <div class="col-xs-12">
                                                                    <div class="col-xs-3
                                                                        text-center">
                                                                        <div class="infoimage
                                                                            text-center">
                                                                            <div class="otherinfo">
                                                                                <img *ngIf="data.Photo == null || data.Photo == undefined"
                                                                                    src="assets/Background/Ellipse.png" class="imageeee">
                                                                                <img *ngIf="!!data.Photo" src="{{data.Photo}}" class="imageeee">
                                                                            </div>
                
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xs-9">
                                                                        <div class="first-name">
                                                                                <span class="border-text"> <label for="" class="firlast"
                                                                                    [routerLink]="'/candidate-profile/' + data.UserID + '/' + data.PersonID + '/' + data.CandidateID + '/' + selectedJob">{{data.FirstName}}
                                                                                    {{data.LastName}}</label></span>
                
                                                                            <p class="location">{{data.City}}</p>
                                                                            <p class="message"><em>
                                                                                    {{data.Quote}}</em>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xs-12">
                                                                <div class="col-xs-4 b-top
                                                                    text-center">
                                                                    <div class="mt-favorite">
                                                                        <div class="col-xs-6
                                                                            pl-favorite">
                                                                            <small>Favorite
                                                                                Candidate:</small>
                                                                        </div>
                                                                        <div class="col-xs-6">
                                                                            <img style="cursor: pointer;" width="30" *ngIf="data['IsFav ']== 0"
                                                                                src="../.././assets/Background/Vector4.png"
                                                                                (click)="onFavoriteClick(data)" class="posi1">
                                                                            <img style="cursor: pointer;" *ngIf="data['IsFav ']== 1"
                                                                                src="../.././assets/Background/vector5.jpg"
                                                                                (click)="onFavoriteClick(data)" class="posi1"> 
                                                                        </div>
                
                                                                    </div>
                
                                                                </div>
                                                                <div class="col-xs-4 b-left
                                                                    b-top
                                                                    text-center">
                                                                    <div class="mt-favorite">
                                                                        <div class="col-xs-6
                                                                            pl-favorite">
                                                                            <small>Unselect
                                                                                Candidate:</small>
                                                                        </div>
                                                                        <div class="col-xs-6">
                                                                            <!-- <img class="cen-img"
                                                                                src="../../assets/Background/Groupright.png"
                                                                                alt=""> -->
                                                                            <div class="form-group">
                                                                                <input type="checkbox" id="{{data.ID}}"
                                                                                    [(ngModel)]="data.isSelected"
                                                                                    [ngModelOptions]="{standalone: true}"
                                                                                    (change)="removeCandidate($event, data.ID)">
                                                                                <label for="{{data.ID}}"></label>
                                                                            </div>
                                                                        </div>
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-xs-4 b-left
                                                                    b-top minheight">
                                                                    <div class="added-wrapper">
                                                                        <!-- <img src="../../assets/Background/added.png"
                                                                            alt=""> -->
                                                                        <div class="form-group-circle">
                                                                            <input type="checkbox" id="{{data.CandidateID}}" [(ngModel)]="data.isAdded"
                                                                                [ngModelOptions]="{standalone: true}" (change)="addCandidate($event, data)">
                                                                            <label for="{{data.CandidateID}}"></label>
                                                                        </div>
                                                                        <h3 *ngIf="data.isAdded == true" class="text-center added-text">
                                                                            Added!
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>          
                                                </div>
                                            </div>          
                                    </div>
                                </div>
                            </form>
                        </div>
                </div>
            </div>
        </div>
    <!-- register popup -->
    <app-register-job [ACTIVEMODEL]="ACTIVEMODEL" (hideModel)="hidenModel($event)"></app-register-job>

    <!-- proceed payment -->
    <div class="modal" [ngClass]="{modalShow: paymentMODEL == 'payment',
        modalHide: paymentMODEL !== 'payment'}" id="paymentmodal" tabindex="-1" role="dialog"
        aria-labelledby="paymentmodal" aria-hidden="true">
        <div class="modal-dialog make-ver-center model-xl modal-dialog-centered" role="document">
            <div class="modal-content btn1-grad-border">

                <button type="button" (click)="paymentMODEL= ''" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style="float: right;
                        width: 29px;
                        font-size: 26px;outline: none;">×</span>
                </button>


                <div class="modal-body">
                    <div class="mt-to-bt popup">
                        <div class="inner-text">
                            <h2 class="text-center">Payment Summary</h2>
                            <hr class="border113">
                        </div>
                        <div class="summary">
                            <p class="text-center">To retrieve the contact
                                details of your chosen candidates,
                                you will be redirected to complete payment of
                                R
                                <span *ngIf="!!currentUser.SubscriptionPlan">{{this.amountValueForRec}}</span>
                                <span *ngIf="!currentUser.SubscriptionPlan">0</span>
                            </p>
                        </div>
                        <div class="row content">
                            <div class="col-md-12" *ngIf="currentUser.IsVIP == true">
                                <label for="" class="take1">Method of payment</label>
                            </div>
                            <div class="col-md-12">
                                <select class="option option-job" [(ngModel)]="paymentType"
                                    [ngModelOptions]="{standalone: true}" *ngIf="currentUser.IsVIP == true">
                                    <option value="0" disabled>Method of Payment</option>
                                    <option *ngFor="let data of paymentMethod" [value]="data.ID">
                                        {{data.DisplayValue}}
                                    </option>

                                </select>
                                <div _ngcontent-c0="" class="text-center">
                                    <button _ngcontent-c0="" class="btn-grad-filled
                                        grad-btn m-4 rounded-0 btn-font"  (click)="processToPay()"> Proceed </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
<div hidden>
            <!--Sandbox-->
            <!-- <form action="https://sandbox.payfast.co.za/eng/process" method="POST" #form> <input type="hidden"
            name="merchant_id" value="10015419"> <input type="hidden" name="merchant_key" value="s2o90g07oejjt"> -->
            <!--Live-->
            <form action="https://www.payfast.co.za/eng/process" method="POST" #form> 
            <input type="hidden" name="merchant_id" value="14616740"> <input type="hidden" name="merchant_key" value="0z8c1r0oz375o">
            <input
            type="hidden" id="amount" name="amount" value=""> <input type="hidden" name="item_name"
            value="Contact Details"> <input type="hidden" name="payment_method" value="cc"> <input type="hidden"
            name="name_first" value="John"> <input type="hidden" name="name_last" value="Doe">
             <input type="hidden" name="email_address" id="useremail" value="">
            <input type="hidden" name="cell_number" value="0823456789"> 
            <input type="hidden" name="return_url" value="https://recruiter.searchadam.com/after-online-payment"> 
            <!-- <input type="hidden" name="return_url" value="http://techext-002-site60.atempurl.com/after-online-payment"> -->
            <input type="hidden" name="cancel_url" value="" id="cancelurl"> <input type="hidden" name="notify_url"
            value="https://www.yoursite.com/notify"> <button type="submit" id="paypalbtn"
            (click)="form.submit()">Search</button> </form>
</div>
</section>