import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CoreService } from './core/core.service';
import { LoaderService } from './core/loader.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styles: []
})
export class AppComponent implements OnInit {
  showLoader: any = false;
  isLoggedIn: boolean = false;
  private loaderSubscription: Subscription;
  private isLoggedInSubscription: Subscription;
  constructor(private _loaderService: LoaderService,private coreService: CoreService) {
  }

  ngOnInit(): void {
    this.isLoggedInSubscription = this.coreService
      .isLoggedIn()
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      });

    this.loaderSubscription = this._loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
  }

  ngOnDestroy(): void {
    this.isLoggedInSubscription.unsubscribe();
    this.loaderSubscription.unsubscribe();
  }
}
